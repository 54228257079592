import { Typography, Button } from "antd";
import { EditOutlined, CloseCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import cx from "classnames";

import { getAttachmentTypeFromKey } from "common/shared";
import { downloadBlob } from "common/helpers";
import getS3File from "common/getS3File";

import FormInputLabel from "Form/FormInputLabel/FormInputLabel";
import Input from "Input/Input";
import FilePreview from "FilePreview/FilePreview";

export default function FormAttachmentPicker({
  fieldData,
  fieldName,
  nestedFieldParams,
  readOnly,
  updateFieldValue,
  refreshKey,
  topLevelParams,
}) {
  const { setFormState, contextForComputations, form, updateFormContent, selectedAttachmentKeyForPreview } =
    topLevelParams;

  async function downloadAttachment(key) {
    const publicUrl = await getS3File(key.replace("public/", ""));

    const fileBlob = (
      await axios({
        url: publicUrl,
        method: "GET",
        responseType: "blob", // Important
      })
    ).data;

    let fileName = key.split("/").pop();

    await downloadBlob({
      blob: fileBlob,
      fileName,
    });
  }

  function removeAttachmentItem({ fieldName, key }) {
    const newFields = JSON.parse(JSON.stringify(form.fields));
    newFields[fieldName].value = newFields[fieldName].value.filter((x) => x !== key);

    updateFormContent({
      ...form,
      fields: newFields,
    });
  }

  let attachmentsForPreview = [];
  let selectedAttachmentForPreview = undefined;
  if (Array.isArray(fieldData.value)) {
    attachmentsForPreview = fieldData.value.map((key) => {
      return {
        key,
        name: key.replace(`public/${window.apiUser.organisation}/`, ""),
        type: getAttachmentTypeFromKey(key),
      };
    });
  }
  if (selectedAttachmentKeyForPreview) {
    selectedAttachmentForPreview = attachmentsForPreview.find(
      (attachment) => attachment.key === selectedAttachmentKeyForPreview
    );
  }

  function displayInputLabelExtraContent() {
    if (readOnly || fieldData.readOnly) {
      return null;
    }
    return (
      <>
        {" "}
        <Button
          type="clear"
          icon={<EditOutlined />}
          onClick={() => {
            if (nestedFieldParams?.rootFieldName) {
              setFormState({
                fieldUnderEditName: nestedFieldParams.rootFieldName,
                subFieldUnderEditIndex: nestedFieldParams.childIndex,
                subFieldUnderEditName: fieldName,
                isAttachmentPickerOpen: true,
              });
            } else {
              setFormState({
                isAttachmentPickerOpen: true,
                fieldUnderEditName: fieldName,
              });
            }
          }}
        >
          Choose attachments
        </Button>
      </>
    );
  }

  return (
    <>
      {fieldData.allowPreview && selectedAttachmentKeyForPreview && (
        <FilePreview
          attachments={attachmentsForPreview}
          initiallySelectedAttachment={selectedAttachmentForPreview}
          onClose={() => setFormState({ selectedAttachmentKeyForPreview: undefined })}
          organisationId={contextForComputations.organisationDetails.id}
          allowedTypes={["IMAGE"]}
        />
      )}
      <div className="input-group" key={`${fieldName}-attachment-picker-${refreshKey}`}>
        <FormInputLabel fieldData={fieldData} fieldName={fieldName} extraContent={displayInputLabelExtraContent()} />

        <ul className="attachment-items">
          {Array.isArray(fieldData.value) &&
            fieldData.value.map((key, i) => {
              let type = getAttachmentTypeFromKey(key);
              return (
                <li key={i} style={{ marginBottom: fieldData.fieldsPerItem && 10 }}>
                  {!readOnly && !fieldData.readOnly && (
                    <Button
                      type="clear"
                      className="remove-attachment-button"
                      data-cy="remove-attachment-button"
                      icon={<CloseCircleOutlined />}
                      onClick={() => removeAttachmentItem({ key, fieldName })}
                    />
                  )}
                  {fieldData.withLabels && (
                    <Input
                      fullWidth
                      className="attachment-label-input"
                      fireOnChangeWithoutBlur
                      defaultValue={(fieldData.labels || {})[key]}
                      placeholder="Add a label"
                      onChange={(label) => {
                        let labels = JSON.parse(JSON.stringify(fieldData)).labels || {};
                        labels[key] = label;

                        updateFieldValue({
                          fieldName,
                          keyName: "labels",
                          value: labels,
                          nestedFieldParams,
                        });
                      }}
                    />
                  )}
                  {fieldData.fieldsPerItem &&
                    fieldData.fieldsPerItem
                      .filter((extraField) => !extraField.hidden)
                      .map((extraField) => {
                        return (
                          <Input
                            key={extraField.name}
                            className="attachment-extra-field-input"
                            fireOnChangeWithoutBlur
                            defaultValue={(fieldData[`${extraField.name}s`] || {})[key] || ""}
                            placeholder={extraField.label}
                            showBorder
                            onChange={(value) => {
                              let newFieldData = JSON.parse(JSON.stringify(fieldData));
                              let extraFieldPluralName = newFieldData[`${extraField.name}s`] || {};
                              extraFieldPluralName[key] = value;

                              updateFieldValue({
                                fieldName,
                                keyName: [`${extraField.name}s`],
                                value: extraFieldPluralName,
                                nestedFieldParams,
                              });
                            }}
                          />
                        );
                      })}
                  <Typography.Text
                    className={cx("attachment-name", {
                      "clickable-attachment-name": fieldData.allowPreview,
                    })}
                    onClick={() => {
                      if (["IMAGE", "PDF"].includes(type)) {
                        setFormState({
                          selectedAttachmentKeyForPreview: key,
                        });
                      } else {
                        downloadAttachment(key);
                      }
                    }}
                  >
                    {key.replace(`public/${window.apiUser.organisation}/`, "")}
                  </Typography.Text>{" "}
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
}

// export default React.memo(FormAttachmentPicker, (prevProps, nextProps) => {
//   const PROPS_TO_MONITOR = ["readOnly", "refreshKey", "validationErrors", "hiddenFormFieldKeys", "form"];
//   for (let propName of PROPS_TO_MONITOR) {
//     if (prevProps.topLevelParams[propName] !== nextProps.topLevelParams[propName]) {
//       return false;
//     }
//   }

//   return true;
// });
