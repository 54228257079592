import { useEffect } from "react";
import moment from "moment";
import Card from "../../Card/Card";
import withSubscriptions from "common/withSubscriptions";
import { getFeesForClient } from "common/feeHelpers";

import InfoItem from "InfoItem/InfoItem";
import BudgetBar from "BudgetBar/BudgetBar";

import "./ProjectBudget.scss";

export function ProjectBudget({ quotes, project, organisationDetails }) {
  function areAllQuoteLineItemsHourly() {
    let allQuoteLineItemsAreHourly = true;
    let quoteLineItemsCount = 0;
    quotes
      .filter((quote) => quote.projectId === project.id && !quote.isArchived)
      .forEach((quote) => {
        if (quote.isArchived) {
          return;
        }
        quote.lineItems?.items.forEach((quoteLineItem) => {
          if (quoteLineItem.isHourly) {
            return;
          } else {
            allQuoteLineItemsAreHourly = false;
          }
        });
      });

    if (quoteLineItemsCount === 0) {
      allQuoteLineItemsAreHourly = false;
    }

    return allQuoteLineItemsAreHourly;
  }

  let amountInvoiced = project.amountInvoiced || 0;

  let quotesInHours = organisationDetails.settings?.quote?.quotesInHours;

  let spent;
  let budget;
  if (quotesInHours) {
    spent = project.hoursSpent;
    budget = project.hoursBudget;
  } else {
    spent = project.amountSpent;
    budget = project.amountBudget;
  }

  if (!budget) {
    budget = 0;
  }

  if (!spent) {
    spent = 0;
  }

  let allQuoteLineItemsAreHourly = areAllQuoteLineItemsHourly();

  let formattedSpent = quotesInHours ? `${spent} hour${spent === 1 ? "" : "s"}` : window.formatCurrency("GBP", spent);

  let formattedBudget = quotesInHours
    ? `${budget} hour${budget === 1 ? "" : "s"}`
    : window.formatCurrency("GBP", budget);

  return (
    <>
      <Card title="Budget" className="project-budget-card">
        <div className="info-items">
          <div className="info-items-column">
            <InfoItem label="Budget" value={formattedBudget} />

            <InfoItem
              label="Used %"
              value={
                <BudgetBar
                  size="large"
                  hideFinancials={quotesInHours}
                  amountSpent={spent}
                  amountBudget={budget}
                  replacementLabel={allQuoteLineItemsAreHourly ? `Hourly rate - ${formattedSpent} used` : undefined}
                />
              }
            />
          </div>
          {organisationDetails.settings?.invoice?.usesInvoices && (
            <div className="info-items-column">
              <InfoItem label="Invoiced" value={window.formatCurrency("GBP", amountInvoiced)} />
              <InfoItem
                label="Budget invoiced"
                value={
                  <BudgetBar
                    size="large"
                    amountSpent={amountInvoiced}
                    amountBudget={project.amountBudget}
                    hideFinancials={organisationDetails.settings?.general?.hideFinancials}
                  />
                }
              />
            </div>
          )}
        </div>
      </Card>
    </>
  );
}

export default withSubscriptions({
  Component: ProjectBudget,
  subscriptions: ["organisationDetails", "projects", "quotes"],
});
