import React, { useEffect, useCallback } from "react";
import { useGetSetState } from "react-use";
import _ from "lodash";

import { getHiddenFormFields } from "./formHelpers";
import displayFields from "Form/displayFields";
import { checkForLargeAttachments } from "ReportPage/Report/reportHelpers";

import InsertAttachmentModal from "Modals/InsertAttachmentModal/InsertAttachmentModal";

import "./Form.scss";

function Form(props) {
  const { saveUserFields, contextForComputations, readOnly, isFormEditor, refreshKey, validationErrors } = props;

  const debouncedSaveUserFields = useCallback(_.debounce(saveUserFields, 100), []);

  const [getState, setState] = useGetSetState({
    form: null,
    selectedNestedContainerField: null,
    subFieldUnderEditIndex: null,
    subFieldUnderEditName: undefined,
    modalWithFieldsName: null,
    hiddenFormFields: {},
    hiddenFormFieldKeys: "",
    fieldUnderEditName: undefined,
    fieldUnderEditValue: undefined,
    selectedAttachmentKeyForPreview: undefined,
    isAttachmentPickerOpen: false,
  });

  const {
    form,
    selectedNestedContainerField,
    subFieldUnderEditIndex,
    subFieldUnderEditName,
    modalWithFieldsName,
    hiddenFormFields,
    hiddenFormFieldKeys,
    fieldUnderEditName,
    fieldUnderEditValue,
    selectedAttachmentKeyForPreview,
    isAttachmentPickerOpen,
  } = getState();

  useEffect(() => {
    const formFromState = getState().form;
    if (!props.form || formFromState) {
      return;
    }
    setState({ form: props.form });
    computeAndSetHiddenFormFields({ form: props.form });
  }, [props.form]);

  const updateFormContent = useCallback((newForm) => {
    setState({ form: newForm });
    computeAndSetHiddenFormFields({ form: newForm });
    debouncedSaveUserFields({ form: newForm });
  }, []);

  const setFormState = useCallback((newState) => setState(newState), []);

  async function computeAndSetHiddenFormFields({ form }) {
    let hiddenFormFields = await getHiddenFormFields({
      ...contextForComputations,
      form,
      formPreview: form,
    });

    setState({
      hiddenFormFields,
      hiddenFormFieldKeys: Object.keys(hiddenFormFields).sort().join(","),
    });
  }

  const updateFieldValue = useCallback(async ({ fieldName, value, keyName = "value", nestedFieldParams }) => {
    if (!saveUserFields) {
      return;
    }

    let { rootFieldName, childIndex } = nestedFieldParams || {};
    const form = getState().form;

    let newForm = JSON.parse(JSON.stringify(form));
    let targetField = newForm.fields[rootFieldName];
    if (childIndex !== undefined) {
      if (!Array.isArray(targetField.value)) {
        targetField.value = [{}];
      }
      targetField = targetField.value[childIndex];
      keyName = fieldName;
    } else {
      rootFieldName = fieldName;
    }

    if (!targetField) {
      targetField = newForm.fields[rootFieldName];
    }

    targetField[keyName] = value;

    // console.log({
    //   fieldName,
    //   value,
    //   keyName,
    //   nestedFieldParams,
    //   newForm,
    //   oldform,
    // });
    // console.log("");
    // console.log("");
    // console.log("");

    updateFormContent(newForm);

    // if (targetField?.triggersFormUpdate && recalculateDynamicFormFields) {
    //   await recalculateDynamicFormFields();
    // }
  }, []);

  async function onAttachmentPickerSubmit(attachments, dates, sizes) {
    const { subFieldUnderEditName } = getState();
    // TODO: figure out how to replace this
    // if (this.props.task) {
    //   await fetchAndSetTask.call(this, { id: this.props.match.params.taskId });
    // }

    const newFields = JSON.parse(JSON.stringify(form.fields));
    let fieldData;
    if (subFieldUnderEditIndex !== null) {
      if (subFieldUnderEditName) {
        fieldData = newFields[fieldUnderEditName].fields[subFieldUnderEditName];
      } else {
        fieldData = newFields[fieldUnderEditName].value[subFieldUnderEditIndex];
      }
    } else {
      fieldData = newFields[fieldUnderEditName];
    }

    if (fieldData.renderedInReport !== false) {
      attachments = await checkForLargeAttachments(attachments, sizes);
    }

    if (subFieldUnderEditIndex !== null) {
      if (subFieldUnderEditName) {
        if (!newFields[fieldUnderEditName].value[subFieldUnderEditIndex][subFieldUnderEditName]) {
          newFields[fieldUnderEditName].value[subFieldUnderEditIndex][subFieldUnderEditName] = {};
        }
        newFields[fieldUnderEditName].value[subFieldUnderEditIndex][subFieldUnderEditName].value = attachments;
        newFields[fieldUnderEditName].value[subFieldUnderEditIndex][subFieldUnderEditName].dates = dates;
        newFields[fieldUnderEditName].value[subFieldUnderEditIndex][subFieldUnderEditName].sizes = sizes;
      } else {
        newFields[fieldUnderEditName].value[subFieldUnderEditIndex].value = attachments;
        newFields[fieldUnderEditName].value[subFieldUnderEditIndex].dates = dates;
        newFields[fieldUnderEditName].value[subFieldUnderEditIndex].sizes = sizes;
      }
    } else {
      newFields[fieldUnderEditName].value = attachments;
      newFields[fieldUnderEditName].dates = dates;
      newFields[fieldUnderEditName].sizes = sizes;
    }

    setState({
      subFieldUnderEditIndex: null,
      fieldUnderEditName: null,
      isAttachmentPickerOpen: false,
    });

    updateFormContent({
      ...form,
      fields: newFields,
    });
  }

  function displayInsertAttachmentPickerModal() {
    const { apiUser, task, quote, invoice, request } = contextForComputations;

    let project = quote?.project || invoice?.project;

    if (!isAttachmentPickerOpen) {
      return null;
    }

    const fieldDetails = form.fields[fieldUnderEditName];

    let editTarget = fieldDetails;
    if (subFieldUnderEditIndex !== null && subFieldUnderEditIndex !== undefined) {
      editTarget = editTarget.value[subFieldUnderEditIndex];
    }

    let allowedFileTypes = fieldDetails.renderedInReport ? ["IMAGE", "PDF"] : undefined;

    let selectedItems = [];

    if (subFieldUnderEditIndex === null) {
      selectedItems = form.fields[fieldUnderEditName].value;
    } else {
      selectedItems = form.fields[fieldUnderEditName].value[subFieldUnderEditIndex].value;
    }

    if (selectedItems && !Array.isArray(selectedItems)) {
      selectedItems = [];
    }

    return (
      <InsertAttachmentModal
        task={task}
        project={project}
        request={request}
        apiUser={apiUser}
        defaultPath={editTarget.defaultPath}
        defaultRelativePath={editTarget.defaultRelativePath}
        onSubmit={(attachments, dates, sizes) => onAttachmentPickerSubmit(attachments, dates, sizes)}
        onClose={() => setState({ isAttachmentPickerOpen: false })}
        selectedItems={selectedItems}
        allowedFileTypes={allowedFileTypes}
      />
    );
  }

  return (
    <>
      {displayInsertAttachmentPickerModal()}
      {displayFields({
        topLevelParams: {
          selectedNestedContainerField,
          subFieldUnderEditIndex,
          subFieldUnderEditName,
          modalWithFieldsName,
          hiddenFormFields,
          hiddenFormFieldKeys,
          fieldUnderEditName,
          fieldUnderEditValue,
          selectedAttachmentKeyForPreview,
          isAttachmentPickerOpen,
          form,
          isFormEditor,
          readOnly,
          updateFieldValue,
          contextForComputations,
          refreshKey,
          setFormState,
          validationErrors,
          updateFormContent,
        },
      })}
    </>
  );
}

export default React.memo(Form, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["readOnly", "refreshKey"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps[propName] !== nextProps[propName]) {
      return false;
    }
  }
  return true;
});
