import { useState, useEffect } from "react";
import moment from "moment";
import { Modal, Button, Typography } from "antd";
import { UploadOutlined, CloseCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { processIdForDisplay } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";

import Explanation from "Explanation/Explanation";
import Avatar from "Avatar/Avatar";
import RichTextDisplay from "RichTextDisplay/RichTextDisplay";
import Textarea from "Form/Textarea/Textarea";

import "./ActivityItem.scss";

export default function ActivityElement({
  activityItem,
  users,
  index,
  fetchActivityItems,
  parentLabel,
  apiUser,
  clients,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [initialCommentValue, setInitialCommentValue] = useState();

  useEffect(() => {
    if (activityItem.type === "COMMENT") {
      try {
        let parsedContent = JSON.parse(activityItem.content);
        setInitialCommentValue(parsedContent);
      } catch (error) {
        setInitialCommentValue(undefined);
      }
    } else {
      setInitialCommentValue(undefined);
    }
  }, [activityItem.content]); // eslint-disable-line react-hooks/exhaustive-deps

  const [commentValue, setCommentValue] = useState(activityItem.content);

  async function updateComment() {
    let mutationName;
    if (parentLabel === "Task") {
      mutationName = "updateTaskActivityItem";
    } else if (parentLabel === "Project") {
      mutationName = "updateActivityItem";
    }

    await callGraphQLSimple({
      message: "Failed to update comment",
      mutation: mutationName,
      variables: {
        input: {
          id: activityItem.id,
          content: commentValue,
        },
      },
    });
    setIsEditing(false);
    fetchActivityItems();
  }
  function confirmDeleteComment(activityItem) {
    Modal.confirm({
      title: "Are you sure you want to delete this comment?",
      content: "This action cannot be undone.",
      onOk: async () => {
        if (parentLabel === "Task") {
          await callGraphQLSimple({
            message: "Failed to delete comment",
            mutation: "deleteTaskActivityItem",
            variables: {
              input: {
                id: activityItem.id,
              },
            },
          });
        } else if (parentLabel === "Project") {
          await callGraphQLSimple({
            message: "Failed to delete comment",
            mutation: "deleteActivityItem",
            variables: {
              input: {
                id: activityItem.id,
              },
            },
          });
        }
        fetchActivityItems();
      },
    });
  }
  function getActivityDetails({ activityItem, users }) {
    let title;
    let content;

    switch (activityItem.type) {
      case "PUBLIC_UPLOAD":
        title = <>Direct attachment upload from an external person</>;
        break;
      case "LIFECYCLE_EVENT":
        const parsedContent = JSON.parse(activityItem.content);
        switch (parsedContent.type) {
          case "ASSIGNEE_CHANGED":
            title = (
              <>
                Resposible senior engineer changed:
                <br />
                From: <Avatar user={users.find((x) => x.id === parsedContent.oldAssignedTo)} showLabel />
                <br />
                To:
                <Avatar user={users.find((x) => x.id === parsedContent.assignedTo)} showLabel />
              </>
            );
            break;
          case "CLIENT_CHANGED":
            title = (
              <>
                {getSimpleLabel("Client")} changed <br />
                From: {parsedContent.oldClientName}
                <br />
                To: {parsedContent.clientName}
              </>
            );
            break;
          case "PO_NUMBER_CHANGED":
            title = (
              <>
                PO number changed <br />
                From: {parsedContent.oldPoNumber}
                <br />
                To: {parsedContent.poNumber}
              </>
            );
            break;
          case "TASK_COUNT_CHANGED":
            title = (
              <>
                {getSimpleLabel("Task")} count changed <br />
                From: {parsedContent.oldTaskCount}
                <br />
                To: {parsedContent.taskCount}
              </>
            );
            break;
          case "INVOICE_COUNT_CHANGED":
            title = (
              <>
                Invoice count changed <br />
                From: {parsedContent.oldInvoiceCount}
                <br />
                To: {parsedContent.invoiceCount}
              </>
            );
            break;
          case "QUOTE_COUNT_CHANGED":
            title = (
              <>
                {getSimpleLabel("Quote")} count changed <br />
                From: {parsedContent.oldQuoteCount}
                <br />
                To: {parsedContent.quoteCount}
              </>
            );
            break;
          case "PURCHASE_ORDER_COUNT_CHANGED":
            title = (
              <>
                {getSimpleLabel("Purchase order")} count changed <br />
                From: {parsedContent.oldPurchaseOrderCount}
                <br />
                To: {parsedContent.purchaseOrderCount}
              </>
            );
            break;
          case "PUBLIC_UPLOAD_LINK_COPIED":
            title = <>Public upload link copied</>;
            break;
          case "PUBLIC_UPLOAD_EMAIL_SENT":
            title = <>Public upload email sent to {parsedContent.clientContactDetails?.email}</>;
            break;
          case "PUBLIC_UPLOAD_URL_VIEWED":
            title = <>Design package viewed</>;
            break;
          case "TASK_FILES_DOWNLOADED_INDIVIDUAL":
            title = <>Design package file downloaded: {parsedContent.fileName}</>;
            break;
          case "TASK_FILES_DOWNLOADED_ALL":
            title = <>Design package downloaded</>;
            break;
          case "TASK_REVISION_STATUS_CHANGED":
            title = (
              <>
                Status changed from {parsedContent.oldStatus} to {parsedContent.newStatus} on{" "}
                {parsedContent.taskRevisionName}
              </>
            );
            break;

          case "FILE_DELETED":
            title = (
              <>
                File deleted: {parsedContent.fileType} {parsedContent.fileName}
              </>
            );
            break;

          case "START_DATE_CHANGED":
            let oldStartDate = parsedContent.oldDate
              ? moment(parsedContent.oldDate).format("DD-MM-YYYY")
              : "not being set";
            title = (
              <>
                Start date changed from {oldStartDate} to {moment(parsedContent.newDate).format("DD-MM-YYYY")}
              </>
            );
            break;
          case "END_DATE_CHANGED":
            let oldEndDate = parsedContent.oldDate
              ? moment(parsedContent.oldDate).format("DD-MM-YYYY")
              : "not being set";
            title = (
              <>
                End date changed from {oldEndDate} to {moment(parsedContent.newDate).format("DD-MM-YYYY")}
              </>
            );

            break;
          case "REQUEST_FORM_ADDED_TO_TASK_REVISION":
            title = (
              <>
                {getSimpleLabel("Request")} form "{parsedContent.requestFormName}" from {getSimpleLabel("request")}{" "}
                {processIdForDisplay(parsedContent.requestId)} ({parsedContent.requestTitle}) linked to{" "}
                {getSimpleLabel("task revision")} {parsedContent.taskRevisionName}
              </>
            );
            break;
          case "REQUEST_FORM_REMOVED_FROM_TASK_REVISION":
            title = (
              <>
                {getSimpleLabel("Request")} form "{parsedContent.requestFormName}" from {getSimpleLabel("request")}{" "}
                {processIdForDisplay(parsedContent.requestId)} ({parsedContent.requestTitle}) removed from{" "}
                {getSimpleLabel("task revision")} {parsedContent.taskRevisionName}
              </>
            );
            break;
          case "TASK_FILES_SENT":
            title = (
              <>
                Design package issued{" "}
                <Explanation
                  title={
                    <>
                      <Typography.Paragraph>To: {parsedContent.to}</Typography.Paragraph>
                      {parsedContent.cc && <Typography.Paragraph>CC: {parsedContent.cc}</Typography.Paragraph>}
                      {parsedContent.bcc && <Typography.Paragraph>BCC: {parsedContent.bcc}</Typography.Paragraph>}
                      <Typography.Paragraph>Task revision name: {parsedContent.taskRevisionName}</Typography.Paragraph>
                      {parsedContent.sharing && (
                        <>
                          {parsedContent.sharing?.type === "PUBLIC" && (
                            <Typography.Paragraph>
                              Anyone with the link can access the design package
                            </Typography.Paragraph>
                          )}
                          {parsedContent.sharing?.type === "PASSWORD" && (
                            <Typography.Paragraph>The design package is password-protected</Typography.Paragraph>
                          )}
                          {parsedContent.sharing?.expiresAt &&
                            moment(parsedContent.sharing?.expiresAt).isAfter(moment()) && (
                              <Typography.Paragraph type="danger">
                                Link expires {moment(parsedContent.sharing?.expiresAt).fromNow()}, on{" "}
                                {moment(parsedContent.sharing?.expiresAt).format("DD/MM/YYYY HH:mm:ss")}{" "}
                              </Typography.Paragraph>
                            )}
                          {parsedContent.sharing?.expiresAt &&
                            moment(parsedContent.sharing?.expiresAt).isBefore(moment()) && (
                              <Typography.Paragraph>
                                Link expired {moment(parsedContent.sharing?.expiresAt).fromNow()}, on{" "}
                                {moment(parsedContent.sharing?.expiresAt).format("DD/MM/YYYY HH:mm:ss")}
                              </Typography.Paragraph>
                            )}
                        </>
                      )}
                    </>
                  }
                />
                {parsedContent.emailSentSuccessfully === false && (
                  <Typography.Text type="danger"> (Failed to send email)</Typography.Text>
                )}
              </>
            );
            break;
          default:
            title = <>Lifecycle event</>;
            break;
        }
        break;
      case "COMMENT":
        content = (
          <div className="comment-content">
            {activityItem.author === apiUser.id && (
              <div className="comment-actions">
                {isEditing ? (
                  <>
                    <Button
                      onClick={() => {
                        updateComment();
                      }}
                      type="link"
                      icon={<UploadOutlined />}
                    >
                      Save
                    </Button>
                    <Button type="link" onClick={() => setIsEditing(false)} icon={<CloseCircleOutlined />}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => {
                        setIsEditing(true);
                      }}
                      type="link"
                    >
                      Edit
                    </Button>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        confirmDeleteComment(activityItem);
                      }}
                      type="link"
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
            )}
            {isEditing ? (
              <div className="comment-editor">
                <Textarea
                  onChange={(value) => {
                    setCommentValue(value);
                  }}
                  defaultValue={initialCommentValue}
                  basicFormattingOnly
                />
              </div>
            ) : (
              <RichTextDisplay content={activityItem.content} />
            )}
          </div>
        );
        break;
      case "REVIEW_ACCEPTED":
        break;
      case "CREATED":
        title = <>Created</>;
        break;
      case "FINISHED": {
        title = <>Marked as finished</>;
        break;
      }
      case "ARCHIVED":
        title = <>Archived</>;

        break;
      case "REVISION_CREATED": {
        title = <>Revision created</>;
        const parsedFields = JSON.parse(activityItem.content);

        if (!parsedFields || !parsedFields.revisionName) {
          break;
        }

        content = `${parsedFields.revisionName}`;

        if (parsedFields.revisionDescription) {
          content += ` - ${parsedFields.revisionDescription}`;
        }

        break;
      }
      case "ASSIGNEE_CHANGED": {
        title = <>Assignee changed</>;
        const parsedFields = JSON.parse(activityItem.content);

        if (!parsedFields || !parsedFields.newAssigneeId) {
          content = "Unassigned";
          break;
        }

        const newAssigneeDetails = users.find((x) => x.id === parsedFields.newAssigneeId);
        content = `${newAssigneeDetails.firstName} ${newAssigneeDetails.lastName}`;
        break;
      }
      case "ASSIGNEE_INITIALLY_SET": {
        title = <>Assignee initially set</>;
        const parsedFields = JSON.parse(activityItem.content);

        if (!parsedFields || !parsedFields.newAssigneeId) {
          content = "Unassigned";
          break;
        }

        const newAssigneeDetails = users.find((x) => x.id === parsedFields.newAssigneeId);
        content = `${newAssigneeDetails.firstName} ${newAssigneeDetails.lastName}`;
        break;
      }
      case "STATUS_CHANGED": {
        title = <>Status changed</>;
        const parsedFields = JSON.parse(activityItem.content);
        const newStatus = parsedFields.newStatus;

        content = `${newStatus}`;

        break;
      }
      case "DUE_DATE_CHANGED": {
        title = <>Due date changed</>;
        const parsedFields = JSON.parse(activityItem.content);

        if (!parsedFields || !parsedFields.newDate) {
          break;
        }

        content = `${parsedFields.newDate}`;

        break;
      }
      case "REVISION_DELETED": {
        title = <>Revision deleted</>;
        const parsedFields = JSON.parse(activityItem.content);

        if (!parsedFields || !parsedFields.revisionName) {
          break;
        }

        content = `${parsedFields.revisionName}`;

        break;
      }
      case "TITLE_EDITED": {
        title = <>Title edited</>;
        const parsedFields = JSON.parse(activityItem.content);

        if (!parsedFields || !parsedFields.newTitle) {
          break;
        }

        content = `${parsedFields.newTitle}`;

        break;
      }

      case "DESCRIPTION_EDITED": {
        title = <>Description edited</>;
        const parsedFields = JSON.parse(activityItem.content);

        if (!parsedFields || !parsedFields.newDescription) {
          break;
        }

        break;
      }
      case "RESUMED": {
        title = <>Resumed</>;

        break;
      }
      case "RESTORED": {
        title = <>Restored</>;
        break;
      }
      default:
        break;
    }

    return {
      title,
      content,
    };
  }

  const { title, content } = getActivityDetails({ activityItem, users });

  let authorDetails = users.find((x) => x.id === activityItem.author);
  let authorName: string | null = null;
  let avatar = <Avatar user={authorDetails || "draughthub"} />;

  if (authorDetails) {
    authorName = `${authorDetails.firstName} ${authorDetails.lastName} on `;
  }

  let contentElement: any = null;
  if (content) {
    if (typeof content === "string") {
      contentElement = ` - ${content}`;
    } else {
      contentElement = <div>{content}</div>;
    }
  }

  return (
    <div className="activity-item" data-cy="activity-item" data-index={index}>
      <div className="item-avatar">{avatar}</div>

      <div className="item-content">
        <div className="item-date">
          {authorName}
          {moment(activityItem.createdAt).format("DD MMM YYYY - HH:mm:ss")}
          {activityItem.type === "COMMENT" &&
            activityItem.updatedAt &&
            activityItem.updatedAt !== activityItem.createdAt && (
              <>
                {" "}
                <br /> (edited {moment(activityItem.updatedAt).format("DD MMM YYYY - HH:mm:ss")})
              </>
            )}
        </div>
        {title}
        {contentElement}
      </div>
    </div>
  );
}
