import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Button, Typography, Input, Checkbox, Dropdown, Select, Menu, Switch } from "antd";
import query from "query-string";
import moment from "moment";
import { PlusCircleOutlined, SearchOutlined, DownOutlined, EyeOutlined } from "@ant-design/icons";

import { COOKIE_NAME_INVOICE_FILTERS } from "common/constants";
import withSubscriptions from "common/withSubscriptions";
import { isAuthorised } from "common/permissions";
import { getLabel, getMonthlyDateRangePresets } from "common/helpers";

import DatePicker from "DatePicker/DatePicker";
import AvatarList from "AvatarList/AvatarList";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";
import CreateInvoiceModal from "Modals/CreateInvoiceModal/CreateInvoiceModal";

import "./InvoiceFilters.scss";

const COMBINED_FILTERS_BREAKPOINT = 1080;
const COMBINED_ACTIONS_BREAKPOINT = 760;

export class InvoiceFilters extends React.Component {
  state = {
    containsText: "",
    selectedUsers: [],
    clientId: null,
    projectId: null,
    invoicedAtStart: null,
    invoicedAtEnd: null,
    includeActive: true,
    includeArchived: false,
    onlyReviewsRequested: false,
    onlyReviewsAssigned: false,
    onlyReviewsThatNeedAction: false,
    onlyInvoicesThatNeedSecondReview: false,
    onlyInvoicesWithoutReview: false,
    onlyInvoicesInReview: false,
    onlyReviewedInvoices: false,
    isDropdownVisible: false,
    isCreateInvoiceModalVisible: false,
    includeWithoutPONumber: true,
    includePaidInvoices: true,
    includePartiallyPaidInvoices: true,
    includeWithoutAnyPayment: true,
  };

  async componentDidMount() {
    const { location } = this.props;

    const queryParams = query.parse(location.search);
    let newState = {};

    const cookieValue = await window.localDatabase.getItem(
      `${COOKIE_NAME_INVOICE_FILTERS}-${this.props.organisationDetails.id}`
    );

    if (cookieValue) {
      try {
        const cookieValueParsed = JSON.parse(cookieValue);
        const { isDropdownVisible, isCreateInvoiceModalVisible, ...existingFilters } = cookieValueParsed;
        newState = existingFilters;
      } catch (e) {
        console.error("Failed to parse local database value for InvoiceFilters:", cookieValue);
      }
    }

    if (queryParams.containsText) {
      newState.containsText = queryParams.containsText;
    }

    if (queryParams.selectedUsers) {
      if (Array.isArray(queryParams.selectedUsers)) {
        newState.selectedUsers = queryParams.selectedUsers;
      } else {
        newState.selectedUsers = [queryParams.selectedUsers];
      }
    }

    if (newState.selectedUsers) {
      // eliminate disabled users
      newState.selectedUsers = newState.selectedUsers.filter((userId) => {
        let targetUser = this.props.users.find((user) => user.id === userId);
        return targetUser && !targetUser.isDisabled;
      });
    }

    if (queryParams.includeActive) {
      newState.includeActive = queryParams.includeActive === "true";
    }

    if (queryParams.includeWithoutPONumber) {
      newState.includeWithoutPONumber = queryParams.includeWithoutPONumber === "true";
    }

    if (queryParams.includeArchived) {
      newState.includeArchived = queryParams.includeArchived === "true";
    }

    if (queryParams.onlyReviewsRequested) {
      newState.onlyReviewsRequested = queryParams.onlyReviewsRequested === "true";
    }

    if (queryParams.onlyInvoicesInReview) {
      newState.onlyInvoicesInReview = queryParams.onlyInvoicesInReview === "true";
    }

    if (queryParams.onlyReviewedInvoices) {
      newState.onlyReviewedInvoices = queryParams.onlyReviewedInvoices === "true";
    }

    if (queryParams.onlyReviewsAssigned) {
      newState.onlyReviewsAssigned = queryParams.onlyReviewsAssigned === "true";
    }

    if (queryParams.onlyReviewsThatNeedAction) {
      newState.onlyReviewsThatNeedAction = queryParams.onlyReviewsThatNeedAction === "true";
    }

    if (queryParams.onlyInvoicesThatNeedSecondReview) {
      newState.onlyInvoicesThatNeedSecondReview = queryParams.onlyInvoicesThatNeedSecondReview === "true";
    }

    if (queryParams.onlyInvoicesWithoutReview) {
      newState.onlyInvoicesWithoutReview = queryParams.onlyInvoicesWithoutReview === "true";
    }

    if (queryParams.clientId) {
      newState.clientId = queryParams.clientId;
    }

    if (queryParams.projectId) {
      newState.projectId = queryParams.projectId;
    }

    if (queryParams.includePaidInvoices) {
      newState.includePaidInvoices = queryParams.includePaidInvoices === "true";
    }

    if (queryParams.includePartiallyPaidInvoices) {
      newState.includePartiallyPaidInvoices = queryParams.includePartiallyPaidInvoices === "true";
    }

    if (queryParams.includeWithoutAnyPayment) {
      newState.includeWithoutAnyPayment = queryParams.includeWithoutAnyPayment === "true";
    }

    if (queryParams.invoicedAtStart) {
      newState.invoicedAtStart = queryParams.invoicedAtStart;
    }

    if (queryParams.invoicedAtEnd) {
      newState.invoicedAtEnd = queryParams.invoicedAtEnd;
    }

    this.setState(newState, this.onChange);
  }

  changeDateFilterRange = (dates, datesStr) => {
    let invoicedAtStart = null;
    let invoicedAtEnd = null;

    if (dates) {
      invoicedAtStart = datesStr[0];
      invoicedAtEnd = datesStr[1];
    }

    this.setState(
      {
        invoicedAtStart,
        invoicedAtEnd,
      },
      this.onChange
    );
  };

  onChange = () => {
    const {
      selectedUsers,
      containsText,
      clientId,
      projectId,
      invoicedAtStart,
      invoicedAtEnd,
      includeActive,
      includeArchived,
      includeWithoutPONumber,
      onlyReviewsRequested,
      onlyReviewsAssigned,
      onlyReviewsThatNeedAction,
      onlyInvoicesThatNeedSecondReview,
      onlyInvoicesWithoutReview,
      onlyInvoicesInReview,
      onlyReviewedInvoices,
      includePaidInvoices,
      includeWithoutAnyPayment,
      includePartiallyPaidInvoices,
    } = this.state;

    const { isCreateInvoiceModalVisible, isDropdownVisible, ...stateForQueryParams } = this.state;

    const { history, location, apiUser, organisationDetails } = this.props;

    const nonNullKeys = Object.keys(stateForQueryParams).filter(
      (keyName) =>
        stateForQueryParams[keyName] !== null &&
        stateForQueryParams[keyName] !== undefined &&
        stateForQueryParams[keyName] !== "" &&
        stateForQueryParams[keyName] !== false
    );
    const queryParams = {};
    nonNullKeys.forEach((keyName) => {
      if (moment.isMoment(stateForQueryParams[keyName])) {
        queryParams[keyName] = stateForQueryParams[keyName].format("DD-MM-YYYY");
      } else {
        queryParams[keyName] = stateForQueryParams[keyName];
      }
    });
    const queryString = query.stringify(queryParams);

    history.replace(`${location.pathname}?${queryString}`);

    let usesDoubleInvoiceReview = organisationDetails.settings?.invoice?.usesDoubleInvoiceReview;
    let userIsDoubleReviewer = usesDoubleInvoiceReview && isAuthorised(["INVOICES.PERFORM_SECOND_REVIEW"]);

    let filter = {
      callbacks: [],
    };

    if (!includeActive) {
      filter.callbacks.push((invoice) => invoice.isArchived);
    }

    if (!includeWithoutPONumber) {
      filter.callbacks.push((invoice) => invoice.poNumber);
    }

    if (!includeArchived) {
      filter.callbacks.push((invoice) => !invoice.isArchived);
    }

    if (clientId) {
      filter.callbacks.push((invoice) => {
        return invoice.clientId === clientId;
      });
    }

    if (projectId) {
      filter.callbacks.push((invoice) => {
        return invoice.projectId === projectId;
      });
    }

    if (selectedUsers && selectedUsers.length > 0) {
      filter.callbacks.push((task) => {
        if (selectedUsers.includes("unassigned") && !task.assignedTo) {
          return true;
        } else if (selectedUsers.includes(task.assignedTo)) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (containsText) {
      filter.containsText = {
        contains: (containsText || "").toLowerCase(),
      };
    }

    if (onlyReviewsRequested) {
      filter.callbacks.push((invoice) => {
        return (
          invoice.assignedTo === apiUser.id &&
          (invoice.isUnderReview ||
            (usesDoubleInvoiceReview && !invoice.secondReviewApprovedAt && invoice.reviewApprovedAt))
        );
      });
    }

    if (onlyReviewsAssigned) {
      filter.callbacks.push((invoice) => {
        return (
          invoice.checkedBy === apiUser.id &&
          (invoice.isUnderReview ||
            (usesDoubleInvoiceReview && !invoice.secondReviewApprovedAt && invoice.reviewApprovedAt))
        );
      });
    }

    if (onlyReviewsThatNeedAction) {
      filter.callbacks.push((invoice) => {
        if (
          invoice.checkedBy === apiUser.id &&
          invoice.isUnderReview &&
          (invoice.reviewStatus === "IN_PROGRESS" || invoice.reviewSecondaryStatus)
        ) {
          return true;
        }

        if (
          userIsDoubleReviewer &&
          invoice.reviewApprovedAt &&
          !invoice.secondReviewApprovedAt &&
          invoice.reviewStatus !== "CLOSED"
        ) {
          return true;
        } else if (invoice.reviewStatus === "WITH_COMMENTS" || invoice.reviewStatus === "CHANGES_REQUESTED") {
          return invoice.assignedTo === apiUser.id;
        }

        return false;
      });
    }

    if (onlyInvoicesThatNeedSecondReview) {
      filter.callbacks.push((invoice) => invoice.reviewApprovedAt && !invoice.secondReviewApprovedAt);
    }

    if (onlyInvoicesWithoutReview) {
      filter.callbacks.push((invoice) => !invoice.reviewApprovedAt);
    }

    if (onlyInvoicesInReview) {
      filter.callbacks.push(
        (invoice) =>
          invoice.isUnderReview ||
          (userIsDoubleReviewer &&
            usesDoubleInvoiceReview &&
            !invoice.secondReviewApprovedAt &&
            invoice.reviewApprovedAt)
      );
    }

    if (invoicedAtStart && invoicedAtEnd) {
      filter.callbacks.push((invoice) => {
        return (
          moment(invoicedAtStart, "DD-MM-YYYY").isSameOrBefore(invoice.invoiceDate) &&
          moment(invoicedAtEnd, "DD-MM-YYYY").isSameOrAfter(invoice.invoiceDate)
        );
      });
    }

    if (onlyReviewedInvoices) {
      filter.callbacks.push((invoice) => {
        if (usesDoubleInvoiceReview) {
          return invoice.secondReviewApprovedAt;
        } else {
          return invoice.reviewApprovedAt;
        }
      });
    }

    if (!includePaidInvoices) {
      filter.callbacks.push((invoice) => invoice.amountPaid !== invoice.total);
    }

    if (!includePartiallyPaidInvoices) {
      filter.callbacks.push((invoice) => !(invoice.amountPaid > 0 && invoice.amountPaid < invoice.total));
    }

    if (!includeWithoutAnyPayment) {
      filter.callbacks.push((invoice) => invoice.amountPaid);
    }

    window.localDatabase.setItem(
      `${COOKIE_NAME_INVOICE_FILTERS}-${this.props.organisationDetails.id}`,
      JSON.stringify(this.state)
    );

    this.props.onChange(filter);
  };

  onUserSelected = (_, user) => {
    if (!user) {
      user = { id: "unassigned" };
    }
    let selectedUsers = this.state.selectedUsers || [];
    if (selectedUsers.includes(user.id)) {
      selectedUsers = selectedUsers.filter((x) => x !== user.id);
    } else {
      selectedUsers.push(user.id);
    }
    this.setState({ selectedUsers }, this.onChange);
  };

  render() {
    const {
      containsText,
      selectedUsers,
      includeArchived,
      includeActive,
      includeWithoutPONumber,
      isCreateInvoiceModalVisible,
      isDropdownVisible,
      onlyReviewsRequested,
      onlyReviewsAssigned,
      onlyReviewsThatNeedAction,
      onlyInvoicesThatNeedSecondReview,
      onlyInvoicesInReview,
      onlyReviewedInvoices,
      onlyInvoicesWithoutReview,
      includePaidInvoices,
      includePartiallyPaidInvoices,
      includeWithoutAnyPayment,
      clientId,
      projectId,
    } = this.state;

    const { projects, clients, orderedActiveUsers, windowWidth, organisationDetails } = this.props;

    let usesDoubleInvoiceReview = organisationDetails.settings?.invoice?.usesDoubleInvoiceReview;
    let userIsDoubleReviewer = usesDoubleInvoiceReview && isAuthorised(["INVOICES.PERFORM_SECOND_REVIEW"]);

    let dateRangePresets = {
      ...getMonthlyDateRangePresets(),
    };

    let invoicedAtStart = null;
    let invoicedAtEnd = null;
    if (
      this.state.invoicedAtStart &&
      this.state.invoicedAtEnd &&
      typeof this.state.invoicedAtStart === "string" &&
      typeof this.state.invoicedAtEnd === "string" &&
      this.state.invoicedAtStart.length <= 10 &&
      this.state.invoicedAtEnd.length <= 10
    ) {
      invoicedAtStart = moment(this.state.invoicedAtStart, "DD-MM-YYYY");
      invoicedAtEnd = moment(this.state.invoicedAtEnd, "DD-MM-YYYY");
    }

    let mobileFilters = null;

    if (windowWidth <= COMBINED_FILTERS_BREAKPOINT) {
      mobileFilters = (
        <>
          <Menu.Item>
            <Input
              className="filter-input"
              placeholder="Filter invoice list"
              allowClear
              prefix={<SearchOutlined />}
              onChange={(e) => this.setState({ containsText: e.target.value }, this.onChange)}
              value={containsText}
            />
          </Menu.Item>
          <Menu.Item>
            <AvatarList
              users={orderedActiveUsers}
              onClick={this.onUserSelected}
              selectedUsers={selectedUsers}
              minAvatarsToDisplay={10}
              maxAvatarsToDisplay={10}
            />
          </Menu.Item>
        </>
      );
    }

    const filterMenu = (
      <Menu>
        {mobileFilters}
        <Menu.Item key="include-active">
          <Checkbox
            checked={includeActive}
            onChange={(e) => this.setState({ includeActive: e.target.checked }, this.onChange)}
          >
            Include active
          </Checkbox>
        </Menu.Item>

        <Menu.Item key="include-archived">
          <Checkbox
            checked={includeArchived}
            onChange={(e) => this.setState({ includeArchived: e.target.checked }, this.onChange)}
          >
            Include archived
          </Checkbox>
        </Menu.Item>

        <Menu.Item key="include-without-po-number">
          <Checkbox
            checked={includeWithoutPONumber}
            onChange={(e) => this.setState({ includeWithoutPONumber: e.target.checked }, this.onChange)}
          >
            Include without PO number
          </Checkbox>
        </Menu.Item>

        <Menu.Item key="include-fully-paid">
          <Checkbox
            checked={includePaidInvoices}
            onChange={(e) => this.setState({ includePaidInvoices: e.target.checked }, this.onChange)}
          >
            Include fully paid invoices
          </Checkbox>
        </Menu.Item>

        <Menu.Item key="include-partially-paid">
          <Checkbox
            checked={includePartiallyPaidInvoices}
            onChange={(e) => this.setState({ includePartiallyPaidInvoices: e.target.checked }, this.onChange)}
          >
            Include partially paid invoices
          </Checkbox>
        </Menu.Item>

        <Menu.Item key="include-without-any-payment">
          <Checkbox
            checked={includeWithoutAnyPayment}
            onChange={(e) => this.setState({ includeWithoutAnyPayment: e.target.checked }, this.onChange)}
          >
            Include without any payment
          </Checkbox>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item key="invoiced-in-range">
          <div className="invoiced-in-range-container">
            <Typography.Text className="top-label">Invoiced in range:</Typography.Text>
            <br />
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              dropdownClassName="task-created-date-range-picker"
              value={[
                typeof invoicedAtStart === "string" ? moment(invoicedAtStart) : invoicedAtStart,
                typeof invoicedAtEnd === "string" ? moment(invoicedAtEnd) : invoicedAtEnd,
              ]}
              onChange={this.changeDateFilterRange}
              ranges={dateRangePresets}
            />
          </div>
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item key="reviews-i-need-to-action">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={onlyReviewsThatNeedAction}
            onChange={(checked) => {
              let newState = { onlyReviewsThatNeedAction: checked };
              if (checked) {
                newState.onlyReviewsAssigned = false;
                newState.onlyReviewsRequested = false;
                newState.onlyInvoicesInReview = false;
                newState.onlyInvoicesThatNeedSecondReview = false;
                newState.onlyInvoicesWithoutReview = false;
                newState.onlyReviewedInvoices = false;
              }
              this.setState(newState, this.onChange);
            }}
          />
          <Typography.Text className="right-label">
            Only reviews I need to <br />
            take action on
          </Typography.Text>
        </Menu.Item>
        <Menu.Item key="what-i-need-to-review">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={onlyReviewsAssigned}
            onChange={(checked) => {
              let newState = { onlyReviewsAssigned: checked };
              if (checked) {
                newState.onlyReviewsRequested = false;
                newState.onlyInvoicesInReview = false;
                newState.onlyInvoicesThatNeedSecondReview = false;
                newState.onlyInvoicesWithoutReview = false;
                newState.onlyReviewedInvoices = false;
              }
              this.setState(newState, this.onChange);
            }}
          />
          <Typography.Text className="right-label">Only where I am the reviewer</Typography.Text>
        </Menu.Item>
        <Menu.Item key="reviews-i-requested">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={onlyReviewsRequested}
            onChange={(checked) => {
              let newState = { onlyReviewsRequested: checked };
              if (checked) {
                newState.onlyReviewsAssigned = false;
                newState.onlyInvoicesInReview = false;
                newState.onlyInvoicesThatNeedSecondReview = false;
                newState.onlyInvoicesWithoutReview = false;
                newState.onlyReviewedInvoices = false;
              }
              this.setState(newState, this.onChange);
            }}
          />
          <Typography.Text className="right-label">Only reviews I requested</Typography.Text>
        </Menu.Item>
        <Menu.Item key="without-successful-review">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={onlyInvoicesWithoutReview}
            onChange={(checked) => {
              let newState = { onlyInvoicesWithoutReview: checked };
              if (checked) {
                newState.onlyReviewsThatNeedAction = false;
                newState.onlyReviewsAssigned = false;
                newState.onlyInvoicesInReview = false;
                newState.onlyReviewsRequested = false;
                newState.onlyInvoicesThatNeedSecondReview = false;
                newState.onlyReviewedInvoices = false;
              }
              this.setState(newState, this.onChange);
            }}
          />
          <Typography.Text className="right-label">
            Only invoices without a successful {usesDoubleInvoiceReview ? "first-stage " : ""}review
          </Typography.Text>
        </Menu.Item>

        {usesDoubleInvoiceReview && (
          <Menu.Item key="needs-second-review">
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={onlyInvoicesThatNeedSecondReview}
              onChange={(checked) => {
                let newState = { onlyInvoicesThatNeedSecondReview: checked };
                if (checked) {
                  newState.onlyReviewsThatNeedAction = false;
                  newState.onlyReviewsAssigned = false;
                  newState.onlyInvoicesInReview = false;
                  newState.onlyReviewsRequested = false;
                  newState.onlyInvoicesWithoutReview = false;
                  newState.onlyReviewedInvoices = false;
                }
                this.setState(newState, this.onChange);
              }}
            />
            <Typography.Text className="right-label">Only invoices that need second review</Typography.Text>
          </Menu.Item>
        )}

        <Menu.Item key="invoices-fully-reviewed">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={onlyReviewedInvoices}
            onChange={(checked) => {
              let newState = { onlyReviewedInvoices: checked };
              if (checked) {
                newState.onlyReviewsThatNeedAction = false;
                newState.onlyReviewsAssigned = false;
                newState.onlyInvoicesInReview = false;
                newState.onlyReviewsRequested = false;
                newState.onlyInvoicesWithoutReview = false;
                newState.onlyInvoicesThatNeedSecondReview = false;
              }
              this.setState(newState, this.onChange);
            }}
          />
          <Typography.Text className="right-label">
            Only invoices that are {usesDoubleInvoiceReview ? "fully " : ""}
            reviewed
          </Typography.Text>
        </Menu.Item>

        <Menu.Item key="all-under-review">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={onlyInvoicesInReview}
            onChange={(checked) => {
              let newState = { onlyInvoicesInReview: checked };
              if (checked) {
                newState.onlyReviewsThatNeedAction = false;
                newState.onlyReviewsRequested = false;
                newState.onlyReviewsAssigned = false;
                newState.onlyInvoicesThatNeedSecondReview = false;
                newState.onlyReviewedInvoices = false;
              }
              this.setState(newState, this.onChange);
            }}
          />
          <Typography.Text className="right-label">All under review</Typography.Text>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item className="flex-menu-item" key="client">
          <Typography.Text className="left-label">Client:</Typography.Text>

          <Select
            showSearch
            allowClear
            placeholder="Choose a client"
            className="invoice-filter-client"
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={(clientId) => {
              this.setState({ clientId }, this.onChange);
            }}
            value={clientId}
          >
            {clients.map((client, i) => (
              <Select.Option key={i} value={client.id}>
                {client.name}
              </Select.Option>
            ))}
          </Select>
        </Menu.Item>
        <Menu.Item className="flex-menu-item" key="project">
          <Typography.Text className="left-label">
            {getLabel({
              id: "Project",
              defaultValue: "Project",
            })}
            :
          </Typography.Text>

          <Select
            showSearch
            allowClear
            placeholder="Choose a project"
            className="invoice-filter-project"
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={(projectId) => {
              this.setState({ projectId }, this.onChange);
            }}
            value={projectId}
          >
            {projects
              .filter((project) => !project.isArchived && !project.id.includes("TEMPLATES"))
              .map((project, i) => (
                <Select.Option key={i} value={project.id}>
                  {project.title}
                </Select.Option>
              ))}
          </Select>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="invoice-filters">
        {windowWidth > COMBINED_FILTERS_BREAKPOINT && (
          <>
            <Input
              className="filter-input"
              placeholder="Filter invoice list"
              allowClear
              prefix={<SearchOutlined />}
              onChange={(e) => this.setState({ containsText: e.target.value }, this.onChange)}
              value={containsText}
            />
            <AvatarList
              users={orderedActiveUsers}
              onClick={this.onUserSelected}
              selectedUsers={selectedUsers}
              containerWidthToSubtract={1050}
            />
          </>
        )}
        <Dropdown
          trigger={["click"]}
          overlayClassName="invoice-filters-dropdown-overlay"
          overlay={filterMenu}
          open={isDropdownVisible}
          onVisibleChange={(isDropdownVisible) => this.setState({ isDropdownVisible })}
        >
          <Button className="filter-button" icon={<DownOutlined />}>
            Filters
          </Button>
        </Dropdown>
        {windowWidth > COMBINED_ACTIONS_BREAKPOINT ? (
          <>
            {isAuthorised(["EXPORT_INVOICES"]) && (
              <Button className="export" onClick={this.props.export} data-cy="export-to-csv-button">
                Export to CSV
              </Button>
            )}
            {isAuthorised(["INVOICES.BATCH_DOWNLOAD_INVOICES"]) && (
              <Button className="batch-download" onClick={this.props.batchDownload} data-cy="batch-download-button">
                Batch download
              </Button>
            )}

            {this.props.includeCreateInvoice === false ? null : (
              <div className="create-buttons">
                <ButtonWithPermissions
                  type="primary"
                  className="create-invoice"
                  onClick={() => this.setState({ isCreateInvoiceModalVisible: true })}
                  data-cy="create-invoice-button"
                  permissions={["CREATE_INVOICE"]}
                >
                  <PlusCircleOutlined /> Create single invoice
                </ButtonWithPermissions>
                <Link to="/invoices/batch-create">
                  <ButtonWithPermissions
                    type="dark"
                    className="batch-create-invoices"
                    data-cy="batch-create-invoices-button"
                    permissions={["INVOICES.VIEW_BATCH_CREATE_PAGE"]}
                  >
                    <PlusCircleOutlined /> Batch create invoices
                  </ButtonWithPermissions>
                </Link>

                {userIsDoubleReviewer && (
                  <Button
                    type="dark"
                    className="batch-review-invoices"
                    data-cy="batch-review-invoices-button"
                    onClick={this.props.startBatchReview}
                  >
                    <EyeOutlined /> Batch review invoices
                  </Button>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <Button className="export" onClick={this.props.export} data-cy="export-to-csv-button">
              Export
            </Button>

            <ButtonWithPermissions
              className="batch-download"
              onClick={this.props.batchDownload}
              data-cy="batch-download-button"
              permissions={["INVOICES.BATCH_DOWNLOAD_INVOICES"]}
            >
              Download
            </ButtonWithPermissions>

            <Dropdown
              trigger={["click"]}
              overlay={
                <Menu>
                  {this.props.includeCreateInvoice === false ? null : (
                    <>
                      <Menu.Item>
                        <ButtonWithPermissions
                          type="primary"
                          className="create-invoice"
                          onClick={() => this.setState({ isCreateInvoiceModalVisible: true })}
                          data-cy="create-invoice-button"
                          permissions={["CREATE_INVOICE"]}
                        >
                          <PlusCircleOutlined /> Create single invoice
                        </ButtonWithPermissions>
                      </Menu.Item>

                      <Menu.Item>
                        <Link to="/invoices/batch-create">
                          <ButtonWithPermissions
                            type="dark"
                            className="batch-create-invoices"
                            data-cy="batch-create-invoices-button"
                            permissions={["INVOICES.VIEW_BATCH_CREATE_PAGE"]}
                          >
                            <PlusCircleOutlined /> Batch create invoices
                          </ButtonWithPermissions>
                        </Link>
                      </Menu.Item>

                      {userIsDoubleReviewer && (
                        <Menu.Item>
                          <Button
                            type="dark"
                            className="batch-review-invoices"
                            data-cy="batch-review-invoices-button"
                            onClick={this.props.startBatchReview}
                          >
                            <EyeOutlined /> Batch review invoices
                          </Button>
                        </Menu.Item>
                      )}
                    </>
                  )}
                </Menu>
              }
            >
              <Button className="combined-actions-button" type="primary" icon={<PlusCircleOutlined />}>
                Create
              </Button>
            </Dropdown>
          </>
        )}

        {isCreateInvoiceModalVisible && (
          <CreateInvoiceModal onClose={() => this.setState({ isCreateInvoiceModalVisible: false })} />
        )}
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: InvoiceFilters,
    subscriptions: ["organisationDetails", "users", "clients", "projects"],
  })
);
