import React from "react";
import DatePicker from "DatePicker/DatePicker";
import moment from "moment";

import FormInputLabel from "Form/FormInputLabel/FormInputLabel";

function FormDatePicker({ fieldData, fieldName, nestedFieldParams, readOnly, updateFieldValue, refreshKey }) {
  return (
    <div className="input-group inline-label" key={`${fieldName}-date-picker-${refreshKey}`}>
      <FormInputLabel fieldData={fieldData} fieldName={fieldName} />

      <DatePicker
        format={"DD-MM-YYYY"}
        placeholder={fieldData.placeholder}
        disabled={readOnly}
        allowClear={fieldData.allowClear}
        onChange={(value) => {
          updateFieldValue({
            fieldName,
            value: value ? value.format("YYYY-MM-DD") : null,
            nestedFieldParams,
          });
        }}
        defaultValue={fieldData.value ? moment(fieldData.value) : undefined}
      />
    </div>
  );
}

export default React.memo(FormDatePicker, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["readOnly", "refreshKey", "validationErrors", "hiddenFormFieldKeys"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps.topLevelParams[propName] !== nextProps.topLevelParams[propName]) {
      return false;
    }
  }

  return true;
});
