import { useState } from "react";
import { Form, Modal, Button, Checkbox } from "antd";

import withSubscriptions from "common/withSubscriptions";

function ControlTaskRevisionAccessModal({ onClose, onSubmit, parent, groups }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={`Control access to ${parent.name}`}
      open={true}
      onCancel={onClose}
      footer={null}
      className="control-task-revision-access-modal"
    >
      <Form
        {...layout}
        form={form}
        initialValues={{
          writeAccessGroups: parent.writeAccessGroups || [],
        }}
        onFinish={async (params) => {
          setIsLoading(true);
          if (onSubmit && typeof onSubmit === "function") {
            await onSubmit(params);
          }
          setIsLoading(false);
        }}
      >
        <Form.Item label="Groups that can access it" name="writeAccessGroups">
          <Checkbox.Group
            options={groups.map((group) => {
              return {
                label: group.name,
                value: group.id,
              };
            })}
          />
        </Form.Item>

        <div className="submit-container">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            data-cy="control-task-revision-access-modal-submit"
          >
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}

export default withSubscriptions({
  Component: ControlTaskRevisionAccessModal,
  subscriptions: ["groups"],
});
