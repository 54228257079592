import React from "react";
import { Typography } from "antd";
import cx from "classnames";

function FormSectionHeading({ fieldData }) {
  return (
    <div className={cx("form-section-heading")}>
      <Typography.Title level={2}>{fieldData.label}</Typography.Title>
    </div>
  );
}

export default React.memo(FormSectionHeading, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["readOnly", "refreshKey", "validationErrors", "hiddenFormFieldKeys"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps.topLevelParams[propName] !== nextProps.topLevelParams[propName]) {
      return false;
    }
  }

  return true;
});
