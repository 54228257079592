import React from "react";
import DatePicker from "DatePicker/DatePicker";
import moment from "moment";

import FormInputLabel from "Form/FormInputLabel/FormInputLabel";

function FormDateRangePicker({ fieldData, fieldName, nestedFieldParams, readOnly, updateFieldValue, refreshKey }) {
  return (
    <div className="input-group inline-label" key={`${fieldName}-date-range-picker-${refreshKey}`}>
      <FormInputLabel fieldData={fieldData} fieldName={fieldName} />

      <DatePicker.RangePicker
        placeholder={fieldData.placeholder}
        disabled={readOnly}
        allowClear={fieldData.allowClear}
        onChange={(value) => {
          updateFieldValue({
            fieldName,
            value,
            nestedFieldParams,
          });
        }}
        defaultValue={fieldData.value ? [moment(fieldData.value[0]), moment(fieldData.value[1])] : undefined}
      />
    </div>
  );
}

export default React.memo(FormDateRangePicker, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["readOnly", "refreshKey", "validationErrors", "hiddenFormFieldKeys"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps.topLevelParams[propName] !== nextProps.topLevelParams[propName]) {
      return false;
    }
  }

  return true;
});
