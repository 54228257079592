import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Storage } from "aws-amplify";
import cx from "classnames";
import { DeleteOutlined, PlusCircleOutlined, FilePdfOutlined, RightOutlined } from "@ant-design/icons";
import { Modal, Button, Typography, Space, Select, Switch, message } from "antd";
import { useGetSetState } from "react-use";

import { getAutoGeneratedSheetReferenceNumber } from "common/naming";
import { pointSheetsToLatestFileVersion, getReadableStatus } from "common/helpers";
import { isAuthorised } from "common/permissions";
import { computeDiff, getPdfImage, getDataUrl } from "common/pdfDiffHelpers";
import { getSimpleLabel } from "common/labels";
import { HAS_SHEETS } from "common/shared";
import { callGraphQLSimple } from "common/apiHelpers";

import { MemoZoomable as Zoomable } from "Zoomable/Zoomable";
import Input from "Input/Input";
import InfoItem from "InfoItem/InfoItem";

import "./SheetDetails.scss";

const MESSAGE_KEY_FAILED_TO_LOAD_SHEET_PDF = "failed-to-load-sheet-pdf-message";

export function SheetDetails({
  apiUser,
  sheet,
  file,
  task,
  users,
  setSelectedSheet,
  setSelectedSheetIndex,
  openCreateSheetRevisionModal,
  sheetIndex,
  taskRevision,
  organisationDetails,
  triggerPublish,
  downloadSheetPDF,
  windowWidth,
  inProgressOrPendingAsyncJobsForFile,
  numberForPreviewRefresh,
  triggerAnnotate,
}) {
  const isComponentMounted = useRef();
  const pdfPreviewDownloadFailedModal = useRef();
  const [pdfPreviewRandomNumber, setPdfPreviewRandomNumber] = useState(0);
  const [isPdfPreviewLoaded, setIsPdfPreviewLoaded] = useState(false);
  const [chosenSheetRevisionIdForPreview, setChosenSheetRevisionIdForPreview] = useState();
  const [isHighlightingDrawingDifferences, setIsHighlightingDrawingDifferences] = useState(false);
  const [diffImage, setDiffImage] = useState();
  const [currentImagePreviewUrl, setCurrentImagePreviewUrl] = useState();
  const [getState, setState] = useGetSetState({
    autoGeneratedSheetReferenceNumberAfterChange: undefined,
  });

  useEffect(() => {
    recomputeAutoGeneratedSheetReferenceNumber();
  });

  const { autoGeneratedSheetReferenceNumberAfterChange } = getState();

  async function recomputeAutoGeneratedSheetReferenceNumber() {
    const { autoGeneratedSheetReferenceNumberAfterChange } = getState();
    const newAutoGeneratedSheetReferenceNumber = await getAutoGeneratedSheetReferenceNumber({
      sheet,
      file,
    });

    if (newAutoGeneratedSheetReferenceNumber !== autoGeneratedSheetReferenceNumberAfterChange) {
      setState({
        autoGeneratedSheetReferenceNumberAfterChange: newAutoGeneratedSheetReferenceNumber,
      });
    }
  }

  let documentContainerRef = useRef();

  let sheetRevisionIdForPreview = sheet?.revisions?.items?.slice(-1)[0] && sheet?.revisions?.items?.slice(-1)[0].id;
  if (chosenSheetRevisionIdForPreview) {
    sheetRevisionIdForPreview = chosenSheetRevisionIdForPreview;
  }

  const sheetRevisionForPreview = sheet?.revisions?.items?.find((x) => x.id === sheetRevisionIdForPreview);

  /* eslint-disable */
  useEffect(() => {
    if (!apiUser || !sheet || !task || !HAS_SHEETS[file.type]) {
      return;
    }
    isComponentMounted.current = true;

    downloadPdfPreview(0);

    return () => {
      isComponentMounted.current = false;
      message.destroy(MESSAGE_KEY_FAILED_TO_LOAD_SHEET_PDF);
      if (pdfPreviewDownloadFailedModal.current) {
        pdfPreviewDownloadFailedModal.current.destroy();
        pdfPreviewDownloadFailedModal.current = undefined;
      }
    };
  }, [
    sheetRevisionIdForPreview,
    sheetRevisionForPreview?.updatedAt,
    inProgressOrPendingAsyncJobsForFile?.length,
    numberForPreviewRefresh,
  ]);
  /* eslint-enable */

  if (!apiUser || !sheet || !task || !sheet.revisions || !sheet.revisions.items) {
    return null;
  }

  function previewSheetPDF(sheetRevision) {
    setChosenSheetRevisionIdForPreview(sheetRevision.id);
  }

  const nonArchivedSheetRevisions = sheet.revisions?.items.filter((sheetRevision) => !sheetRevision.isArchived);

  async function updateSheetRevisionStatus(revision, status) {
    await callGraphQLSimple({
      message: "Failed to update sheet revision status",
      queryName: "updateSheetRevision",
      variables: {
        input: {
          id: revision.id,
          status,
        },
      },
    });

    message.warning("Status updated, but the revision name is still the same. You may want to update it too.", 10);

    await triggerAnnotate();

    updateFileRandomNumber();
  }

  async function downloadPdfPreview(retryCount) {
    if (!sheetRevisionForPreview?.exports || !isComponentMounted.current) {
      return;
    }

    const MAX_RETRY_COUNT = 10;
    const exportKey = sheetRevisionForPreview.exports[0].key;

    if (window.Cypress) {
      retryCount = 0;
    }

    if (retryCount < MAX_RETRY_COUNT) {
      try {
        const pdfResponse = await Storage.get(exportKey.replace("public/", ""), {
          download: true,
          cacheControl: "no-cache",
        });
        const pdfData = await new Response(pdfResponse.Body).arrayBuffer();

        if (taskRevision.base) {
          const { newImageDataUrl, diffDataUrl } = await computeDiff(pdfData, taskRevision, sheet, file);

          setCurrentImagePreviewUrl(newImageDataUrl);
          setDiffImage(diffDataUrl);
          setPdfPreviewRandomNumber(Math.random());
        } else {
          const { pdfImage, imageCanvas } = await getPdfImage(pdfData);
          const newImageDataUrl = getDataUrl(pdfImage, imageCanvas);
          setCurrentImagePreviewUrl(newImageDataUrl);
          setPdfPreviewRandomNumber(Math.random());
        }
        setIsPdfPreviewLoaded(true);
        message.destroy(MESSAGE_KEY_FAILED_TO_LOAD_SHEET_PDF);
      } catch (e) {
        console.error("failed to load PDF data:", e);

        if (window.Cypress || inProgressOrPendingAsyncJobsForFile?.length > 0) {
          message.destroy(MESSAGE_KEY_FAILED_TO_LOAD_SHEET_PDF);
          setTimeout(() => {
            downloadPdfPreview(0);
          }, 2000);
        } else {
          if (retryCount > 2) {
            if (isComponentMounted.current) {
              // message.loading({
              //   // content: `Failed to load the sheet PDF, retrying: ${retryCount}/${MAX_RETRY_COUNT}...`,
              //   content: `Failed to load the sheet PDF, retrying...`,
              //   duration: 0,
              //   key: MESSAGE_KEY_FAILED_TO_LOAD_SHEET_PDF,
              // });
            } else {
              message.destroy(MESSAGE_KEY_FAILED_TO_LOAD_SHEET_PDF);
            }
          }
          setTimeout(() => {
            downloadPdfPreview(retryCount + 1);
          }, 2000);
        }
      }
    } else {
      message.destroy("failed-to-load-sheet-pdf-message");
      if (isComponentMounted.current && !pdfPreviewDownloadFailedModal.current) {
        // pdfPreviewDownloadFailedModal.current = Modal.warning({
        //   title: "The file needs to be published",
        //   content: "The sheet PDF couldn't be loaded. The file needs to be published.",
        // });
      }
    }
  }

  async function confirmDeleteSheetRevision(sheetRevision) {
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm delete sheet revision",
          maskClosable: true,
          content: (
            <>
              Are you sure you want to delete <b>{sheetRevision.name}</b>?
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    await callGraphQLSimple({
      message: "Could not archive sheet revision",
      queryName: "deleteSheetRevision",
      variables: {
        input: {
          id: sheetRevision.id,
        },
      },
    });

    const updatedFile = (
      await callGraphQLSimple({
        message: "Could not retrieve file details",
        queryName: "getFile",
        variables: {
          id: file.id,
        },
      })
    ).data.getFile;

    await pointSheetsToLatestFileVersion({
      apiUser,
      task,
      file: updatedFile,
      taskRevision,
    });

    await triggerAnnotate();
    updateFileRandomNumber();
  }

  async function updateSheetRevisionName(revision, name) {
    let targetSheet = sheet || file.sheets.items[0];
    let nameIsAlreadyUsed = targetSheet.revisions.items.some(
      (crtRevision) => crtRevision.name === name && crtRevision.id !== revision.id
    );

    if (nameIsAlreadyUsed) {
      message.error("This name is already used by another revision and hasn't been saved. Please choose another one.");
      return;
    }

    await callGraphQLSimple({
      message: "Failed to update sheet revision name",
      queryName: "updateSheetRevision",
      variables: {
        input: {
          id: revision.id,
          name,
        },
      },
    });

    await triggerAnnotate();

    updateFileRandomNumber();
  }

  async function updateSheetRevisionDescription(revision, description) {
    await callGraphQLSimple({
      message: "Failed to update sheet revision description",
      queryName: "updateSheetRevision",
      variables: {
        input: {
          id: revision.id,
          description,
        },
      },
    });

    await triggerAnnotate();

    updateFileRandomNumber();
  }

  async function updateFileRandomNumber() {
    await callGraphQLSimple({
      message: "Could not update file",
      queryName: "updateFile",
      variables: {
        input: {
          id: file.id,
          itemSubscription: Number(String(Date.now()).substring(4)),
        },
      },
    });
  }

  async function changeAttribute({ key, value }) {
    await callGraphQLSimple({
      message: "Failed to update sheet details",
      queryName: "updateSheet",
      variables: {
        input: {
          id: sheet.id,
          [key]: value,
        },
      },
    });

    await triggerAnnotate();

    await updateFileRandomNumber();
  }

  function displayPdfPreviewContent() {
    let imageSrc = "";
    if (isHighlightingDrawingDifferences) {
      if (diffImage) {
        imageSrc = diffImage;
      }
    } else {
      if (currentImagePreviewUrl) {
        imageSrc = currentImagePreviewUrl;
      }
    }

    return (
      <div className="zoomable-content">
        {isPdfPreviewLoaded && !imageSrc ? (
          <Typography.Text className="no-preview-available-message">No preview available</Typography.Text>
        ) : (
          <img
            src={imageSrc}
            alt="differences between revisions"
            key={imageSrc}
            onLoad={() => {
              setPdfPreviewRandomNumber(imageSrc);
            }}
            onDragStart={(e) => {
              e.preventDefault();
            }}
          />
        )}
      </div>
    );
  }

  if (file.type === "REPORT") {
    return (
      <div className="sheet-details">
        <Button type="primary">Edit Report</Button>
      </div>
    );
  }

  let sheetTitleLabel = "";
  if (HAS_SHEETS[file.type]) {
    sheetTitleLabel = getSimpleLabel("sheet-title");
  } else {
    sheetTitleLabel = getSimpleLabel("sheet-title-for-non-sheet-files");
  }

  let shouldShowSheetTitle = true;

  if (organisationDetails.settings?.file?.hideSheetTitleInput) {
    shouldShowSheetTitle = false;
  } else if (organisationDetails.id === "BYRNE" && !HAS_SHEETS[file.type]) {
    shouldShowSheetTitle = false;
  }

  return (
    <div className={cx("sheet-details", { "no-sheets": !HAS_SHEETS[file.type] })} data-cy="sheet-details">
      <div className="metadata-and-preview">
        <div className="metadata-container">
          {HAS_SHEETS[file.type] && (
            <InfoItem label="Sheet name" value={sheet.name} onClick={() => console.log("clicked")} />
          )}
          {shouldShowSheetTitle && (
            <InfoItem
              label={sheetTitleLabel}
              value={
                <Input
                  fullWidth
                  defaultValue={sheet.description}
                  showBorder
                  disabled={taskRevision.isReadOnly}
                  onChange={(value) => {
                    let newValueIsDifferentFromOldValue = value !== sheet.description;
                    if (!newValueIsDifferentFromOldValue) {
                      return;
                    }
                    let newValueIsAlreadyUsedByAnotherSheet = file.sheets.items.some(
                      (crtSheet) => crtSheet.description === value
                    );
                    if (newValueIsAlreadyUsedByAnotherSheet) {
                      message.error(
                        `This ${
                          HAS_SHEETS[file.type] ? "sheet " : ""
                        }title is already used by another sheet and hasn't been saved. Please choose another one.`
                      );

                      return;
                    }
                    changeAttribute({ key: "description", value });
                  }}
                />
              }
            />
          )}
          {organisationDetails.id !== "BYRNE" && !organisationDetails.settings?.file?.hideAutoGeneratedReference && (
            <InfoItem label="Auto-generated reference number" value={autoGeneratedSheetReferenceNumberAfterChange} />
          )}

          {!organisationDetails.settings?.file?.hideCustomReference && HAS_SHEETS[file.type] && (
            <InfoItem
              label="Custom sheet reference number"
              value={
                <Input
                  fullWidth
                  defaultValue={sheet.customReferenceNumber}
                  showBorder
                  disabled={taskRevision.isReadOnly}
                  onChange={async (value) => {
                    await changeAttribute({
                      key: "customReferenceNumber",
                      value,
                    });
                  }}
                />
              }
            />
          )}

          {!organisationDetails.settings?.file?.hideIncludeInPublishSwitch && HAS_SHEETS[file.type] && (
            <InfoItem
              inline
              label="Include in publish process"
              value={
                <Switch
                  size="small"
                  disabled={taskRevision.isReadOnly}
                  checked={sheet.includeInPublish}
                  onChange={async (checked) => {
                    await changeAttribute({
                      key: "includeInPublish",
                      value: checked,
                    });
                    triggerPublish(null, { force: true });
                  }}
                />
              }
            />
          )}
          {!organisationDetails.settings?.file?.hideIncludeInDocumentRegister && (
            <InfoItem
              inline
              label="Include in Document Register"
              value={
                <Switch
                  size="small"
                  checked={!sheet.excludeFromRegister}
                  // disabled={taskRevision.isReadOnly}
                  onChange={async (checked) => {
                    const messageKey = "include-in-document-register";
                    if (checked) {
                      message.loading({ content: "Including in Document Register...", key: messageKey, duration: 30 });
                    } else {
                      message.loading({
                        content: "Excluding from Document Register...",
                        key: messageKey,
                        duration: 30,
                      });
                    }
                    try {
                      await changeAttribute({
                        key: "excludeFromRegister",
                        value: !checked,
                      });
                      updateFileRandomNumber();
                      if (checked) {
                        message.success({ content: "Included in Document Register", key: messageKey });
                      } else {
                        message.success({ content: "Excluded from Document Register", key: messageKey });
                      }
                    } catch (e) {
                      message.error({ content: "Failed to update Document Register status", key: messageKey });
                    }
                  }}
                />
              }
            />
          )}
        </div>
      </div>

      <div className="revisions-and-preview">
        <div className="sheet-revisions" data-cy="sheet-revisions">
          <div className="title-and-actions">
            <Typography.Title level={3}>{HAS_SHEETS[file.type] ? "Sheet" : ""} Revisions</Typography.Title>

            {!HAS_SHEETS[file.type] ||
            organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions ? null : (
              <Space>
                <Button
                  icon={<PlusCircleOutlined />}
                  type="primary"
                  onClick={() => {
                    setSelectedSheet(sheet);
                    setSelectedSheetIndex(sheetIndex);
                    openCreateSheetRevisionModal();
                  }}
                  disabled={taskRevision.isReadOnly}
                  data-cy="add-revision-to-sheet-button"
                >
                  Add revision to sheet
                </Button>
              </Space>
            )}
          </div>
          {nonArchivedSheetRevisions?.reverse().map((sheetRevision, index) => {
            const authorDetails = users.find((user) => user.id === sheetRevision.author);
            const reviewerDetails = users.find((user) => user.id === sheetRevision.checkedBy);
            const correspondingFileVersion = file.versions.items.find(
              (version) => version.id === sheetRevision.fileVersionId
            );
            // const publishStatus = computePublishStatus(
            //   correspondingFileVersion
            // );

            const sheetRevisionIsInPreview = HAS_SHEETS[file.type] && sheetRevisionIdForPreview === sheetRevision.id;

            return (
              <div
                className={cx("sheet-revision-item", {
                  "in-preview": sheetRevisionIsInPreview,
                })}
                key={index}
                data-cy="sheet-revision-item"
                data-name={sheetRevision.name}
              >
                <div className="revision-details">
                  <div className="revision-detail">
                    <Typography.Text>Name:</Typography.Text>
                    {organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions &&
                    !isAuthorised(["FILES.EDIT_READ_ONLY_SHEET_REVISION_DETAILS"]) ? (
                      <Typography.Text>{sheetRevision.name}</Typography.Text>
                    ) : (
                      <Input
                        fullWidth
                        data-cy="revision-name"
                        defaultValue={sheetRevision.name}
                        disabled={
                          !isAuthorised(["FILES.EDIT_READ_ONLY_SHEET_REVISION_DETAILS"]) &&
                          (taskRevision.isReadOnly ||
                            task.isFinished ||
                            task.isArchived ||
                            (!isAuthorised(["FILES.DELETE_SHEET_REVISION"]) && index !== 0) ||
                            organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions)
                        }
                        showBorder
                        onChange={(value) => updateSheetRevisionName(sheetRevision, value)}
                      />
                    )}
                  </div>
                  <div className="revision-detail">
                    <Typography.Text>Description:</Typography.Text>
                    {organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions &&
                    !isAuthorised(["FILES.EDIT_READ_ONLY_SHEET_REVISION_DETAILS"]) ? (
                      <Typography.Text>{sheetRevision.description}</Typography.Text>
                    ) : (
                      <Input
                        fullWidth
                        data-cy="revision-description"
                        defaultValue={sheetRevision.description}
                        disabled={
                          !isAuthorised(["FILES.EDIT_READ_ONLY_SHEET_REVISION_DETAILS"]) &&
                          (taskRevision.isReadOnly ||
                            task.isFinished ||
                            task.isArchived ||
                            (!isAuthorised(["FILES.DELETE_SHEET_REVISION"]) && index !== 0) ||
                            organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions)
                        }
                        showBorder
                        onChange={(description) => updateSheetRevisionDescription(sheetRevision, description)}
                      />
                    )}
                  </div>
                  {authorDetails && (
                    <div className="revision-detail">
                      <Typography.Text>Author:</Typography.Text>
                      <Typography.Text data-cy="revision-author">
                        {authorDetails.firstName} {authorDetails.lastName}
                      </Typography.Text>
                    </div>
                  )}
                  {reviewerDetails && (
                    <div className="revision-detail">
                      <Typography.Text>Reviewer:</Typography.Text>
                      <Typography.Text data-cy="revision-reviewer">
                        {reviewerDetails.firstName} {reviewerDetails.lastName}
                      </Typography.Text>
                    </div>
                  )}

                  {sheetRevision.reviewAcceptDate && (
                    <div className="revision-detail">
                      <Typography.Text>Reviewed At:</Typography.Text>
                      <Typography.Text data-cy="revision-reviewed-at">
                        {moment(sheetRevision.reviewAcceptDate).format("DD-MM-YYYY HH:mm:ss")}
                      </Typography.Text>
                    </div>
                  )}
                  {correspondingFileVersion?.savedAt && (
                    <div className="revision-detail">
                      <Typography.Text>Saved At:</Typography.Text>
                      <Typography.Text data-cy="revision-reviewer">
                        {moment(correspondingFileVersion.savedAt).format("DD-MM-YYYY HH:mm:ss")}
                      </Typography.Text>
                    </div>
                  )}
                  <div className="revision-detail">
                    <Typography.Text>Created At:</Typography.Text>
                    <Typography.Text data-cy="revision-reviewer">
                      {moment(sheetRevision.realCreatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </Typography.Text>
                  </div>
                  <div className="revision-detail">
                    <Typography.Text>Status:</Typography.Text>
                    <div data-cy="revision-status">
                      <div className="revision-status-container ">
                        {organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions &&
                        !isAuthorised([
                          "FULL.READ_WRITE",
                          "FILES.EDIT_READ_ONLY_SHEET_REVISION_DETAILS",
                          "EDIT_INDIVIDUAL_SHEET_STATUS",
                        ]) ? (
                          <Typography.Text>{getReadableStatus(sheetRevision.status)}</Typography.Text>
                        ) : (
                          <Select
                            onChange={(status) => updateSheetRevisionStatus(sheetRevision, status)}
                            value={sheetRevision.status.toUpperCase().split(" ").join("_")}
                            disabled={
                              !isAuthorised(["FILES.EDIT_READ_ONLY_SHEET_REVISION_DETAILS"]) &&
                              (taskRevision.isReadOnly ||
                                task.isFinished ||
                                task.isArchived ||
                                (!isAuthorised(["FILES.DELETE_SHEET_REVISION"]) && index !== 0))
                            }
                          >
                            {(organisationDetails.fileStatuses || []).map((fileStatus) => {
                              return (
                                <Select.Option
                                  key={fileStatus.name}
                                  value={fileStatus.name.toUpperCase().split(" ").join("_")}
                                >
                                  {fileStatus.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        )}
                      </div>
                    </div>
                  </div>
                  {HAS_SHEETS[file.type] && (
                    <div className="revision-detail">
                      <Typography.Text>PDF:</Typography.Text>
                      <div className="buttons">
                        <Button
                          data-cy="revision-pdf-download-button"
                          onClick={() =>
                            downloadSheetPDF({
                              sheetRevision,
                              sheet,
                              file,
                              task,
                            })
                          }
                          // disabled={publishStatus !== "FINISHED"}
                          // icon={
                          //   publishStatus === "IN_PROGRESS" ? (
                          //     <LoadingOutlined className="pdf-loading" />
                          //   ) : publishStatus === "ERROR" ? (
                          //     <CloseCircleOutlined className="pdf-error" />
                          //   ) : (
                          //     <FilePdfOutlined className="pdf-file" />
                          //   )
                          // }
                          icon={<FilePdfOutlined className="pdf-file" />}
                        >
                          Sheet PDF
                        </Button>
                        <Button
                          data-cy="revision-pdf-preview"
                          onClick={() => previewSheetPDF(sheetRevision)}
                          disabled={sheetRevisionIsInPreview}
                        >
                          {sheetRevisionIsInPreview ? (
                            <>
                              Currently in preview <RightOutlined />
                            </>
                          ) : (
                            <>
                              Show in preview <RightOutlined />
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  )}

                  {!organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions && (
                    <div className="revision-detail">
                      <Typography.Text>Actions:</Typography.Text>
                      <div className="buttons">
                        <Button
                          icon={<DeleteOutlined />}
                          data-cy="delete-sheet-revision"
                          disabled={
                            nonArchivedSheetRevisions.length <= 1 ||
                            !isAuthorised(["FILES.DELETE_SHEET_REVISION"]) ||
                            taskRevision.isReadOnly ||
                            task.isFinished ||
                            task.isArchived
                          }
                          onClick={() => confirmDeleteSheetRevision(sheetRevision)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {HAS_SHEETS[file.type] && windowWidth > 1000 && (
          <div className="preview-container">
            <Typography.Title level={3} className="pdf-preview-title">
              {HAS_SHEETS[file.type] ? sheet.name : ""} {sheetRevisionForPreview.name} - PDF Preview
            </Typography.Title>

            <InfoItem
              inline
              label={<>Highlight changes since last revision</>}
              value={
                // <DismissableTooltip
                //   cookie="dismissable-sheet-details-cad-diff"
                //   title={
                //     <>
                //       You can now automatically highlight differences for this sheet since the last{" "}
                //       {getLabel({
                //         id: "task revision",
                //         defaultValue: "task revision",
                //       })}
                //       . <br />
                //       <br />
                //       <b>Only works for CAD drawings for now.</b>
                //     </>
                //   }
                //   placement="bottom"
                // >
                <Switch
                  size="small"
                  checked={isHighlightingDrawingDifferences}
                  disabled={!taskRevision.base || !diffImage}
                  onChange={async (checked) => {
                    setIsHighlightingDrawingDifferences(checked);
                    setPdfPreviewRandomNumber(Math.random());
                  }}
                />
                // </DismissableTooltip>
              }
            />

            <div className="zoomable-container" ref={documentContainerRef}>
              <Zoomable
                key={pdfPreviewRandomNumber}
                isLoaded={isPdfPreviewLoaded}
                refreshOnChange={[
                  pdfPreviewRandomNumber,
                  isPdfPreviewLoaded,
                  windowWidth,
                  isHighlightingDrawingDifferences,
                  currentImagePreviewUrl,
                ]}
                active={true}
                containerRef={documentContainerRef}
                content={displayPdfPreviewContent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default SheetDetails;
