import axios from "axios";
import { message } from "antd";
import * as Sentry from "@sentry/react";

import { isObjectHidden } from "common/sharedTemplateRenderHelpers";
import getS3File from "common/getS3File";

export async function loadFormFile({ formFileKey }) {
  if (!formFileKey) {
    return {
      form: undefined,
      hiddenFormFields: {},
    };
  }

  const filePublicURL = await getS3File(formFileKey.replace("public/", ""));
  const form = (await axios.get(filePublicURL)).data;

  return {
    form,
  };
}

export async function getHiddenFormFields(contextForComputations) {
  let newHiddenFormFields = {};

  if (!contextForComputations.form) {
    return;
  }

  let isObjectHiddenPromises = [];

  for (let fieldName in contextForComputations.form.fields) {
    let fieldDetails = contextForComputations.form.fields[fieldName];
    if (!fieldDetails.usesConditionalDisplay) {
      continue;
    }

    let fieldDetailsWithParamsForConditionalDisplay = { ...fieldDetails };
    for (let keyName of Object.keys(fieldDetailsWithParamsForConditionalDisplay)) {
      // add "custom" to each key that starts with "conditionalDisplay"
      if (keyName.startsWith("conditionalDisplay")) {
        fieldDetailsWithParamsForConditionalDisplay[`custom_${keyName}`] =
          fieldDetailsWithParamsForConditionalDisplay[keyName];
        delete fieldDetailsWithParamsForConditionalDisplay[keyName];
      }
    }

    isObjectHiddenPromises.push(
      new Promise(async (resolve) => {
        // if (fieldDetailsWithParamsForConditionalDisplay.id === "type-1710778807241") {
        //   debugger;
        // }
        let fieldIsHidden = await isObjectHidden({
          object: fieldDetailsWithParamsForConditionalDisplay,
          params: contextForComputations,
        });
        resolve({
          fieldDetails,
          fieldIsHidden,
        });
      })
    );
  }

  const results = await Promise.all(isObjectHiddenPromises);

  for (let result of results) {
    let { fieldDetails, fieldIsHidden } = result;
    if (fieldIsHidden) {
      newHiddenFormFields[fieldDetails.id] = true;
    }
  }

  return newHiddenFormFields;
}

export function getFieldsToDisplay({
  form,
  selectedNestedContainerField,
  showNestedFieldsInModal,
  subFieldUnderEditIndex,
  rootField,
  childField,
}) {
  let fieldsToDisplay;

  if (selectedNestedContainerField && showNestedFieldsInModal) {
    fieldsToDisplay = JSON.parse(JSON.stringify(selectedNestedContainerField.fields));
    if (subFieldUnderEditIndex !== undefined) {
      let subfieldValues = selectedNestedContainerField.value[subFieldUnderEditIndex];
      for (let fieldName in fieldsToDisplay) {
        if (subfieldValues.hasOwnProperty(fieldName)) {
          fieldsToDisplay[fieldName].value = subfieldValues[fieldName];
        }
      }
    }
  } else if (childField) {
    fieldsToDisplay = JSON.parse(JSON.stringify(rootField.fields)) || {};
    for (let fieldName in fieldsToDisplay) {
      if (Array.isArray(childField[fieldName]) || typeof childField[fieldName] === "string") {
        fieldsToDisplay[fieldName].value = childField[fieldName];
      } else {
        for (let childFieldName in childField[fieldName]) {
          fieldsToDisplay[fieldName][childFieldName] = childField[fieldName][childFieldName];
        }
      }
    }
  } else {
    if (!form) {
      Sentry.captureMessage("Form is missing when trying to display fields", {
        level: "error",
      });
      message.error(
        "Form could not be loaded. Our team has been notified of this problem. If you need assistance, please contact us."
      );
      return;
    } else if (!form.fields) {
      Sentry.captureMessage("Form fields are missing when trying to display fields", {
        level: "error",
      });
      message.error(
        "Form fields could not be loaded. Our team has been notified of this problem. If you need assistance, please contact us."
      );
      return;
    }

    fieldsToDisplay = JSON.parse(JSON.stringify(form.fields));
  }

  return fieldsToDisplay;
}

export function getSortedAndNotHiddenFieldNames({
  fieldsToDisplay,
  sectionNameToDisplayFieldsFor,
  showHiddenByModal,
  modalWithFieldsName,
  isFormEditor,
  hiddenFormFields,
  onFieldEdit,
}) {
  let visibleFieldNames = Object.keys(fieldsToDisplay).filter((fieldName) => {
    if (fieldName === "lineItems") {
      // this is a reserved field name for storing preset line items in quotes, invoices, and purchase orders
      return false;
    }

    let fieldDetails = fieldsToDisplay[fieldName];
    if (sectionNameToDisplayFieldsFor && !fieldDetails.hiddenInModalBy) {
      return fieldDetails.parentSection === sectionNameToDisplayFieldsFor;
    } else {
      if (showHiddenByModal && modalWithFieldsName) {
        return fieldDetails.hiddenInModalBy === modalWithFieldsName;
      } else if (fieldDetails.parentSection) {
        return false;
      } else if (fieldDetails.isForLineItems && !isFormEditor) {
        return false;
      } else if (!showHiddenByModal) {
        let isVisible = !fieldDetails.hiddenInModalBy;
        return isVisible;
      }
    }

    return false;
  });

  const sortedFieldNames = visibleFieldNames.sort(
    (a, b) => fieldsToDisplay[a].adminOrder - fieldsToDisplay[b].adminOrder
  );

  let sortedNotHiddenFieldNames = sortedFieldNames.filter((fieldName) => {
    const fieldDetails = fieldsToDisplay[fieldName];

    if (!onFieldEdit && hiddenFormFields && hiddenFormFields[fieldName]) {
      return null;
    }

    if (isFormEditor && fieldDetails.isHidden) {
      return null;
    }

    return true;
  });

  return { sortedFieldNames, sortedNotHiddenFieldNames };
}
