import React from "react";
import { Input as AntInput } from "antd";

import FormInputLabel from "Form/FormInputLabel/FormInputLabel";
import Textarea from "Form/Textarea/Textarea";
// import ReviewTarget from "ReviewTarget/ReviewTarget";

function FormTextarea({ fieldData, fieldName, nestedFieldParams, readOnly, updateFieldValue }) {
  function onChange(value) {
    updateFieldValue({
      fieldName,
      value,
      nestedFieldParams,
    });
  }

  let parsedFieldValue;
  const isEmailText = fieldName === "emailText";
  let useNewEditor = true;

  if (isEmailText) {
    useNewEditor = false;
  } else {
    try {
      if (fieldData.value && fieldData.value !== "") {
        parsedFieldValue = JSON.parse(fieldData.value);
      }
    } catch (e) {
      useNewEditor = false;
    }
  }

  if (useNewEditor && parsedFieldValue && !Array.isArray(parsedFieldValue)) {
    parsedFieldValue = [];
  }

  return (
    <div className="input-group" key={fieldName}>
      <FormInputLabel fieldData={fieldData} fieldName={fieldName} />

      {/* <ReviewTarget name={`dynamic-field-${fieldName}`} {...this} {...this.props} visible={isUnderReview}> */}
      {useNewEditor ? (
        <Textarea
          key={fieldName}
          memoize
          defaultValue={parsedFieldValue}
          debouncedOnChange={onChange}
          // documentProps={{
          //   ...this.props,
          //   ...this.state,
          // }}
          extraToolbarButtons={fieldData.textOnly ? undefined : ["page-break", "attachment"]}
          basicFormattingOnly={fieldData.basicFormattingOnly}
          disabled={readOnly}
          defaultImageWidth={fieldData.defaultImageWidth}
          onlyDisplayAttachmentName={fieldData.onlyDisplayAttachmentName}
        />
      ) : (
        <AntInput.TextArea
          data-cy={`${fieldName}-textarea`}
          autoSize={{ minRows: 3 }}
          disabled={readOnly}
          defaultValue={fieldData.value || ""}
          onChange={(e) => {
            updateFieldValue({
              fieldName,
              value: e.target.value,
              nestedFieldParams,
            });
          }}
        />
      )}
      {/* </ReviewTarget> */}
    </div>
  );
}

export default React.memo(FormTextarea, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["readOnly", "refreshKey", "validationErrors", "hiddenFormFieldKeys"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps.topLevelParams[propName] !== nextProps.topLevelParams[propName]) {
      return false;
    }
  }

  return true;
});
