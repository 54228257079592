import { message } from "antd";

import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import { getRequestId } from "common/naming";
import { REQUEST_STATUSES } from "common/constants";
import { displayErrorMessage, createFileWithSheets } from "common/helpers";
import { sendRequestAssignedNotification, sendRequestFormCreatedNotification } from "common/notificationHelpers";
import { CREATE_RETRY_LIMIT } from "common/constants";

export async function createRequest(params) {
  const { apiUser, organisationDetails, requestedForDate, requestedForDateReason, priorityId, users, templateId } =
    params;

  // if (!templatesForRequestForms || templatesForRequestForms.length === 0) {
  //   message.error(`No ${getSimpleLabel("request")} form template found, please create one first`);
  //   throw new Error(`No ${getSimpleLabel("request")} form template found`);
  // }

  let extraOffset = 0;
  if (params.extraOffset !== undefined && params.extraOffset !== null) {
    extraOffset = params.extraOffset;
  }

  let newRequest;

  while (true) {
    try {
      newRequest = await createRequestInApi({ ...params, extraOffset });
      break;
    } catch (e) {
      console.error("error when creating request: e = ", e);
      const errorType = e?.errors && e.errors[0]?.errorType;
      console.log("extraOffset = ", extraOffset);
      console.log("CREATE_RETRY_LIMIT = ", CREATE_RETRY_LIMIT);

      if (errorType === "DynamoDB:ConditionalCheckFailedException" && extraOffset <= CREATE_RETRY_LIMIT) {
        console.log("not showing error");
        extraOffset++;
      } else {
        console.error(`error creating ${getSimpleLabel("request")}:`, e);
        displayErrorMessage(e, `Failed to create ${getSimpleLabel("request")}`);
        throw e;
      }
    }
  }

  await callGraphQLSimple({
    mutation: "createActivityItem",
    message: "Failed to record activity item",
    variables: {
      input: {
        parentId: newRequest.id,
        author: apiUser.id,
        content: JSON.stringify({
          type: "REQUEST_CREATED",
        }),
        organisation: organisationDetails.id,
      },
    },
  });

  try {
    await addFormToRequest({
      apiUser,
      organisationDetails,
      request: newRequest,
      users,
      formType: "REQUEST",
      formName: `Initial ${getSimpleLabel("request")} form`,
      requestedForDate,
      requestedForDateReason,
      priorityId,
      toBeApprovedBy: undefined,
      templateId,
    });
  } catch (e) {
    displayErrorMessage(e, `Failed to create ${getSimpleLabel("request")}`);
    await callGraphQLSimple({
      mutation: "deleteRequest",
      displayError: false,
      variables: {
        input: {
          id: newRequest.id,
        },
      },
    });
    throw e;
  }

  return newRequest;
}

async function createRequestInApi(params) {
  const {
    title,
    organisationDetails,
    extraOffset,
    apiUser,
    priorityId = undefined,
    clientId = undefined,
    projectId = undefined,
    requestedForDate,
    status = "MORE_INFORMATION_REQUESTED",
  } = params;

  let requestId = await getRequestId({
    organisationDetails,
    extraOffset,
  });

  const newRequest = (
    await callGraphQLSimple({
      mutation: "createRequest",
      displayError: false,
      variables: {
        input: {
          id: requestId,
          organisation: organisationDetails.id,
          title,
          createdBy: apiUser.id,
          requestedBy: apiUser.id,
          requestedAt: new Date().toISOString(),
          priorityId,
          clientId,
          projectId,
          status,
          requestedForDate: requestedForDate?.toISOString(),
        },
      },
    })
  ).data.createRequest;

  await callGraphQLSimple({
    mutation: "updateOrganisation",
    message: `Failed to update number of ${getSimpleLabel("requests")}`,
    variables: {
      input: {
        id: organisationDetails.id,
        requestCount: (organisationDetails.requestCount || 0) + extraOffset + 1,
      },
    },
  });
  return newRequest;
}

export async function addFormToRequest({
  apiUser,
  organisationDetails,
  formType,
  formName,
  requestedForDate,
  requestedForDateReason,
  priorityId,
  request,
  users,
  toBeApprovedBy,
  templateId,
}) {
  let formTemplateId = templateId;
  if (!formTemplateId) {
    let allTemplatesForRequests = organisationDetails.templates?.items?.filter((x) => x.type === formType);

    let validTemplates = allTemplatesForRequests.filter((template) => !template.isDeprecated);

    if (validTemplates && validTemplates.length > 0) {
      let defaultTemplate = validTemplates.find((template) => template.isDefault);
      if (defaultTemplate) {
        formTemplateId = defaultTemplate.id;
      } else {
        let liveTemplates = validTemplates.filter((template) => template.isLive);
        if (liveTemplates && liveTemplates.length > 0) {
          formTemplateId = liveTemplates[0].id;
        } else {
          formTemplateId = validTemplates[0].id;
        }
      }
    }
  }

  if (!formTemplateId) {
    message.error(`No ${getSimpleLabel("request")} form template found, please create one first`);
    throw new Error(`No ${getSimpleLabel("request")} form template found`);
  }

  const formFile = await createFileWithSheets({
    apiUser: apiUser,
    fileType: "REPORT",
    extension: "json",
    sheetCount: 1,
    name: formName,
    templateId: formTemplateId,
    doPublish: false,
    excludeFromRegister: true,
    taskRevisionId: "nothing",
    taskRevision: {
      id: "nothing",
      name: "A",
    },
    task: {
      id: `REQUEST_FORM_${Date.now()}_${Math.floor(Math.random() * 10000)}`,
      projectId: request.id,
      organisation: organisationDetails.id,
    },
  });

  await callGraphQLSimple({
    mutation: "createActivityItem",
    message: "Failed to record activity item",
    variables: {
      input: {
        parentId: request.id,
        author: apiUser.id,
        content: JSON.stringify({
          type: "REQUEST_FORM_CREATED",
          formFileId: formFile.id,
          templateId: formTemplateId,
          formType,
          formName,
          requestedForDate: requestedForDate?.toISOString(),
          requestedForDateReason,
          priorityId,
          toBeApprovedBy,
        }),
        organisation: organisationDetails.id,
      },
    },
  });

  await callGraphQLSimple({
    mutation: "updateRequest",
    message: `Failed to update ${getSimpleLabel("request")} status`,
    variables: {
      input: {
        id: request.id,
        status: "MORE_INFORMATION_REQUESTED",
        latestFormName: formName,
        priorityId,
      },
    },
  });

  await sendRequestFormCreatedNotification({
    users,
    request,
    apiUser,
    organisationDetails,
    formName,
    formFileId: formFile.id,
  });
}

export async function deleteRequestAndRemoveFromTasks({
  apiUser,
  organisationDetails,
  request,
  activityItems,
  history,
}) {
  const messageKey = "delete-request";

  if (activityItems) {
    let taskRevisionsToUpdateCount = 0;

    // first we count how many task revisions we need to update, in order to give users an accurate progress report,
    // then we can actually proceed with the updates
    for (let activityItem of activityItems) {
      if (!activityItem || !activityItem.content || !activityItem.content.formFileId) {
        continue;
      }
      const taskIdsAndRevisionIds = getTaskIdsAndTaskRevisionIdsForRequestForm({
        activityItems,
        formFileId: activityItem.content.formFileId,
      });

      if (!taskIdsAndRevisionIds || taskIdsAndRevisionIds.length === 0) {
        continue;
      }

      taskRevisionsToUpdateCount += taskIdsAndRevisionIds.length;
    }

    // now we can actually proceed with the updates
    let taskRevisionsUpdatedCount = 0;
    message.loading({
      content: `Removing ${getSimpleLabel("request")} from ${getSimpleLabel("task revisions")}. Progress: 0%...`,
      key: messageKey,
      duration: 0,
    });
    for (let activityItem of activityItems) {
      // console.log("activityItem = ", activityItem);
      if (!activityItem || !activityItem.content || !activityItem.content.formFileId) {
        continue;
      }
      const taskIdsAndRevisionIds = getTaskIdsAndTaskRevisionIdsForRequestForm({
        activityItems,
        formFileId: activityItem.content.formFileId,
      });

      if (!taskIdsAndRevisionIds || taskIdsAndRevisionIds.length === 0) {
        continue;
      }

      for (let { taskId, taskRevisionId } of taskIdsAndRevisionIds) {
        taskRevisionsToUpdateCount++;
        const taskDetails = (
          await callGraphQLSimple({
            queryCustom: "getTaskSimple",
            message: `Failed to fetch ${getSimpleLabel("task")}`,
            variables: {
              id: taskId,
            },
          })
        ).data.getTask;
        const taskRevisionDetails = taskDetails?.revisions?.items?.find(
          (taskRevision) => taskRevision.id === taskRevisionId
        );
        await removeRequestFormFromTaskRevision({
          apiUserId: apiUser.id,
          activityItems,
          taskId,
          requestId: request.id,
          taskRevisionId,
          taskTitle: taskDetails.title,
          projectTitle: taskDetails.project?.title,
          taskRevisionName: taskRevisionDetails.name,
          formName: activityItem.content.formName,
          formFileId: activityItem.content.formFileId,
          organisation: organisationDetails.id,
          taskRequestIds: taskDetails.requestIds,
        });
        taskRevisionsUpdatedCount++;
        if (taskRevisionsUpdatedCount < taskRevisionsToUpdateCount) {
          let updatedPercentage = Math.floor((taskRevisionsUpdatedCount / taskRevisionsToUpdateCount) * 100);
          message.loading({
            content: `Removing ${getSimpleLabel("request")} from ${getSimpleLabel(
              "task revisions"
            )}. Progress: ${updatedPercentage}%...`,
            key: messageKey,
            duration: 0,
          });
        }
      }
    }
  }

  message.loading({
    content: `Deleting ${getSimpleLabel("request")}...`,
    key: messageKey,
    duration: 0,
  });

  try {
    await callGraphQLSimple({
      mutation: "deleteRequest",
      message: `Failed to delete ${getSimpleLabel("request")}`,
      variables: {
        input: {
          id: request.id,
        },
      },
    });
    message.success({
      content: `${getSimpleLabel("Request")} deleted successfully.`,
      key: messageKey,
      duration: 2,
    });
    history.push("/requests");
  } catch (e) {
    message.error({
      content: `Failed to delete ${getSimpleLabel("request")}. `,
      key: messageKey,
      duration: 0,
    });
  }
}

export function getReadableRequestStatus({ request }) {
  let readableStatus: null | string = null;
  if (!request.status) {
    readableStatus = "(status not found)";
  }
  let statusDetails = REQUEST_STATUSES.find((status) => status.value === request.status);
  if (!statusDetails) {
    readableStatus = "(status not found)";
  } else {
    readableStatus = statusDetails.label;
  }
  return readableStatus;
}

export function getRequestFormFileIds({ activityItems }) {
  if (!activityItems) {
    return undefined;
  }

  const requestIdsAdded = activityItems
    .filter((activityItem) => activityItem.content.type === "REQUEST_FORM_CREATED")
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    .map((activityItem) => activityItem.content.formFileId);

  const requestIdsDeleted = activityItems
    .filter((activityItem) => activityItem.content.type === "REQUEST_FORM_DELETED")
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    .map((activityItem) => activityItem.content.formFileId);

  const requestIdsAddedAndNotDeleted = requestIdsAdded.filter((requestId) => !requestIdsDeleted.includes(requestId));
  return requestIdsAddedAndNotDeleted;
}

export function haveTaskFilesBeenSentForFormFileId({ activityItems, formFileId }) {
  if (!activityItems) {
    return undefined;
  }

  const activityItemsForForm = getActivityItemsForFormFileId({ activityItems, formFileId });
  const latestItemForSendingTaskFiles = [...activityItemsForForm]
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    .find((activityItem) => activityItem.content.type === "TASK_FILES_SENT");

  return !!latestItemForSendingTaskFiles;
}

export function getFormExternalReviewOutcome({ activityItems, formFileId }) {
  if (!activityItems) {
    return undefined;
  }

  const activityItemsForForm = getActivityItemsForFormFileId({ activityItems, formFileId });
  const latestItemForReviewOutcome = [...activityItemsForForm]
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    .find((activityItem) => activityItem.content.type === "EXTERNAL_FORM_REVIEW_RESPONSE");

  return latestItemForReviewOutcome;
}

export function getSubmittedRequestFormFileIds({ activityItems }) {
  if (!activityItems) {
    return undefined;
  }

  const formFileIds = getRequestFormFileIds({ activityItems });

  return formFileIds.filter((formFileId) => {
    const activityItemsForForm = getActivityItemsForFormFileId({ activityItems, formFileId });
    const latestItemForSubmitting = [...activityItemsForForm]
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      .find((activityItem) => activityItem.content.type === "INFORMATION_SUBMITTED");
    const latestItemForRequestingMoreInformation = [...activityItemsForForm]
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      .find((activityItem) => activityItem.content.type === "MORE_INFORMATION_REQUESTED");

    if (
      latestItemForSubmitting &&
      (!latestItemForRequestingMoreInformation ||
        latestItemForSubmitting.createdAt > latestItemForRequestingMoreInformation.createdAt)
    ) {
      return true;
    }

    return false;
  });
}

export function getOpenRequestFormFileIds({ activityItems }) {
  if (!activityItems) {
    return undefined;
  }
  const formFileIds = getRequestFormFileIds({ activityItems });

  const submittedFormFileIds = getSubmittedRequestFormFileIds({ activityItems });

  const openFormFileIds = formFileIds.filter((formFileId) => !submittedFormFileIds.includes(formFileId));
  return openFormFileIds;
}

export function getTaskIdForRequest({ activityItems }) {
  if (!activityItems) {
    return undefined;
  }
  const latestItemForAdding = [...activityItems]
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    .find((activityItem) => activityItem.content.type === "REQUEST_FORM_ADDED_TO_TASK_REVISION");
  const latestItemForRemoving = [...activityItems]
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    .find((activityItem) => ["REQUEST_FORM_REMOVED_FROM_TASK_REVISION"].includes(activityItem.content.type));

  if (
    latestItemForAdding &&
    (!latestItemForRemoving || latestItemForAdding.createdAt > latestItemForRemoving.createdAt)
  ) {
    return latestItemForAdding.content.taskId;
  }

  return undefined;
}

export function getTaskIdsAndTaskRevisionIdsForRequestForm({ activityItems, formFileId }) {
  if (!activityItems) {
    return undefined;
  }

  let taskRevisionIdsThatWereEverAdded: string[] = [];

  const activityItemsForForm: any[] = getActivityItemsForFormFileId({ activityItems, formFileId });

  for (let activityItem of activityItemsForForm) {
    if (
      activityItem.content.type === "REQUEST_FORM_ADDED_TO_TASK_REVISION" &&
      !taskRevisionIdsThatWereEverAdded.includes(activityItem.content?.taskRevisionId)
    ) {
      taskRevisionIdsThatWereEverAdded.push(activityItem.content?.taskRevisionId);
    }
  }

  let taskIdsAndRevisionIds: { taskId: string; taskRevisionId: string }[] = [];

  for (let taskRevisionId of taskRevisionIdsThatWereEverAdded) {
    const activityItemsForTaskRevision = activityItemsForForm.filter(
      (activityItem) => activityItem.content?.taskRevisionId === taskRevisionId
    );
    const latestItemForAdding = [...activityItemsForTaskRevision]
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      .find((activityItem) => activityItem.content.type === "REQUEST_FORM_ADDED_TO_TASK_REVISION");
    const latestItemForRemoving = [...activityItemsForTaskRevision]
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      .find((activityItem) => activityItem.content.type === "REQUEST_FORM_REMOVED_FROM_TASK_REVISION");

    if (
      latestItemForAdding &&
      (!latestItemForRemoving || latestItemForAdding.createdAt > latestItemForRemoving.createdAt)
    ) {
      taskIdsAndRevisionIds.push({
        taskId: latestItemForAdding.content.taskId,
        taskRevisionId: latestItemForAdding.content.taskRevisionId,
      });
    }
  }

  return taskIdsAndRevisionIds;
}
export function getTaskIdsAndTaskRevisionIdsForRequest({ activityItems }) {
  if (!activityItems) {
    return undefined;
  }

  let taskRevisionIdsThatWereEverAdded: string[] = [];

  for (let activityItem of activityItems) {
    if (
      activityItem.content.type === "REQUEST_FORM_ADDED_TO_TASK_REVISION" &&
      !taskRevisionIdsThatWereEverAdded.includes(activityItem.content?.taskRevisionId)
    ) {
      taskRevisionIdsThatWereEverAdded.push(activityItem.content?.taskRevisionId);
    }
  }

  let taskIdsAndRevisionIds: { taskId: string; taskRevisionId: string }[] = [];

  for (let taskRevisionId of taskRevisionIdsThatWereEverAdded) {
    const activityItemsForTaskRevision = activityItems.filter(
      (activityItem) => activityItem.content?.taskRevisionId === taskRevisionId
    );
    const latestItemForAdding = [...activityItemsForTaskRevision]
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      .find((activityItem) => activityItem.content.type === "REQUEST_FORM_ADDED_TO_TASK_REVISION");
    const latestItemForRemoving = [...activityItemsForTaskRevision]
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      .find((activityItem) => activityItem.content.type === "REQUEST_FORM_REMOVED_FROM_TASK_REVISION");

    if (
      latestItemForAdding &&
      (!latestItemForRemoving || latestItemForAdding.createdAt > latestItemForRemoving.createdAt)
    ) {
      taskIdsAndRevisionIds.push({
        taskId: latestItemForAdding.content.taskId,
        taskRevisionId: latestItemForAdding.content.taskRevisionId,
      });
    }
  }

  return taskIdsAndRevisionIds;
}

export function getActivityItemsForFormFileId({ activityItems, formFileId }) {
  return activityItems.filter((activityItem) => activityItem.content.formFileId === formFileId);
}

export function getLatestMessageFromEngineerForFormFileId({ activityItems, formFileId }) {
  const items = getActivityItemsForFormFileId({ activityItems, formFileId });
  const latestItem = [...items].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))[0];
  if (latestItem && latestItem.content.type === "MORE_INFORMATION_REQUESTED") {
    return latestItem;
  }
  return undefined;
}

export function getLatestMessageForFormFileId({ activityItems, formFileId }) {
  const items = getActivityItemsForFormFileId({ activityItems, formFileId });
  const latestItem = [...items].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))[0];
  if (latestItem && ["MORE_INFORMATION_REQUESTED", "INFORMATION_SUBMITTED"].includes(latestItem.content.type)) {
    return latestItem;
  }
  return undefined;
}

export function getUrlsForRequest({ activityItems }) {
  return activityItems.filter((activityItem) => activityItem.content.type === "REQUEST_SHARED");
}

export async function removeRequestFormFromTaskRevision({
  activityItems,
  taskId,
  requestId,
  apiUserId,
  taskRevisionId,
  taskTitle,
  projectTitle,
  taskRevisionName,
  formName,
  formFileId,
  organisation,
  taskRequestIds,
}) {
  const taskIdsAndRevisionIdsForRequest = getTaskIdsAndTaskRevisionIdsForRequest({ activityItems });
  const taskRevisionIdsForRequestBeforeRemoving = taskIdsAndRevisionIdsForRequest?.filter(
    (crtElement) => crtElement.taskId === taskId
  );

  const request = (
    await callGraphQLSimple({
      query: "getRequest",
      message: `Failed to fetch ${getSimpleLabel("request")} details`,
      variables: {
        id: requestId,
      },
    })
  ).data.getRequest;

  await callGraphQLSimple({
    mutation: "createActivityItem",
    message: "Failed to record activity item",
    variables: {
      input: {
        parentId: requestId,
        author: apiUserId,
        content: JSON.stringify({
          type: "REQUEST_FORM_REMOVED_FROM_TASK_REVISION",
          taskRevisionId,
          taskId,
          taskTitle,
          projectTitle,
          taskRevisionName,
          formName,
          formFileId,
        }),
        organisation,
      },
    },
  });

  await window.callGraphQLSimple({
    mutation: "createTaskActivityItem",
    message: `Failed to record ${getSimpleLabel("task")} activity item`,
    variables: {
      input: {
        taskId,
        organisation: request.organisation,
        type: "LIFECYCLE_EVENT",
        author: apiUserId,
        content: JSON.stringify({
          type: "REQUEST_FORM_REMOVED_FROM_TASK_REVISION",
          requestFormName: formName,
          requestId: request.id,
          requestTitle: request.title,
          taskRevisionName,
          taskRevisionId,
        }),
      },
    },
  });

  if (taskRevisionIdsForRequestBeforeRemoving?.length === 1) {
    await callGraphQLSimple({
      queryCustom: "updateTask",
      message: `Failed to remove ${getSimpleLabel("request")} from ${getSimpleLabel("task")}`,
      variables: {
        input: {
          id: taskId,
          requestIds: (taskRequestIds || []).filter((x) => x !== requestId),
        },
      },
    });
  } else {
    await callGraphQLSimple({
      queryCustom: "updateTask",
      displayError: false,
      variables: {
        input: {
          id: taskId,
          itemSubscription: Math.floor(Math.random() * 1000000),
        },
      },
    });
  }

  let resultingTaskIds = request.resultingTaskIds || [];
  let projectId = request.projectId;
  let clientId = request.clientId;
  resultingTaskIds = resultingTaskIds.filter((x) => x !== taskId);
  if (resultingTaskIds.length === 0) {
    projectId = null;
    clientId = null;
  }

  await callGraphQLSimple({
    mutation: "updateRequest",
    message: `Failed to refresh ${getSimpleLabel("request")}`,
    variables: {
      input: {
        id: requestId,
        itemSubscription: Math.floor(Math.random() * 1000000),
        resultingTaskIds,
        projectId,
        clientId,
      },
    },
  });
}

export async function assignRequest({ requestId, assignedToDetails, apiUser, includeNotification = true }) {
  if (window.organisationDetails?.settings?.request?.hideAssignedTo) {
    return;
  }
  const request = (
    await callGraphQLSimple({
      mutation: "updateRequest",
      message: `Failed to assign ${getSimpleLabel("request")}`,
      variables: {
        input: {
          id: requestId,
          assignedTo: assignedToDetails?.id || null,
        },
      },
    })
  ).data.updateRequest;

  if (includeNotification) {
    await sendRequestAssignedNotification({
      request,
      apiUser,
      newAssigneeDetails: assignedToDetails,
    });
  }

  await callGraphQLSimple({
    mutation: "createActivityItem",
    message: "Failed to record activity item",
    variables: {
      input: {
        parentId: request.id,
        author: apiUser.id,
        content: JSON.stringify({
          type: "ASSIGNEE_CHANGED",
          newAssigneeId: assignedToDetails?.id,
        }),
        organisation: request.organisation,
      },
    },
  });
}
