import { Modal, Typography, Tooltip, Button, message } from "antd";
import axios from "axios";
import { PlusCircleOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useGetSetState } from "react-use";
import cx from "classnames";

import { isAuthorised } from "common/permissions";
import getS3File from "common/getS3File";
import { FORM_NESTED_FIELD_LIST_FIELDS_TO_IGNORE } from "common/constants";

import InfoItem from "InfoItem/InfoItem";
import ModalAddItemFromList from "Form/ModalAddItemFromList/ModalAddItemFromList";
import ModalManageItemList from "Form/ModalManageItemList/ModalManageItemList";

import "./NestedFieldListWithModal.scss";

export default function NestedFieldListWithModal({
  fieldName,
  fieldData,
  isFormEditor,
  validationErrors,
  readOnly,
  removeUserListItem,
  setState: setParentState,
  form,
  templateDetails,
  saveUserFields,
  onReportUserListSubmit,
  windowWidth,
  labelFunction,
}) {
  const [getState, setState] = useGetSetState({
    isAddItemFromListModalOpen: false,
    isManageItemListModalOpen: false,
  });

  async function onAddItemFromListSubmit(item) {
    onReportUserListSubmit(item);
    if (isFormEditor) {
      setParentState({
        fieldUnderEditName: fieldName,
        selectedNestedContainerField: fieldData,
      });
    } else {
      setParentState({
        fieldUnderEditName: fieldName,
      });
    }
  }

  async function addNestedFieldToTemplate({ fieldData, fieldName, itemIndex, item }) {
    const currentForm = form;

    try {
      const formFilePublicUrl = await getS3File(templateDetails.key);
      let templateForm = (await axios.get(formFilePublicUrl)).data;

      let templateFormField = templateForm.fields[fieldName];
      if (!templateFormField.templateValues) {
        templateFormField.templateValues = [];
      }
      templateFormField.templateValues.push({
        item,
        addedFromPath: window.location.pathname,
      });

      try {
        await Storage.put(templateDetails.key, JSON.stringify(templateForm));
      } catch (e) {
        console.error(e);
        throw e;
      }

      const newForm = JSON.parse(JSON.stringify(currentForm));
      let itemFromCurrentForm = newForm.fields[fieldName].value[itemIndex];
      if (itemFromCurrentForm) {
        itemFromCurrentForm.addedToTemplate = true;
        setParentState(
          {
            form: newForm,
          },
          saveUserFields
        );
      }

      message.success("Item added to presets");
    } catch (e) {
      console.error("Error when adding to presets:", e);
      message.error("Failed to add field to presets");
    }
  }

  function displayTopLevelActionButtons() {
    if (readOnly) {
      return null;
    }
    return (
      <div className="top-level-actions">
        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            if (isFormEditor) {
              setParentState({
                fieldUnderEditName: fieldName,
                isNestedFieldModalOpen: true,
                selectedNestedContainerField: fieldData,
              });
            } else {
              setParentState({
                fieldUnderEditName: fieldName,
                isReportUserListModalOpen: true,
              });
            }
          }}
        >
          {windowWidth < 550 ? "Add new" : "Add new item"}
        </Button>
        {!fieldData.doNotAllowList && (
          <>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setState({
                  isAddItemFromListModalOpen: true,
                });

                if (isFormEditor) {
                  setParentState({
                    fieldUnderEditName: fieldName,
                    selectedNestedContainerField: fieldData,
                  });
                } else {
                  setParentState({
                    fieldUnderEditName: fieldName,
                  });
                }
              }}
            >
              Add preset item
            </Button>
            {isAuthorised(["MANAGE_TEMPLATES"]) && (
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  setState({
                    isManageItemListModalOpen: true,
                  });
                }}
              >
                Manage presets
              </Button>
            )}
          </>
        )}
      </div>
    );
  }

  function displayItemActionButtons({ item, itemIndex }) {
    return (
      <>
        {!readOnly && (
          <Button
            icon={<DeleteOutlined />}
            className="delete-item"
            onClick={() =>
              Modal.confirm({
                title: "Are you sure you want to delete this item?",
                onOk: () => {
                  removeUserListItem({
                    itemIndex,
                    fieldName,
                  });
                },
              })
            }
          />
        )}
        {!readOnly && (
          <Button
            icon={<EditOutlined />}
            className="edit-item"
            onClick={() =>
              setParentState({
                isReportUserListModalOpen: true,
                fieldUnderEditName: fieldName,
                fieldUnderEditValue: fieldData.value,
                subFieldUnderEditIndex: itemIndex,
              })
            }
          />
        )}

        {!fieldData.doNotAllowList && isAuthorised(["MANAGE_TEMPLATES", "ADD_FORM_ITEMS_TO_PRESETS"]) && (
          <Tooltip title="Add to presets">
            <Button
              icon={<PlusOutlined />}
              className="add-item-to-template"
              onClick={() =>
                addNestedFieldToTemplate.call(this, {
                  fieldName,
                  item,
                  itemIndex,
                  fieldData,
                })
              }
            />
          </Tooltip>
        )}
      </>
    );
  }

  function displayItems() {
    if (!fieldData.value || (Array.isArray(fieldData.value) && fieldData.value.length === 0)) {
      return null;
    }

    return fieldData.value.filter((item) => item).map(displayIndividualItem);
  }

  function displayIndividualItem(item, itemIndex) {
    return (
      <li className="user-list-option-item" key={itemIndex}>
        <div className="item-index">{itemIndex + 1}.</div>
        <div className="item-content">{displayItemFields({ item })}</div>
        <div className="item-action-buttons">{displayItemActionButtons({ item, itemIndex })}</div>
      </li>
    );
  }

  function displayItemFields({ item }) {
    let validKeys = Object.keys(item).filter((itemKey) => !FORM_NESTED_FIELD_LIST_FIELDS_TO_IGNORE.includes(itemKey));

    return validKeys.map((itemKey, itemFieldIndex) => {
      let itemFieldValue = fieldData.fields[itemKey];

      if (!itemFieldValue) {
        return null;
      }

      let valueElement = null;
      if (typeof item[itemKey] !== "object") {
        valueElement = item[itemKey];
      } else {
        switch (itemFieldValue.type) {
          case "attachmentPicker":
            valueElement = (
              <div>
                {item[itemKey].map((attachmentPath) => {
                  return <div key={attachmentPath}>{attachmentPath.split("/").slice(-1)[0]}</div>;
                })}
              </div>
            );
            break;
          default:
            valueElement = null;
        }
      }

      return (
        <Typography.Paragraph key={itemFieldIndex}>
          <b>{itemFieldValue.shortLabel || itemFieldValue.label}:</b> {valueElement}
        </Typography.Paragraph>
      );
    });
  }

  const { isAddItemFromListModalOpen, isManageItemListModalOpen } = getState();

  return (
    <>
      <div
        className={cx("input-group", "nested-field-list-with-modal", {
          "is-not-form-editor": !isFormEditor,
        })}
        key={fieldName}
      >
        <InfoItem
          includeColon={false}
          label={labelFunction()}
          inline
          value={displayTopLevelActionButtons()}
          extraContent={<ul className="user-list-options">{displayItems()}</ul>}
        />
      </div>
      {isAddItemFromListModalOpen && (
        <ModalAddItemFromList
          onSubmit={onAddItemFromListSubmit}
          onClose={() => {
            setState({ isAddItemFromListModalOpen: false });
            if (isFormEditor) {
              setParentState({
                fieldUnderEditName: null,
                selectedNestedContainerField: null,
              });
            } else {
              setParentState({
                fieldUnderEditName: null,
              });
            }
          }}
          templateDetails={templateDetails}
          fieldName={fieldName}
        />
      )}
      {isManageItemListModalOpen && (
        <ModalManageItemList
          form={form}
          onClose={() => {
            setState({ isManageItemListModalOpen: false });
            if (isFormEditor) {
              setParentState({
                fieldUnderEditName: null,
                selectedNestedContainerField: null,
              });
            } else {
              setParentState({
                fieldUnderEditName: null,
              });
            }
          }}
          templateDetails={templateDetails}
          fieldName={fieldName}
        />
      )}
    </>
  );
}
