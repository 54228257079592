import moment from "moment";
import TimesheetsPage from "TimesheetsPage/TimesheetsPage";

import "./ProjectTimesheets.scss";

export default function ProjectTimesheets({ project }) {
  return (
    <TimesheetsPage
      defaultStartDate={moment().subtract(2, "years")}
      onlyShowUsersWithTime
      defaultFilterProjectId={project.id}
      defaultFilterClientId={project.clientId}
      displayAllUsers
    />
  );
}
