import moment from "moment";

const fields = [
  {
    id: "requestFirstRequestId",
    fieldTypes: ["textfield"],
    label: "Request ID",
    value: ({ request }) => {
      return request?.id;
    },
  },
  {
    id: "requestRequestedForDate",
    fieldTypes: ["textfield"],
    label: "Request requested for date",
    isDate: true,
    value: ({ request, dateFormat, addDays }) => {
      if (!request || !request.requestedForDate) {
        return "";
      }
      return moment(request.requestedForDate).add(addDays, "day").format(dateFormat);
    },
  },
  {
    id: "requestRequesterInitials",
    fieldTypes: ["textfield"],
    label: "Requester initials",
    value: ({ request, users }) => {
      let requesterDetails = users.find((user) => user.id === request?.createdBy);
      if (!requesterDetails) {
        return;
      }

      let initials = [requesterDetails?.firstName?.charAt(0), requesterDetails?.lastName?.charAt(0)];
      return initials.join("");
    },
  },
  {
    id: "requestRequesterFullName",
    fieldTypes: ["textfield"],
    label: "Requester full name",
    value: ({ request, users }) => {
      let requesterDetails = users.find((user) => user.id === request?.createdBy);
      if (!requesterDetails) {
        return;
      }

      return `${requesterDetails?.firstName} ${requesterDetails?.lastName}`.trim();
    },
  },
  {
    id: "requestRequesterEmail",
    fieldTypes: ["textfield"],
    label: "Requester email",
    value: ({ request, users }) => {
      let requesterDetails = users.find((user) => user.id === request?.createdBy);
      if (!requesterDetails) {
        return;
      }

      return requesterDetails?.id;
    },
  },
  {
    id: "requestRequesterPhone",
    fieldTypes: ["textfield"],
    label: "Requester phone number",
    value: ({ request, users }) => {
      let requesterDetails = users.find((user) => user.id === request?.createdBy);
      if (!requesterDetails) {
        return;
      }

      let phone1 = requesterDetails.phone1 || "";
      let phone2 = requesterDetails.phone2 || "";
      let phones = [phone1, phone2].filter((x) => x);

      return phones.join(", ");
    },
  },
  {
    id: "requestRequesterSignature",
    fieldTypes: ["signature"],
    label: "Requester signature",
    value: ({ request, users }) => {
      let requesterDetails = users.find((user) => user.id === request?.createdBy);
      if (!requesterDetails) {
        return;
      }

      return {
        image: requesterDetails.signature,
        firstName: requesterDetails.firstName,
        lastName: requesterDetails.lastName,
      };
    },
  },
  {
    id: "requestTitle",
    fieldTypes: ["textfield"],
    label: "Request title",
    value: ({ request }) => {
      return request?.title;
    },
  },
];

export function getFields() {
  return fields;
}

export const label = "Request";
