import React from "react";
import { Select } from "antd";

import { assignRequest } from "common/requestHelpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";

import FormInputLabel from "Form/FormInputLabel/FormInputLabel";

function FormDropdown({
  fieldData,
  fieldName,
  nestedFieldParams,
  readOnly,
  updateFieldValue,
  contextForComputations,
  refreshKey,
}) {
  let style = {
    width: "100%",
  };
  if (fieldData.hasOwnProperty("width")) {
    style.width = fieldData.width;
  }

  async function onChange(newValue) {
    await updateFieldValue({
      fieldName,
      value: newValue,
      nestedFieldParams,
    });

    if (fieldData.assignsTheRequestToUser && contextForComputations.request) {
      const assignedToDetails = contextForComputations.users.find((x) => x.id === newValue);
      await assignRequest({
        requestId: contextForComputations.request.id,
        assignedToDetails,
        apiUser: contextForComputations.apiUser,
      });

      await callGraphQLSimple({
        message: `Failed to reassign ${getSimpleLabel("request")}`,
        queryName: "updateRequest",
        variables: {
          input: {
            id: contextForComputations.request.id,
            assignedTo: newValue || null,
          },
        },
      });
    }
  }

  return (
    <div className="input-group" key={`${fieldName}-dropdown-${refreshKey}`}>
      <FormInputLabel fieldData={fieldData} fieldName={fieldName} />

      <Select
        style={style}
        defaultValue={fieldData.value ? fieldData.value : undefined}
        placeholder={fieldData.placeholder}
        disabled={readOnly}
        onChange={onChange}
        allowClear={fieldData.allowClear}
      >
        {fieldData.options.map((option, index) => (
          <Select.Option key={index} value={option.value} disabled={option.disabled}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default React.memo(FormDropdown, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["readOnly", "refreshKey", "validationErrors", "hiddenFormFieldKeys"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps.topLevelParams[propName] !== nextProps.topLevelParams[propName]) {
      return false;
    }
  }

  return true;
});
