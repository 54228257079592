import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { DOCUMENT_FORM_SPREADSHEET_DEFAULT_ROWS, DOCUMENT_FORM_SPREADSHEET_DEFAULT_COLUMNS } from "common/constants";

import FormInputLabel from "Form/FormInputLabel/FormInputLabel";
// import ReviewTarget from "ReviewTarget/ReviewTarget";
import Spreadsheet from "Spreadsheet/Spreadsheet";
import ErrorBoundary from "ErrorBoundary/ErrorBoundary";

function FormSpreadsheet({ fieldData, fieldName, nestedFieldParams, updateFieldValue, readOnly }) {
  // const { isUnderReview } = this.state;

  let spreadsheetData;
  if (fieldData.value) {
    spreadsheetData = JSON.parse(fieldData.value);
  } else {
    spreadsheetData = {
      columnCount: DOCUMENT_FORM_SPREADSHEET_DEFAULT_COLUMNS,
      rowCount: DOCUMENT_FORM_SPREADSHEET_DEFAULT_ROWS,
      rows: [],
    };
  }

  return (
    <div className="input-group" key={fieldName}>
      <FormInputLabel fieldData={fieldData} fieldName={fieldName} />

      {/* <ReviewTarget name={`spreadsheet-${fieldName}`} {...this} {...this.props} visible={isUnderReview}> */}
      <ErrorBoundary>
        <div className="spreadsheet-container">
          <div className="spreadsheet-inner-container">
            <Spreadsheet
              data={spreadsheetData}
              onChange={(value) => {
                updateFieldValue({
                  fieldName,
                  value,
                  nestedFieldParams,
                });
              }}
              isReadOnly={readOnly}
            />

            {!readOnly && (
              <div className="after-spreadsheet">
                <Button
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    updateFieldValue({
                      fieldName,
                      value: JSON.stringify({
                        ...spreadsheetData,
                        rowCount: spreadsheetData.rowCount + 1,
                      }),
                      nestedFieldParams,
                    });
                  }}
                >
                  Add row
                </Button>
              </div>
            )}
          </div>
        </div>
      </ErrorBoundary>
      {/* </ReviewTarget> */}
    </div>
  );
}

export default React.memo(FormSpreadsheet, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["readOnly", "refreshKey", "validationErrors", "hiddenFormFieldKeys"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps.topLevelParams[propName] !== nextProps.topLevelParams[propName]) {
      return false;
    }
  }

  return true;
});
