import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { message, Typography, Modal, Tooltip, Button, Empty, Tag, Select, Table } from "antd";
import { DeleteOutlined, PlusCircleOutlined, ExclamationCircleOutlined, StarFilled } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { downloadBase64, sanitiseCSVValue, getLabel } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { isAuthorised } from "common/permissions";
import { getSimpleLabel } from "common/labels";
import { contactColumns } from "./contactColumns";

import CreateClientModal from "Modals/CreateClientModal/CreateClientModal";
import BatchCreateClientModal from "Modals/BatchCreateClientModal/BatchCreateClientModal";
import ClientLogo from "ClientLogo/ClientLogo";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";

import "./ClientsPage.scss";

export class ClientsPage extends React.Component {
  _isMounted = false;

  state = {
    isCreateClientModalVisible: false,
    isBatchCreateClientModalVisible: false,
    selectedClientId: null,
    sortBy: "name",
  };

  componentDidMount() {
    this.props.setBoxedLayout(false);

    callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "CLIENTS",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
  }

  componentWillUnmount() {
    this.props.setBoxedLayout(true);
  }

  deleteClient = async (client) => {
    try {
      await callGraphQLSimple({
        message: "Could not delete client",
        queryName: "deleteClient",
        variables: {
          input: {
            id: client.id,
          },
        },
      });
    } catch (e) {
      message.error(
        <Typography.Text>
          Client <b>{client.name}</b> could not be deleted: <b>{e.message}</b>
        </Typography.Text>
      );
    }
  };

  confirmClientDelete = async (client) => {
    const { history, projects } = this.props;

    const projectsInClient = projects.filter((x) => x.clientId === client.id);
    if (projectsInClient.length !== 0) {
      let modal = Modal.error({
        title: "Cannot delete client",
        className: "cannot-delete-client-modal",
        content: (
          <>
            <b>{client.name}</b> has the following projects associated to it, and thus cannot be deleted:
            <ul className="projects-which-use-client">
              {projectsInClient.map((project) => (
                <li
                  key={project.id}
                  onClick={() => {
                    modal.destroy();
                    history.push(`/projects/${project.id}`);
                  }}
                >
                  {project.title}
                </li>
              ))}
            </ul>
          </>
        ),
      });
      return;
    }

    Modal.confirm({
      title: "Confirm client delete",
      icon: <ExclamationCircleOutlined />,
      className: "delete-client-modal",
      content: (
        <>
          Are you sure you want to delete <b>{client.name}</b>?
        </>
      ),
      onOk: () => this.deleteClient(client),
    });
  };

  displayActionsForClient = (client) => {
    let actions = [];

    actions.push(
      <Tooltip title="Delete client" key={2} data-cy="delete-client-button">
        <Button
          className="delete-client"
          icon={<DeleteOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            this.confirmClientDelete(client);
          }}
        />
      </Tooltip>
    );

    return actions;
  };

  getSortedClients = (clients, sortBy) => {
    if (sortBy === "name") {
      const sortedClients = clients.sort((a, b) =>
        a.name.trim().toLowerCase() < b.name.trim().toLowerCase() ? -1 : 1
      );
      return sortedClients;
    } else if (sortBy === "fee-overrides") {
      const sortedClients = clients.sort((a, b) => {
        if (a.fees && a.fees.length > 0 && (!b.fees || b.fees.length === 0)) {
          return 1;
        } else if (b.fees && b.fees.length > 0 && (!a.fees || a.fees.length === 0)) {
          return -1;
        } else {
          return a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? -1 : 1;
        }
      });
      return sortedClients.reverse();
    } else if (sortBy === "priority") {
      const sortedClients = clients.sort((a, b) => {
        if (a.isPriority && !b.isPriority) {
          return 1;
        } else if (!a.isPriority && b.isPriority) {
          return -1;
        } else {
          return a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? -1 : 1;
        }
      });
      return sortedClients.reverse();
    } else if (sortBy === "creation-date") {
      const sortedClients = clients.sort((a, b) => {
        if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
          return 1;
        } else if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
          return -1;
        } else {
          return a.name.trim().toLowerCase() < b.name.trim().toLowerCase() ? -1 : 1;
        }
      });
      return sortedClients;
    } else {
      return clients;
    }
  };

  displayClientList = () => {
    const { clients } = this.props;
    const { sortBy } = this.state;
    if (!clients || clients.length === 0) {
      return (
        <Empty
          description={`No ${getLabel({
            id: "clients",
            defaultValue: "clients",
          })} found`}
        />
      );
    }

    const sortedClients = this.getSortedClients([...clients], sortBy);
    const columns = [
      {
        title: `${getSimpleLabel("Client")} name`,
        dataIndex: "name",
        key: "name",
        align: "left",
        render: (text) => (
          <Typography.Text className="client-name" data-cy="client-name">
            {text}
          </Typography.Text>
        ),
      },

      {
        title: "Tags",
        key: "tags",
        align: "left",
        dataIndex: "tags",
        render: (_, client) => (
          <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            {client?.fees && client?.fees?.length > 0 && (
              <div>
                <Tag className="client-fee-override-tag">Has fee override</Tag>
              </div>
            )}
            {client.isPriority && (
              <Tag color="blue" style={{ width: "fit-content" }}>
                <StarFilled /> Priority {getSimpleLabel("client")}
              </Tag>
            )}
          </div>
        ),
      },
      {
        title: "Logo",
        dataIndex: "logo",
        key: "logo",
        width: 150,
        align: "center",
        render: (_, client) => (
          <div className="client-logo-container">
            <ClientLogo client={client} displayNameIfLogoIsMissing={false} />
          </div>
        ),
      },
    ];

    const handleRowClick = (client) => {
      this.props.history.push(`/clients/${client.id}`);
    };

    return (
      <div className="clients">
        <Table
          rowKey="id"
          sticky={{
            offsetHeader: 0,
          }}
          columns={columns}
          dataSource={sortedClients}
          onRow={(client) => {
            return {
              onClick: () => handleRowClick(client),
            };
          }}
          pagination={{ pageSize: 50, hideOnSinglePage: true }}
        />
      </div>
    );
  };

  getAllClientContacts = (clients) => {
    let allContacts = [];

    for (let i = 0; i < clients.length; i++) {
      const client = clients[i];

      if (client.contacts && client.contacts?.length > 0) {
        for (let j = 0; j < client.contacts.length; j++) {
          const contact = client.contacts[j];

          allContacts.push({ ...contact, clientName: client.name });
        }
      }
    }

    return allContacts;
  };

  export = async () => {
    const { clients, organisationDetails } = this.props;

    const columns = [...contactColumns];
    let csvContent = columns.map((column) => column.title).join(",") + "\n";

    const allContacts = this.getAllClientContacts(clients);

    csvContent += allContacts
      .map((contact, i) => {
        return columns
          .map((column) => {
            if (column.fieldFunction) {
              return sanitiseCSVValue(column.fieldFunction(contact));
            } else {
              return sanitiseCSVValue(contact[column.dataIndex]);
            }
          })
          .join(",");
      })
      .join("\n");

    let base64CSV = `data:text/csv;base64,${btoa(unescape(encodeURIComponent(csvContent)))}`;
    await downloadBase64({
      base64String: base64CSV,
      fileName: `${organisationDetails.id}-all-client-contacts-${moment().format("DD-MM-YYYY")}.csv`,
    });
  };

  render() {
    const { apiUser, clients, organisationDetails } = this.props;
    const { isCreateClientModalVisible, isBatchCreateClientModalVisible, sortBy } = this.state;

    return (
      <div className="clients-page">
        <div className="main-actions">
          <Typography.Title className="clients-page-title">
            {clients.length}{" "}
            {getLabel({
              id: "clients",
              defaultValue: "clients",
            })}
          </Typography.Title>
          {!organisationDetails.usesClientInitials && (
            <ButtonWithPermissions
              permissions={["CREATE_CLIENT"]}
              className="batch-create"
              onClick={() => this.setState({ isBatchCreateClientModalVisible: true })}
            >
              <PlusCircleOutlined /> Batch create
            </ButtonWithPermissions>
          )}
          <div className="sort-filters">
            <Typography.Text className="sort-by-label">Sort by: </Typography.Text>
            <Select
              placeholder="Choose a sorting type"
              className="client-sort"
              onChange={(type) => {
                this.setState({ sortBy: type });
              }}
              value={sortBy}
            >
              <Select.Option key={"name"} value={"name"}>
                Name
              </Select.Option>
              <Select.Option key={"fee-overrides"} value={"fee-overrides"}>
                Fee overrides
              </Select.Option>
              <Select.Option key={"priority"} value={"priority"}>
                Priority
              </Select.Option>
              <Select.Option key={"creation-date"} value={"creation-date"}>
                Creation date
              </Select.Option>
            </Select>
          </div>
          {isAuthorised(["CLIENTS.DOWNLOAD_CONTACTS"]) && (
            <Button
              type="primary"
              className="export-client-contacts-list"
              onClick={this.export}
              data-cy="export-client-contacts-list"
            >
              Export contacts to CSV
            </Button>
          )}
          <ButtonWithPermissions
            permissions={["CREATE_CLIENT"]}
            type="primary"
            className="create-client"
            onClick={() => this.setState({ isCreateClientModalVisible: true })}
            data-cy="create-client-modal"
          >
            <PlusCircleOutlined /> Create{" "}
            {getLabel({
              id: "client",
              defaultValue: "client",
            })}
          </ButtonWithPermissions>
        </div>
        <div className="main-content">
          {this.displayClientList()}
          {isCreateClientModalVisible && (
            <CreateClientModal apiUser={apiUser} onClose={() => this.setState({ isCreateClientModalVisible: false })} />
          )}
          {isBatchCreateClientModalVisible && (
            <BatchCreateClientModal
              apiUser={apiUser}
              onClose={() => this.setState({ isBatchCreateClientModalVisible: false })}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: ClientsPage,
    subscriptions: ["clients", "organisationDetails", "projects"],
  })
);
