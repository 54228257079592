import { Tag, Modal, Menu, Dropdown, Button, Typography, message } from "antd";
import uniqid from "uniqid";
import moment from "moment";
import {
  CheckCircleOutlined,
  CommentOutlined,
  CloseCircleOutlined,
  CaretDownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { checkIfS3FileHasChangedSince } from "common/helpers";
import { REVIEW_STATUS_READABLE, REVIEW_SECONDARY_STATUS_READABLE } from "common/constants";
import { getSimpleLabel } from "common/labels";
import { callGraphQLSimple } from "common/apiHelpers";
import { recordActivityItem } from "QuoteDetailsPage/quoteHelpers";
import {
  sendQuoteReviewRequestNotification,
  sendQuoteReviewResultNotification,
  sendNotificationToQuoteReviewer,
} from "common/notificationHelpers";

import Card from "Card/Card";
import Avatar from "Avatar/Avatar";
import UsersFilter from "UsersFilter/UsersFilter";

import "./QuoteReviewSummary.scss";

export default function QuoteReviewSummary(props) {
  const { quote, apiUser, users, clients, potentialReviewers, onSubmitReviewStart, dateTimeToCheckFormAgainst } = props;

  let review = quote.reviews.items[0];
  const reviewStatus = quote.reviewStatus;
  const userIsAuthor = quote.assignedTo === apiUser.id;
  const userIsReviewer = review && quote.checkedBy === apiUser.id;
  const usersForDropdown = [...potentialReviewers];

  if (quote.checkedBy && !usersForDropdown.some((user) => user.id === quote.checkedBy)) {
    usersForDropdown.unshift(users.find((user) => user.id === quote.checkedBy));
  }

  async function approveReview() {
    let reviewerQuoteReviewLimit = apiUser.quoteReviewLimit;
    let reviewerQuoteCreationLimit = apiUser.quoteCreationLimit;

    let authorityLevelToUse = quote.author === apiUser.id ? reviewerQuoteCreationLimit : reviewerQuoteReviewLimit;

    let exceedsWording = `exceeds the review authority of the reviewer`;

    if (quote.author === apiUser.id) {
      exceedsWording = `exceeds your self-review authority`;
    }

    if (!authorityLevelToUse || authorityLevelToUse < quote.total) {
      Modal.info({
        title: "Insufficient review authority",
        maskClosable: true,
        content: (
          <>
            The total value of this {getSimpleLabel("quote")} <b>({window.formatCurrency("GBP", quote.total)})</b>{" "}
            {exceedsWording} <b>({window.formatCurrency("GBP", authorityLevelToUse || 0)})</b>.
          </>
        ),
      });
      return;
    }
    submitReview("SUCCESS");
  }

  async function notifyReviewer(reviewSecondaryStatus) {
    await callGraphQLSimple({
      message: "Failed to record review status",
      queryName: "updateQuote",
      variables: {
        input: {
          id: quote.id,
          reviewSecondaryStatus,
        },
      },
    });

    recordActivityItem({
      quote: { ...quote, reviewStatus, reviewSecondaryStatus },
      type: "REVIEW_ACTIVITY",
      content: `Reviewer notified: ${REVIEW_SECONDARY_STATUS_READABLE[reviewSecondaryStatus]}`,
      author: apiUser.id,
      clients,
      users,
    });

    sendNotificationToQuoteReviewer({
      users,
      apiUser,
      reviewer: quote.checkedBy,
      quoteId: quote.id,
      quoteTitle: quote.title,
      clientName: quote.client?.name,
      projectTitle: quote.project?.title,
      reviewSecondaryStatusReadable: REVIEW_SECONDARY_STATUS_READABLE[reviewSecondaryStatus],
    });
  }

  async function confirmCancelApproval() {
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm cancel approval",
          maskClosable: true,
          content: <>Are you sure you want to cancel the approval for this review?</>,
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }
    submitReview.call(this, "IN_PROGRESS");
  }

  async function reopenReview() {
    submitReview("IN_PROGRESS");
  }

  async function closeReview() {
    submitReview("CLOSED");
  }

  async function submitReview(reviewStatus) {
    const messageKey = "submit-quote-review";

    message.loading({
      content: "Checking for a newer version...",
      key: messageKey,
      duration: 0,
    });

    const upToDateReview = (
      await callGraphQLSimple({
        message: "Failed to retrieve review details",
        queryName: "getReview",
        variables: {
          id: review.id,
        },
      })
    ).data.getReview;

    if (!window.Cypress) {
      if (
        await checkIfS3FileHasChangedSince({
          key: quote.fileKey,
          dateTimeToCompareAgainst: dateTimeToCheckFormAgainst,
        })
      ) {
        message.error({
          content: `This version of the ${getSimpleLabel("quote")} is out of date, please refresh and try again.`,
          key: messageKey,
          duration: 5,
        });
        return;
      }
    }

    message.destroy(messageKey);

    onSubmitReviewStart();

    const loadingModal = Modal.info({
      title: "Updating review status",
      maskClosable: false,
      closable: false,
      icon: <LoadingOutlined />,
      footer: null,
      className: "modal-no-buttons",
      content: (
        <>
          <Typography.Text style={{ display: "block", marginBottom: "1rem" }}>
            Do not leave this page while we submit your review.
            <br /> We are generating a new PDF. This may take a few seconds.
          </Typography.Text>
        </>
      ),
    });

    try {
      const now = new Date().toISOString();
      await callGraphQLSimple({
        message: "Failed to update review status",
        queryName: "updateQuote",
        variables: {
          input: {
            id: quote.id,
            isUnderReview: reviewStatus !== "SUCCESS" && reviewStatus !== "CLOSED",
            reviewStatus,
            reviewSecondaryStatus: null,
            reviewApprovedAt: reviewStatus === "SUCCESS" ? now : null,
          },
        },
      });

      await callGraphQLSimple({
        message: "Failed to submit review",
        queryName: "updateReview",
        variables: {
          input: {
            id: upToDateReview.id,
            reviewThread: [
              ...upToDateReview.reviewThread,
              {
                id: uniqid(),
                type: "STATUS_CHANGE",
                createdAt: new Date().toISOString(),
                content: reviewStatus,
                author: apiUser.id,
              },
            ],
          },
        },
      });
      await new Promise((resolve) => setTimeout(resolve, 5000));
      loadingModal.destroy();
      message.success({
        content: "Review submitted successfully",
        key: messageKey,
      });
    } catch (e) {
      loadingModal.destroy();
      message.error({
        content: "Failed to submit review",
        key: messageKey,
      });
    }

    let type = "REVIEW_REJECTED";
    if (reviewStatus === "SUCCESS") {
      type = "REVIEW_ACCEPTED";
    } else if (reviewStatus === "CLOSED") {
      type = "REVIEW_CLOSED";
    } else if (reviewStatus === "IN_PROGRESS") {
      type = "REVIEW_OPENED";
      sendQuoteReviewRequestNotification({
        users,
        apiUser,
        reviewer: quote.checkedBy,
        quoteId: quote.id,
        quoteTitle: quote.title,
        clientName: quote.client?.name,
        projectTitle: quote.project?.title,
      });
    }

    sendQuoteReviewResultNotification({
      users,
      apiUser,
      quoteId: quote.id,
      quoteTitle: quote.title,
      clientName: quote.client?.name,
      projectTitle: quote.project?.title,
      reviewStatusReadable: REVIEW_STATUS_READABLE[reviewStatus].label,
      quoteAssignedTo: quote.assignedTo,
    });

    recordActivityItem({
      quote: { ...quote, reviewStatus },
      type,
      author: apiUser.id,
      clients,
      users,
    });
  }

  function displayReviewerOptions() {
    return (
      <Menu className="review-options-menu">
        <Menu.Item
          key="1"
          className="request-changes"
          onClick={() => submitReview("CHANGES_REQUESTED")}
          data-cy="request-changes-button"
        >
          <CloseCircleOutlined /> Request changes
        </Menu.Item>

        <Menu.Item
          key="0"
          className="with-comments"
          onClick={() => submitReview("WITH_COMMENTS")}
          data-cy="with-comments-button"
        >
          <CommentOutlined /> With comments
        </Menu.Item>
        <Menu.Item key="3" className="approve" onClick={approveReview} data-cy="approve-review-button">
          <CheckCircleOutlined /> Approve
        </Menu.Item>
      </Menu>
    );
  }

  function displayAuthorOptions() {
    return (
      <Menu className="review-options-menu">
        <Menu.Item key="0" onClick={() => notifyReviewer("COMMENTS_ADDRESSED")}>
          <CheckCircleOutlined /> Comments addressed
        </Menu.Item>

        <Menu.Item key="1" onClick={() => notifyReviewer("INFO_REQUIRED")}>
          <CommentOutlined /> Information required
        </Menu.Item>
      </Menu>
    );
  }

  async function changeReviewer(newReviewer) {
    sendQuoteReviewRequestNotification({
      users,
      apiUser,
      reviewer: newReviewer,
      quoteId: quote.id,
      quoteTitle: quote.title,
      clientName: quote.client?.name,
      projectTitle: quote.project?.title,
    });

    await callGraphQLSimple({
      message: "Failed to request review",
      queryName: "updateQuote",
      variables: {
        input: {
          id: quote.id,
          checkedBy: newReviewer,
        },
      },
    });
  }

  return (
    <Card
      className="quote-review-summary"
      withSpace
      title={
        <Typography.Text data-cy="quote-review-summary-title">{getSimpleLabel("Quote")} review summary</Typography.Text>
      }
      actions={
        <div className="actions" key="actions">
          <div className="section">
            {reviewStatus === "CLOSED" ? (
              <Button className="reopen-review" onClick={reopenReview} type="primary" data-cy="reopen-review-button">
                Reopen review
              </Button>
            ) : reviewStatus !== "SUCCESS" ? (
              <Button
                disabled={reviewStatus === "SUCCESS"}
                className="close-review"
                onClick={closeReview}
                data-cy="cancel-review-button"
              >
                Cancel review
              </Button>
            ) : null}

            {reviewStatus === "SUCCESS" && (
              <Button type="primary" onClick={confirmCancelApproval} data-cy="cancel-approval-button">
                <span>Cancel approval</span>
              </Button>
            )}

            {reviewStatus !== "CLOSED" && userIsReviewer && reviewStatus !== "SUCCESS" && (
              <Dropdown
                overlay={displayReviewerOptions()}
                overlayClassName="review-revision-dropdown"
                trigger={["click"]}
              >
                <Button type="primary" data-cy="submit-review-button">
                  <CaretDownOutlined />
                  <span>Submit review</span>
                </Button>
              </Dropdown>
            )}

            {reviewStatus !== "CLOSED" && reviewStatus !== "SUCCESS" && userIsAuthor ? (
              <Dropdown
                overlay={displayAuthorOptions()}
                overlayClassName="review-revision-dropdown"
                trigger={["click"]}
              >
                <Button type="primary">
                  <CaretDownOutlined />
                  <span>Notify reviewer</span>
                </Button>
              </Dropdown>
            ) : null}
          </div>
        </div>
      }
    >
      <div className="content">
        <div className="review-metadata-item">
          <Typography.Text className="item-label">Reviewer</Typography.Text>
          {!quote.isFinished && !quote.isArchived && !quote.reviewApprovedAt ? (
            <UsersFilter
              className="reviewer-picker"
              value={quote.checkedBy}
              onChange={changeReviewer}
              orderedActiveUsers={usersForDropdown}
              includeUnassigned={false}
            />
          ) : (
            <Avatar user={users.find((x) => x.id === quote.checkedBy)} showLabel={true} />
          )}
        </div>
        <div className="review-metadata-item">
          <Typography.Text className="item-label">Review status</Typography.Text>
          <div className="item-value">
            {REVIEW_STATUS_READABLE[quote.reviewStatus] && (
              <Tag color={REVIEW_STATUS_READABLE[quote.reviewStatus].color} data-cy="review-status-tag">
                {REVIEW_STATUS_READABLE[quote.reviewStatus].label}
              </Tag>
            )}
            {quote.reviewSecondaryStatus && (
              <Tag
                color={quote.reviewSecondaryStatus === "INFO_REQUIRED" ? "orange" : "green"}
                data-cy="review-secondary-status-tag"
              >
                {REVIEW_SECONDARY_STATUS_READABLE[quote.reviewSecondaryStatus]}
              </Tag>
            )}
          </div>
        </div>
        <div className="review-metadata-item">
          <Typography.Text className="item-label">Review started</Typography.Text>
          <Typography.Text className="item-value">
            {moment(review.createdAt).format("DD MMM YYYY - HH:mm:ss")}
          </Typography.Text>
        </div>
        {quote.reviewApprovedAt && (
          <div className="review-metadata-item">
            <Typography.Text className="item-label">Review approved</Typography.Text>
            <Typography.Text className="item-value">
              {moment(quote.reviewApprovedAt).format("DD MMM YYYY - HH:mm:ss")}
            </Typography.Text>
          </div>
        )}
      </div>
    </Card>
  );
}
