import moment from "moment";
import TimesheetsPage from "TimesheetsPage/TimesheetsPage";

import "./TaskTimesheets.scss";

export default function TaskTimesheets({ task }) {
  return (
    <TimesheetsPage
      defaultStartDate={moment().subtract(20, "years")}
      onlyShowUsersWithTime
      defaultFilterTaskId={task.id}
      displayAllUsers
    />
  );
}
