import moment from "moment";
import { REPEATABLE_FIELDS, getSectionContents } from "./form";

const fields = [];

// const REPEATABLE_FIELDS = ["checkbox-list", "attachmentPicker", "nestedFieldListWithModal", "nestedFieldListNoModal"];

export function calculateReadableSize(item) {
  let sizeReadable;
  let GB = Math.pow(1024, 3);
  let MB = Math.pow(1024, 2);
  let KB = 1024;

  if (item.size) {
    if (item.size > GB * 0.9) {
      sizeReadable = `${Math.round((item.size / GB) * 100) / 100}GB`;
    } else if (item.size > MB * 0.9) {
      sizeReadable = `${Math.round((item.size / MB) * 100) / 100}MB`;
    } else {
      sizeReadable = `${Math.floor(item.size / KB)}KB`;
    }
  }
  return sizeReadable;
}

export function getFields(params) {
  let { form, repeatFor, formElement, repeatElement, parentRepeat } = params;
  let formFields = [];

  // debugger;

  if (formElement) {
    switch (repeatElement.type) {
      case "checkbox-list":
        formFields = [
          {
            id: "checkbox-list-item-value",
            value: formElement,
            supportsSeparator: true,
          },
          {
            id: "sheet-export-checkbox-list-item-value",
            value: async (params) => {
              let sheetDetails;
              if (!formElement) {
                return;
              }
              if (global.isBrowser) {
                sheetDetails = (
                  await window.callGraphQLSimple({
                    query: "getSheet",
                    variables: {
                      id: formElement,
                    },
                  })
                ).data.getSheet;
              } else {
                sheetDetails = (
                  await global.callGraphQLSimple({
                    queryName: "getSheet",
                    variables: {
                      id: formElement,
                    },
                  })
                ).data.getSheet;
              }

              if (!sheetDetails) {
                throw new Error(`Cannot find sheet with id ${formElement}`);
              }

              let latestSheetRevision = sheetDetails.revisions.items.slice(-1)[0];
              let firstExport = latestSheetRevision.exports[0].key;
              return firstExport;
            },
          },
          {
            id: "file-export-checkbox-list-item-value",
            value: async (params) => {
              let fileDetails;
              if (!formElement) {
                return;
              }
              if (global.isBrowser) {
                fileDetails = (
                  await window.callGraphQLSimple({
                    queryCustom: "getFile",
                    variables: {
                      id: formElement,
                    },
                  })
                ).data.getFile;
              } else {
                fileDetails = (
                  await global.callGraphQLSimple({
                    queryName: "getFile",
                    variables: {
                      id: formElement,
                    },
                  })
                ).data.getFile;
              }

              if (!fileDetails) {
                throw new Error(`Cannot find file with id ${formElement}`);
              }

              let latestFileVersion = fileDetails.versions.items.slice(-1)[0];
              let firstExport = latestFileVersion.exports[0].key;
              console.log("firstExport = ", firstExport);
              return firstExport;
            },
          },
        ];
        break;
      case "attachmentPicker":
        formFields = [
          {
            id: "attachment-picker-item-path",
            value: formElement,
          },
          {
            id: "attachment-picker-item-name",
            value: formElement.split("/").slice(-1)[0],
          },
          {
            id: "attachment-picker-item-name-without-extension",
            value: formElement.split("/").slice(-1)[0].split(".")[0],
          },
          {
            id: "attachment-picker-item-size",
            value:
              repeatElement.sizes && repeatElement.sizes[formElement]
                ? calculateReadableSize({
                    size: repeatElement.sizes[formElement],
                  })
                : "",
          },
          {
            id: "attachment-picker-item-last-modified",
            value:
              repeatElement.dates && repeatElement.dates[formElement]
                ? moment(repeatElement.dates[formElement]).format("DD-MM-YYYY")
                : "",
          },
          {
            id: "attachment-picker-custom-field",
            label: "Custom field",
            value: ({ customField, dateFormat }) => {
              if (!customField) {
                return "";
              }

              if (!repeatElement) {
                return "";
              }

              let customFieldPlural = `${customField}s`;

              let targetValueSeries = repeatElement[customFieldPlural];

              if (!targetValueSeries) {
                return "";
              }
              const targetValue = targetValueSeries[formElement];

              if (!targetValue) {
                return "";
              }

              // check if targetValue is a valid date
              if (moment(targetValue).isValid()) {
                return moment(targetValue).format(dateFormat);
              }

              return targetValue;
            },
          },
        ];
        break;
      case "nestedFieldListWithModal":
      case "nestedFieldListNoModal":
        for (let fieldId in formElement) {
          let formFieldToAdd = {
            ...repeatElement.fields[fieldId],
            id: fieldId,
            value: formElement[fieldId],
          };
          formFields.push(formFieldToAdd);
        }
        break;
      case "dynamicSectionList":
        // this part is to actually display the values in the document output
        for (let childFieldId in form.fields) {
          let childField = form.fields[childFieldId];
          if (childField.parentSection === formElement.id) {
            formFields.push({
              ...childField,
              id: childFieldId.split("-").slice(0, -1).join("-"),
            });
          }
        }

      default:
        break;
    }

    return formFields;
  } else {
    if (!form) {
      return fields;
    }

    if (!repeatFor) {
      if (parentRepeat && parentRepeat.repeatForFieldName === "formElement") {
        for (let fieldId in parentRepeat.fields) {
          let field = parentRepeat.fields[fieldId];

          if (REPEATABLE_FIELDS.includes(field.type)) {
            let newField = {
              ...field,
              label: `Each item in ${field.label}`,
              id: `repeat_${fieldId}`,
              type: field.type,
              fieldTypes: ["repeatFor"],
              repeatForFieldName: "formElementChild",
            };

            console.log("newField = ", newField);

            formFields.push(newField);
          }
        }

        let returnValue = [...fields, ...formFields];
        return returnValue;
      } else {
        return fields;
      }
    }

    if (repeatFor.startsWith("repeat_")) {
      repeatFor = repeatFor.replace("repeat_", "");
    }
    let targetContainerField = findFieldWithIdInTree(form, repeatFor);
    formFields = getFieldsForFieldType(targetContainerField, form);
  }

  return [...fields, ...formFields];
}

// write a function which searches a tree where each element has a "fields" property and returns the field with the given id
function findFieldWithIdInTree(tree, id) {
  if (tree.fields && tree.fields[id]) {
    return tree.fields[id];
  } else {
    for (let childId in tree.fields) {
      let field = findFieldWithIdInTree(tree.fields[childId], id);
      if (field) {
        return field;
      }
    }
  }
}

function getFieldsForFieldType(containerField, form) {
  let formFields = [];
  switch (containerField?.type) {
    case "checkbox-list":
      formFields = [
        {
          id: "checkbox-list-item-value",
          label: "Checkbox item",
        },
        {
          id: "sheet-export-checkbox-list-item-value",
          label: "Latest sheet export",
        },
        {
          id: "file-export-checkbox-list-item-value",
          label: "Latest file export",
        },
      ];
      break;
    case "attachmentPicker":
      formFields = [
        {
          id: "attachment-picker-item-name",
          label: "File name",
        },
        {
          id: "attachment-picker-item-name-without-extension",
          label: "File name without extension",
        },
        {
          id: "attachment-picker-item-path",
          label: "File path",
        },
        {
          id: "attachment-picker-item-size",
          label: "File size",
        },
        {
          id: "attachment-picker-item-last-modified",
          label: "Last modified",
        },
        {
          id: "attachment-picker-custom-field",
          label: "Custom field",
        },
      ];
      break;
    case "nestedFieldListWithModal":
    case "nestedFieldListNoModal":
      for (let fieldId in containerField?.fields) {
        let field = containerField.fields[fieldId];

        formFields.push({
          ...field,
          id: fieldId,
          type: "string",
          fieldType: field.type,
        });
      }
      break;
    case "dynamicSectionList":
      // this part is to present the possible options in the template editor
      let placeholderId = `placeholderForDynamicSectionList-${containerField.id}`;
      let sectionFields = [];
      for (let fieldName in form.fields) {
        let field = form.fields[fieldName];
        if (field.parentSection === placeholderId) {
          sectionFields.push(field);
        }
      }

      for (let field of sectionFields) {
        formFields.push({
          ...field,
          type: "string",
          fieldType: field.type,
        });
      }

      break;
    default:
      break;
  }
  return formFields;
}

export const label = "Form element";
