import Attachments from "Attachments/Attachments";
import FormInputLabel from "Form/FormInputLabel/FormInputLabel";

export default function FormAttachmentUploader(props) {
  const {
    fieldData,
    fieldName,
    nestedFieldParams,
    readOnly,
    updateFieldValue,
    refreshKey,
    topLevelParams,
    contextForComputations,
  } = props;

  const { form } = topLevelParams;

  return (
    <div className="input-group" key={`${fieldName}-attachment-uploader-${refreshKey}`}>
      <FormInputLabel fieldData={fieldData} fieldName={fieldName} />

      <Attachments
        {...contextForComputations}
        defaultPath={fieldData.defaultPath ? fieldData.defaultPath : undefined}
        defaultRelativePath={fieldData.defaultRelativePath || fieldData.label}
        presentationOnly={fieldData.presentationOnly}
        readOnly={readOnly}
        isInModal={false}
        includeHomeButton={false}
        allowDownload={fieldData.allowDownload !== undefined ? fieldData.allowDownload : true}
        onUpload={({ result }) => {
          const newFields = JSON.parse(JSON.stringify(form.fields));

          let newFieldValue = [...(newFields[fieldName].value || [])];
          for (let resultItem of result) {
            if (resultItem.status === "fulfilled" && !newFieldValue.includes(resultItem.value)) {
              newFieldValue.push(resultItem.value);
            }
          }

          updateFieldValue({
            fieldName,
            value: newFieldValue,
            nestedFieldParams,
          });
        }}
      />
    </div>
  );
}
