import { Checkbox, Select, message } from "antd";
import { useCallback, useRef } from "react";
import { useGetSetState } from "react-use";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import cx from "classnames";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";
import { STOCK_ITEM_TYPES } from "common/constants";

import Card from "Card/Card";
import Tabs from "reusableComponents/Tabs/Tabs";
import InfoItem from "InfoItem/InfoItem";
import Input from "Input/Input";
import StockItemMaintenanceTab from "./StockItemMaintenanceTab/StockItemMaintenanceTab";
import Textarea from "Form/Textarea/Textarea";

import "./StockItemDetailsPage.scss";

export function StockItemDetailsPage({ stockItem, apiUser, organisationDetails, history }) {
  const [getState, setState] = useGetSetState({
    activeTab: "maintenance",
  });

  let initialDescriptionNewEditor = useRef();

  if (stockItem.description && stockItem.description !== "") {
    initialDescriptionNewEditor.current = JSON.parse(stockItem.description);
  }

  const debouncedChangeDescription = useCallback(_.debounce(changeDescription, 1500), []);

  const { activeTab } = getState();

  async function changeDisplayOnTimeline(e) {
    await callGraphQLSimple({
      message: `Failed to update ${getSimpleLabel("stock item")}`,
      queryName: "updateStockItem",
      variables: {
        input: {
          id: stockItem.id,
          displayOnTimeline: e.target.checked,
        },
      },
    });
  }

  async function changeType(type) {
    await callGraphQLSimple({
      message: `Failed to update ${getSimpleLabel("stock item")} type`,
      queryName: "updateStockItem",
      variables: {
        input: {
          id: stockItem.id,
          type,
        },
      },
    });
  }

  async function changeDescription(description) {
    await callGraphQLSimple({
      message: "Failed to update description",
      queryName: "updateStockItem",
      variables: {
        input: {
          id: stockItem.id,
          description,
        },
      },
    });
  }

  async function changeName(name) {
    if (!name) {
      message.error("Name cannot be empty");
      return;
    }

    await callGraphQLSimple({
      message: "Failed to update name",
      queryName: "updateStockItem",
      variables: {
        input: {
          id: stockItem.id,
          name,
        },
      },
    });
  }

  const basePath = `/stock-items/${stockItem.id}`;

  let tabs = [];

  tabs.push({
    id: "maintenance",
    title: `${getSimpleLabel("Maintenance")}`,
    content: (
      <StockItemMaintenanceTab apiUser={apiUser} organisationDetails={organisationDetails} stockItem={stockItem} />
    ),
  });

  return (
    <div className="stock-item-details-page">
      <Card
        className="stock-item-summary-card"
        title={
          <Input
            data-cy="stock-item-details-details-name"
            defaultValue={stockItem.name}
            className="stock-item-name"
            onChange={changeName}
            fullWidth
            flexGrow
            disabled={stockItem.isArchived}
            multiLine
            allowEnter={false}
            fireOnChangeWithoutBlurWithDebounce
            debounceDelay={1500}
          />
        }
      >
        <InfoItem
          label="Description"
          value={<Textarea defaultValue={initialDescriptionNewEditor.current} onChange={debouncedChangeDescription} />}
        />
        <InfoItem
          label="Type"
          value={
            <Select
              className="type-picker"
              value={stockItem.type}
              style={{ width: "200px" }}
              onChange={(value) => {
                changeType(value);
              }}
            >
              {STOCK_ITEM_TYPES.map((typeDefinition) => (
                <Select.Option
                  value={typeDefinition.value}
                  key={`option-type-${typeDefinition.value}`}
                  className={cx("types-option", `option-type-${typeDefinition.value}`)}
                >
                  {typeDefinition.label}
                </Select.Option>
              ))}
            </Select>
          }
        />
        <InfoItem
          inline
          label={`Display on ${getSimpleLabel("timeline")}`}
          value={<Checkbox checked={stockItem.displayOnTimeline} onChange={changeDisplayOnTimeline} />}
        />
      </Card>
      <Tabs
        className="stock-item-main-tabs"
        items={tabs}
        activeTab={activeTab}
        onTabClick={(tabKey) => {
          history.push(`${basePath}?tab=${tabKey}`);

          setState({ activeTab: tabKey });
        }}
      />
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: StockItemDetailsPage,
    subscriptions: ["stockItem"],
  })
);
