import React, { useEffect, useState } from "react";
import { Radio, Button, Typography } from "antd";
// import { useForceUpdate } from "common/helpers";

import FormInputLabel from "Form/FormInputLabel/FormInputLabel";

const DELAY_FOR_FORCE_REFRESH = 100;

function FormRadioButtonList({
  fieldData,
  fieldName,
  nestedFieldParams,
  readOnly,
  updateFieldValue,
  isFormEditor,
  refreshKey,
}) {
  const [keyForInternalUpdate, setKeyForInternalUpdate] = useState(0);
  let fieldContent = null;

  let value = fieldData.value;

  let needToUpdateFieldValue = false;

  if (fieldData.useDynamicOptions && isFormEditor) {
    fieldContent = (
      <Typography.Text style={{ opacity: 0.7, fontStyle: "italic" }}>
        This radio button list uses dynamic options. The options will only appear when you create a document from this
        template
      </Typography.Text>
    );
  } else {
    if (value) {
      let valueIsAmongOptions = fieldData.options.some((option) => option.value === value);
      if (!valueIsAmongOptions) {
        if (fieldData.autoSelectFirstOption && fieldData.options.length > 0) {
          value = fieldData.options[0].value;
        } else {
          value = undefined;
        }

        needToUpdateFieldValue = true;
      }
    }

    if (fieldData.options && fieldData.options.length > 0) {
      fieldContent = (
        <Radio.Group
          key={`${fieldName}-radio-list-inner-${keyForInternalUpdate}-${refreshKey}`}
          disabled={readOnly}
          onChange={async (e) => {
            await updateFieldValue({
              fieldName,
              value: e.target.value,
              nestedFieldParams,
            });

            // if ((!value || value.length === 0) && e.target.value) {
            //   setTimeout(() => {
            //     setKeyForInternalUpdate((oldValue) => oldValue + 1);
            //   }, DELAY_FOR_FORCE_REFRESH);
            // }
          }}
          options={fieldData.options}
          defaultValue={value}
        />
      );
    } else {
      fieldContent = (
        <Typography.Text style={{ opacity: 0.7, fontStyle: "italic" }}>No options available</Typography.Text>
      );
    }
  }

  useEffect(() => {
    if ((fieldData.useDynamicOptions && isFormEditor) || !needToUpdateFieldValue) {
      return;
    }

    updateFieldValue({
      fieldName,
      value,
      nestedFieldParams,
    });

    setTimeout(() => {
      setKeyForInternalUpdate((oldValue) => oldValue + 1);
    }, DELAY_FOR_FORCE_REFRESH);
  }, []);

  return (
    <div className="input-group" key={`${fieldName}-radio-list-${refreshKey}`}>
      <FormInputLabel
        fieldData={fieldData}
        fieldName={fieldName}
        extraContent={
          <>
            {fieldData.value && fieldData.value?.length !== 0 && fieldData.editable !== false && !readOnly && (
              <>
                <Button
                  type="clear"
                  onClick={async () => {
                    await updateFieldValue({
                      fieldName,
                      value: undefined,
                      nestedFieldParams,
                    });

                    setTimeout(() => {
                      setKeyForInternalUpdate((oldValue) => oldValue + 1);
                    }, DELAY_FOR_FORCE_REFRESH);
                  }}
                >
                  Clear selected option
                </Button>
              </>
            )}
          </>
        }
      />

      {fieldContent}
    </div>
  );
}

export default React.memo(FormRadioButtonList, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["readOnly", "refreshKey", "validationErrors", "hiddenFormFieldKeys"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps.topLevelParams[propName] !== nextProps.topLevelParams[propName]) {
      return false;
    }
  }

  return true;
});
