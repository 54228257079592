import React, { useState, useEffect, useCallback } from "react";
import { Typography, Pagination } from "antd";
import cx from "classnames";
import { Document, Page } from "react-pdf";
import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { usePDF } from "@react-pdf/renderer";

import "./ReportPreview.scss";

export function ReportPreview({ document, layout, renderMode, onLoad, onError, onDataUri, renderKey }) {
  const [documentBlob, setDocumentBlob] = useState();

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [backupBlob, setBackupBlob] = useState();
  const [{ loading, blob, error }, updatePdfInstance] = usePDF({ document });

  useEffect(() => {
    updatePdfInstance();
  }, [renderKey]); // eslint-disable-line

  useEffect(() => {
    if (pageCount && currentPageNumber > pageCount) {
      setCurrentPageNumber(pageCount);
    }
  }, [pageCount, currentPageNumber]);

  useEffect(() => {
    setDocumentBlob(blob);

    setTimeout(() => {
      setBackupBlob(blob);
    }, 300);

    if (blob) {
      convertBlobToDataUrl(blob);
    }

    function convertBlobToDataUrl(blob) {
      var reader = new FileReader();
      reader.onload = function (e) {
        if (onDataUri) {
          onDataUri(e.target.result);
        }
      };
      reader.readAsDataURL(blob);
    }
  }, [blob?.size, onDataUri]);

  const onLoadSuccess = useCallback(({ numPages }) => {
    if (numPages !== pageCount) {
      setPageCount(numPages);
      if (onLoad && typeof onLoad === "function") {
        onLoad();
      }
    }
  }, []);

  const onLoadError = useCallback((e) => {
    console.error("load error:", e);
    if (onError && typeof onError === "function") {
      onError();
    }
  }, []);

  const isLoading = backupBlob?.size !== documentBlob?.size;

  return (
    <div className={cx("pdf-renderer", layout)}>
      {pageCount > 0 && (
        <Pagination
          className="pagination"
          defaultCurrent={1}
          pageSize={1}
          current={currentPageNumber}
          onChange={(value) => setCurrentPageNumber(value)}
          total={pageCount}
          simple
        />
      )}
      <div className={cx("pdf-loader", { visible: !document && loading })}>
        <LoadingOutlined />
      </div>
      <div className={cx("pdf-error", { visible: error })}>
        <CloseCircleOutlined />
        <Typography.Text className="message"> Cannot generate PDF </Typography.Text>
        <Typography.Text className="reason"> {error} </Typography.Text>
      </div>

      <div className="document-preview-container">
        <BackupDocumentMemo pageNumber={currentPageNumber} renderMode={renderMode} file={backupBlob} error={error} />
        {documentBlob && !error && (
          <Document file={documentBlob} loading={null} onLoadSuccess={onLoadSuccess} onLoadError={onLoadError}>
            {pageCount > 0 && (
              <Page
                className={cx("live-page", { "live-page-loading": isLoading })}
                loading={null}
                pageNumber={currentPageNumber}
                renderMode={renderMode}
              />
            )}
          </Document>
        )}
      </div>
    </div>
  );
}

function BackupDocument({ file, pageNumber, renderMode, error }) {
  if (!file || error) {
    return null;
  }

  return (
    <Document file={file} loading={null}>
      <Page loading={null} pageNumber={pageNumber} renderMode={renderMode} className="backup-page" />
    </Document>
  );
}

const BackupDocumentMemo = React.memo(BackupDocument, (prevProps, nextProps) => {
  if (prevProps.pageNumber === nextProps.pageNumber && prevProps.file === nextProps.file) {
    return true;
  }

  return false;
});

export default React.memo(ReportPreview);
