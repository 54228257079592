import { useState, useEffect } from "react";
import { Button } from "antd";
import moment from "moment";

import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";
import { PlusCircleOutlined } from "@ant-design/icons";
import { getFilteredRequests } from "common/filterHelpers";
import { downloadBase64, sanitiseCSVValue } from "common/helpers";
import { getTableColumns } from "./tableColumns";

import RequestsTable from "./RequestsTable/RequestsTable";
import PlaceRequestModal from "Modals/PlaceRequestModal/PlaceRequestModal";
import RequestFilters from "RequestFilters/RequestFilters";

import "./RequestsPage.scss";

interface Props {
  setBoxedLayout: (boxedLayout: boolean) => void;
  setRightMargin: (hasRightMargin: boolean) => void;
  requests: any[];
  projects: any[];
  clients: any[];
  apiUser: any;
}

// eslint-disable-next-line no-empty-pattern
export function RequestsPage({ setBoxedLayout, setRightMargin, requests, projects, clients, apiUser }: Props) {
  const [isCreateRequestModalVisible, setIsCreateRequestModalVisible] = useState(false);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    setBoxedLayout(false);
    setRightMargin(false);
    return () => {
      setBoxedLayout(true);
      setRightMargin(true);
    };
  }, []); //eslint-disable-line

  async function exportToCSV() {
    const columns = [...getTableColumns()];

    let csvContent = columns.map((column) => column.title).join(",") + "\n";
    let filteredRequests = getFilteredRequests({
      requests,
      filter,
      clients,
      projects,
      apiUser,
    }).sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));

    filteredRequests.forEach((request) => {
      csvContent +=
        columns
          .map((column) => {
            if (column.fieldFunction) {
              return sanitiseCSVValue(column.fieldFunction(request));
            } else {
              return sanitiseCSVValue(request[column.dataIndex]);
            }
          })
          .join(",") + "\n";
    });

    console.log("csvContent = ", csvContent);

    let base64CSV = `data:text/csv;base64,${btoa(unescape(encodeURIComponent(csvContent)))}`;
    await downloadBase64({
      base64String: base64CSV,
      fileName: `quotes ${moment().format("DD-MM-YYYY")}.csv`,
    });
  }

  let filteredRequests = getFilteredRequests({
    requests,
    filter,
    clients,
    projects,
    apiUser,
  });

  return (
    <div className="requests-page">
      <div className="page-header">
        <RequestFilters
          onChange={(filter) => setFilter(filter)}
          includeCreateQuote
          requests={requests}
          export={exportToCSV}
        />

        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          data-cy="create-request-button"
          className="create-request-button"
          onClick={() => setIsCreateRequestModalVisible(true)}
        >
          Place {getSimpleLabel("request")}
        </Button>
      </div>
      <RequestsTable filteredRequests={filteredRequests} />
      {isCreateRequestModalVisible && <PlaceRequestModal onClose={() => setIsCreateRequestModalVisible(false)} />}
    </div>
  );
}

export default withSubscriptions({
  Component: RequestsPage,
  subscriptions: ["requests", "projects", "clients"],
});
