import { Link } from "react-router-dom";
import cx from "classnames";
import { Typography } from "antd";

import "./SheetListItem.scss";

export default function SheetListItem({ sheet, taskRevision, basePath, selected }) {
  return (
    <Link
      className={cx("sheet-list-item", {
        selected,
        "read-only": taskRevision.isReadOnly,
      })}
      data-sheet-id={sheet.id}
      to={`${basePath}?tab=${sheet.id}`}
    >
      <Typography.Text className="sheet-name">{sheet.name}</Typography.Text>
    </Link>
  );
}
