import React, { useState, useMemo, useCallback } from "react";
import _ from "lodash";
import cx from "classnames";
import { PDFDocument } from "pdf-lib";
import axios from "axios";

import { isVisibleOnScreen } from "common/helpers";
import { pdfjs, Document as ViewerDocument, Page as ViewerPage } from "react-pdf";
import { Pagination, Typography } from "antd";
import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import ErrorBoundary from "ErrorBoundary/ErrorBoundary";

import "./PdfRenderer.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export class PdfRenderer extends React.Component {
  state = {
    pageCount: 0,
    currentPageNumber: 1,
    refToCheck: {},
    dataUri: null,
    isLoading: true,
    error: null,
    pdfsToInsert: null,
    assetFiles: null,
    pagesInViewport: [0],
  };

  constructor() {
    super();

    this.debouncedExtractPdfPageSizes = _.debounce(this.extractPdfPageSizes, 1000);
    this.debouncedCheckPagesInViewport = _.debounce(this.checkPagesInViewport, 200);
    this.pageRefs = null;
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.refToCheck) !== JSON.stringify(this.props.refToCheck)) {
      if (!this.state.isLoading) {
        this.setState({ isLoading: true, pageCount: 0 });
      }
      this.debouncedExtractPdfPageSizes();
    }
  }

  componentDidMount() {
    this.debouncedExtractPdfPageSizes();
    if (this.props.assets) {
      this.loadAssets();
    } else {
      this.setState({ assetFiles: {} });
    }
  }

  checkPagesInViewport = () => {
    const { pageRefs } = this.state;

    if (!pageRefs) {
      setTimeout(this.checkPagesInViewport, 200);
      return;
    }

    let pagesInViewport = [];
    pageRefs.forEach((pageRef, i) => {
      if (pageRef.current) {
        const isVisible = isVisibleOnScreen(pageRef.current);
        if (isVisible) {
          pagesInViewport.push(i);
        }
      }
    });

    if (pagesInViewport.length === 0) {
      setTimeout(this.checkPagesInViewport, 200);
      return;
    }

    if (JSON.stringify(pagesInViewport) !== JSON.stringify(this.state.pagesInViewport)) {
      this.setState({ pagesInViewport });
    }
  };

  loadAssets = async () => {
    const { assets } = this.props;
    let assetFiles = {};
    for (let assetName in assets) {
      const asset = assets[assetName];

      const assetBlob = (await axios.get(asset.url, { responseType: "blob" })).data;
      const assetBuffer = await assetBlob.arrayBuffer();
      assetFiles[assetName] = {
        ...asset,
        buffer: assetBuffer,
      };
    }
    this.setState({ assetFiles });
  };

  extractPdfPageSizes = async () => {
    const { fileData, layout } = this.props;
    const { isLoading } = this.state;

    if (!fileData) {
      return;
    }

    if (!isLoading) {
      return;
    }

    if (layout === "default") {
      return;
    }

    const pdfDocument = await PDFDocument.load(fileData);

    const pageDimensions = pdfDocument.getPages().map((page) => page.getSize());

    const pageRefs = new Array(pageDimensions.length).fill(null).map((_, i) => React.createRef());

    this.setState({
      isLoading: false,
      pageDimensions,
      pageCount: pageDimensions.length,
      pageRefs,
    });

    if (this.props.onLoad && typeof this.props.onLoad === "function") {
      this.props.onLoad({
        pageCount: pageDimensions.length,
        totalHeight: pageDimensions.reduce((acc, { height }) => acc + height, 0),
        onScroll: this.debouncedCheckPagesInViewport,
      });
    }
  };

  onPageLoadSuccess = (page) => {
    const { onPageLoad } = this.props;
    if (onPageLoad && typeof onPageLoad === "function") {
      onPageLoad({ width: page._pageInfo.view[2], height: page._pageInfo.view[3] });
    }
  };

  displayDefaultViewer = () => {
    const { pageCount } = this.state;
    const { renderMode = "svg", fileData, scale = 1.5 } = this.props;

    const propsCurrentPageNumber = this.props.currentPageNumber;
    const stateCurrentPageNumber = this.state.currentPageNumber;
    const currentPageNumberToUse = propsCurrentPageNumber || stateCurrentPageNumber;
    return (
      <ViewerDocument
        ref={this.props.scrollingPdfRef}
        loading={null}
        file={{ data: fileData }}
        onLoadSuccess={async (pdf) => {
          const { numPages } = pdf;

          if (numPages !== pageCount) {
            this.setState({ pageCount: numPages });
            if (this.props.onLoad && typeof this.props.onLoad === "function") {
              this.props.onLoad({ pageCount: numPages });
            }
          }
        }}
        onLoadError={(e) => {
          console.error("load error:", e);
          if (this.props.onError && typeof this.props.onError === "function") {
            this.props.onError();
          }
        }}
      >
        <ViewerPage
          pageNumber={currentPageNumberToUse}
          renderMode={renderMode}
          scale={scale}
          onLoadSuccess={this.onPageLoadSuccess}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          className={`page-scale-${String(scale).split(".").join("-")}`}
        />
      </ViewerDocument>
    );
  };

  getPageViewerTop = (pageIndex) => {
    const { pageDimensions } = this.state;
    if (!pageDimensions) {
      return undefined;
    }
    return pageDimensions.slice(0, pageIndex).reduce((acc, { height }) => acc + height, 0);
  };

  displayScrollingViewer = () => {
    const { renderMode = "svg", fileData } = this.props;
    const { pagesInViewport, pageDimensions, pageRefs } = this.state;

    return (
      <div className="scrolling-viewer-container">
        <MemoScrollingPdfLowResPageViewer
          fileData={fileData}
          pageDimensions={pageDimensions}
          renderMode={renderMode}
          pageRefs={pageRefs}
          onError={this.props.onError}
        />
        <ScrollingFullResViewer
          renderMode={renderMode}
          fileData={fileData}
          pagesInViewport={pagesInViewport}
          pageDimensions={pageDimensions}
          onError={this.props.onError}
        />
      </div>
    );
  };

  displayViewer = () => {
    const { error, fileBuffer } = this.state;
    const { layout = "default", fileData } = this.props;

    if (error || (!fileBuffer && !fileData)) {
      return null;
    }

    if (layout === "default") {
      return this.displayDefaultViewer();
    } else {
      return this.displayScrollingViewer();
    }
  };

  render() {
    const { isLoading, error, pageCount } = this.state;
    const { layout = "default", includePagination = true, className, zoom, includePreloader = true } = this.props;

    const stateCurrentPageNumber = this.state.currentPageNumber;

    let style = {};
    if (zoom !== undefined) {
      style.transform = `scale(${zoom})`;
    }

    return (
      <ErrorBoundary>
        <div className={cx("pdf-renderer", layout, className)} style={style}>
          {layout === "default" && includePagination && (
            <Pagination
              className="pagination"
              defaultCurrent={1}
              pageSize={1}
              current={stateCurrentPageNumber}
              onChange={(currentPageNumber) => this.setState({ currentPageNumber })}
              total={pageCount}
              simple
            />
          )}

          {includePreloader && (
            <div className={cx("pdf-loader", { visible: isLoading })}>
              <LoadingOutlined />
            </div>
          )}
          <div className={cx("pdf-error", { visible: error })}>
            <CloseCircleOutlined />

            <Typography.Text className="message"> Cannot generate PDF </Typography.Text>
            <Typography.Text className="reason"> {error} </Typography.Text>
          </div>

          {this.displayViewer()}
        </div>
      </ErrorBoundary>
    );
  }
}

function ScrollingFullResViewer({ fileData, pagesInViewport, renderMode, pageDimensions, onError }) {
  const cachedFileData = useMemo(() => {
    return { data: fileData };
  }, []);

  const onLoadError = useCallback((e) => {
    console.log("load error:", e);
    if (onError && typeof onError === "function") {
      onError();
    }
  });

  function getPageViewerTop(pageIndex) {
    if (!pageDimensions) {
      return undefined;
    }
    return pageDimensions.slice(0, pageIndex).reduce((acc, { height }) => acc + height, 0);
  }

  function displayScrollingPageViewer({ pageIndex }) {
    let viewerTop = getPageViewerTop(pageIndex);
    if (viewerTop === undefined) {
      return null;
    }
    return (
      <div
        className="page-viewer"
        style={{ top: viewerTop, transform: "scale(0.5)", transformOrigin: "0 0" }}
        key={pageIndex}
      >
        <ScrollingPdfPageViewer pageIndex={pageIndex} renderMode={renderMode} />
      </div>
    );
  }

  return (
    <ViewerDocument key={"full-viewer"} loading={null} file={cachedFileData} onLoadError={onLoadError}>
      {pagesInViewport.map((pageIndex) => {
        return displayScrollingPageViewer({ pageIndex, renderMode });
      })}
    </ViewerDocument>
  );
}

function ScrollingPdfPageViewer({ pageIndex, renderMode }) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className={cx("scrolling-pdf-page-inner-container", { loaded: isLoaded })}>
      <ViewerPage
        pageNumber={pageIndex + 1}
        renderMode={renderMode}
        scale={2}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        onLoadSuccess={() => {
          setIsLoaded(true);
        }}
      />
    </div>
  );
}

function ScrollingPdfLowResPageViewer({ fileData, renderMode, pageDimensions, pageRefs, onError }) {
  if (!pageDimensions) {
    return null;
  }

  return (
    <div className="scrolling-pdf-low-res-page-inner-container">
      <ViewerDocument
        loading={null}
        file={{ data: fileData }}
        onLoadError={(e) => {
          if (onError && typeof onError === "function") {
            onError();
          }
        }}
      >
        {pageDimensions.map(({ width, height }, pageIndex) => {
          return (
            <div style={{ width, height }} key={`page_${pageIndex}`}>
              <ViewerPage
                canvasRef={pageRefs[pageIndex]}
                key={`page_${pageIndex}`}
                pageNumber={pageIndex + 1}
                renderMode={renderMode}
                scale={0.5}
                style={{ opacity: 0.5 }}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </div>
          );
        })}
      </ViewerDocument>
    </div>
  );
}

const MemoScrollingPdfLowResPageViewer = React.memo(ScrollingPdfLowResPageViewer);

export default React.memo(PdfRenderer);
