import { useState } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { message, Select, Dropdown, Button, Switch, Modal, Menu, Typography, Table } from "antd";
import { OpenIcon } from "common/icons";
import { Link } from "react-router-dom";

import prettyBytes from "pretty-bytes";
import cx from "classnames";
import awsExports from "aws-exports";
import {
  PlusCircleOutlined,
  LoadingOutlined,
  DeleteOutlined,
  CloudUploadOutlined,
  FilePdfOutlined,
  EyeOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import { platformSupportsLink } from "common/link";
import withSubscriptions from "common/withSubscriptions";
import { isAuthorised } from "common/permissions";
import { FILE_TYPES_READABLE, SUPPORTS_EXTERNAL_REFERENCES, VERSION_VISIBLE_COUNT } from "common/constants";
import { callRest } from "common/apiHelpers";
import { getDataSourceForTable } from "common/asyncJobHelpers";
import { HAS_SHEETS } from "common/shared";
import { deleteFile } from "graphql/mutations";
import { updateFile, updateTask } from "graphql/queries_custom";
import { openFileWithLink, downloadPDF } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { updateDeletedFiles } from "common/taskHelpers";
import { getSimpleLabel } from "common/labels";

import VersionsModal from "Modals/VersionsModal/VersionsModal";
import RawCloudFilesModal from "Modals/RawCloudFilesModal/RawCloudFilesModal";
import Input from "Input/Input";
import Card from "Card/Card";
import InfoItem from "InfoItem/InfoItem";
import UploadFilePdfModal from "../UploadFilePdfModal/UploadFilePdfModal";

import "./FileSidebar.scss";

export function FileSidebar({
  file,
  users,
  taskRevision,
  setIsUploadCustomFileModalOpen,
  latestFileVersion,
  removeExternalReference,
  tasks,
  projects,
  task,
  setIsCreateExternalReferenceModalOpen,
  changeCustomId,
  triggerPublish,
  history,
  taskPath,
  setSelectedSheet,
  setIsCreateSheetRevisionModalOpen,
  apiUser,
  downloadSheetPDF,
  splitLayout,
  organisationDetails,
  project,
  asyncJobsForFile,
  inProgressOrPendingAsyncJobsForFile,
  refreshPreview,
  triggerAnnotate,
}) {
  const [isVersionsModalVisible, setIsVersionsModalVisible] = useState(false);
  const [isCloudFileHistoryModalVisible, setIsCloudFileHistoryModalVisible] = useState(false);
  const [isUploadPdfModalVisible, setIsUploadPdfModalVisible] = useState(false);
  const [completePdfVersions, setCompletePdfVersions] = useState();

  async function fetchCompletePdfS3Versions() {
    let versions = [];
    for (let i = 0; i < file.versions.items.length; i++) {
      const fileVersion = file.versions.items[i];
      const key = fileVersion.exports[0].key;
      const versionsResponse = await callRest({
        method: "GET",
        route: `/s3-list-versions?prefix=${btoa(key)}`,
        includeCredentials: false,
      });
      versions.push(...versionsResponse.Versions);
    }
    setCompletePdfVersions(versions.sort((a, b) => (a.LastModified < b.LastModified ? 1 : -1)));
  }

  async function onTemplateChange(templateId) {
    const messageKey = "update-template-for-file";
    message.loading({
      content: "Selecting the new template...",
      key: messageKey,
      duration: 0,
    });
    try {
      await callGraphQLSimple({
        message: "Could not update file",
        queryName: "updateFile",
        variables: {
          input: {
            id: file.id,
            templateId,
          },
        },
      });
    } catch (e) {
      message.error({
        content: "Failed to select the new template",
        key: messageKey,
      });
    }

    try {
      await triggerAnnotate();
    } catch (e) {
      message.error({
        content:
          "New template was selected but failed to trigger annotation process. You can trigger a publish manually.",
        key: messageKey,
      });
    }

    message.success({
      content: "Selected the new template and re-annotated the result of the latest publish",
      key: messageKey,
      duration: 5,
    });

    refreshPreview();
  }

  function displayCompletePdfVersions() {
    if (!completePdfVersions) {
      return (
        <Menu>
          <Menu.Item key="loading" className="file-version-item" disabled>
            <LoadingOutlined /> Loading...
          </Menu.Item>
        </Menu>
      );
    }

    let truncatedVersions = completePdfVersions.slice(0, VERSION_VISIBLE_COUNT);
    let listIsTruncated = false;
    if (truncatedVersions.length < completePdfVersions.length) {
      listIsTruncated = true;
    }

    return (
      <Menu>
        {truncatedVersions.map((version) => {
          let readableDate = moment(version.LastModified).format("DD MMMM YYYY, HH:mm:ss");
          return (
            <Menu.Item
              key={version.VersionId}
              className="file-version-item"
              onClick={() =>
                downloadPDF({
                  fileKey: version.Key,
                  versionId: version.VersionId,
                  readableDate,
                  file,
                  task,
                })
              }
            >
              <span className="date">{readableDate}</span> <span className="size">{prettyBytes(version.Size)}</span>
            </Menu.Item>
          );
        })}
        {listIsTruncated && (
          <Menu.Item key="all-versions" className="reveal-all-versions" onClick={() => setIsVersionsModalVisible(true)}>
            See all versions
          </Menu.Item>
        )}
      </Menu>
    );
  }

  function displayExternalReferences() {
    if (!SUPPORTS_EXTERNAL_REFERENCES[file.type] || organisationDetails.settings?.file?.hideXref) {
      return null;
    }

    return (
      <div className="external-references-container">
        <InfoItem
          inline
          label="Xrefs"
          value={
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => setIsCreateExternalReferenceModalOpen(true)}
              disabled={taskRevision.isReadOnly}
            >
              Add Xref
            </Button>
          }
        />

        {!latestFileVersion?.externalReferences || latestFileVersion?.externalReferences.length === 0 ? null : (
          <div className="external-references-items">
            {latestFileVersion?.externalReferences.map((reference, i) => {
              const referenceTaskDetails = tasks.find((x) => x.id === reference.taskId);
              return (
                <Link to={`/tasks/${reference.taskId}`} key={`external-reference-` + i} className="stat-value item">
                  <DeleteOutlined
                    onClick={(e) => {
                      e.preventDefault();
                      removeExternalReference(reference);
                    }}
                    className="remove-linked-task"
                  />
                  {reference.taskRevisionName ? (
                    <>
                      <b>({reference.taskRevisionName})</b>
                    </>
                  ) : null}{" "}
                  {referenceTaskDetails.title}
                </Link>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  function displayAnnotateSwitch() {
    if (task.isExternalReference || organisationDetails.settings?.file?.hideAnnotateSwitch) {
      return null;
    }

    return (
      <InfoItem
        inline
        label="Auto-fill title block"
        value={
          <Switch
            size="small"
            disabled={taskRevision.isReadOnly}
            checked={!file.skipAnnotation}
            onChange={async (checked) => {
              const updatedFile = (
                await callGraphQLSimple({
                  message: "Could not update file",
                  queryName: "updateFile",
                  variables: {
                    input: {
                      id: file.id,
                      skipAnnotation: !checked,
                    },
                  },
                })
              ).data.updateFile;
              await triggerAnnotate();
            }}
          />
        }
      />
    );
  }

  function displayTemplatePicker() {
    if (task.isExternalReference) {
      return null;
    }

    let validTemplatesForFileType = [];
    let currentTemplateDetails;
    if (!organisationDetails.templates || !organisationDetails.templates.items) {
      return null;
    }
    for (let template of organisationDetails.templates.items) {
      if (template.type === file.type && !template.isDeprecated && template.isLive) {
        validTemplatesForFileType.push(template);
      }
      if (template.id === file.templateId) {
        currentTemplateDetails = template;
      }
    }

    // if the current template is not among the valid templates, add it to the list nonetheless
    if (!validTemplatesForFileType.some((x) => x.id === file.templateId)) {
      validTemplatesForFileType.push(currentTemplateDetails);
    }

    return (
      <InfoItem
        label="Template for title block"
        value={
          <Select defaultValue={file.templateId} style={{ width: "100%" }} onChange={onTemplateChange}>
            {validTemplatesForFileType
              .filter((x) => x)
              .map((template) => {
                return (
                  <Select.Option key={template.id} value={template.id}>
                    {template.name}
                    {template.isDeprecated ? " (obsolete)" : ""}
                  </Select.Option>
                );
              })}
          </Select>
        }
      />
    );
  }

  function displayCloudFileHistoryButton() {
    return (
      <Button onClick={() => setIsCloudFileHistoryModalVisible(true)} className="cloud-file-history-button">
        <EyeOutlined />
        Raw cloud storage
      </Button>
    );
  }

  function displayStartingFileSwitch() {
    if (task.isExternalReference) {
      return null;
    }

    return (
      <>
        <InfoItem
          inline
          label="Use as starting file"
          value={
            <Switch
              size="small"
              disabled={!isAuthorised(["MANAGE_STARTING_FILES"])}
              defaultChecked={file.isStartingFile}
              onChange={async (checked) => {
                callGraphQLSimple({
                  message: "Could not update file",
                  queryName: "updateFile",
                  variables: {
                    input: {
                      id: file.id,
                      isStartingFile: checked,
                    },
                  },
                });
              }}
            />
          }
        />
        {file.isStartingFile && (
          <InfoItem
            label="Name for starting file"
            value={
              <Input
                showBorder
                fullWidth
                fireOnChangeWithoutBlurWithDebounce
                debounceDelay={1000}
                defaultValue={file.startingFileName}
                onChange={(startingFileName) => {
                  callGraphQLSimple({
                    message: "Could not update file",
                    queryName: "updateFile",
                    variables: {
                      input: {
                        id: file.id,
                        startingFileName,
                      },
                    },
                  });
                }}
              />
            }
          />
        )}
      </>
    );
  }

  function displayCustomId() {
    if (!latestFileVersion || task.isExternalReference || organisationDetails.settings?.file?.hideCustomReference) {
      return null;
    }

    return (
      <InfoItem
        label="Custom file reference number"
        value={
          <Input
            data-cy="custom-reference-input"
            disabled={taskRevision.isReadOnly}
            className="file-custom-id"
            defaultValue={latestFileVersion?.customId}
            onChange={changeCustomId}
            showBorder
            fullWidth
          />
        }
      />
    );
  }

  async function confirmDeleteFile() {
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm delete file",
          maskClosable: true,
          content: (
            <>
              Are you sure you want to delete <b>{FILE_TYPES_READABLE[file.type]}</b>?
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    await callGraphQLSimple({
      message: "Could not delete file",
      queryName: "deleteFile",
      variables: {
        input: {
          id: file.id,
        },
      },
    });

    await callGraphQLSimple({
      mutation: "createTaskActivityItem",
      message: "Failed to record task activity item",
      variables: {
        input: {
          taskId: task.id,
          author: window.apiUser.id,
          organisation: organisationDetails.id,
          type: "LIFECYCLE_EVENT",
          content: JSON.stringify({
            fileId: file.id,
            fileType: file.type,
            fileName: file.name,
            type: "FILE_DELETED",
          }),
        },
      },
    });

    await updateDeletedFiles(taskRevision, file.type);

    await callGraphQLSimple({
      message: `Could not update ${getSimpleLabel("task")}`,
      queryName: "updateTask",
      variables: {
        input: {
          id: task.id,
          randomNumber: Math.floor(Math.random() * 100000),
        },
      },
    });

    history.push(taskPath);
  }

  function displayDeleteFileButton() {
    return (
      <Button
        icon={<DeleteOutlined />}
        className="delete-file"
        onClick={confirmDeleteFile}
        disabled={taskRevision.isReadOnly || task.isFinished || task.isArchived}
      >
        Delete file
      </Button>
    );
  }

  function displayAddRevisionButton() {
    if (task.isExternalReference || organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions) {
      return null;
    }

    return (
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        disabled={!latestFileVersion || taskRevision.isReadOnly}
        onClick={() => {
          setSelectedSheet();
          setIsCreateSheetRevisionModalOpen(true);
        }}
        className="add-revision-to-all-sheets"
      >
        Add revision {HAS_SHEETS[file.type] ? "to all sheets" : ""}
      </Button>
    );
  }

  function displayCustomFileUploadButton() {
    return (
      <Button onClick={() => setIsUploadCustomFileModalOpen(true)} disabled={taskRevision.isReadOnly}>
        <CloudUploadOutlined />
        Upload {FILE_TYPES_READABLE[file.type]} file
      </Button>
    );
  }

  function displayUploadPdfButton() {
    if (task.isExternalReference || file.type === "BLUEBEAM" || file.type === "PDF") {
      return null;
    }

    return (
      <Button
        onClick={() => {
          setIsUploadPdfModalVisible(true);
        }}
        disabled={taskRevision.isReadOnly}
      >
        <CloudUploadOutlined />
        Upload PDF
      </Button>
    );
  }

  function displayLivePdfButton() {
    if (window.location.hostname !== "localhost" && !apiUser.isHidden) {
      return null;
    }

    return (
      <div className="live-pdf-button-container">
        <Button
          onClick={async () => {
            const messageKey = "annotation-button-process";
            try {
              message.loading({
                content: "Re-annotating PDF...",
                key: messageKey,
                duration: 0,
              });

              await triggerAnnotate();
              message.success({
                content: "Successfully re-annotated PDF",
                key: messageKey,
                duration: 5,
              });
            } catch (e) {
              message.error({
                content: "Failed to re-annotate PDF",
                key: messageKey,
              });
            }
          }}
          type="primary"
        >
          Annotate
        </Button>
      </div>
    );
  }

  function displayS3FileVersionButton() {
    if (!apiUser.isHidden && window.location.hostname !== "localhost") {
      return null;
    }
    return (
      <div className="s3-button-container">
        <Button
          className="s3-file-version-button"
          onClick={() => {
            window
              .open(
                `https://s3.console.aws.amazon.com/s3/buckets/${awsExports.aws_user_files_s3_bucket}?region=${awsExports.aws_user_files_s3_bucket_region}&prefix=${latestFileVersion.key}&showversions=false`,
                "_blank"
              )
              .focus();
          }}
        >
          S3 file version
        </Button>
      </div>
    );
  }

  function onVersionClick(version, readableDate) {
    downloadPDF({
      fileKey: version.Key,
      versionId: version.VersionId,
      readableDate,
      file,
      task,
    });
  }

  function displayOpenInButton() {
    if (!platformSupportsLink()) {
      return null;
    }
    return (
      <Button
        type="dark"
        data-cy="open-in-button"
        icon={latestFileVersion?.processingStatus === "IN_QUEUE" ? <LoadingOutlined /> : <OpenIcon />}
        disabled={!latestFileVersion || latestFileVersion?.processingStatus === "IN_QUEUE"}
        onClick={async () => {
          callGraphQLSimple({
            displayError: false,
            mutation: "createAuditItem",
            variables: {
              input: {
                taskId: task.id,
                projectId: task.projectId,
                fileId: file.id,
                clientId: task.clientId,
                page: "FILE",
                type: "OPEN_IN_APP",
                fileType: file.type,
                fileName: file.name,
                userId: window.apiUser.id,
                organisation: window.apiUser.organisation,
              },
            },
          });
          await openFileWithLink({
            revisionData: taskRevision,
            task,
            file,
            history,
            fileVersion: latestFileVersion,
            page: "FILE_DETAILS",
          });
        }}
      >
        Open in {FILE_TYPES_READABLE[file.type]}
      </Button>
    );
  }

  async function cancelJob(row) {
    await callGraphQLSimple({
      message: "Failed to cancel async job",
      queryCustom: "updateAsyncJob",
      variables: {
        input: {
          id: row.id,
          status: "CANCELLED",
          createdAt: row.originalCreatedAt,
        },
      },
    });
  }

  function displayAsyncJobsTable() {
    let dataSource = getDataSourceForTable({
      asyncJobs: asyncJobsForFile || [],
      tasks,
      projects,
      users,
    });

    let someJobsCanBeCancelled = false;
    for (let row of dataSource) {
      if (["PENDING", "IN_PROGRESS"].includes(row.status)) {
        someJobsCanBeCancelled = true;
        break;
      }
    }

    return (
      <Table
        pagination={{ pageSize: 1000000000, hideOnSinglePage: true }}
        scroll={{ y: 300 }}
        dataSource={dataSource}
        loading={!asyncJobsForFile || !dataSource}
        columns={[
          { title: "Progress", dataIndex: "progressPercent", width: 100 },
          {
            title: "Author",
            dataIndex: "userAvatar",
            width: 130,
            align: "left",
          },
          { title: "Type", dataIndex: "type", width: 100, align: "left" },
          { title: "Status", dataIndex: "statusTag", width: 100 },
          { title: "Created", dataIndex: "createdAtFormatted", width: 100 },
          someJobsCanBeCancelled
            ? {
                title: "",
                width: 100,
                render: (_, row) => {
                  return (
                    <Button
                      icon={<CloseCircleOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        cancelJob(row);
                      }}
                    >
                      Cancel
                    </Button>
                  );
                },
              }
            : undefined,
        ].filter((x) => x)}
      />
    );
  }

  return (
    <Card className={cx("file-sidebar", { "split-layout": splitLayout })} withSpace>
      <div className="action-buttons">
        {displayOpenInButton()}

        {!task.isExternalReference && (
          <Dropdown.Button
            className="publish"
            onClick={() => triggerPublish()}
            disabled={!latestFileVersion}
            data-cy="publish-button"
            trigger={["click"]}
            overlayClassName="file-sidebar-async-jobs-table-overlay"
            menu={{
              items: [
                {
                  label: (
                    <div className="file-sidebar-async-jobs-table-container">
                      <Typography.Title level={3} style={{ marginBottom: "0.5rem" }}>
                        Jobs for this file
                      </Typography.Title>
                      {displayAsyncJobsTable()}
                    </div>
                  ),
                  key: "1",
                },
              ],
            }}
            type="primary"
          >
            {inProgressOrPendingAsyncJobsForFile?.length > 0 ? (
              <LoadingOutlined style={{ marginRight: "0.5rem" }} />
            ) : null}
            Publish
            {inProgressOrPendingAsyncJobsForFile?.length > 0 ? "ing" : ""}
          </Dropdown.Button>
        )}
        {displayAddRevisionButton()}
        {!task.isExternalReference && (
          <Dropdown.Button
            className="open-revision-pdf"
            data-cy="download-entire-pdf-button"
            overlayStyle={{ minWidth: "300px" }}
            onOpenChange={(open) => {
              if (open) {
                fetchCompletePdfS3Versions();
              }
            }}
            onClick={() => {
              if (!HAS_SHEETS[file.type]) {
                downloadSheetPDF({
                  sheetRevision: file.sheets.items[0].revisions.items.slice(-1)[0],
                  file,
                  task,
                });
              } else {
                downloadPDF({
                  fileKey: file.versions.items[file.versions.items.length - 1].exports[0].key,
                  file,
                  task,
                });
              }
            }}
            overlay={displayCompletePdfVersions()}
            trigger={["click"]}
            placement="bottomLeft"
          >
            <FilePdfOutlined /> Download PDF
          </Dropdown.Button>
        )}
        {displayCustomFileUploadButton()}
        {displayUploadPdfButton()}
        {displayCloudFileHistoryButton()}
        {displayDeleteFileButton()}
      </div>
      <div className="file-settings">
        {displayAnnotateSwitch()}
        {displayTemplatePicker()}
        {displayStartingFileSwitch()}
        {displayCustomId()}
        {displayExternalReferences()}
        {displayLivePdfButton()}
        {displayS3FileVersionButton()}
      </div>

      {isVersionsModalVisible && (
        <VersionsModal
          versions={completePdfVersions}
          onVersionClick={onVersionClick}
          onClose={() => setIsVersionsModalVisible(false)}
        />
      )}

      {isCloudFileHistoryModalVisible && (
        <RawCloudFilesModal
          onClose={() => setIsCloudFileHistoryModalVisible(false)}
          file={file}
          taskRevision={taskRevision}
          taskPath={taskPath}
          project={project}
        />
      )}

      {isUploadPdfModalVisible && (
        <UploadFilePdfModal
          file={file}
          task={task}
          onClose={() => setIsUploadPdfModalVisible(false)}
          latestFileVersion={latestFileVersion}
          taskRevision={taskRevision}
          organisationDetails={organisationDetails}
          triggerAnnotate={triggerAnnotate}
        />
      )}
    </Card>
  );
}

export default withRouter(
  withSubscriptions({
    Component: FileSidebar,
    subscriptions: ["projects", "tasks", "users"],
  })
);
