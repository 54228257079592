import { useState } from "react";
import { Form, Modal, Button } from "antd";
import withSubscriptions from "common/withSubscriptions";
import { recordActivityItem } from "common/purchaseOrderHelpers";
import { sendPurchaseOrderReviewRequestNotification } from "common/notificationHelpers";
import UsersFilter from "UsersFilter/UsersFilter";

import "./RequestPurchaseOrderReviewModal.scss";

export function RequestPurchaseOrderReviewModal({
  onClose,
  purchaseOrder,
  users,
  clients,
  projects,
  potentialReviewers,
  apiUser,
  suppliers,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const client = clients.find((client) => client.id === purchaseOrder.clientId);
  const supplier = suppliers.find((supplier) => supplier.id === purchaseOrder.supplierId);
  const project = projects.find((project) => project.id === purchaseOrder.projectId);

  function closeModal() {
    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  async function onSubmit({ reviewer }) {
    setIsLoading(true);

    window.callGraphQLSimple({
      message: "Failed to start review",
      mutation: "updatePurchaseOrder",
      variables: {
        input: {
          id: purchaseOrder.id,
          isUnderReview: true,
          reviewStatus: "IN_PROGRESS",
          checkedBy: reviewer,
        },
      },
    });

    window.callGraphQLSimple({
      message: "Failed to start review",
      mutation: "createReview",
      variables: {
        input: {
          parentId: purchaseOrder.id,
          organisation: purchaseOrder.organisation,
          reviewThread: [
            { type: "START", createdAt: new Date().toISOString() },
            { type: "REVIEWER_SET", createdAt: new Date().toISOString(), content: reviewer },
          ],
        },
      },
    });

    sendPurchaseOrderReviewRequestNotification({
      users,
      apiUser,
      reviewer,
      purchaseOrderId: purchaseOrder.id,
      purchaseOrderTitle: purchaseOrder.title,
      clientName: client?.name,
      projectTitle: project?.title,
      supplierName: supplier?.name,
    });

    recordActivityItem({
      purchaseOrder: { ...purchaseOrder, checkedBy: reviewer },
      type: "REVIEW_OPENED",
      author: apiUser.id,
      clients,
      users,
      suppliers,
    });
    closeModal();
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title="Request review"
      open={true}
      onOk={onSubmit}
      onCancel={closeModal}
      footer={null}
      className="request-purchase-order-review-modal"
    >
      <Form {...layout} form={form} initialValues={{}} onFinish={onSubmit}>
        <Form.Item
          label="Reviewer"
          name="reviewer"
          rules={[
            {
              required: true,
              message: "You must choose a reviewer",
            },
          ]}
        >
          <UsersFilter orderedActiveUsers={potentialReviewers} includeUnassigned={false} />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
            {isLoading ? "Requesting" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default withSubscriptions({
  Component: RequestPurchaseOrderReviewModal,
});
