import React from "react";
import { Button, notification, message } from "antd";
import axios from "axios";

import { callRest } from "common/apiHelpers";
import { downloadBlob } from "common/helpers";

function FormButton({ fieldData, fieldName, topLevelParams, refreshKey }) {
  const { contextForComputations } = topLevelParams;
  let { quote } = contextForComputations || {};

  async function onClick() {
    if (!quote) {
      return;
    }
    let fileKey = quote.fileKey.replace(".json", ".pdf");

    let buildDocumentResponse;
    let messageKey = "building-document-for-button";
    try {
      message.loading({
        content: "Generating document...",
        key: messageKey,
        duration: 0,
      });
      buildDocumentResponse = await callRest({
        route: "/build-document",
        method: "POST",
        displayError: false,
        body: {
          organisationId: quote.organisation,
          quoteId: quote.id,
          projectId: quote.projectId,
          clientId: quote.clientId,
          targetFormButtonId: fieldData.id,
        },
        includeCredentials: false,
      });
      if (buildDocumentResponse.errorMessage) {
        message.destroy();
        notification.error({
          message: (
            <>
              <span>
                <b>Encountered an error while generating the document:</b>
                <br /> {buildDocumentResponse.errorMessage}
              </span>
            </>
          ),
          duration: 0,
        });
        return;
      }
    } catch (e) {
      message.destroy();

      let errorMessage = e?.response?.data?.message || e?.response?.data || e.message;

      notification.error({
        message: (
          <>
            <span>
              <b>Encountered an error while generating the document:</b>
              <br /> {errorMessage}
            </span>
          </>
        ),
        duration: 0,
      });
      return;
    }

    message.destroy();
    const publicUrl = buildDocumentResponse.signedUrl;

    const fileBlob = (
      await axios({
        url: publicUrl,
        method: "GET",
        responseType: "blob", // Important
      })
    ).data;

    let fileName = fileKey.split("/").slice(-1)[0];

    if (quote.currentRevisionName) {
      fileName = fileName.replace(".pdf", ` ${quote.currentRevisionName}.pdf`);
    }

    if (fieldData.resultingFileNameSuffix) {
      fileName = fileName.replace(".pdf", `${fieldData.resultingFileNameSuffix}.pdf`);
    }

    await downloadBlob({
      blob: fileBlob,
      fileName,
    });
  }

  return (
    <div className="input-group" key={`${fieldName}-button-${refreshKey}`}>
      <Button type="primary" onClick={onClick}>
        {fieldData.label}
      </Button>
    </div>
  );
}

export default React.memo(FormButton, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["readOnly", "refreshKey", "validationErrors", "hiddenFormFieldKeys"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps.topLevelParams[propName] !== nextProps.topLevelParams[propName]) {
      return false;
    }
  }

  return true;
});
