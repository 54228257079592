/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      logoKey
      name
      description
      projectCount
      requestCount
      projectIdOffset
      hasAutoPublish
      slackWebhookURL
      usesFiles
      usesAuthority
      usesDesignAuthority
      usesCheckAuthority
      usesReviewAuthority
      usesInitials
      usesClientInitials
      usesProjectInitials
      usesTaskInitials
      usesRevisions
      usesReviews
      usesSprints
      usesDelete
      billingDay
      pricePerSeat
      taxRate
      accountBalance
      monthlyCost
      discountPerSeat
      lastBillCreatedAt
      seats
      deploymentAlertNumber
      itemSubscription
      settings {
        review {
          excludedFileTypes
          defaultCommentFontSize
          allowSimpleReviews
        }
        quote {
          isValidFromHidden
          isValidUntilHidden
          hiddenLineItemColumns
          hiddenLineItemColumnsByTemplate {
            templateId
            hiddenLineItemColumns
          }
          shouldArchiveTasksWhenClosingQuote
          shouldOpenCreateTaskModal
          defaultQuoteBccEmails
          usesQuotes
          usesDelete
          tasksCreatedFromQuoteDefaultToQuoteTitle
          tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
          defaultUnitPrice
          disablePreview
          fieldsToCopyFromFirstQuoteLineItemByTemplate {
            templateId
            fieldsToCopyFromFirstQuoteLineItem
          }
          emailAddressToSendQuotesFrom
          quotesInHours
        }
        task {
          createsCat2Check
          defaultSprint
          attachmentTemplates {
            id
            name
            path
          }
          taskRevisionsAreSyncedWithSheetRevisions
          allowUseOfCatZero
          usesTaskEstimates
          useTaskRevisionEstimates
          useDueDatesOnTaskRevisions
          usesPriority
          usesRequestedDate
          displayTaskEstimatesAsTag
          createAttachmentFolderWithTask
          dontCreateTaskFolders
          defaultTaskCatLevel
          isTaskAssigneeMandatory
          allowUnassignedAsMandatoryException
          isTaskDueDateMandatory
          priorities {
            id
            name
            color
          }
          maxTaskRevisionNameLength
          maxTaskRevisionDescriptionLength
          needsRequestForTaskRevisionCreation
          needsRequestForTaskRevisionReview
          automaticallyCreateProject
          hideCustomTaskNumberAtCreationTime
          hideCopyTaskAtCreationTime
          hideTaskDueDates
          taskIdToCopyByDefault
          cannotCreateNewTaskRevisions
          allowMultipleLiveTaskRevisions
          copyTaskAlsoCopiesAllTaskRevisions
          usesStartAndEndDates
          allowMultipleUsersToBeAssignedToATask
          allowMultipleStockItemsToBeAssignedToATask
          hideRawCloudStorageTab
        }
        file {
          hideIncludeInPublishSwitch
          hideSheetTitleInput
          hideCustomReference
          hideIncludeInDocumentRegister
          hideAnnotateSwitch
          hideXref
          maxSheetRevisionDescriptionLength
          maxSheetRevisionNameLength
          sheetDescriptionsMustBeUnique
        }
        general {
          usesDocumentRegister
          usesTaskConfirmation
          usesTeams
          usesTimeOff
          usesUploadFromGoogleDrive
          usesUploadFromOneDrive
          usesUploadFromDropbox
          sectors {
            id
            label
          }
          geographicalLocations {
            id
            label
          }
          hideFinancials
          hideOrganisationIdInTags
          requirePermissionToDisplayUsers
          defaultAddressToSendEmailsFrom
          scheduledNotifications {
            id
            name
            title
            body
            targetUserGroups
            schedule
          }
        }
        timesheet {
          usesTimesheets
          areTagsMandatory
          isDescriptionMandatory
          requireQuoteOrVariationForBillableTimesheetBlocks
          canAssignTimesheetBlocksToTaskRevisions
          usesReview
          recordLatLongWhenClockingInOut
          recordLatLongEveryXMinutes
          usesPartDayTimeOff
        }
        timeline {
          usesTimeline
          usesPhysicalBlockInteraction
          usesGapFilling
          usesColoredBlocks
          usesCustomColorLogic
          defaultRoundToHours
          defaultTaskLengthHours
          shouldAssignTimelineTaskToUser
          shouldDisplayOwnUserAtTheTop
          planProjectsInsteadOfTasks
          planTaskRevisionsInsteadOfTasks
          defaultTaskLengthToEstimatedHours
          pseudoTaskOptions {
            id
            defaultColor
          }
        }
        calendar {
          usesCalendar
        }
        documentLibrary {
          usesDocumentLibrary
        }
        queue {
          usesQueue
        }
        dashboard {
          displayViewTypePicker
        }
        invoice {
          usesInvoices
          usesDoubleInvoiceReview
          usesPercentagesInsteadOfQuantity
          invoiceDate
          useCancelledStatus
          displayPaidInvoicesInSeparateColumns
          emailAddressToSendInvoicesFrom
          defaultExpectPaymentAfterDays
        }
        purchaseOrder {
          usesPurchaseOrders
          emailAddressToSendPurchaseOrdersFrom
        }
        supplier {
          usesSuppliers
        }
        project {
          attachmentTemplates {
            id
            name
            path
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          hideCustomProjectNumberAtCreationTime
          hideAssignedToAtCreationTime
          hideClientAtCreationTime
          customNumberCheckboxOnByDefault
        }
        client {
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
        }
        request {
          usesRequests
          emailsToNotifyOnNewRequest
          usesClientReview
          displayRequestFormInTaskReview
          allowRequestFormPdfDownload
          allowExternalReviews
          formsCanOnlyBeAddedToOneTask
          reassigningTaskAlsoReassignsRequest
          hideAssignedTo
          externalReviewersCanPerformInternalReviews
        }
        holiday {
          onlyAllowsFullDays
        }
        stock {
          usesStock
        }
      }
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      vatNumber
      fileTypesUsed
      financialControllers
      addons {
        pricePerSeat
        name
        discountPercent
      }
      labels {
        id
        value
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      teams {
        id
        label
      }
      fileStatuses {
        name
        canSkipReview
        canUseExternalReview
      }
      taskStatuses {
        name
        type
        canSkipReview
        canUseExternalReview
      }
      customFields {
        id
        label
        target
        type
        options {
          value
          label
          color
          showAsTag
          tagLabel
        }
        defaultValue
        showAsTag
        displayTagIfChecked
        displayTagIfEmpty
        onlyVisibleForCertainUserGroups
        groupsThatCanSee
        tagLabel
        color
        formula
        isStatic
        mandatory
        nonAdminsCanRead
        nonAdminsCanWrite
      }
      integrations {
        id
        authorisedAt
      }
      accountancyOrganisationId
      includeInBilling
      financialsLastRecalculatedAt
      financialsLastStartedRecalculatingAt
      financialsRecalculationProgress
      financialsRecalculationStatus
      menus {
        id
        label
        items {
          id
          label
          url
          icon
          items {
            id
            label
            url
            icon
          }
        }
      }
      menuAssignments {
        id
        menuId
        locationId
      }
      createdAt
      updatedAt
      variables {
        items {
          id
          organisation
          name
          type
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      templates {
        items {
          id
          organisation
          name
          type
          createdAt
          currentVersionNumber
          previewTaskId
          previewFileId
          previewTaskRevisionId
          isLive
          isDeprecated
          isDefault
          key
          reviewCommentFontSize
          excludeFromRegisterByDefault
          isCat2Restricted
          outputType
          systemVersion
          parentType
          updatedAt
        }
        nextToken
      }
      bills {
        items {
          id
          customId
          organisation
          createdAt
          isPaid
          subtotal
          totalWithTax
          taxAmount
          lineItems {
            id
            name
            quantity
            pricePerUnit
            amount
            amountWithTax
            discountPercent
            discountAmount
          }
          s3Key
          itemSubscription
          accountancyInvoiceId
          updatedAt
        }
        nextToken
      }
      timesheetTags {
        items {
          id
          organisation
          label
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        logoKey
        name
        description
        projectCount
        requestCount
        projectIdOffset
        hasAutoPublish
        slackWebhookURL
        usesFiles
        usesAuthority
        usesDesignAuthority
        usesCheckAuthority
        usesReviewAuthority
        usesInitials
        usesClientInitials
        usesProjectInitials
        usesTaskInitials
        usesRevisions
        usesReviews
        usesSprints
        usesDelete
        billingDay
        pricePerSeat
        taxRate
        accountBalance
        monthlyCost
        discountPerSeat
        lastBillCreatedAt
        seats
        deploymentAlertNumber
        itemSubscription
        settings {
          review {
            excludedFileTypes
            defaultCommentFontSize
            allowSimpleReviews
          }
          quote {
            isValidFromHidden
            isValidUntilHidden
            hiddenLineItemColumns
            shouldArchiveTasksWhenClosingQuote
            shouldOpenCreateTaskModal
            defaultQuoteBccEmails
            usesQuotes
            usesDelete
            tasksCreatedFromQuoteDefaultToQuoteTitle
            tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
            defaultUnitPrice
            disablePreview
            emailAddressToSendQuotesFrom
            quotesInHours
          }
          task {
            createsCat2Check
            defaultSprint
            taskRevisionsAreSyncedWithSheetRevisions
            allowUseOfCatZero
            usesTaskEstimates
            useTaskRevisionEstimates
            useDueDatesOnTaskRevisions
            usesPriority
            usesRequestedDate
            displayTaskEstimatesAsTag
            createAttachmentFolderWithTask
            dontCreateTaskFolders
            defaultTaskCatLevel
            isTaskAssigneeMandatory
            allowUnassignedAsMandatoryException
            isTaskDueDateMandatory
            maxTaskRevisionNameLength
            maxTaskRevisionDescriptionLength
            needsRequestForTaskRevisionCreation
            needsRequestForTaskRevisionReview
            automaticallyCreateProject
            hideCustomTaskNumberAtCreationTime
            hideCopyTaskAtCreationTime
            hideTaskDueDates
            taskIdToCopyByDefault
            cannotCreateNewTaskRevisions
            allowMultipleLiveTaskRevisions
            copyTaskAlsoCopiesAllTaskRevisions
            usesStartAndEndDates
            allowMultipleUsersToBeAssignedToATask
            allowMultipleStockItemsToBeAssignedToATask
            hideRawCloudStorageTab
          }
          file {
            hideIncludeInPublishSwitch
            hideSheetTitleInput
            hideCustomReference
            hideIncludeInDocumentRegister
            hideAnnotateSwitch
            hideXref
            maxSheetRevisionDescriptionLength
            maxSheetRevisionNameLength
            sheetDescriptionsMustBeUnique
          }
          general {
            usesDocumentRegister
            usesTaskConfirmation
            usesTeams
            usesTimeOff
            usesUploadFromGoogleDrive
            usesUploadFromOneDrive
            usesUploadFromDropbox
            hideFinancials
            hideOrganisationIdInTags
            requirePermissionToDisplayUsers
            defaultAddressToSendEmailsFrom
          }
          timesheet {
            usesTimesheets
            areTagsMandatory
            isDescriptionMandatory
            requireQuoteOrVariationForBillableTimesheetBlocks
            canAssignTimesheetBlocksToTaskRevisions
            usesReview
            recordLatLongWhenClockingInOut
            recordLatLongEveryXMinutes
            usesPartDayTimeOff
          }
          timeline {
            usesTimeline
            usesPhysicalBlockInteraction
            usesGapFilling
            usesColoredBlocks
            usesCustomColorLogic
            defaultRoundToHours
            defaultTaskLengthHours
            shouldAssignTimelineTaskToUser
            shouldDisplayOwnUserAtTheTop
            planProjectsInsteadOfTasks
            planTaskRevisionsInsteadOfTasks
            defaultTaskLengthToEstimatedHours
          }
          calendar {
            usesCalendar
          }
          documentLibrary {
            usesDocumentLibrary
          }
          queue {
            usesQueue
          }
          dashboard {
            displayViewTypePicker
          }
          invoice {
            usesInvoices
            usesDoubleInvoiceReview
            usesPercentagesInsteadOfQuantity
            invoiceDate
            useCancelledStatus
            displayPaidInvoicesInSeparateColumns
            emailAddressToSendInvoicesFrom
            defaultExpectPaymentAfterDays
          }
          purchaseOrder {
            usesPurchaseOrders
            emailAddressToSendPurchaseOrdersFrom
          }
          supplier {
            usesSuppliers
          }
          project {
            hideCustomProjectNumberAtCreationTime
            hideAssignedToAtCreationTime
            hideClientAtCreationTime
            customNumberCheckboxOnByDefault
          }
          client {
          }
          request {
            usesRequests
            emailsToNotifyOnNewRequest
            usesClientReview
            displayRequestFormInTaskReview
            allowRequestFormPdfDownload
            allowExternalReviews
            formsCanOnlyBeAddedToOneTask
            reassigningTaskAlsoReassignsRequest
            hideAssignedTo
            externalReviewersCanPerformInternalReviews
          }
          holiday {
            onlyAllowsFullDays
          }
          stock {
            usesStock
          }
        }
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        vatNumber
        fileTypesUsed
        financialControllers
        addons {
          pricePerSeat
          name
          discountPercent
        }
        labels {
          id
          value
        }
        defaultFees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        teams {
          id
          label
        }
        fileStatuses {
          name
          canSkipReview
          canUseExternalReview
        }
        taskStatuses {
          name
          type
          canSkipReview
          canUseExternalReview
        }
        customFields {
          id
          label
          target
          type
          options {
            value
            label
            color
            showAsTag
            tagLabel
          }
          defaultValue
          showAsTag
          displayTagIfChecked
          displayTagIfEmpty
          onlyVisibleForCertainUserGroups
          groupsThatCanSee
          tagLabel
          color
          formula
          isStatic
          mandatory
          nonAdminsCanRead
          nonAdminsCanWrite
        }
        integrations {
          id
          authorisedAt
        }
        accountancyOrganisationId
        includeInBilling
        financialsLastRecalculatedAt
        financialsLastStartedRecalculatingAt
        financialsRecalculationProgress
        financialsRecalculationStatus
        menus {
          id
          label
          items {
            id
            label
            url
            icon
          }
        }
        menuAssignments {
          id
          menuId
          locationId
        }
        createdAt
        updatedAt
        variables {
          items {
            id
            organisation
            name
            type
            value
            createdAt
            updatedAt
          }
          nextToken
        }
        templates {
          items {
            id
            organisation
            name
            type
            createdAt
            currentVersionNumber
            previewTaskId
            previewFileId
            previewTaskRevisionId
            isLive
            isDeprecated
            isDefault
            key
            reviewCommentFontSize
            excludeFromRegisterByDefault
            isCat2Restricted
            outputType
            systemVersion
            parentType
            updatedAt
          }
          nextToken
        }
        bills {
          items {
            id
            customId
            organisation
            createdAt
            isPaid
            subtotal
            totalWithTax
            taxAmount
            s3Key
            itemSubscription
            accountancyInvoiceId
            updatedAt
          }
          nextToken
        }
        timesheetTags {
          items {
            id
            organisation
            label
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getVariable = /* GraphQL */ `
  query GetVariable($id: ID!) {
    getVariable(id: $id) {
      id
      organisation
      name
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const listVariables = /* GraphQL */ `
  query ListVariables(
    $filter: ModelVariableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVariables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        name
        type
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      permissions
      members
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        permissions
        members
        organisation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupsByOrganisation = /* GraphQL */ `
  query ListGroupsByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        permissions
        members
        organisation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      id
      organisation
      name
      type
      createdAt
      currentVersionNumber
      previewTaskId
      previewFileId
      previewTaskRevisionId
      isLive
      isDeprecated
      isDefault
      key
      reviewCommentFontSize
      excludeFromRegisterByDefault
      isCat2Restricted
      outputType
      systemVersion
      parentType
      updatedAt
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        name
        type
        createdAt
        currentVersionNumber
        previewTaskId
        previewFileId
        previewTaskRevisionId
        isLive
        isDeprecated
        isDefault
        key
        reviewCommentFontSize
        excludeFromRegisterByDefault
        isCat2Restricted
        outputType
        systemVersion
        parentType
        updatedAt
      }
      nextToken
    }
  }
`;
export const listVariablesByOrganisation = /* GraphQL */ `
  query ListVariablesByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVariableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVariablesByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        name
        type
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      isAdmin
      isDisabled
      isLoginDisabled
      disabledAt
      cognitoUsername
      organisation
      isHidden
      activated
      firstName
      lastName
      avatarKey
      catLevelDesign
      catLevelCheck
      catLevelIssue
      canReviewTasksWithStatuses
      quoteCreationLimit
      quoteReviewLimit
      invoiceCreationLimit
      invoiceReviewLimit
      purchaseOrderCreationLimit
      purchaseOrderReviewLimit
      position
      qualifications
      signature
      signatureWidth
      signatureHeight
      createdAt
      hasAgreedToCookies
      hasAgreedToTerms
      notificationSettings {
        webSystemNotifications
        webSystemReviewsRequest
        webSystemReviewsResult
        webAppReviewsRequest
        webAppReviewsResult
        webAppTaskAssigned
        webAppPublicUpload
        webAppMention
        webAppRequestActivity
        webAppQuoteAcceptedRejected
        webAppTimeOffRequest
        webAppStockMaintenance
        emailReviewsRequest
        emailReviewsResult
        emailTaskAssigned
        emailPublicUpload
        emailMention
        emailRequestActivity
        emailQuoteAcceptedRejected
        emailTimeOffRequest
        emailStockMaintenance
        mobileAppReviewsRequest
        mobileAppReviewsResult
        mobileMention
      }
      order
      computers {
        id
        name
        cloudWatchLogStreamName
        linkIsInstalled
        linkIsRunning
      }
      lastActiveComputer
      lastLoginAt
      feeRoles
      defaultFeeRole
      phone1
      phone2
      office
      team
      teams
      department
      featureFlags {
        autoBackup
        serverPoweredReportPublish
        serverPoweredQuotePublish
        serverPoweredInvoicePublish
        batchEditSheets
        newQuotePage
        newReportPage
      }
      workingHours {
        id
        repeatPattern
        startTime
        endTime
        intervalId
      }
      permissions
      allowanceHolidayDays
      allowances {
        year
        allowance
        type
        intervalId
      }
      lastOnlineAt
      hasPrioritySignature
      managerIds
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isAdmin
        isDisabled
        isLoginDisabled
        disabledAt
        cognitoUsername
        organisation
        isHidden
        activated
        firstName
        lastName
        avatarKey
        catLevelDesign
        catLevelCheck
        catLevelIssue
        canReviewTasksWithStatuses
        quoteCreationLimit
        quoteReviewLimit
        invoiceCreationLimit
        invoiceReviewLimit
        purchaseOrderCreationLimit
        purchaseOrderReviewLimit
        position
        qualifications
        signature
        signatureWidth
        signatureHeight
        createdAt
        hasAgreedToCookies
        hasAgreedToTerms
        notificationSettings {
          webSystemNotifications
          webSystemReviewsRequest
          webSystemReviewsResult
          webAppReviewsRequest
          webAppReviewsResult
          webAppTaskAssigned
          webAppPublicUpload
          webAppMention
          webAppRequestActivity
          webAppQuoteAcceptedRejected
          webAppTimeOffRequest
          webAppStockMaintenance
          emailReviewsRequest
          emailReviewsResult
          emailTaskAssigned
          emailPublicUpload
          emailMention
          emailRequestActivity
          emailQuoteAcceptedRejected
          emailTimeOffRequest
          emailStockMaintenance
          mobileAppReviewsRequest
          mobileAppReviewsResult
          mobileMention
        }
        order
        computers {
          id
          name
          cloudWatchLogStreamName
          linkIsInstalled
          linkIsRunning
        }
        lastActiveComputer
        lastLoginAt
        feeRoles
        defaultFeeRole
        phone1
        phone2
        office
        team
        teams
        department
        featureFlags {
          autoBackup
          serverPoweredReportPublish
          serverPoweredQuotePublish
          serverPoweredInvoicePublish
          batchEditSheets
          newQuotePage
          newReportPage
        }
        workingHours {
          id
          repeatPattern
          startTime
          endTime
          intervalId
        }
        permissions
        allowanceHolidayDays
        allowances {
          year
          allowance
          type
          intervalId
        }
        lastOnlineAt
        hasPrioritySignature
        managerIds
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByOrganisation = /* GraphQL */ `
  query ListUsersByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isAdmin
        isDisabled
        isLoginDisabled
        disabledAt
        cognitoUsername
        organisation
        isHidden
        activated
        firstName
        lastName
        avatarKey
        catLevelDesign
        catLevelCheck
        catLevelIssue
        canReviewTasksWithStatuses
        quoteCreationLimit
        quoteReviewLimit
        invoiceCreationLimit
        invoiceReviewLimit
        purchaseOrderCreationLimit
        purchaseOrderReviewLimit
        position
        qualifications
        signature
        signatureWidth
        signatureHeight
        createdAt
        hasAgreedToCookies
        hasAgreedToTerms
        notificationSettings {
          webSystemNotifications
          webSystemReviewsRequest
          webSystemReviewsResult
          webAppReviewsRequest
          webAppReviewsResult
          webAppTaskAssigned
          webAppPublicUpload
          webAppMention
          webAppRequestActivity
          webAppQuoteAcceptedRejected
          webAppTimeOffRequest
          webAppStockMaintenance
          emailReviewsRequest
          emailReviewsResult
          emailTaskAssigned
          emailPublicUpload
          emailMention
          emailRequestActivity
          emailQuoteAcceptedRejected
          emailTimeOffRequest
          emailStockMaintenance
          mobileAppReviewsRequest
          mobileAppReviewsResult
          mobileMention
        }
        order
        computers {
          id
          name
          cloudWatchLogStreamName
          linkIsInstalled
          linkIsRunning
        }
        lastActiveComputer
        lastLoginAt
        feeRoles
        defaultFeeRole
        phone1
        phone2
        office
        team
        teams
        department
        featureFlags {
          autoBackup
          serverPoweredReportPublish
          serverPoweredQuotePublish
          serverPoweredInvoicePublish
          batchEditSheets
          newQuotePage
          newReportPage
        }
        workingHours {
          id
          repeatPattern
          startTime
          endTime
          intervalId
        }
        permissions
        allowanceHolidayDays
        allowances {
          year
          allowance
          type
          intervalId
        }
        lastOnlineAt
        hasPrioritySignature
        managerIds
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userId
      readAt
      createdAt
      displayedAt
      message
      type
      link
      author
      target
      organisation
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        readAt
        createdAt
        displayedAt
        message
        type
        link
        author
        target
        organisation
        updatedAt
      }
      nextToken
    }
  }
`;
export const listNotificationsByUser = /* GraphQL */ `
  query ListNotificationsByUser(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        readAt
        createdAt
        displayedAt
        message
        type
        link
        author
        target
        organisation
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      key
      name
      organisation
      notes
      initials
      isPriority
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      fees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      customFields {
        id
        value
      }
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      defaultExpectPaymentAfterDays
      sharedWithGroups
      sharedWithUsers
      updatedAt
      projects {
        items {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listClientsByOrganisation = /* GraphQL */ `
  query ListClientsByOrganisation(
    $organisation: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientsByOrganisation(
      organisation: $organisation
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSupplier = /* GraphQL */ `
  query GetSupplier($id: ID!) {
    getSupplier(id: $id) {
      id
      key
      name
      organisation
      notes
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      updatedAt
    }
  }
`;
export const listSuppliers = /* GraphQL */ `
  query ListSuppliers(
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuppliers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        name
        organisation
        notes
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSuppliersByOrganisation = /* GraphQL */ `
  query ListSuppliersByOrganisation(
    $organisation: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuppliersByOrganisation(
      organisation: $organisation
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        name
        organisation
        notes
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSprint = /* GraphQL */ `
  query GetSprint($id: ID!) {
    getSprint(id: $id) {
      id
      name
      goal
      startDate
      endDate
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      lastTaskOrder
      isActive
      isFinished
      createdAt
      startedAt
      finishedAt
      updatedAt
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listSprints = /* GraphQL */ `
  query ListSprints(
    $filter: ModelSprintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSprints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        goal
        startDate
        endDate
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        lastTaskOrder
        isActive
        isFinished
        createdAt
        startedAt
        finishedAt
        updatedAt
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listSprintsByOrganisation = /* GraphQL */ `
  query ListSprintsByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSprintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSprintsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        goal
        startDate
        endDate
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        lastTaskOrder
        isActive
        isFinished
        createdAt
        startedAt
        finishedAt
        updatedAt
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBill = /* GraphQL */ `
  query GetBill($id: ID!) {
    getBill(id: $id) {
      id
      customId
      organisation
      createdAt
      isPaid
      subtotal
      totalWithTax
      taxAmount
      lineItems {
        id
        name
        quantity
        pricePerUnit
        amount
        amountWithTax
        discountPercent
        discountAmount
      }
      s3Key
      itemSubscription
      accountancyInvoiceId
      updatedAt
    }
  }
`;
export const listBills = /* GraphQL */ `
  query ListBills(
    $filter: ModelBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customId
        organisation
        createdAt
        isPaid
        subtotal
        totalWithTax
        taxAmount
        lineItems {
          id
          name
          quantity
          pricePerUnit
          amount
          amountWithTax
          discountPercent
          discountAmount
        }
        s3Key
        itemSubscription
        accountancyInvoiceId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listBillsByOrganisation = /* GraphQL */ `
  query ListBillsByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customId
        organisation
        createdAt
        isPaid
        subtotal
        totalWithTax
        taxAmount
        lineItems {
          id
          name
          quantity
          pricePerUnit
          amount
          amountWithTax
          discountPercent
          discountAmount
        }
        s3Key
        itemSubscription
        accountancyInvoiceId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listBillsByAccountancyInvoiceId = /* GraphQL */ `
  query ListBillsByAccountancyInvoiceId(
    $accountancyInvoiceId: String
    $sortDirection: ModelSortDirection
    $filter: ModelBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillsByAccountancyInvoiceId(
      accountancyInvoiceId: $accountancyInvoiceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customId
        organisation
        createdAt
        isPaid
        subtotal
        totalWithTax
        taxAmount
        lineItems {
          id
          name
          quantity
          pricePerUnit
          amount
          amountWithTax
          discountPercent
          discountAmount
        }
        s3Key
        itemSubscription
        accountancyInvoiceId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTimesheetTag = /* GraphQL */ `
  query GetTimesheetTag($id: ID!) {
    getTimesheetTag(id: $id) {
      id
      organisation
      label
      createdAt
      updatedAt
    }
  }
`;
export const listTimesheetTags = /* GraphQL */ `
  query ListTimesheetTags(
    $filter: ModelTimesheetTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimesheetTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        label
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTimesheetTagsByOrganisation = /* GraphQL */ `
  query ListTimesheetTagsByOrganisation(
    $organisation: ID
    $label: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimesheetTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimesheetTagsByOrganisation(
      organisation: $organisation
      label: $label
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        label
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTimelineBlock = /* GraphQL */ `
  query GetTimelineBlock($id: ID!) {
    getTimelineBlock(id: $id) {
      id
      startDate
      endDate
      startHours
      durationHours
      taskId
      taskRevisionId
      userId
      organisation
      isPseudoTask
      createdAt
      repeatPattern
      userChosenColor
      isFixed
      updatedAt
    }
  }
`;
export const listTimelineBlocks = /* GraphQL */ `
  query ListTimelineBlocks(
    $filter: ModelTimelineBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimelineBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startDate
        endDate
        startHours
        durationHours
        taskId
        taskRevisionId
        userId
        organisation
        isPseudoTask
        createdAt
        repeatPattern
        userChosenColor
        isFixed
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTimelineBlocksByOrganisation = /* GraphQL */ `
  query ListTimelineBlocksByOrganisation(
    $organisation: ID
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimelineBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimelineBlocksByOrganisation(
      organisation: $organisation
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        startHours
        durationHours
        taskId
        taskRevisionId
        userId
        organisation
        isPseudoTask
        createdAt
        repeatPattern
        userChosenColor
        isFixed
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTimelineBlocksByUser = /* GraphQL */ `
  query ListTimelineBlocksByUser(
    $organisation: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimelineBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimelineBlocksByUser(
      organisation: $organisation
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        startHours
        durationHours
        taskId
        taskRevisionId
        userId
        organisation
        isPseudoTask
        createdAt
        repeatPattern
        userChosenColor
        isFixed
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTimelineBlocksByUserAndDate = /* GraphQL */ `
  query ListTimelineBlocksByUserAndDate(
    $userId: ID
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimelineBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimelineBlocksByUserAndDate(
      userId: $userId
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        startHours
        durationHours
        taskId
        taskRevisionId
        userId
        organisation
        isPseudoTask
        createdAt
        repeatPattern
        userChosenColor
        isFixed
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      author
      title
      description
      clientId
      assignedTo
      initials
      taskCount
      quoteCount
      invoiceCount
      purchaseOrderCount
      createdAt
      isArchived
      isFinished
      archivedAt
      finishedAt
      adminFiles {
        id
        name
        taskId
      }
      clientContacts
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      addressCoordinates {
        lat
        lng
      }
      poNumber
      customFields {
        id
        value
      }
      team
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      sharedWithGroups
      sharedWithUsers
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          parentId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listProjectsByOrganisation = /* GraphQL */ `
  query ListProjectsByOrganisation(
    $organisation: ID
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByOrganisation(
      organisation: $organisation
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBoard = /* GraphQL */ `
  query GetBoard($id: ID!) {
    getBoard(id: $id) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          id
          taskId
          boardId
          order
          organisation
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listBoards = /* GraphQL */ `
  query ListBoards(
    $filter: ModelBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isPublic
        organisation
        createdAt
        updatedAt
        tasks {
          items {
            id
            taskId
            boardId
            order
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listBoardsByOrganisation = /* GraphQL */ `
  query ListBoardsByOrganisation(
    $organisation: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoardsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isPublic
        organisation
        createdAt
        updatedAt
        tasks {
          items {
            id
            taskId
            boardId
            order
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBoardTask = /* GraphQL */ `
  query GetBoardTask($id: ID!) {
    getBoardTask(id: $id) {
      id
      taskId
      boardId
      order
      organisation
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
            canSkipReview
            canUseExternalReview
          }
          taskStatuses {
            name
            type
            canSkipReview
            canUseExternalReview
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          menus {
            id
            label
          }
          menuAssignments {
            id
            menuId
            locationId
          }
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listBoardTasks = /* GraphQL */ `
  query ListBoardTasks(
    $filter: ModelBoardTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoardTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        boardId
        order
        organisation
        createdAt
        updatedAt
        task {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      isReadOnly
      isHidden
      isConfirmed
      linkedTasks {
        id
        correspondingId
        taskId
        relationship
        label
      }
      quoteIds
      quoteLineItemIds
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      catLevel
      organisation
      projectId
      sprintId
      clientId
      author
      title
      titleLowerCase
      assignedTo
      assignedToUsers
      assignedToStockItems
      subtitle
      dueDate
      startDate
      endDate
      estimatedHours
      budget
      checkPrice
      isArchived
      isFinished
      finishedAt
      archivedAt
      isUnderReview
      reviewStatus
      reviewSecondaryStatus
      status
      isExternalReference
      customFields {
        id
        value
      }
      order
      createdAt
      subtaskProgress
      checkedBy
      amountBudget
      amountSpent
      hoursBudget
      hoursSpent
      clientContact
      clientContacts
      clientAddress
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      team
      requestIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
      organisationDetails {
        id
        logoKey
        name
        description
        projectCount
        requestCount
        projectIdOffset
        hasAutoPublish
        slackWebhookURL
        usesFiles
        usesAuthority
        usesDesignAuthority
        usesCheckAuthority
        usesReviewAuthority
        usesInitials
        usesClientInitials
        usesProjectInitials
        usesTaskInitials
        usesRevisions
        usesReviews
        usesSprints
        usesDelete
        billingDay
        pricePerSeat
        taxRate
        accountBalance
        monthlyCost
        discountPerSeat
        lastBillCreatedAt
        seats
        deploymentAlertNumber
        itemSubscription
        settings {
          review {
            excludedFileTypes
            defaultCommentFontSize
            allowSimpleReviews
          }
          quote {
            isValidFromHidden
            isValidUntilHidden
            hiddenLineItemColumns
            shouldArchiveTasksWhenClosingQuote
            shouldOpenCreateTaskModal
            defaultQuoteBccEmails
            usesQuotes
            usesDelete
            tasksCreatedFromQuoteDefaultToQuoteTitle
            tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
            defaultUnitPrice
            disablePreview
            emailAddressToSendQuotesFrom
            quotesInHours
          }
          task {
            createsCat2Check
            defaultSprint
            taskRevisionsAreSyncedWithSheetRevisions
            allowUseOfCatZero
            usesTaskEstimates
            useTaskRevisionEstimates
            useDueDatesOnTaskRevisions
            usesPriority
            usesRequestedDate
            displayTaskEstimatesAsTag
            createAttachmentFolderWithTask
            dontCreateTaskFolders
            defaultTaskCatLevel
            isTaskAssigneeMandatory
            allowUnassignedAsMandatoryException
            isTaskDueDateMandatory
            maxTaskRevisionNameLength
            maxTaskRevisionDescriptionLength
            needsRequestForTaskRevisionCreation
            needsRequestForTaskRevisionReview
            automaticallyCreateProject
            hideCustomTaskNumberAtCreationTime
            hideCopyTaskAtCreationTime
            hideTaskDueDates
            taskIdToCopyByDefault
            cannotCreateNewTaskRevisions
            allowMultipleLiveTaskRevisions
            copyTaskAlsoCopiesAllTaskRevisions
            usesStartAndEndDates
            allowMultipleUsersToBeAssignedToATask
            allowMultipleStockItemsToBeAssignedToATask
            hideRawCloudStorageTab
          }
          file {
            hideIncludeInPublishSwitch
            hideSheetTitleInput
            hideCustomReference
            hideIncludeInDocumentRegister
            hideAnnotateSwitch
            hideXref
            maxSheetRevisionDescriptionLength
            maxSheetRevisionNameLength
            sheetDescriptionsMustBeUnique
          }
          general {
            usesDocumentRegister
            usesTaskConfirmation
            usesTeams
            usesTimeOff
            usesUploadFromGoogleDrive
            usesUploadFromOneDrive
            usesUploadFromDropbox
            hideFinancials
            hideOrganisationIdInTags
            requirePermissionToDisplayUsers
            defaultAddressToSendEmailsFrom
          }
          timesheet {
            usesTimesheets
            areTagsMandatory
            isDescriptionMandatory
            requireQuoteOrVariationForBillableTimesheetBlocks
            canAssignTimesheetBlocksToTaskRevisions
            usesReview
            recordLatLongWhenClockingInOut
            recordLatLongEveryXMinutes
            usesPartDayTimeOff
          }
          timeline {
            usesTimeline
            usesPhysicalBlockInteraction
            usesGapFilling
            usesColoredBlocks
            usesCustomColorLogic
            defaultRoundToHours
            defaultTaskLengthHours
            shouldAssignTimelineTaskToUser
            shouldDisplayOwnUserAtTheTop
            planProjectsInsteadOfTasks
            planTaskRevisionsInsteadOfTasks
            defaultTaskLengthToEstimatedHours
          }
          calendar {
            usesCalendar
          }
          documentLibrary {
            usesDocumentLibrary
          }
          queue {
            usesQueue
          }
          dashboard {
            displayViewTypePicker
          }
          invoice {
            usesInvoices
            usesDoubleInvoiceReview
            usesPercentagesInsteadOfQuantity
            invoiceDate
            useCancelledStatus
            displayPaidInvoicesInSeparateColumns
            emailAddressToSendInvoicesFrom
            defaultExpectPaymentAfterDays
          }
          purchaseOrder {
            usesPurchaseOrders
            emailAddressToSendPurchaseOrdersFrom
          }
          supplier {
            usesSuppliers
          }
          project {
            hideCustomProjectNumberAtCreationTime
            hideAssignedToAtCreationTime
            hideClientAtCreationTime
            customNumberCheckboxOnByDefault
          }
          client {
          }
          request {
            usesRequests
            emailsToNotifyOnNewRequest
            usesClientReview
            displayRequestFormInTaskReview
            allowRequestFormPdfDownload
            allowExternalReviews
            formsCanOnlyBeAddedToOneTask
            reassigningTaskAlsoReassignsRequest
            hideAssignedTo
            externalReviewersCanPerformInternalReviews
          }
          holiday {
            onlyAllowsFullDays
          }
          stock {
            usesStock
          }
        }
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        vatNumber
        fileTypesUsed
        financialControllers
        addons {
          pricePerSeat
          name
          discountPercent
        }
        labels {
          id
          value
        }
        defaultFees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        teams {
          id
          label
        }
        fileStatuses {
          name
          canSkipReview
          canUseExternalReview
        }
        taskStatuses {
          name
          type
          canSkipReview
          canUseExternalReview
        }
        customFields {
          id
          label
          target
          type
          options {
            value
            label
            color
            showAsTag
            tagLabel
          }
          defaultValue
          showAsTag
          displayTagIfChecked
          displayTagIfEmpty
          onlyVisibleForCertainUserGroups
          groupsThatCanSee
          tagLabel
          color
          formula
          isStatic
          mandatory
          nonAdminsCanRead
          nonAdminsCanWrite
        }
        integrations {
          id
          authorisedAt
        }
        accountancyOrganisationId
        includeInBilling
        financialsLastRecalculatedAt
        financialsLastStartedRecalculatingAt
        financialsRecalculationProgress
        financialsRecalculationStatus
        menus {
          id
          label
          items {
            id
            label
            url
            icon
          }
        }
        menuAssignments {
          id
          menuId
          locationId
        }
        createdAt
        updatedAt
        variables {
          items {
            id
            organisation
            name
            type
            value
            createdAt
            updatedAt
          }
          nextToken
        }
        templates {
          items {
            id
            organisation
            name
            type
            createdAt
            currentVersionNumber
            previewTaskId
            previewFileId
            previewTaskRevisionId
            isLive
            isDeprecated
            isDefault
            key
            reviewCommentFontSize
            excludeFromRegisterByDefault
            isCat2Restricted
            outputType
            systemVersion
            parentType
            updatedAt
          }
          nextToken
        }
        bills {
          items {
            id
            customId
            organisation
            createdAt
            isPaid
            subtotal
            totalWithTax
            taxAmount
            s3Key
            itemSubscription
            accountancyInvoiceId
            updatedAt
          }
          nextToken
        }
        timesheetTags {
          items {
            id
            organisation
            label
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          taskId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quoteLineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      revisions {
        items {
          id
          taskId
          name
          isReadOnly
          organisation
          isArchived
          status
          dueDate
          requestedDate
          estimatedHours
          priorityId
          requestIds
          base
          randomNumber
          itemSubscription
          collectionSubscription
          description
          author
          checkedBy
          reviewId
          reviewAcceptDate
          reviewStatus
          reviewSecondaryStatus
          deletedFilesByType
          deletedFilesByTemplate
          readAccessGroups
          writeAccessGroups
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          files {
            nextToken
          }
          review {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          notesStart
          notesEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
            canSkipReview
            canUseExternalReview
          }
          taskStatuses {
            name
            type
            canSkipReview
            canUseExternalReview
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          menus {
            id
            label
          }
          menuAssignments {
            id
            menuId
            locationId
          }
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listTasksByProject = /* GraphQL */ `
  query ListTasksByProject(
    $projectId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByProject(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
            canSkipReview
            canUseExternalReview
          }
          taskStatuses {
            name
            type
            canSkipReview
            canUseExternalReview
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          menus {
            id
            label
          }
          menuAssignments {
            id
            menuId
            locationId
          }
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listTasksBySprintOrder = /* GraphQL */ `
  query ListTasksBySprintOrder(
    $sprintId: ID
    $order: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksBySprintOrder(
      sprintId: $sprintId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
            canSkipReview
            canUseExternalReview
          }
          taskStatuses {
            name
            type
            canSkipReview
            canUseExternalReview
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          menus {
            id
            label
          }
          menuAssignments {
            id
            menuId
            locationId
          }
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listTasksByOrganisation = /* GraphQL */ `
  query ListTasksByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
            canSkipReview
            canUseExternalReview
          }
          taskStatuses {
            name
            type
            canSkipReview
            canUseExternalReview
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          menus {
            id
            label
          }
          menuAssignments {
            id
            menuId
            locationId
          }
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSubtask = /* GraphQL */ `
  query GetSubtask($id: ID!) {
    getSubtask(id: $id) {
      id
      parentId
      order
      assignedTo
      title
      description
      isFinished
      isArchived
      author
      dueDate
      organisation
      createdAt
      updatedAt
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listSubtasks = /* GraphQL */ `
  query ListSubtasks(
    $filter: ModelSubtaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubtasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentId
        order
        assignedTo
        title
        description
        isFinished
        isArchived
        author
        dueDate
        organisation
        createdAt
        updatedAt
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTaskActivityItem = /* GraphQL */ `
  query GetTaskActivityItem($id: ID!) {
    getTaskActivityItem(id: $id) {
      id
      taskId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const listTaskActivityItems = /* GraphQL */ `
  query ListTaskActivityItems(
    $filter: ModelTaskActivityItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskActivityItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        author
        content
        type
        organisation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTaskActivityItemsByTask = /* GraphQL */ `
  query ListTaskActivityItemsByTask(
    $taskId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskActivityItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskActivityItemsByTask(
      taskId: $taskId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        author
        content
        type
        organisation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActivityItem = /* GraphQL */ `
  query GetActivityItem($id: ID!) {
    getActivityItem(id: $id) {
      id
      parentId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const listActivityItems = /* GraphQL */ `
  query ListActivityItems(
    $filter: ModelActivityItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentId
        author
        content
        type
        organisation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listActivityItemsByParent = /* GraphQL */ `
  query ListActivityItemsByParent(
    $parentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityItemsByParent(
      parentId: $parentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        author
        content
        type
        organisation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPurchaseOrder = /* GraphQL */ `
  query GetPurchaseOrder($id: ID!) {
    getPurchaseOrder(id: $id) {
      id
      organisation
      isArchived
      assignedTo
      author
      supplierId
      projectId
      clientId
      quoteId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      status
      randomNumber
      itemSubscription
      collectionSubscription
      supplierContact
      supplierContacts
      supplierAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      updatedAt
      activity {
        items {
          id
          documentId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          purchaseOrderId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          isHourly
          lastUpdateAuthorId
          supplierQuoteId
          customFields {
            id
            value
          }
          order
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPurchaseOrders = /* GraphQL */ `
  query ListPurchaseOrders(
    $filter: ModelPurchaseOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        isArchived
        assignedTo
        author
        supplierId
        projectId
        clientId
        quoteId
        fileKey
        validFrom
        validUntil
        reference
        title
        description
        currency
        taxInclusive
        taxRate
        subtotal
        totalTax
        total
        createdAt
        status
        randomNumber
        itemSubscription
        collectionSubscription
        supplierContact
        supplierContacts
        supplierAddress
        templateId
        templateVersionNumber
        savedAt
        metadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        checkedBy
        isUnderReview
        reviewApprovedAt
        reviewStatus
        reviewSecondaryStatus
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        customFields {
          id
          value
        }
        team
        updatedAt
        activity {
          items {
            id
            documentId
            total
            type
            organisation
            createdAt
            author
            content
            updatedAt
          }
          nextToken
        }
        lineItems {
          items {
            id
            purchaseOrderId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            isHourly
            lastUpdateAuthorId
            supplierQuoteId
            order
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listPurchaseOrdersByOrganisation = /* GraphQL */ `
  query ListPurchaseOrdersByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchaseOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseOrdersByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        isArchived
        assignedTo
        author
        supplierId
        projectId
        clientId
        quoteId
        fileKey
        validFrom
        validUntil
        reference
        title
        description
        currency
        taxInclusive
        taxRate
        subtotal
        totalTax
        total
        createdAt
        status
        randomNumber
        itemSubscription
        collectionSubscription
        supplierContact
        supplierContacts
        supplierAddress
        templateId
        templateVersionNumber
        savedAt
        metadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        checkedBy
        isUnderReview
        reviewApprovedAt
        reviewStatus
        reviewSecondaryStatus
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        customFields {
          id
          value
        }
        team
        updatedAt
        activity {
          items {
            id
            documentId
            total
            type
            organisation
            createdAt
            author
            content
            updatedAt
          }
          nextToken
        }
        lineItems {
          items {
            id
            purchaseOrderId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            isHourly
            lastUpdateAuthorId
            supplierQuoteId
            order
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listPurchaseOrdersBySupplier = /* GraphQL */ `
  query ListPurchaseOrdersBySupplier(
    $supplierId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchaseOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseOrdersBySupplier(
      supplierId: $supplierId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        isArchived
        assignedTo
        author
        supplierId
        projectId
        clientId
        quoteId
        fileKey
        validFrom
        validUntil
        reference
        title
        description
        currency
        taxInclusive
        taxRate
        subtotal
        totalTax
        total
        createdAt
        status
        randomNumber
        itemSubscription
        collectionSubscription
        supplierContact
        supplierContacts
        supplierAddress
        templateId
        templateVersionNumber
        savedAt
        metadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        checkedBy
        isUnderReview
        reviewApprovedAt
        reviewStatus
        reviewSecondaryStatus
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        customFields {
          id
          value
        }
        team
        updatedAt
        activity {
          items {
            id
            documentId
            total
            type
            organisation
            createdAt
            author
            content
            updatedAt
          }
          nextToken
        }
        lineItems {
          items {
            id
            purchaseOrderId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            isHourly
            lastUpdateAuthorId
            supplierQuoteId
            order
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listPurchaseOrdersByProject = /* GraphQL */ `
  query ListPurchaseOrdersByProject(
    $projectId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchaseOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseOrdersByProject(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        isArchived
        assignedTo
        author
        supplierId
        projectId
        clientId
        quoteId
        fileKey
        validFrom
        validUntil
        reference
        title
        description
        currency
        taxInclusive
        taxRate
        subtotal
        totalTax
        total
        createdAt
        status
        randomNumber
        itemSubscription
        collectionSubscription
        supplierContact
        supplierContacts
        supplierAddress
        templateId
        templateVersionNumber
        savedAt
        metadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        checkedBy
        isUnderReview
        reviewApprovedAt
        reviewStatus
        reviewSecondaryStatus
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        customFields {
          id
          value
        }
        team
        updatedAt
        activity {
          items {
            id
            documentId
            total
            type
            organisation
            createdAt
            author
            content
            updatedAt
          }
          nextToken
        }
        lineItems {
          items {
            id
            purchaseOrderId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            isHourly
            lastUpdateAuthorId
            supplierQuoteId
            order
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuote = /* GraphQL */ `
  query GetQuote($id: ID!) {
    getQuote(id: $id) {
      id
      organisation
      currentRevisionName
      isArchived
      assignedTo
      author
      clientId
      projectId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      rejectionDetails {
        type
        notes
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      isTenderWin
      manuallyInvoicedAmount
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      requestIds
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          quoteId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listQuotes = /* GraphQL */ `
  query ListQuotes(
    $filter: ModelQuoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        currentRevisionName
        isArchived
        assignedTo
        author
        clientId
        projectId
        fileKey
        validFrom
        validUntil
        reference
        title
        description
        currency
        taxInclusive
        taxRate
        subtotal
        totalTax
        total
        createdAt
        termsText
        termsAttachment
        status
        randomNumber
        itemSubscription
        collectionSubscription
        clientContact
        clientContacts
        clientAddress
        templateId
        templateVersionNumber
        savedAt
        metadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        poNumber
        checkedBy
        isUnderReview
        reviewApprovedAt
        reviewStatus
        reviewSecondaryStatus
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        rejectionDetails {
          type
          notes
        }
        defaultFees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        isTenderWin
        manuallyInvoicedAmount
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        customFields {
          id
          value
        }
        team
        requestIds
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        activity {
          items {
            id
            quoteId
            total
            type
            organisation
            createdAt
            author
            content
            updatedAt
          }
          nextToken
        }
        lineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listQuotesByOrganisation = /* GraphQL */ `
  query ListQuotesByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuotesByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        currentRevisionName
        isArchived
        assignedTo
        author
        clientId
        projectId
        fileKey
        validFrom
        validUntil
        reference
        title
        description
        currency
        taxInclusive
        taxRate
        subtotal
        totalTax
        total
        createdAt
        termsText
        termsAttachment
        status
        randomNumber
        itemSubscription
        collectionSubscription
        clientContact
        clientContacts
        clientAddress
        templateId
        templateVersionNumber
        savedAt
        metadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        poNumber
        checkedBy
        isUnderReview
        reviewApprovedAt
        reviewStatus
        reviewSecondaryStatus
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        rejectionDetails {
          type
          notes
        }
        defaultFees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        isTenderWin
        manuallyInvoicedAmount
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        customFields {
          id
          value
        }
        team
        requestIds
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        activity {
          items {
            id
            quoteId
            total
            type
            organisation
            createdAt
            author
            content
            updatedAt
          }
          nextToken
        }
        lineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuoteActivityItem = /* GraphQL */ `
  query GetQuoteActivityItem($id: ID!) {
    getQuoteActivityItem(id: $id) {
      id
      quoteId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const listQuoteActivityItems = /* GraphQL */ `
  query ListQuoteActivityItems(
    $filter: ModelQuoteActivityItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuoteActivityItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quoteId
        total
        type
        organisation
        createdAt
        author
        content
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvoiceActivityItem = /* GraphQL */ `
  query GetInvoiceActivityItem($id: ID!) {
    getInvoiceActivityItem(id: $id) {
      id
      invoiceId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const listInvoiceActivityItems = /* GraphQL */ `
  query ListInvoiceActivityItems(
    $filter: ModelInvoiceActivityItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceActivityItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invoiceId
        total
        type
        organisation
        createdAt
        author
        content
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocumentActivityItem = /* GraphQL */ `
  query GetDocumentActivityItem($id: ID!) {
    getDocumentActivityItem(id: $id) {
      id
      documentId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const listDocumentActivityItems = /* GraphQL */ `
  query ListDocumentActivityItems(
    $filter: ModelDocumentActivityItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentActivityItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        total
        type
        organisation
        createdAt
        author
        content
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuoteLineItem = /* GraphQL */ `
  query GetQuoteLineItem($id: ID!) {
    getQuoteLineItem(id: $id) {
      id
      quoteId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      authorityLevel
      checkPrice
      resultingTaskId
      resultingPurchaseOrderId
      isHourly
      isRejected
      sector
      geographicalLocation
      isHourlyFullyInvoiced
      lastUpdateAuthorId
      manuallyInvoicedAmount
      isManuallyFullyInvoiced
      progressPercent
      customFields {
        id
        value
      }
      requestIds
      order
      updatedAt
      invoiceLineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listQuoteLineItems = /* GraphQL */ `
  query ListQuoteLineItems(
    $filter: ModelQuoteLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuoteLineItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quoteId
        organisation
        title
        description
        quantity
        unitPrice
        discountPercent
        taxRate
        createdAt
        taxAmount
        amount
        authorityLevel
        checkPrice
        resultingTaskId
        resultingPurchaseOrderId
        isHourly
        isRejected
        sector
        geographicalLocation
        isHourlyFullyInvoiced
        lastUpdateAuthorId
        manuallyInvoicedAmount
        isManuallyFullyInvoiced
        progressPercent
        customFields {
          id
          value
        }
        requestIds
        order
        updatedAt
        invoiceLineItems {
          items {
            id
            invoiceId
            organisation
            quoteLineItemId
            taskId
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            order
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listQuoteLineItemsByOrganisation = /* GraphQL */ `
  query ListQuoteLineItemsByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuoteLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuoteLineItemsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quoteId
        organisation
        title
        description
        quantity
        unitPrice
        discountPercent
        taxRate
        createdAt
        taxAmount
        amount
        authorityLevel
        checkPrice
        resultingTaskId
        resultingPurchaseOrderId
        isHourly
        isRejected
        sector
        geographicalLocation
        isHourlyFullyInvoiced
        lastUpdateAuthorId
        manuallyInvoicedAmount
        isManuallyFullyInvoiced
        progressPercent
        customFields {
          id
          value
        }
        requestIds
        order
        updatedAt
        invoiceLineItems {
          items {
            id
            invoiceId
            organisation
            quoteLineItemId
            taskId
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            order
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPurchaseOrderLineItem = /* GraphQL */ `
  query GetPurchaseOrderLineItem($id: ID!) {
    getPurchaseOrderLineItem(id: $id) {
      id
      purchaseOrderId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      isHourly
      lastUpdateAuthorId
      supplierQuoteId
      customFields {
        id
        value
      }
      order
      updatedAt
    }
  }
`;
export const listPurchaseOrderLineItems = /* GraphQL */ `
  query ListPurchaseOrderLineItems(
    $filter: ModelPurchaseOrderLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseOrderLineItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        purchaseOrderId
        organisation
        title
        description
        quantity
        unitPrice
        discountPercent
        taxRate
        createdAt
        taxAmount
        amount
        isHourly
        lastUpdateAuthorId
        supplierQuoteId
        customFields {
          id
          value
        }
        order
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPurchaseOrderLineItemsByOrganisation = /* GraphQL */ `
  query ListPurchaseOrderLineItemsByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchaseOrderLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseOrderLineItemsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        purchaseOrderId
        organisation
        title
        description
        quantity
        unitPrice
        discountPercent
        taxRate
        createdAt
        taxAmount
        amount
        isHourly
        lastUpdateAuthorId
        supplierQuoteId
        customFields {
          id
          value
        }
        order
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvoiceLineItem = /* GraphQL */ `
  query GetInvoiceLineItem($id: ID!) {
    getInvoiceLineItem(id: $id) {
      id
      invoiceId
      organisation
      quoteLineItemId
      taskId
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      customFields {
        id
        value
      }
      order
      updatedAt
      quoteLineItem {
        id
        quoteId
        organisation
        title
        description
        quantity
        unitPrice
        discountPercent
        taxRate
        createdAt
        taxAmount
        amount
        authorityLevel
        checkPrice
        resultingTaskId
        resultingPurchaseOrderId
        isHourly
        isRejected
        sector
        geographicalLocation
        isHourlyFullyInvoiced
        lastUpdateAuthorId
        manuallyInvoicedAmount
        isManuallyFullyInvoiced
        progressPercent
        customFields {
          id
          value
        }
        requestIds
        order
        updatedAt
        invoiceLineItems {
          items {
            id
            invoiceId
            organisation
            quoteLineItemId
            taskId
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            order
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listInvoiceLineItems = /* GraphQL */ `
  query ListInvoiceLineItems(
    $filter: ModelInvoiceLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceLineItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invoiceId
        organisation
        quoteLineItemId
        taskId
        title
        description
        quantity
        unitPrice
        discountPercent
        taxRate
        createdAt
        taxAmount
        amount
        customFields {
          id
          value
        }
        order
        updatedAt
        quoteLineItem {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getTaskRevision = /* GraphQL */ `
  query GetTaskRevision($id: ID!) {
    getTaskRevision(id: $id) {
      id
      taskId
      name
      isReadOnly
      organisation
      isArchived
      status
      dueDate
      requestedDate
      estimatedHours
      priorityId
      requestIds
      base
      randomNumber
      itemSubscription
      collectionSubscription
      description
      author
      checkedBy
      reviewId
      reviewAcceptDate
      reviewStatus
      reviewSecondaryStatus
      deletedFilesByType
      deletedFilesByTemplate
      readAccessGroups
      writeAccessGroups
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
            canSkipReview
            canUseExternalReview
          }
          taskStatuses {
            name
            type
            canSkipReview
            canUseExternalReview
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          menus {
            id
            label
          }
          menuAssignments {
            id
            menuId
            locationId
          }
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      files {
        items {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        nextToken
      }
      review {
        id
        organisation
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        approvedItems {
          id
          approvedAt
        }
        randomNumber
        itemSubscription
        collectionSubscription
        parentId
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTaskRevisions = /* GraphQL */ `
  query ListTaskRevisions(
    $filter: ModelTaskRevisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskRevisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        name
        isReadOnly
        organisation
        isArchived
        status
        dueDate
        requestedDate
        estimatedHours
        priorityId
        requestIds
        base
        randomNumber
        itemSubscription
        collectionSubscription
        description
        author
        checkedBy
        reviewId
        reviewAcceptDate
        reviewStatus
        reviewSecondaryStatus
        deletedFilesByType
        deletedFilesByTemplate
        readAccessGroups
        writeAccessGroups
        createdAt
        updatedAt
        task {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        files {
          items {
            id
            taskRevisionId
            name
            organisation
            isArchived
            isHidden
            type
            extension
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            skipAnnotation
            hasCustomFile
            autoPublishDisabled
            templateId
            templateVersionNumber
            isCorrupted
            isStartingFile
            startingFileName
            lastNonCorruptedS3VersionId
            constantId
            updatedAt
          }
          nextToken
        }
        review {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      taskRevisionId
      name
      organisation
      isArchived
      isHidden
      type
      extension
      sheetsInApplication {
        name
        exportKey
      }
      customFields {
        id
        value
      }
      reportMetadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      skipAnnotation
      hasCustomFile
      autoPublishDisabled
      templateId
      templateVersionNumber
      isCorrupted
      isStartingFile
      startingFileName
      lastNonCorruptedS3VersionId
      constantId
      updatedAt
      sheets {
        items {
          id
          taskId
          fileId
          name
          description
          fileType
          autoGeneratedReferenceNumber
          customReferenceNumber
          createdAt
          order
          includeInPublish
          constantId
          excludeFromRegister
          updatedAt
          revisions {
            nextToken
          }
        }
        nextToken
      }
      versions {
        items {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskRevisionId
        name
        organisation
        isArchived
        isHidden
        type
        extension
        sheetsInApplication {
          name
          exportKey
        }
        customFields {
          id
          value
        }
        reportMetadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        skipAnnotation
        hasCustomFile
        autoPublishDisabled
        templateId
        templateVersionNumber
        isCorrupted
        isStartingFile
        startingFileName
        lastNonCorruptedS3VersionId
        constantId
        updatedAt
        sheets {
          items {
            id
            taskId
            fileId
            name
            description
            fileType
            autoGeneratedReferenceNumber
            customReferenceNumber
            createdAt
            order
            includeInPublish
            constantId
            excludeFromRegister
            updatedAt
          }
          nextToken
        }
        versions {
          items {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listFilesByOrganisation = /* GraphQL */ `
  query ListFilesByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilesByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskRevisionId
        name
        organisation
        isArchived
        isHidden
        type
        extension
        sheetsInApplication {
          name
          exportKey
        }
        customFields {
          id
          value
        }
        reportMetadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        skipAnnotation
        hasCustomFile
        autoPublishDisabled
        templateId
        templateVersionNumber
        isCorrupted
        isStartingFile
        startingFileName
        lastNonCorruptedS3VersionId
        constantId
        updatedAt
        sheets {
          items {
            id
            taskId
            fileId
            name
            description
            fileType
            autoGeneratedReferenceNumber
            customReferenceNumber
            createdAt
            order
            includeInPublish
            constantId
            excludeFromRegister
            updatedAt
          }
          nextToken
        }
        versions {
          items {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSheet = /* GraphQL */ `
  query GetSheet($id: ID!) {
    getSheet(id: $id) {
      id
      taskId
      fileId
      name
      description
      fileType
      autoGeneratedReferenceNumber
      customReferenceNumber
      createdAt
      order
      includeInPublish
      constantId
      excludeFromRegister
      updatedAt
      revisions {
        items {
          id
          sheetId
          name
          description
          status
          author
          checkedBy
          reviewAcceptDate
          fileVersionId
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          isArchived
          realCreatedAt
          createdAt
          updatedAt
          fileVersion {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listSheets = /* GraphQL */ `
  query ListSheets(
    $filter: ModelSheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSheets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        fileId
        name
        description
        fileType
        autoGeneratedReferenceNumber
        customReferenceNumber
        createdAt
        order
        includeInPublish
        constantId
        excludeFromRegister
        updatedAt
        revisions {
          items {
            id
            sheetId
            name
            description
            status
            author
            checkedBy
            reviewAcceptDate
            fileVersionId
            isArchived
            realCreatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSheetRevision = /* GraphQL */ `
  query GetSheetRevision($id: ID!) {
    getSheetRevision(id: $id) {
      id
      sheetId
      name
      description
      status
      author
      checkedBy
      reviewAcceptDate
      fileVersionId
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      isArchived
      realCreatedAt
      createdAt
      updatedAt
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSheetRevisions = /* GraphQL */ `
  query ListSheetRevisions(
    $filter: ModelSheetRevisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSheetRevisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sheetId
        name
        description
        status
        author
        checkedBy
        reviewAcceptDate
        fileVersionId
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        isArchived
        realCreatedAt
        createdAt
        updatedAt
        fileVersion {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getFileVersion = /* GraphQL */ `
  query GetFileVersion($id: ID!) {
    getFileVersion(id: $id) {
      id
      versionNumber
      customId
      fileId
      key
      organisation
      pageCount
      processingSource
      processingDestination
      processingStatus
      processingOperation
      publishStartAt
      publishEndAt
      publishError
      savedAt
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      externalReferences {
        id
        fileVersionId
        taskId
        taskRevisionId
        taskRevisionName
        fileId
        versionNumber
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFileVersions = /* GraphQL */ `
  query ListFileVersions(
    $filter: ModelFileVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFileVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      organisation
      reviewThread {
        id
        type
        createdAt
        sheetId
        sheetLabel
        itemId
        content
        author
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        resolved
        edited
        resolvedBy
        resolvedAt
      }
      approvedItems {
        id
        approvedAt
      }
      randomNumber
      itemSubscription
      collectionSubscription
      parentId
      createdAt
      updatedAt
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        approvedItems {
          id
          approvedAt
        }
        randomNumber
        itemSubscription
        collectionSubscription
        parentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAsyncJob = /* GraphQL */ `
  query GetAsyncJob($id: ID!) {
    getAsyncJob(id: $id) {
      id
      retriedTimes
      retriedFromAsyncJobId
      retryReason
      fileType
      taskId
      fileId
      type
      fileVersionId
      organisation
      userId
      status
      createdAt
      progressPercent
      cancelRequested
      error
      startedAt
      finishedAt
      durationSeconds
      inputS3VersionId
      outputS3VersionId
      outputS3Key
      auditItemId
      consumerId
      logStreamName
      athenaExecutionId
      resultKey
      executionId
      monitorContext
      restUrl
      bucket
      region
      graphQLUrl
      operations {
        type
        sheetName
        copySheetName
        oldSheetName
        newSheetName
        sheetNames
        newSheetNames
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
      }
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
            canSkipReview
            canUseExternalReview
          }
          taskStatuses {
            name
            type
            canSkipReview
            canUseExternalReview
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          menus {
            id
            label
          }
          menuAssignments {
            id
            menuId
            locationId
          }
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      file {
        id
        taskRevisionId
        name
        organisation
        isArchived
        isHidden
        type
        extension
        sheetsInApplication {
          name
          exportKey
        }
        customFields {
          id
          value
        }
        reportMetadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        skipAnnotation
        hasCustomFile
        autoPublishDisabled
        templateId
        templateVersionNumber
        isCorrupted
        isStartingFile
        startingFileName
        lastNonCorruptedS3VersionId
        constantId
        updatedAt
        sheets {
          items {
            id
            taskId
            fileId
            name
            description
            fileType
            autoGeneratedReferenceNumber
            customReferenceNumber
            createdAt
            order
            includeInPublish
            constantId
            excludeFromRegister
            updatedAt
          }
          nextToken
        }
        versions {
          items {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
      }
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAsyncJobs = /* GraphQL */ `
  query ListAsyncJobs(
    $filter: ModelAsyncJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsyncJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        retriedTimes
        retriedFromAsyncJobId
        retryReason
        fileType
        taskId
        fileId
        type
        fileVersionId
        organisation
        userId
        status
        createdAt
        progressPercent
        cancelRequested
        error
        startedAt
        finishedAt
        durationSeconds
        inputS3VersionId
        outputS3VersionId
        outputS3Key
        auditItemId
        consumerId
        logStreamName
        athenaExecutionId
        resultKey
        executionId
        monitorContext
        restUrl
        bucket
        region
        graphQLUrl
        operations {
          type
          sheetName
          copySheetName
          oldSheetName
          newSheetName
          sheetNames
          newSheetNames
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
        }
        updatedAt
        task {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        file {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        fileVersion {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPublishJobsByOrganisation = /* GraphQL */ `
  query ListPublishJobsByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAsyncJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishJobsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retriedTimes
        retriedFromAsyncJobId
        retryReason
        fileType
        taskId
        fileId
        type
        fileVersionId
        organisation
        userId
        status
        createdAt
        progressPercent
        cancelRequested
        error
        startedAt
        finishedAt
        durationSeconds
        inputS3VersionId
        outputS3VersionId
        outputS3Key
        auditItemId
        consumerId
        logStreamName
        athenaExecutionId
        resultKey
        executionId
        monitorContext
        restUrl
        bucket
        region
        graphQLUrl
        operations {
          type
          sheetName
          copySheetName
          oldSheetName
          newSheetName
          sheetNames
          newSheetNames
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
        }
        updatedAt
        task {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        file {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        fileVersion {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPublishJobsToRun = /* GraphQL */ `
  query ListPublishJobsToRun(
    $fileType: FileType
    $statusCreatedAt: ModelAsyncJobToRunCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAsyncJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishJobsToRun(
      fileType: $fileType
      statusCreatedAt: $statusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retriedTimes
        retriedFromAsyncJobId
        retryReason
        fileType
        taskId
        fileId
        type
        fileVersionId
        organisation
        userId
        status
        createdAt
        progressPercent
        cancelRequested
        error
        startedAt
        finishedAt
        durationSeconds
        inputS3VersionId
        outputS3VersionId
        outputS3Key
        auditItemId
        consumerId
        logStreamName
        athenaExecutionId
        resultKey
        executionId
        monitorContext
        restUrl
        bucket
        region
        graphQLUrl
        operations {
          type
          sheetName
          copySheetName
          oldSheetName
          newSheetName
          sheetNames
          newSheetNames
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
        }
        updatedAt
        task {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        file {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        fileVersion {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listAsyncJobsToRun = /* GraphQL */ `
  query ListAsyncJobsToRun(
    $status: PublishJobStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAsyncJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsyncJobsToRun(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retriedTimes
        retriedFromAsyncJobId
        retryReason
        fileType
        taskId
        fileId
        type
        fileVersionId
        organisation
        userId
        status
        createdAt
        progressPercent
        cancelRequested
        error
        startedAt
        finishedAt
        durationSeconds
        inputS3VersionId
        outputS3VersionId
        outputS3Key
        auditItemId
        consumerId
        logStreamName
        athenaExecutionId
        resultKey
        executionId
        monitorContext
        restUrl
        bucket
        region
        graphQLUrl
        operations {
          type
          sheetName
          copySheetName
          oldSheetName
          newSheetName
          sheetNames
          newSheetNames
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
        }
        updatedAt
        task {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        file {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        fileVersion {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listAsyncJobsByStatus = /* GraphQL */ `
  query ListAsyncJobsByStatus(
    $status: PublishJobStatus
    $sortDirection: ModelSortDirection
    $filter: ModelAsyncJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsyncJobsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retriedTimes
        retriedFromAsyncJobId
        retryReason
        fileType
        taskId
        fileId
        type
        fileVersionId
        organisation
        userId
        status
        createdAt
        progressPercent
        cancelRequested
        error
        startedAt
        finishedAt
        durationSeconds
        inputS3VersionId
        outputS3VersionId
        outputS3Key
        auditItemId
        consumerId
        logStreamName
        athenaExecutionId
        resultKey
        executionId
        monitorContext
        restUrl
        bucket
        region
        graphQLUrl
        operations {
          type
          sheetName
          copySheetName
          oldSheetName
          newSheetName
          sheetNames
          newSheetNames
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
        }
        updatedAt
        task {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        file {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        fileVersion {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPublishJobsByFile = /* GraphQL */ `
  query ListPublishJobsByFile(
    $fileVersionId: ID
    $statusCreatedAt: ModelAsyncJobByFileVersionCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAsyncJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishJobsByFile(
      fileVersionId: $fileVersionId
      statusCreatedAt: $statusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retriedTimes
        retriedFromAsyncJobId
        retryReason
        fileType
        taskId
        fileId
        type
        fileVersionId
        organisation
        userId
        status
        createdAt
        progressPercent
        cancelRequested
        error
        startedAt
        finishedAt
        durationSeconds
        inputS3VersionId
        outputS3VersionId
        outputS3Key
        auditItemId
        consumerId
        logStreamName
        athenaExecutionId
        resultKey
        executionId
        monitorContext
        restUrl
        bucket
        region
        graphQLUrl
        operations {
          type
          sheetName
          copySheetName
          oldSheetName
          newSheetName
          sheetNames
          newSheetNames
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
        }
        updatedAt
        task {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        file {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        fileVersion {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listAsyncJobsByFileId = /* GraphQL */ `
  query ListAsyncJobsByFileId(
    $fileId: ID
    $statusCreatedAt: ModelAsyncJobByFileCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAsyncJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsyncJobsByFileId(
      fileId: $fileId
      statusCreatedAt: $statusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retriedTimes
        retriedFromAsyncJobId
        retryReason
        fileType
        taskId
        fileId
        type
        fileVersionId
        organisation
        userId
        status
        createdAt
        progressPercent
        cancelRequested
        error
        startedAt
        finishedAt
        durationSeconds
        inputS3VersionId
        outputS3VersionId
        outputS3Key
        auditItemId
        consumerId
        logStreamName
        athenaExecutionId
        resultKey
        executionId
        monitorContext
        restUrl
        bucket
        region
        graphQLUrl
        operations {
          type
          sheetName
          copySheetName
          oldSheetName
          newSheetName
          sheetNames
          newSheetNames
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
        }
        updatedAt
        task {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        file {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        fileVersion {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPublishJobsByFileVersion = /* GraphQL */ `
  query ListPublishJobsByFileVersion(
    $fileVersionId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAsyncJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishJobsByFileVersion(
      fileVersionId: $fileVersionId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retriedTimes
        retriedFromAsyncJobId
        retryReason
        fileType
        taskId
        fileId
        type
        fileVersionId
        organisation
        userId
        status
        createdAt
        progressPercent
        cancelRequested
        error
        startedAt
        finishedAt
        durationSeconds
        inputS3VersionId
        outputS3VersionId
        outputS3Key
        auditItemId
        consumerId
        logStreamName
        athenaExecutionId
        resultKey
        executionId
        monitorContext
        restUrl
        bucket
        region
        graphQLUrl
        operations {
          type
          sheetName
          copySheetName
          oldSheetName
          newSheetName
          sheetNames
          newSheetNames
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
        }
        updatedAt
        task {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        file {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        fileVersion {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAuditItem = /* GraphQL */ `
  query GetAuditItem($id: ID!) {
    getAuditItem(id: $id) {
      id
      taskId
      projectId
      fileId
      userId
      clientId
      content
      page
      type
      organisation
      fileType
      fileName
      createdAt
      fileVersionId
      s3VersionId
      updatedAt
    }
  }
`;
export const listAuditItems = /* GraphQL */ `
  query ListAuditItems(
    $filter: ModelAuditItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        projectId
        fileId
        userId
        clientId
        content
        page
        type
        organisation
        fileType
        fileName
        createdAt
        fileVersionId
        s3VersionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAuditItemsByFile = /* GraphQL */ `
  query ListAuditItemsByFile(
    $fileId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditItemsByFile(
      fileId: $fileId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        projectId
        fileId
        userId
        clientId
        content
        page
        type
        organisation
        fileType
        fileName
        createdAt
        fileVersionId
        s3VersionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAuditItemsByTask = /* GraphQL */ `
  query ListAuditItemsByTask(
    $taskId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditItemsByTask(
      taskId: $taskId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        projectId
        fileId
        userId
        clientId
        content
        page
        type
        organisation
        fileType
        fileName
        createdAt
        fileVersionId
        s3VersionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAuditItemsByProject = /* GraphQL */ `
  query ListAuditItemsByProject(
    $projectId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditItemsByProject(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        projectId
        fileId
        userId
        clientId
        content
        page
        type
        organisation
        fileType
        fileName
        createdAt
        fileVersionId
        s3VersionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAuditItemsByClient = /* GraphQL */ `
  query ListAuditItemsByClient(
    $clientId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditItemsByClient(
      clientId: $clientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        projectId
        fileId
        userId
        clientId
        content
        page
        type
        organisation
        fileType
        fileName
        createdAt
        fileVersionId
        s3VersionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAuditItemsByUser = /* GraphQL */ `
  query ListAuditItemsByUser(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditItemsByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        projectId
        fileId
        userId
        clientId
        content
        page
        type
        organisation
        fileType
        fileName
        createdAt
        fileVersionId
        s3VersionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAuditItemsByOrganisation = /* GraphQL */ `
  query ListAuditItemsByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditItemsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        projectId
        fileId
        userId
        clientId
        content
        page
        type
        organisation
        fileType
        fileName
        createdAt
        fileVersionId
        s3VersionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnalytics = /* GraphQL */ `
  query GetAnalytics($id: ID!) {
    getAnalytics(id: $id) {
      id
      organisation
      parentId
      userId
      parentType
      eventType
      data
      createdAt
      updatedAt
    }
  }
`;
export const listAnalyticss = /* GraphQL */ `
  query ListAnalyticss(
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnalyticss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        parentId
        userId
        parentType
        eventType
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAnalyticsByParent = /* GraphQL */ `
  query ListAnalyticsByParent(
    $parentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnalyticsByParent(
      parentId: $parentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        parentId
        userId
        parentType
        eventType
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAnalyticsByOrganisation = /* GraphQL */ `
  query ListAnalyticsByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnalyticsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        parentId
        userId
        parentType
        eventType
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHistoricData = /* GraphQL */ `
  query GetHistoricData($id: ID!) {
    getHistoricData(id: $id) {
      id
      date
      tasksTotal
      tasksFinished
      tasksArchived
      tasksOpen
      projectsTotal
      projectsArchived
      usersTotal
      clientsTotal
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const listHistoricDatas = /* GraphQL */ `
  query ListHistoricDatas(
    $filter: ModelHistoricDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistoricDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        tasksTotal
        tasksFinished
        tasksArchived
        tasksOpen
        projectsTotal
        projectsArchived
        usersTotal
        clientsTotal
        organisation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listHistoricDataByOrganisation = /* GraphQL */ `
  query ListHistoricDataByOrganisation(
    $organisation: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHistoricDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistoricDataByOrganisation(
      organisation: $organisation
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        tasksTotal
        tasksFinished
        tasksArchived
        tasksOpen
        projectsTotal
        projectsArchived
        usersTotal
        clientsTotal
        organisation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!) {
    getHistory(id: $id) {
      id
      organisation
      parentId
      startDate
      endDate
      type
      parentType
      interval
      content
      createdAt
      updatedAt
    }
  }
`;
export const listHistorys = /* GraphQL */ `
  query ListHistorys(
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        parentId
        startDate
        endDate
        type
        parentType
        interval
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listHistoryDataByOrganisation = /* GraphQL */ `
  query ListHistoryDataByOrganisation(
    $organisation: ID
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistoryDataByOrganisation(
      organisation: $organisation
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        parentId
        startDate
        endDate
        type
        parentType
        interval
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listHistoryByParent = /* GraphQL */ `
  query ListHistoryByParent(
    $parentId: ID
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistoryByParent(
      parentId: $parentId
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        parentId
        startDate
        endDate
        type
        parentType
        interval
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTimesheetBlock = /* GraphQL */ `
  query GetTimesheetBlock($id: ID!) {
    getTimesheetBlock(id: $id) {
      id
      startAt
      endAt
      invoiceId
      taskId
      taskRevisionId
      projectId
      clientId
      description
      billable
      tags
      mileage
      userId
      organisation
      quoteLineItemId
      quoteId
      variation
      onSite
      invoicingStatus
      feeRole
      reviewStatus
      startLatLong
      endLatLong
      isRecording
      latLongStart
      latLongEnd
      notesStart
      notesEnd
      createdAt
      updatedAt
      expenses {
        items {
          id
          taskId
          projectId
          clientId
          amount
          description
          receiptPhotos
          timesheetBlockId
          invoiceId
          userId
          organisation
          incurredAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listTimesheetBlocks = /* GraphQL */ `
  query ListTimesheetBlocks(
    $filter: ModelTimesheetBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimesheetBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startAt
        endAt
        invoiceId
        taskId
        taskRevisionId
        projectId
        clientId
        description
        billable
        tags
        mileage
        userId
        organisation
        quoteLineItemId
        quoteId
        variation
        onSite
        invoicingStatus
        feeRole
        reviewStatus
        startLatLong
        endLatLong
        isRecording
        latLongStart
        latLongEnd
        notesStart
        notesEnd
        createdAt
        updatedAt
        expenses {
          items {
            id
            taskId
            projectId
            clientId
            amount
            description
            receiptPhotos
            timesheetBlockId
            invoiceId
            userId
            organisation
            incurredAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listTimesheetBlocksByOrganisation = /* GraphQL */ `
  query ListTimesheetBlocksByOrganisation(
    $organisation: ID
    $startAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimesheetBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimesheetBlocksByOrganisation(
      organisation: $organisation
      startAt: $startAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startAt
        endAt
        invoiceId
        taskId
        taskRevisionId
        projectId
        clientId
        description
        billable
        tags
        mileage
        userId
        organisation
        quoteLineItemId
        quoteId
        variation
        onSite
        invoicingStatus
        feeRole
        reviewStatus
        startLatLong
        endLatLong
        isRecording
        latLongStart
        latLongEnd
        notesStart
        notesEnd
        createdAt
        updatedAt
        expenses {
          items {
            id
            taskId
            projectId
            clientId
            amount
            description
            receiptPhotos
            timesheetBlockId
            invoiceId
            userId
            organisation
            incurredAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listTimesheetBlocksByTask = /* GraphQL */ `
  query ListTimesheetBlocksByTask(
    $taskId: ID
    $startAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimesheetBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimesheetBlocksByTask(
      taskId: $taskId
      startAt: $startAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startAt
        endAt
        invoiceId
        taskId
        taskRevisionId
        projectId
        clientId
        description
        billable
        tags
        mileage
        userId
        organisation
        quoteLineItemId
        quoteId
        variation
        onSite
        invoicingStatus
        feeRole
        reviewStatus
        startLatLong
        endLatLong
        isRecording
        latLongStart
        latLongEnd
        notesStart
        notesEnd
        createdAt
        updatedAt
        expenses {
          items {
            id
            taskId
            projectId
            clientId
            amount
            description
            receiptPhotos
            timesheetBlockId
            invoiceId
            userId
            organisation
            incurredAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listTimesheetBlocksByUser = /* GraphQL */ `
  query ListTimesheetBlocksByUser(
    $userId: ID
    $startAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimesheetBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimesheetBlocksByUser(
      userId: $userId
      startAt: $startAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startAt
        endAt
        invoiceId
        taskId
        taskRevisionId
        projectId
        clientId
        description
        billable
        tags
        mileage
        userId
        organisation
        quoteLineItemId
        quoteId
        variation
        onSite
        invoicingStatus
        feeRole
        reviewStatus
        startLatLong
        endLatLong
        isRecording
        latLongStart
        latLongEnd
        notesStart
        notesEnd
        createdAt
        updatedAt
        expenses {
          items {
            id
            taskId
            projectId
            clientId
            amount
            description
            receiptPhotos
            timesheetBlockId
            invoiceId
            userId
            organisation
            incurredAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listTimesheetBlocksByInvoice = /* GraphQL */ `
  query ListTimesheetBlocksByInvoice(
    $invoiceId: ID
    $startAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimesheetBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimesheetBlocksByInvoice(
      invoiceId: $invoiceId
      startAt: $startAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startAt
        endAt
        invoiceId
        taskId
        taskRevisionId
        projectId
        clientId
        description
        billable
        tags
        mileage
        userId
        organisation
        quoteLineItemId
        quoteId
        variation
        onSite
        invoicingStatus
        feeRole
        reviewStatus
        startLatLong
        endLatLong
        isRecording
        latLongStart
        latLongEnd
        notesStart
        notesEnd
        createdAt
        updatedAt
        expenses {
          items {
            id
            taskId
            projectId
            clientId
            amount
            description
            receiptPhotos
            timesheetBlockId
            invoiceId
            userId
            organisation
            incurredAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getExpense = /* GraphQL */ `
  query GetExpense($id: ID!) {
    getExpense(id: $id) {
      id
      taskId
      projectId
      clientId
      amount
      description
      receiptPhotos
      timesheetBlockId
      invoiceId
      userId
      organisation
      incurredAt
      createdAt
      updatedAt
    }
  }
`;
export const listExpenses = /* GraphQL */ `
  query ListExpenses(
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        projectId
        clientId
        amount
        description
        receiptPhotos
        timesheetBlockId
        invoiceId
        userId
        organisation
        incurredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listExpensesByOrganisation = /* GraphQL */ `
  query ListExpensesByOrganisation(
    $organisation: ID
    $incurredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpensesByOrganisation(
      organisation: $organisation
      incurredAt: $incurredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        projectId
        clientId
        amount
        description
        receiptPhotos
        timesheetBlockId
        invoiceId
        userId
        organisation
        incurredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listExpensesByUser = /* GraphQL */ `
  query ListExpensesByUser(
    $userId: ID
    $incurredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpensesByUser(
      userId: $userId
      incurredAt: $incurredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        projectId
        clientId
        amount
        description
        receiptPhotos
        timesheetBlockId
        invoiceId
        userId
        organisation
        incurredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listExpensesByTask = /* GraphQL */ `
  query ListExpensesByTask(
    $taskId: ID
    $incurredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpensesByTask(
      taskId: $taskId
      incurredAt: $incurredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        projectId
        clientId
        amount
        description
        receiptPhotos
        timesheetBlockId
        invoiceId
        userId
        organisation
        incurredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listExpensesByProject = /* GraphQL */ `
  query ListExpensesByProject(
    $projectId: ID
    $incurredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpensesByProject(
      projectId: $projectId
      incurredAt: $incurredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        projectId
        clientId
        amount
        description
        receiptPhotos
        timesheetBlockId
        invoiceId
        userId
        organisation
        incurredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listExpensesByClient = /* GraphQL */ `
  query ListExpensesByClient(
    $clientId: ID
    $incurredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpensesByClient(
      clientId: $clientId
      incurredAt: $incurredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        projectId
        clientId
        amount
        description
        receiptPhotos
        timesheetBlockId
        invoiceId
        userId
        organisation
        incurredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHoliday = /* GraphQL */ `
  query GetHoliday($id: ID!) {
    getHoliday(id: $id) {
      id
      organisation
      userId
      createdAt
      startsAt
      endsAt
      days {
        day
        startHours
        endHours
      }
      repeatPattern
      status
      approvedAt
      approvedBy
      rejectedAt
      rejectedBy
      isSick
      message
      updatedAt
    }
  }
`;
export const listHolidays = /* GraphQL */ `
  query ListHolidays(
    $filter: ModelHolidayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHolidays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        userId
        createdAt
        startsAt
        endsAt
        days {
          day
          startHours
          endHours
        }
        repeatPattern
        status
        approvedAt
        approvedBy
        rejectedAt
        rejectedBy
        isSick
        message
        updatedAt
      }
      nextToken
    }
  }
`;
export const listHolidaysByOrganisation = /* GraphQL */ `
  query ListHolidaysByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHolidayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHolidaysByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        userId
        createdAt
        startsAt
        endsAt
        days {
          day
          startHours
          endHours
        }
        repeatPattern
        status
        approvedAt
        approvedBy
        rejectedAt
        rejectedBy
        isSick
        message
        updatedAt
      }
      nextToken
    }
  }
`;
export const listHolidaysByUser = /* GraphQL */ `
  query ListHolidaysByUser(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHolidayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHolidaysByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        userId
        createdAt
        startsAt
        endsAt
        days {
          day
          startHours
          endHours
        }
        repeatPattern
        status
        approvedAt
        approvedBy
        rejectedAt
        rejectedBy
        isSick
        message
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      organisation
      isArchived
      dueDate
      assignedTo
      invoiceDate
      author
      clientId
      projectId
      fileKey
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      secondReviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      lastUpdateAuthorId
      accountancyInvoiceId
      amountPaid
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      expectPaymentAfterDays
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          invoiceId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          notesStart
          notesEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        isArchived
        dueDate
        assignedTo
        invoiceDate
        author
        clientId
        projectId
        fileKey
        reference
        title
        description
        currency
        taxInclusive
        taxRate
        subtotal
        totalTax
        total
        createdAt
        termsText
        termsAttachment
        status
        randomNumber
        itemSubscription
        collectionSubscription
        clientContact
        clientContacts
        clientAddress
        templateId
        templateVersionNumber
        savedAt
        metadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        poNumber
        checkedBy
        isUnderReview
        reviewApprovedAt
        secondReviewApprovedAt
        reviewStatus
        reviewSecondaryStatus
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        lastUpdateAuthorId
        accountancyInvoiceId
        amountPaid
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        customFields {
          id
          value
        }
        team
        expectPaymentAfterDays
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        activity {
          items {
            id
            invoiceId
            total
            type
            organisation
            createdAt
            author
            content
            updatedAt
          }
          nextToken
        }
        lineItems {
          items {
            id
            invoiceId
            organisation
            quoteLineItemId
            taskId
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            order
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listInvoicesByOrganisation = /* GraphQL */ `
  query ListInvoicesByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoicesByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        isArchived
        dueDate
        assignedTo
        invoiceDate
        author
        clientId
        projectId
        fileKey
        reference
        title
        description
        currency
        taxInclusive
        taxRate
        subtotal
        totalTax
        total
        createdAt
        termsText
        termsAttachment
        status
        randomNumber
        itemSubscription
        collectionSubscription
        clientContact
        clientContacts
        clientAddress
        templateId
        templateVersionNumber
        savedAt
        metadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        poNumber
        checkedBy
        isUnderReview
        reviewApprovedAt
        secondReviewApprovedAt
        reviewStatus
        reviewSecondaryStatus
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        lastUpdateAuthorId
        accountancyInvoiceId
        amountPaid
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        customFields {
          id
          value
        }
        team
        expectPaymentAfterDays
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        activity {
          items {
            id
            invoiceId
            total
            type
            organisation
            createdAt
            author
            content
            updatedAt
          }
          nextToken
        }
        lineItems {
          items {
            id
            invoiceId
            organisation
            quoteLineItemId
            taskId
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            order
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listInvoicesByAccountancyInvoiceId = /* GraphQL */ `
  query ListInvoicesByAccountancyInvoiceId(
    $accountancyInvoiceId: String
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoicesByAccountancyInvoiceId(
      accountancyInvoiceId: $accountancyInvoiceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        isArchived
        dueDate
        assignedTo
        invoiceDate
        author
        clientId
        projectId
        fileKey
        reference
        title
        description
        currency
        taxInclusive
        taxRate
        subtotal
        totalTax
        total
        createdAt
        termsText
        termsAttachment
        status
        randomNumber
        itemSubscription
        collectionSubscription
        clientContact
        clientContacts
        clientAddress
        templateId
        templateVersionNumber
        savedAt
        metadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        poNumber
        checkedBy
        isUnderReview
        reviewApprovedAt
        secondReviewApprovedAt
        reviewStatus
        reviewSecondaryStatus
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        lastUpdateAuthorId
        accountancyInvoiceId
        amountPaid
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        customFields {
          id
          value
        }
        team
        expectPaymentAfterDays
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        activity {
          items {
            id
            invoiceId
            total
            type
            organisation
            createdAt
            author
            content
            updatedAt
          }
          nextToken
        }
        lineItems {
          items {
            id
            invoiceId
            organisation
            quoteLineItemId
            taskId
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            order
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPublicArticle = /* GraphQL */ `
  query GetPublicArticle($id: ID!) {
    getPublicArticle(id: $id) {
      id
      organisation
      createdAt
      createdBy
      slug
      title
      content
      tags
      parentId
      updatedAt
    }
  }
`;
export const listPublicArticles = /* GraphQL */ `
  query ListPublicArticles(
    $filter: ModelPublicArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        createdAt
        createdBy
        slug
        title
        content
        tags
        parentId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      organisation
      itemSubscription
      assignedTo
      createdAt
      createdBy
      status
      requestedAt
      requestedForDate
      requestedBy
      title
      priorityId
      clientId
      projectId
      latestFormName
      resultingTaskIds
      resultingQuoteIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        itemSubscription
        assignedTo
        createdAt
        createdBy
        status
        requestedAt
        requestedForDate
        requestedBy
        title
        priorityId
        clientId
        projectId
        latestFormName
        resultingTaskIds
        resultingQuoteIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRequestsByOrganisation = /* GraphQL */ `
  query ListRequestsByOrganisation(
    $organisation: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        itemSubscription
        assignedTo
        createdAt
        createdBy
        status
        requestedAt
        requestedForDate
        requestedBy
        title
        priorityId
        clientId
        projectId
        latestFormName
        resultingTaskIds
        resultingQuoteIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      organisation
      createdAt
      createdBy
      s3CreatedAt
      s3UpdatedAt
      nameWithoutExtension
      extension
      name
      path
      size
      type
      sharedWith {
        id
        targetType
        targetId
        access
      }
      updatedAt
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        createdAt
        createdBy
        s3CreatedAt
        s3UpdatedAt
        nameWithoutExtension
        extension
        name
        path
        size
        type
        sharedWith {
          id
          targetType
          targetId
          access
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAttachmentsByOrganisation = /* GraphQL */ `
  query ListAttachmentsByOrganisation(
    $organisation: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachmentsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        createdAt
        createdBy
        s3CreatedAt
        s3UpdatedAt
        nameWithoutExtension
        extension
        name
        path
        size
        type
        sharedWith {
          id
          targetType
          targetId
          access
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAttachmentsByPath = /* GraphQL */ `
  query ListAttachmentsByPath(
    $organisation: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachmentsByPath(
      organisation: $organisation
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        createdAt
        createdBy
        s3CreatedAt
        s3UpdatedAt
        nameWithoutExtension
        extension
        name
        path
        size
        type
        sharedWith {
          id
          targetType
          targetId
          access
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAttachmentsByName = /* GraphQL */ `
  query ListAttachmentsByName(
    $organisation: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachmentsByName(
      organisation: $organisation
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        createdAt
        createdBy
        s3CreatedAt
        s3UpdatedAt
        nameWithoutExtension
        extension
        name
        path
        size
        type
        sharedWith {
          id
          targetType
          targetId
          access
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInterval = /* GraphQL */ `
  query GetInterval($id: ID!) {
    getInterval(id: $id) {
      id
      organisation
      createdAt
      startDate
      endDate
      name
      content
      type
      updatedAt
    }
  }
`;
export const listIntervals = /* GraphQL */ `
  query ListIntervals(
    $filter: ModelIntervalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntervals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        createdAt
        startDate
        endDate
        name
        content
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const listIntervalsByOrganisation = /* GraphQL */ `
  query ListIntervalsByOrganisation(
    $organisation: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntervalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntervalsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        createdAt
        startDate
        endDate
        name
        content
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStockItem = /* GraphQL */ `
  query GetStockItem($id: ID!) {
    getStockItem(id: $id) {
      id
      organisation
      createdAt
      name
      description
      type
      customFields {
        id
        value
      }
      displayOnTimeline
      maintenanceIntervals {
        id
        content
        startDate
        endDate
        startTime
        endTime
        status
        completedAt
        notes
        reminders {
          id
          offsetType
          offsetAmount
        }
      }
      order
      updatedAt
    }
  }
`;
export const listStockItems = /* GraphQL */ `
  query ListStockItems(
    $filter: ModelStockItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStockItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        createdAt
        name
        description
        type
        customFields {
          id
          value
        }
        displayOnTimeline
        maintenanceIntervals {
          id
          content
          startDate
          endDate
          startTime
          endTime
          status
          completedAt
          notes
          reminders {
            id
            offsetType
            offsetAmount
          }
        }
        order
        updatedAt
      }
      nextToken
    }
  }
`;
export const listStockItemsByOrganisation = /* GraphQL */ `
  query ListStockItemsByOrganisation(
    $organisation: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStockItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStockItemsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisation
        createdAt
        name
        description
        type
        customFields {
          id
          value
        }
        displayOnTimeline
        maintenanceIntervals {
          id
          content
          startDate
          endDate
          startTime
          endTime
          status
          completedAt
          notes
          reminders {
            id
            offsetType
            offsetAmount
          }
        }
        order
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWorkingHoursRule = /* GraphQL */ `
  query GetWorkingHoursRule($id: ID!) {
    getWorkingHoursRule(id: $id) {
      id
      parentId
      organisation
      startTime
      endTime
      dayOfWeek
      startDate
      endDate
      breaks {
        id
        startTime
        endTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const listWorkingHoursRules = /* GraphQL */ `
  query ListWorkingHoursRules(
    $filter: ModelWorkingHoursRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkingHoursRules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        organisation
        startTime
        endTime
        dayOfWeek
        startDate
        endDate
        breaks {
          id
          startTime
          endTime
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listWorkingHoursRulesByOrganisation = /* GraphQL */ `
  query ListWorkingHoursRulesByOrganisation(
    $organisation: ID
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkingHoursRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkingHoursRulesByOrganisation(
      organisation: $organisation
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        organisation
        startTime
        endTime
        dayOfWeek
        startDate
        endDate
        breaks {
          id
          startTime
          endTime
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listWorkingHoursRulesByParent = /* GraphQL */ `
  query ListWorkingHoursRulesByParent(
    $parentId: ID
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkingHoursRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkingHoursRulesByParent(
      parentId: $parentId
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        organisation
        startTime
        endTime
        dayOfWeek
        startDate
        endDate
        breaks {
          id
          startTime
          endTime
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSeparateContact = /* GraphQL */ `
  query GetSeparateContact($id: ID!) {
    getSeparateContact(id: $id) {
      id
      parentId
      organisation
      firstName
      lastName
      email
      phone1
      phone2
      label
      notes
      createdAt
      updatedAt
    }
  }
`;
export const listSeparateContacts = /* GraphQL */ `
  query ListSeparateContacts(
    $filter: ModelSeparateContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeparateContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        organisation
        firstName
        lastName
        email
        phone1
        phone2
        label
        notes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listContactsByOrganisation = /* GraphQL */ `
  query ListContactsByOrganisation(
    $organisation: ID
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSeparateContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByOrganisation(
      organisation: $organisation
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        organisation
        firstName
        lastName
        email
        phone1
        phone2
        label
        notes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listContactsByParent = /* GraphQL */ `
  query ListContactsByParent(
    $parentId: ID
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSeparateContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByParent(
      parentId: $parentId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        organisation
        firstName
        lastName
        email
        phone1
        phone2
        label
        notes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
