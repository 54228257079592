import React from "react";
import cx from "classnames";

import FormInputLabel from "Form/FormInputLabel/FormInputLabel";

function FormSeparator({ fieldData, fieldName }) {
  return (
    <>
      <div className={cx("form-separator")} />
      {fieldData.hasTooltipDescription || fieldData.hasInlineDescription ? (
        <FormInputLabel fieldData={fieldData} fieldName={fieldName} onlyDisplayExplanation />
      ) : null}
    </>
  );
}

export default React.memo(FormSeparator, (prevProps, nextProps) => {
  return true;
});
