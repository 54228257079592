import React, { useEffect, useRef } from "react";
import cx from "classnames";
import { UpOutlined, DownOutlined, DeleteOutlined, RightOutlined } from "@ant-design/icons";
import { message, Modal, Button, Tooltip, Typography } from "antd";
import { useGetSetState } from "react-use";

import displayFields from "Form/displayFields";

import "./FormSection.scss";

function FormSection({
  fieldData,
  fieldName,
  topLevelParams,
  forParentDynamicSectionListId,
  parentDynamicSectionListField,
  itemIndex,
  itemCountInDynamicSectionList,
  childSectionsInParent,
}) {
  const { isFormEditor, form, updateFormContent, validationErrors = [] } = topLevelParams;
  const scrollHandlerRef = useRef(null);

  let isAlwaysExpanded =
    !isFormEditor && (fieldData.isAlwaysExpanded || parentDynamicSectionListField?.sectionItemsAreAlwaysExpanded);

  const fieldNamesWithValidationErrors = validationErrors.map((x) => x.fieldKey);

  const [getState, setState] = useGetSetState({
    isExpanded: !!isAlwaysExpanded,
    anyFieldsInThisSectionAreInvalid: false,
  });

  // console.log("hiddenFormFields = ", topLevelParams.hiddenFormFields);

  useEffect(() => {
    const currentState = getState();
    let fieldsNamesInThisSection = [];
    for (let currentFieldName in form.fields) {
      let currentFieldDetails = form.fields[currentFieldName];
      if (currentFieldDetails.parentSection === fieldName) {
        fieldsNamesInThisSection.push(currentFieldName);
      }
    }

    let anyFieldsInThisSectionAreInvalid = false;
    for (let currentFieldName of fieldsNamesInThisSection) {
      if (fieldNamesWithValidationErrors.includes(currentFieldName)) {
        anyFieldsInThisSectionAreInvalid = true;
        break;
      }
    }

    let newState = {
      anyFieldsInThisSectionAreInvalid,
    };

    if (anyFieldsInThisSectionAreInvalid) {
      newState.isExpanded = true;
    } else {
      newState.isExpanded = currentState.isExpanded;
    }

    if (
      currentState.isExpanded === newState.isExpanded &&
      currentState.anyFieldsInThisSectionAreInvalid === newState.anyFieldsInThisSectionAreInvalid
    ) {
      return;
    }

    // setTimeout(() => {
    setState(newState);
    // }, 0);
  }, [validationErrors]);

  useEffect(() => {
    let isExpanded = getState().isExpanded;
    if (!isExpanded) {
      return;
    }
    if (!scrollHandlerRef.current) {
      return;
    }
    requestAnimationFrame(() => {
      scrollHandlerRef.current.scrollIntoView();
    });
  }, [getState().isExpanded]);

  const { isExpanded, anyFieldsInThisSectionAreInvalid } = getState();

  if (fieldData.isPlaceholderForDynamicSectionList) {
    // we don't display this field, it's just meant to be kept in the data structure, so we can reference it
    return null;
  }

  if (fieldData.parentDynamicSectionList && !forParentDynamicSectionListId) {
    return;
  }

  let fieldLabel = fieldData.label;
  if (fieldData.isPlaceholderForDynamicSectionList) {
    let dynamicSectionListField = form.fields[fieldData.isPlaceholderForDynamicSectionList];
    if (dynamicSectionListField) {
      fieldLabel = `Fields for "${dynamicSectionListField.label}"`;
    }
  }

  let content = null;
  if (isExpanded) {
    content = displayFields({
      topLevelParams,
      sectionNameToDisplayFieldsFor: fieldName,
    });
  }

  return (
    <div
      className={cx("form-section", {
        open: isExpanded,
        "has-invalid-fields": anyFieldsInThisSectionAreInvalid,
        "placeholder-section": fieldData.isPlaceholderForDynamicSectionList,
        "compact-fields": fieldData.compactFields,
        "always-expanded": isAlwaysExpanded,
      })}
    >
      {parentDynamicSectionListField && (
        <div className="section-item-buttons">
          {itemIndex > 0 && (
            <Button
              icon={<UpOutlined />}
              onClick={() => {
                let temp = childSectionsInParent[itemIndex];
                childSectionsInParent[itemIndex] = childSectionsInParent[itemIndex - 1];
                childSectionsInParent[itemIndex - 1] = temp;

                for (let i = 0; i < childSectionsInParent.length; i++) {
                  childSectionsInParent[i].adminOrder = i / 100;
                }

                message.success("Section moved up");

                updateFormContent({
                  ...form,
                  fields: form.fields,
                });
              }}
            />
          )}
          {itemIndex < itemCountInDynamicSectionList - 1 && (
            <Button
              icon={<DownOutlined />}
              onClick={() => {
                let temp = childSectionsInParent[itemIndex];
                childSectionsInParent[itemIndex] = childSectionsInParent[itemIndex + 1];
                childSectionsInParent[itemIndex + 1] = temp;

                for (let i = 0; i < childSectionsInParent.length; i++) {
                  childSectionsInParent[i].adminOrder = i / 100;
                }

                message.success("Section moved down");

                updateFormContent({
                  ...form,
                  fields: form.fields,
                });
              }}
            />
          )}
          <Tooltip title="Delete section">
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: "Delete section",
                  content: "Are you sure you want to delete this section?",
                  onOk: () => {
                    let newFields = JSON.parse(JSON.stringify(form.fields));
                    for (let crtFieldName in newFields) {
                      let crtField = newFields[crtFieldName];
                      if (crtField.parentSection === fieldName) {
                        delete newFields[crtFieldName];
                      }
                    }
                    delete newFields[fieldName];

                    updateFormContent({
                      ...form,
                      fields: newFields,
                    });
                  },
                });
              }}
            />
          </Tooltip>
        </div>
      )}
      <div
        className={cx("form-section-heading", "clickable")}
        data-name={fieldName}
        onClick={() => {
          setState({ isExpanded: !isExpanded });
        }}
      >
        <div className="section-heading-scroll-handle" ref={scrollHandlerRef} />
        <RightOutlined className={cx("open-close-section-icon", { rotated: isExpanded })} />
        <Typography.Title level={2}>
          {fieldLabel}
          {itemIndex || itemIndex === 0 ? `: ${itemIndex + 1}` : ""}
        </Typography.Title>
      </div>
      <div className="form-section-content">{content}</div>
    </div>
  );
}

export default React.memo(FormSection, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["readOnly", "refreshKey", "validationErrors", "hiddenFormFieldKeys"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps.topLevelParams[propName] !== nextProps.topLevelParams[propName]) {
      return false;
    }
  }

  return true;
});
