import React, { RefObject } from "react";
import { Tag, message, Select, Modal, Tooltip, Button, Typography, Dropdown, Menu, notification } from "antd";
import { Link } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Storage } from "aws-amplify";
import {
  EyeOutlined,
  FilePdfOutlined,
  CaretDownOutlined,
  LoadingOutlined,
  DeleteOutlined,
  EditOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import cx from "classnames";

import { HAS_SHEETS, FILE_TYPES_DETAILS } from "common/shared";
import { DEFAULT_FILE_NAMES } from "common/constants";
import { OpenIcon } from "common/icons";
import { callRest, callGraphQLSimple } from "common/apiHelpers";
import { submitReview } from "ReviewPage/reviewHelpers";
import {
  getSortedFiles,
  getCat2Check,
  downloadPDF,
  downloadSheetPDF,
  openFileWithLink,
  processIdForDisplay,
} from "common/helpers";
import { updateDeletedFiles } from "common/taskHelpers";
import { downloadReport, buildReport } from "ReportPage/Report/reportHelpers";
import { isAuthorised } from "common/permissions";
import { getSimpleLabel } from "common/labels";
import { recalculateTaskEstimatedHours } from "common/taskHelpers";

import DocumentDetailsModal from "Modals/DocumentDetailsModal/DocumentDetailsModal";
import AddFileToTaskRevisionModal from "Modals/AddFileToTaskRevisionModal/AddFileToTaskRevisionModal";
import RenameFileModal from "Modals/RenameFileModal/RenameFileModal";
import TaskRevisionDueDate from "./TaskRevisionDueDate/TaskRevisionDueDate";
import TaskRevisionRequestedDate from "./TaskRevisionRequestedDate/TaskRevisionRequestedDate";
import TaskRevisionPriority from "./TaskRevisionPriority/TaskRevisionPriority";
import TaskRevisionEstimate from "./TaskRevisionEstimate/TaskRevisionEstimate";
import TaskRevisionFinishedAt from "./TaskRevisionFinishedAt/TaskRevisionFinishedAt";
import InfoItem from "InfoItem/InfoItem";
import Input from "Input/Input";
import ControlTaskRevisionAccessModal from "Modals/ControlTaskRevisionAccessModal/ControlTaskRevisionAccessModal";

import "./TaskRevisionItem.scss";

type Props = {
  apiUser: any;
  task: any;
  revision: any;
  history: any;
  linkedTasksDetails: any;
  organisationDetails: any;
  windowWidth: number;
  index: number;
  visualIndex: number;
  users: any[];
  groups: any[];
  requestFormActivityItem: any;
  isHighlighted?: boolean;
};
export class TaskRevisionItem extends React.Component<Props & RouteComponentProps> {
  private containerRef: RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
  }

  state = {
    isCreatingFile: false,
    isAddFileToTaskRevisionModalVisible: false,
    isDownloadWaitingModalVisible: false,
    isRenameModalVisible: false,
    fileTypeToAdd: null,
    selectedFile: null,
    pdfPreviewData: null,
    isPdfPreviewVisible: false,
    isControlTaskRevisionAccessModalVisible: null,
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.containerRef.current && this.props.isHighlighted) {
        this.containerRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);
  }

  onApproveClick = async () => {
    Modal.confirm({
      title: `Approve ${getSimpleLabel("task revision")} "${this.props.revision.name}"`,
      content: `Are you sure you want to approve this ${getSimpleLabel("task revision")}?`,
      onOk: this.approveRevision,
    });
  };

  approveRevision = async () => {
    let { apiUser, task, users, revision } = this.props;
    const review = (
      await callGraphQLSimple({
        message: "Failed to fetch review",
        queryName: "getReview",
        variables: {
          id: revision.reviewId,
        },
      })
    ).data.getReview;

    await submitReview("SUCCESS", {
      apiUser,
      task,
      users,
      taskRevision: revision,
      review,
    });
  };

  displayAddFileMenu = () => {
    const { organisationDetails } = this.props;
    const menuItems = Object.keys(FILE_TYPES_DETAILS)
      .filter((fileTypeUpperCase) => {
        if (!(organisationDetails.fileTypesUsed || []).includes(fileTypeUpperCase)) {
          return false;
        }
        let fileTypeDetails = FILE_TYPES_DETAILS[fileTypeUpperCase];
        if (!fileTypeDetails.isPartOfATask) {
          return false;
        }

        return true;
      })
      .map((fileTypeUpperCase) => (
        <Menu.Item
          data-cy="add-file-menu-item"
          key={fileTypeUpperCase}
          onClick={() => {
            this.setState({
              fileTypeToAdd: fileTypeUpperCase,
              isAddFileToTaskRevisionModalVisible: true,
            });
          }}
        >
          {FILE_TYPES_DETAILS[fileTypeUpperCase]?.label}
        </Menu.Item>
      ));
    return <Menu>{menuItems}</Menu>;
  };

  onDeleteClick = async () => {
    if (isAuthorised(["FULL.READ_WRITE", "TASK_DETAILS.DELETE_TASK_REVISION"])) {
      this.confirmDeleteTaskRevision();
    } else {
      Modal.error({
        title: "Not authorised",
        content: `Not authorised to delete ${getSimpleLabel("task revision")}.`,
      });
    }
  };

  confirmDeleteTaskRevision = async () => {
    const { organisationDetails, revision, apiUser } = this.props;
    try {
      await new Promise<void>((resolve, reject) => {
        Modal.confirm({
          title: `Confirm delete ${getSimpleLabel("task revision")}`,
          maskClosable: true,
          content: (
            <>
              Are you sure you want to delete <b>{this.props.revision.name}</b>?
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    await callGraphQLSimple({
      message: `Could not delete ${getSimpleLabel("task revision")}`,
      queryName: "deleteTaskRevision",
      variables: {
        input: {
          id: this.props.revision.id,
        },
      },
    });

    await callGraphQLSimple({
      mutation: "createTaskActivityItem",
      message: `Failed to record ${getSimpleLabel("task")} activity item`,
      variables: {
        input: {
          taskId: revision.taskId,
          author: apiUser.id,
          organisation: organisationDetails.id,
          type: "REVISION_DELETED",
          content: JSON.stringify({
            revisionName: revision.name,
          }),
        },
      },
    });

    const updatedTask = (
      await callGraphQLSimple({
        message: `Could not fetch ${getSimpleLabel("task")}`,
        queryName: "getTaskSimple",
        variables: {
          id: this.props.revision.taskId,
        },
      })
    ).data.getTask;

    const newLatestTaskRevision = updatedTask.revisions.items.slice(-1)[0];
    if (newLatestTaskRevision && !newLatestTaskRevision.reviewAcceptDate) {
      await callGraphQLSimple({
        message: `Could not update previous ${getSimpleLabel("task revision")}`,
        queryName: "updateTaskRevision",
        variables: {
          input: {
            id: newLatestTaskRevision.id,
            isReadOnly: false,
          },
        },
      });
    }

    await callGraphQLSimple({
      message: `Could not update ${getSimpleLabel("task")}`,
      queryName: "updateTask",
      variables: {
        input: {
          id: this.props.revision.taskId,
          randomNumber: Math.floor(Math.random() * 100000),
          isUnderReview: false,
          dueDate:
            organisationDetails.settings?.task?.useDueDatesOnTaskRevisions && newLatestTaskRevision
              ? newLatestTaskRevision.dueDate
              : undefined,
        },
      },
    });

    if (organisationDetails.settings?.task?.useTaskRevisionEstimates) {
      await recalculateTaskEstimatedHours(updatedTask.id);
    }
  };

  downloadLatestPDF = async (fileInTask, usePreview) => {
    const { apiUser, task, users, organisationDetails } = this.props;

    const file = (
      await callGraphQLSimple({
        message: "Could not retrieve file details",
        queryName: "getFile",
        variables: {
          id: fileInTask.id,
        },
      })
    ).data.getFile;
    const latestFileVersion = file.versions.items.slice(-1)[0];

    let hasPDF = true;
    if (!latestFileVersion) {
      hasPDF = false;
    } else if (file.type !== "REPORT" && !latestFileVersion.savedAt && !latestFileVersion.publishEndAt) {
      hasPDF = false;
    }

    if (!hasPDF) {
      notification.info({
        message: (
          <Typography.Text>{FILE_TYPES_DETAILS[file.type]?.label} file does not yet have a PDF export</Typography.Text>
        ),
      });
      return;
    }

    callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: this.props.task.id,
          projectId: this.props.task.projectId,
          fileId: file.id,
          clientId: this.props.task.clientId,
          page: "TASK_DETAILS_PAGE",
          type: "DOWNLOAD_LATEST_PDF_EXPORT",
          userId: apiUser.id,
          organisation: apiUser.organisation,
          content: `Via dropdown on the file item from the ${getSimpleLabel("task revision")}`,
        },
      },
    });

    if (file.type === "REPORT") {
      this.setState({ isDownloadWaitingModalVisible: true });
      if (usePreview) {
        this.setState({ isPdfPreviewVisible: true, selectedFile: file });
      }

      try {
        let templateDetails = organisationDetails.templates.items.find((template) => template.id === file.templateId);
        if (templateDetails.key) {
          if (usePreview) {
            await buildReport({ fileId: file.id, taskId: task.id, requestId: undefined, users, organisationDetails });
            this.downloadAndSetPreview(latestFileVersion.exports[0].key);
          } else {
            await downloadReport({
              fileId: file.id,
              taskId: task.id,
              requestId: undefined,
              users,
              organisationDetails,
              fileName: undefined,
            });
          }
        } else {
          await callRest({
            route: "/annotate",
            method: "POST",
            body: {
              eventId: task.id,
              fileId: file.id,
              taskId: task.id,
              taskRevisionId: file.taskRevisionId,
              organisation: file.organisation,
              fileType: file.type,
            },
            includeCredentials: false,
          });
          if (usePreview) {
            this.downloadAndSetPreview(latestFileVersion.exports[0].key);
          } else {
            await downloadPDF({
              fileKey: latestFileVersion.exports[0].key,
              file,
              task,
            });
          }
        }
      } catch (e) {
        notification.error({
          message: (
            <Typography.Text>
              Failed to publish report.
              <br />
              <b>Reason:</b> {(e as any).response?.data?.error || (e as any).data?.error || (e as any).message}
            </Typography.Text>
          ),
          duration: 0,
        });
      }
      this.setState({ isDownloadWaitingModalVisible: false });
    } else {
      if (usePreview) {
        this.setState({ isPdfPreviewVisible: true, selectedFile: file });

        this.downloadAndSetPreview(latestFileVersion.exports[0].key);
      } else {
        if (!HAS_SHEETS[file.type]) {
          downloadSheetPDF({
            sheetRevision: file.sheets.items[0].revisions.items.slice(-1)[0],
            file,
            task,
          });
        } else {
          downloadPDF({
            fileKey: file.versions.items[file.versions.items.length - 1].exports[0].key,
            file,
            task,
          });
        }
      }
    }
  };

  downloadAndSetPreview = async (fileKey) => {
    const pdfResponse = await Storage.get(fileKey.replace("public/", ""), {
      download: true,
    });
    const pdfData = await new Response(pdfResponse.Body as any).arrayBuffer();
    this.setState({ pdfPreviewData: pdfData });
  };

  deleteFile = async (file) => {
    const { task, revision, organisationDetails } = this.props;

    try {
      await new Promise<void>((resolve, reject) => {
        Modal.confirm({
          title: "Confirm delete file",
          maskClosable: true,
          content: (
            <>
              Are you sure you want to delete this <b>{FILE_TYPES_DETAILS[file.type]?.label}</b> file?
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    await callGraphQLSimple({
      message: "Could not delete file",
      queryName: "deleteFile",
      variables: {
        input: {
          id: file.id,
        },
      },
    });

    await callGraphQLSimple({
      mutation: "createTaskActivityItem",
      message: "Failed to record task activity item",
      variables: {
        input: {
          taskId: task.id,
          author: window.apiUser.id,
          organisation: organisationDetails.id,
          type: "LIFECYCLE_EVENT",
          content: JSON.stringify({
            fileId: file.id,
            fileType: file.type,
            fileName: file.name,
            type: "FILE_DELETED",
          }),
        },
      },
    });

    await updateDeletedFiles(revision, file.type);

    await callGraphQLSimple({
      message: `Could not update ${getSimpleLabel("task")}`,
      queryName: "updateTask",
      variables: {
        input: {
          id: task.id,
          randomNumber: Math.floor(Math.random() * 100000),
        },
      },
    });
  };

  displayDownloadWaitingModal = () => {
    const { isDownloadWaitingModalVisible } = this.state;

    if (isDownloadWaitingModalVisible) {
      return (
        <Modal
          maskClosable={false}
          title={
            <>
              <LoadingOutlined /> Assembling the report PDF...
            </>
          }
          open={true}
          footer={null}
          closable={false}
          className="preparing-pdf-download-modal"
        >
          <Typography.Paragraph>
            You will receive a PDF file when the report has finished processing.
          </Typography.Paragraph>
        </Modal>
      );
    }
  };

  displayFiles = () => {
    const { apiUser, task, revision, history, linkedTasksDetails } = this.props;
    const sortedFiles = getSortedFiles({ taskRevision: revision });

    return sortedFiles.map((file, i) => {
      let errorMessageOnOpen;
      let errorMessageOnDownload;

      // if the current user is the assignee of the Cat 2 check, they cannot see the calculations or report
      const cat2CheckTask = getCat2Check({
        task,
        linkedTasksDetails,
      });
      if (cat2CheckTask) {
        if (!cat2CheckTask.isFinished && cat2CheckTask.assignedTo === apiUser.id) {
          if (file.type === "MATHCAD") {
            errorMessageOnOpen = `As a Cat 2 checker, you cannot download the calculations file while the check is in progress.`;
            errorMessageOnDownload = errorMessageOnDownload;
          } else if (file.type === "REPORT") {
            errorMessageOnDownload = `As a Cat 2 checker, you can only download the report from the report page.`;
          }
        }
      }
      return (
        <Dropdown.Button
          key={i}
          onClick={() => {
            if (errorMessageOnOpen) {
              Modal.error({
                title: "Access denied",
                maskClosable: true,
                content: errorMessageOnOpen,
              });
            } else {
              history.push(`/tasks/${task.id}/${file.type}/${file.id}`);
            }
          }}
          overlay={
            <Menu>
              <Menu.Item
                key="open-in"
                onClick={async () => {
                  if (errorMessageOnOpen) {
                    Modal.error({
                      title: "Access denied",
                      maskClosable: true,
                      content: errorMessageOnOpen,
                    });
                  } else {
                    if (file.type === "REPORT") {
                      history.push(`/tasks/${task.id}/${file.type}/${file.id}`);
                    } else {
                      const fileVersion = file.versions.items.slice(-1)[0];
                      await openFileWithLink({
                        revisionData: revision,
                        task,
                        file,
                        history,
                        fileVersion,
                        page: "TASK_DETAILS",
                      });
                    }
                  }
                }}
                icon={<OpenIcon />}
              >
                {file.type === "REPORT" ? "Open" : `Open in ${FILE_TYPES_DETAILS[file.type]?.label}`}
              </Menu.Item>

              <Menu.Divider />
              <Menu.Item
                key="preview"
                onClick={() => {
                  if (errorMessageOnOpen) {
                    Modal.error({
                      title: "Access denied",
                      maskClosable: true,
                      content: errorMessageOnOpen,
                    });
                  } else {
                    this.downloadLatestPDF(file, true);
                  }
                }}
                icon={<EyeOutlined />}
              >
                Preview latest PDF
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="download"
                onClick={() => {
                  if (errorMessageOnDownload) {
                    Modal.error({
                      title: "Access denied",
                      maskClosable: true,
                      content: errorMessageOnDownload,
                    });
                  } else {
                    this.downloadLatestPDF(file, false);
                  }
                }}
                icon={<FilePdfOutlined />}
              >
                Download latest PDF
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="rename"
                onClick={() =>
                  this.setState({
                    selectedFile: file,
                    isRenameModalVisible: true,
                  })
                }
                icon={<EditOutlined />}
                disabled={revision.isReadOnly}
              >
                Rename file
              </Menu.Item>

              <Menu.Divider />
              <Menu.Item
                key="delete"
                onClick={() => this.deleteFile(file)}
                icon={<DeleteOutlined />}
                disabled={revision.isReadOnly}
              >
                Delete file
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomLeft"
        >
          {FILE_TYPES_DETAILS[file.type]?.label} - {file.name || DEFAULT_FILE_NAMES[file.type]}
        </Dropdown.Button>
      );
    });
  };

  onControlTaskRevisionAccessSubmit = async ({ writeAccessGroups }) => {
    const { revision } = this.props;
    await callGraphQLSimple({
      message: `Failed to update ${getSimpleLabel("task revision")} access`,
      queryName: "updateTaskRevision",
      variables: {
        input: {
          id: revision.id,
          writeAccessGroups,
        },
      },
    });

    await callGraphQLSimple({
      message: `Could not update ${getSimpleLabel("task")}`,
      queryName: "updateTask",
      variables: {
        input: {
          id: this.props.revision.taskId,
          randomNumber: Math.floor(Math.random() * 100000),
        },
      },
    });

    this.setState({ isControlTaskRevisionAccessModalVisible: false });
  };

  updateRevisionStatus = async (status) => {
    const { revision, task } = this.props;
    const oldStatus = revision.status;

    let messageKey = "updating-task-revision-status";

    message.loading({ content: "Updating the status...", key: messageKey, duration: 0 });

    let updatePromises: Promise<any>[] = [];
    for (let file of revision.files.items) {
      for (let sheet of file.sheets.items) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        let latestSheetRevision = sheet.revisions.items.slice(-1)[0];
        updatePromises.push(
          callGraphQLSimple({
            message: `Failed to update status`,
            mutation: "updateSheetRevision",
            variables: {
              input: {
                id: latestSheetRevision.id,
                status,
              },
            },
          })
        );
      }
    }
    updatePromises.push(
      callGraphQLSimple({
        message: "Failed to update status",
        queryCustom: "updateTaskRevision",
        variables: {
          input: {
            id: revision.id,
            status,
          },
        },
      })
    );

    try {
      await Promise.all(updatePromises);
      await callGraphQLSimple({
        mutation: "createTaskActivityItem",
        message: `Failed to record ${getSimpleLabel("task")} activity item`,
        variables: {
          input: {
            taskId: task.id,
            author: window.apiUser.id,
            organisation: task.organisation,
            type: "LIFECYCLE_EVENT",
            content: JSON.stringify({
              taskRevisionId: revision.id,
              taskRevisionName: revision.name,
              oldStatus: oldStatus,
              newStatus: status,
              type: "TASK_REVISION_STATUS_CHANGED",
            }),
          },
        },
      });
      message.success({ content: "Status updated", key: messageKey, duration: 2 });
    } catch (e) {
      message.error({ content: "Update failed", key: messageKey, duration: 5 });
      for (let file of revision.files.items) {
        for (let sheet of file.sheets.items) {
          await new Promise((resolve) => setTimeout(resolve, 100));
          let latestSheetRevision = sheet.revisions.items.slice(-1)[0];
          updatePromises.push(
            callGraphQLSimple({
              message: "Failed to update status",
              mutation: "updateSheetRevision",
              variables: {
                input: {
                  id: latestSheetRevision.id,
                  status: oldStatus,
                },
              },
            })
          );
        }
      }
      updatePromises.push(
        callGraphQLSimple({
          message: "Failed to update status",
          queryCustom: "updateTaskRevision",
          variables: {
            input: {
              id: revision.id,
              status: oldStatus,
            },
          },
        })
      );

      try {
        await Promise.all(updatePromises);
      } catch (e) {
        // this is the revert operation, so if this one fails too, there's nothing we can do
      }
    }

    callGraphQLSimple({
      message: `Failed to update status`,
      queryCustom: "updateTask",
      variables: {
        input: {
          id: task.id,
          randomNumber: Math.floor(Math.random() * 100000),
        },
      },
    });
  };

  updateDescription = async (value) => {
    const { organisationDetails, revision, task } = this.props;
    if (organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions) {
      let messageKey = "task-revision-edit-description";
      message.loading({
        content: `Updating file and sheet revision descriptions to match ${getSimpleLabel("task revision")}...`,
        key: messageKey,
        duration: 0,
      });

      await new Promise((resolve) => setTimeout(resolve, 500));

      try {
        for (let file of revision.files.items) {
          const fileDetails = (
            await callGraphQLSimple({
              message: "Failed to fetch file details",
              queryCustom: "getFile",
              variables: {
                id: file.id,
              },
            })
          ).data.getFile;

          for (let sheet of fileDetails.sheets.items) {
            let latestSheetRevision = sheet.revisions.items.slice(-1)[0];
            await callGraphQLSimple({
              message: "Failed to update sheet revision description",
              mutation: "updateSheetRevision",
              variables: {
                input: {
                  id: latestSheetRevision.id,
                  description: value,
                },
              },
            });
          }
        }
        message.success({
          content: `File and sheet revision descriptions also updated to match ${getSimpleLabel("task revision")}`,
          key: messageKey,
          duration: 5,
        });
      } catch (e) {
        message.error({
          content: "Failed to update file and sheet revision descriptions",
          key: messageKey,
          duration: 10,
        });
      }
    }

    await callGraphQLSimple({
      message: "Failed to update description",
      queryCustom: "updateTaskRevision",
      variables: {
        input: {
          id: revision.id,
          description: value,
        },
      },
    });

    await callGraphQLSimple({
      message: `Failed to update ${getSimpleLabel("task")}`,
      queryCustom: "updateTask",
      variables: {
        input: {
          id: task.id,
          randomNumber: Math.floor(Math.random() * 100000),
        },
      },
    });
  };

  displayStatus = () => {
    const { revision, organisationDetails } = this.props;
    if (revision.isReadOnly) {
      let statusFromOrganisationDetails = organisationDetails.fileStatuses?.find(
        (status) =>
          status.name.toUpperCase().split(" ").join("_") === revision.status?.toUpperCase().split(" ").join("_")
      );
      return statusFromOrganisationDetails?.name || "";
    }
    return (
      <Select
        style={{ width: "100%" }}
        data-cy="task-revision-status-dropdown"
        value={revision.status ? revision.status.toUpperCase().split(" ").join("_") : undefined}
        onChange={this.updateRevisionStatus}
        className="active-on-hover"
        suffixIcon={null}
      >
        {organisationDetails.fileStatuses?.map((status) => {
          return (
            <Select.Option value={status.name.toUpperCase().split(" ").join("_")} key={status.name}>
              {status.name}
            </Select.Option>
          );
        })}
      </Select>
    );
  };

  displayRevisionDetails = () => {
    const { revision, windowWidth, organisationDetails } = this.props;

    return (
      <div className="revision-details">
        <Typography.Paragraph className="name-and-review-status">{revision.nameWithReviewStatus}</Typography.Paragraph>
        {!organisationDetails?.settings?.task?.allowMultipleLiveTaskRevisions &&
        revision.base &&
        revision.base !== "-" ? (
          <InfoItem inline label="Based on" fullWidth value={revision.base} className="based-on" />
        ) : null}
        {windowWidth < 600 && <InfoItem inline label="Created on" value={revision.createdAt} className="created-on" />}

        <InfoItem
          inline
          label="Description"
          fullWidth
          value={
            <Input
              disabled={revision.isReadOnly}
              defaultValue={revision.description || ""}
              onChange={this.updateDescription}
              fullWidth
            />
          }
        />

        {organisationDetails.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions && (
          <InfoItem inline label="Status" fullWidth value={this.displayStatus()} />
        )}
        {organisationDetails.settings?.task?.usesPriority && (
          <InfoItem
            inline
            label="Priority"
            fullWidth
            value={<TaskRevisionPriority taskRevision={revision} organisationDetails={organisationDetails} />}
          />
        )}
        {organisationDetails.settings?.task?.usesRequestedDate && (
          <InfoItem
            inline
            label="Requested date"
            fullWidth
            value={<TaskRevisionRequestedDate taskRevision={revision} />}
          />
        )}
        {organisationDetails.settings?.task?.useDueDatesOnTaskRevisions && (
          <InfoItem inline label="Due date" fullWidth value={<TaskRevisionDueDate taskRevision={revision} />} />
        )}

        {organisationDetails.settings?.task?.useTaskRevisionEstimates && (
          <InfoItem inline label="Estimated hours" fullWidth value={<TaskRevisionEstimate taskRevision={revision} />} />
        )}

        {!organisationDetails?.settings?.task?.allowMultipleLiveTaskRevisions && (
          <InfoItem
            inline
            label="Author"
            fullWidth
            value={revision.authorForDisplay || "Not set"}
            className="author-item"
          />
        )}

        {revision.checkedBy ? (
          <InfoItem inline label="Checked by" fullWidth value={revision.checkedBy} className="reviewer-item" />
        ) : null}

        <InfoItem inline label="Finished at" fullWidth value={<TaskRevisionFinishedAt taskRevision={revision} />} />
        {this.displayRequestFormDetails()}
      </div>
    );
  };

  displayApproveButton = () => {
    const { revision, organisationDetails } = this.props;

    if (revision.isReadOnly || revision.reviewAcceptDate) {
      return null;
    }

    let latestRevisionForAllSheetsAllowSkippingReview = true;

    if (!revision.files || !revision.files.items || !revision.files.items.length) {
      return null;
    }

    for (let file of revision.files.items) {
      for (let sheet of file.sheets.items) {
        let latestSheetRevision = sheet.revisions.items.slice(-1)[0];
        let latestSheetRevisionStatus = latestSheetRevision.status;
        let statusDetails = organisationDetails.fileStatuses.find(
          (fileStatus) =>
            fileStatus.name.toUpperCase().split(" ").join("_") ===
            latestSheetRevisionStatus.toUpperCase().split(" ").join("_")
        );

        if (!statusDetails?.canSkipReview) {
          latestRevisionForAllSheetsAllowSkippingReview = false;
          break;
        }
      }
    }

    if (!latestRevisionForAllSheetsAllowSkippingReview) {
      return null;
    }

    return (
      <Button
        type="dark"
        onClick={() => this.onApproveClick()}
        className="approve-task-revision-button"
        icon={<CheckCircleFilled />}
      >
        Approve
      </Button>
    );
  };

  displayAccessList = () => {
    const { revision, groups } = this.props;

    let groupAccessElement: any = null;

    if (revision.writeAccessGroups && revision.writeAccessGroups.length > 0) {
      let groupAccessNames = revision.writeAccessGroups
        .map((groupId) => {
          let group = groups.find((group) => group.id === groupId);
          return group ? group.name : "";
        })
        .filter((x) => x)
        .join(", ");
      groupAccessElement = <InfoItem inline label="Groups that can access" fullWidth value={groupAccessNames} />;
    }

    return <>{groupAccessElement}</>;
  };

  displayControlAccessButton = () => {
    const { organisationDetails } = this.props;

    if (organisationDetails.id !== "RDX" || !isAuthorised(["EDIT_TASK_REVISION_ACCESS", null, true])) {
      return null;
    }

    return (
      <>
        <Button
          type="dark"
          icon={<EyeOutlined />}
          onClick={() => {
            this.setState({ isControlTaskRevisionAccessModalVisible: true });
          }}
          className="control-access-button"
        >
          Control access
        </Button>
      </>
    );
  };

  displayAddFileButton = () => {
    const { revision } = this.props;
    const { isCreatingFile } = this.state;

    if (revision.isReadOnly) {
      return null;
    }

    return (
      <Dropdown
        overlay={this.displayAddFileMenu()}
        trigger={["click"]}
        disabled={isCreatingFile}
        overlayClassName="add-file-overlay"
      >
        <Button
          type="primary"
          className="add-file-button"
          icon={isCreatingFile ? <LoadingOutlined /> : <CaretDownOutlined />}
        >
          {isCreatingFile ? "Adding file..." : "Add file"}
        </Button>
      </Dropdown>
    );
  };

  displayRequestFormDetails = () => {
    const { revision, windowWidth } = this.props;

    if (!revision.requestFormActivityItem) {
      return null;
    }

    return (
      <InfoItem
        className="request-form"
        label={`${getSimpleLabel("Request")} form`}
        inline={windowWidth > 600}
        fullWidth
        value={
          <Link
            to={`/requests/${revision.requestFormActivityItem.parentId}?formFileId=${revision.requestFormActivityItem.content.formFileId}`}
            style={{ display: "flex", alignItems: "center", gap: "0rem" }}
          >
            <Tag className="dark-tag">{processIdForDisplay(revision.requestFormActivityItem.parentId)}</Tag>
            <Typography.Text>{revision.requestFormActivityItem.content.formName}</Typography.Text>
          </Link>
        }
      ></InfoItem>
    );
  };

  render() {
    const { organisationDetails, task, revision, windowWidth, visualIndex, isHighlighted } = this.props;
    const { isAddFileToTaskRevisionModalVisible, isRenameModalVisible, fileTypeToAdd } = this.state;

    return (
      <div
        ref={this.containerRef}
        className={cx("task-revision-item", {
          "read-only": revision.isReadOnly,
          "is-highlighted": isHighlighted,
        })}
        data-revision-name={revision.name}
        data-cy="task-revision-item"
      >
        {this.displayDownloadWaitingModal()}
        {task.revisions.items.length > 1 &&
          visualIndex !== 0 &&
          !revision.isReadOnly &&
          !task.isFinished &&
          !task.isArchived &&
          !organisationDetails?.settings?.task?.cannotCreateNewTaskRevisions && (
            <Tooltip title={`Delete ${getSimpleLabel("task revision")}`}>
              <Button icon={<DeleteOutlined />} className="delete-task-revision" onClick={this.onDeleteClick} />
            </Tooltip>
          )}

        <div className="revision-basic-details">
          {this.displayRevisionDetails()}

          <div className="revision-action-buttons">
            {this.displayAccessList()}
            {this.displayApproveButton()}
            {this.displayControlAccessButton()}
            {this.displayAddFileButton()}
          </div>
          <div className="files">{this.displayFiles()}</div>
        </div>
        {isAddFileToTaskRevisionModalVisible && (
          <AddFileToTaskRevisionModal
            visible={true}
            task={task}
            taskRevision={revision}
            fileType={fileTypeToAdd}
            onClose={() => this.setState({ isAddFileToTaskRevisionModalVisible: false })}
            parentType="TASK"
          />
        )}
        {isRenameModalVisible && (
          <RenameFileModal
            visible={true}
            task={task}
            file={this.state.selectedFile}
            onClose={() => this.setState({ isRenameModalVisible: false, selectedFile: null })}
          />
        )}
        {this.state.isPdfPreviewVisible && this.state.selectedFile && (
          <DocumentDetailsModal
            attachment={{
              name: `${FILE_TYPES_DETAILS[(this.state.selectedFile as any).type]?.label} - ${
                (this.state.selectedFile as any).name
              }`,
              type: "PDF",
              lastModified: (this.state.selectedFile as any).versions.items.slice(-1)[0].updatedAt,
              key: (this.state.selectedFile as any).versions.items.slice(-1)[0].exports[0].key,
            }}
            document={this.state.pdfPreviewData}
            onClose={() => this.setState({ pdfPreviewData: null, selectedFile: null })}
            windowWidth={windowWidth}
            allowDownload={false}
          />
        )}
        {this.state.isControlTaskRevisionAccessModalVisible && (
          <ControlTaskRevisionAccessModal
            onClose={() => this.setState({ isControlTaskRevisionAccessModalVisible: false })}
            onSubmit={this.onControlTaskRevisionAccessSubmit}
            parent={revision}
          />
        )}
      </div>
    );
  }
}

export default withRouter(TaskRevisionItem);
