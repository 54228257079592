import React from "react";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";
import { Button, Dropdown, Menu, Typography } from "antd";
import { CloseIcon } from "common/icons";
import { NotificationIcon } from "common/icons";
import cx from "classnames";
import query from "query-string";

import { READ_NOTIFICATION_DELETE_AFTER_DAYS, UNREAD_NOTIFICATION_DELETE_AFTER_DAYS } from "common/constants";
import { downloadAttachment } from "common/helpers";
import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";

import Avatar from "Avatar/Avatar";
import DraughtHubLogo from "graphics/draughthub_logo_symbol_only.png";

import "./NotificationMenu.scss";

export class NotificationMenu extends React.Component {
  componentDidMount() {
    this.requestNotificationPermission();
    this.checkUrlForNotificationId();
    this.deleteOldNotifications();
  }

  getMyNotifications = () => {
    const { notifications, apiUser } = this.props;
    return notifications?.filter((notification) => notification.userId === apiUser.id);
  };

  deleteOldNotifications = async () => {
    const notifications = this.getMyNotifications();
    let now = moment();

    if (!notifications) {
      return;
    }
    for (let i = 0; i < notifications.length; i++) {
      const notification = notifications[i];
      let shouldDelete = false;
      let dateCreatedAt = moment(notification.createdAt);
      let daysSinceCreated = now.diff(dateCreatedAt, "days");
      if (notification.readAt && daysSinceCreated >= READ_NOTIFICATION_DELETE_AFTER_DAYS) {
        shouldDelete = true;
      } else if (!notification.readAt && daysSinceCreated >= UNREAD_NOTIFICATION_DELETE_AFTER_DAYS) {
        shouldDelete = true;
      }
      if (shouldDelete) {
        await callGraphQLSimple({
          message: "Failed to delete notification",
          queryName: "deleteNotification",
          variables: {
            input: {
              id: notification.id,
            },
          },
        });
      }
    }
  };

  requestNotificationPermission = () => {
    if (!window.Cypress) {
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
      } else {
        Notification.requestPermission();
      }
    }
  };

  // if we have a notification ID in the URL, it means we came from an email
  // and must mark that notification as read
  checkUrlForNotificationId = () => {
    const notifications = this.getMyNotifications();

    const queryArgs = query.parseUrl(window.location.href).query;
    if (queryArgs.notificationId) {
      const targetNotification = notifications.find((x) => x.id === queryArgs.notificationId);
      if (targetNotification) {
        this.markNotificationAsRead(targetNotification);
      }
    }
  };

  markNotificationAsRead = async (notification) => {
    callGraphQLSimple({
      message: "Failed to mark notification as read",
      queryName: "updateNotification",
      variables: {
        input: {
          id: notification.id,
          readAt: new Date().toISOString(),
        },
      },
    });
  };

  downloadAttachment = async (notification) => {
    let fileName = "attachments";
    if (notification.parentId) {
      fileName += `-${notification.parentId}`;
    } else {
      fileName += `-document-library`;
    }

    fileName += `-${moment().format("DD-MMM-YYYY")}.zip`;

    await downloadAttachment({ key: notification.link }, fileName);
  };

  displayNotificationContent = (notification) => {
    const { users } = this.props;
    let content = (
      <div className="notification-item-content">
        <div className="avatar-container">
          {notification.author ? (
            <Avatar user={users.find((x) => x.id === notification.author)} />
          ) : (
            <img src={DraughtHubLogo} className="draughthub-logo-no-author" alt="" />
          )}
        </div>

        <div className="message-and-date">
          <span className="message">{notification.message}</span>

          <span className="date">
            {moment(notification.createdAt).fromNow()}{" "}
            <div
              className={cx("unread-notification-marker", {
                hidden: notification.readAt,
              })}
            />
          </span>
        </div>

        <Button
          type="clear"
          onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();

            await callGraphQLSimple({
              message: "Failed to delete notification",
              queryName: "deleteNotification",
              variables: {
                input: {
                  id: notification.id,
                },
              },
            });
          }}
          className="delete-notification-button"
        >
          <CloseIcon />
        </Button>
      </div>
    );

    switch (notification.type) {
      case "ATTACHMENTS_READY":
        return (
          <div
            className={cx("notification-item", {
              read: notification.readAt,
              unread: !notification.readAt,
            })}
            onClick={() => {
              this.markNotificationAsRead(notification);
              this.downloadAttachment(notification);
            }}
          >
            {content}
          </div>
        );
      case "ATTACHMENTS_FAILED":
        return (
          <div
            className={cx("notification-item", {
              read: notification.readAt,
              unread: !notification.readAt,
            })}
            onClick={() => {
              this.markNotificationAsRead(notification);
            }}
          >
            {content}
          </div>
        );
      case "ACCOUNTANCY_TOKEN_WARNING":
        return (
          <Link
            to={notification.link}
            onClick={() => this.markNotificationAsRead(notification)}
            className={cx("notification-item", {
              read: notification.readAt,
              unread: !notification.readAt,
            })}
          >
            {content}
          </Link>
        );
      default:
        return (
          <Link
            to={notification.link}
            onClick={() => this.markNotificationAsRead(notification)}
            className={cx("notification-item", {
              read: notification.readAt,
              unread: !notification.readAt,
            })}
          >
            {content}
          </Link>
        );
    }
  };

  render() {
    const { users } = this.props;
    const notifications = this.getMyNotifications();

    if (!notifications || !users) {
      return null;
    }

    const unreadCount = notifications.filter((x) => !x.readAt).length;

    const notificationsOverlay = (
      <Menu>
        {!notifications || notifications.length === 0 ? (
          <Menu.Item key="no-notifications">
            <Typography.Text className="no-notifications-message">You have no notifications</Typography.Text>
          </Menu.Item>
        ) : (
          <>
            <Menu.Item className="notifications-overlay-title" key="overlay-title">
              <span className="notifications-overlay-title-text">Notifications ({unreadCount} new)</span>
              <Button
                type="primary"
                className="mark-as-read-button"
                onClick={() =>
                  notifications.forEach((notification) =>
                    notification.readAt ? null : this.markNotificationAsRead(notification)
                  )
                }
              >
                Mark all as read
              </Button>
            </Menu.Item>
            {[...notifications].reverse().map((notification, index) => (
              <React.Fragment key={notification.id}>
                <Menu.Item key={notification.id} data-cy="notification-item" data-index={index}>
                  {this.displayNotificationContent(notification)}
                </Menu.Item>
                {index < notifications.length - 1 && <Menu.Divider />}
              </React.Fragment>
            ))}
          </>
        )}
      </Menu>
    );
    return (
      <div className="notification-menu">
        <Dropdown
          overlay={notificationsOverlay}
          overlayClassName="notifications-overlay"
          trigger={["click"]}
          placement="bottomRight"
        >
          <div className={cx("notification-marker", { "with-badge": unreadCount })}>
            {unreadCount > 0 && (
              <span className="unread-notification-count" data-cy="unread-notification-count">
                {unreadCount}
              </span>
            )}
            <NotificationIcon />
          </div>
        </Dropdown>
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: NotificationMenu,
    subscriptions: ["notifications"],
    displayPreloader: false,
  })
);
