import React from "react";
import moment from "moment";
import uniqid from "uniqid";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import cx from "classnames";
import axios from "axios";
import WheelReact from "wheel-react";
import { Typography, message, Empty, Button, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";
import { publish } from "common/helpers";
import getS3File from "common/getS3File";
import { callGraphQLSimple, callRest } from "common/apiHelpers";

import { getLatestRevision, getLatestFileVersion, HAS_SHEETS, KEY_TYPES, encodeKey } from "common/shared";
import {
  FILE_TYPES_READABLE,
  REVIEW_PAGE_SCALE,
  REVIEW_DEFAULT_COMMENT_FONT_SIZE,
  REVIEW_MIN_COMMENT_FONT_SIZE,
} from "common/constants";

import LoadingWrapper from "LoadingWrapper/LoadingWrapper";
import ReviewSheetToolbar, { TOOLS } from "../ReviewSheetToolbar/ReviewSheetToolbar";
import ReviewSheetActivity from "../ReviewSheetActivity/ReviewSheetActivity";
import DrawArea from "../DrawArea/DrawArea";
import PdfRenderer from "ReportPage/PdfRenderer";
import Zoomable from "Zoomable/Zoomable";

import "./ReviewSheet.scss";

export class ReviewSheet extends React.Component {
  constructor() {
    super();

    this.state = {
      // needsRefresh: false,
      lastFileVersionUpdateTime: null,
      isLoading: true,
      numberForRendererRefCheck: 0,
      numberForReportKey: Math.random(),
      pdfData: null,
      activeTool: null,
      zoomableContentInitialWidth: null,
      zoomableContentInitialHeight: null,
      scrollingContentInitialWidth: null,
      scrollingContentInitialHeight: null,
      scrollingContainerScrollTop: null,
      scrollingContainerCurrentPageIndex: 0,
      minZoom: 0,
      highlightedAnnotationId: null,
      scrollingPdfZoom: 0.5,
      scrollingPdfHeight: undefined,
      pdfScale: 1,
      isFitToWidth: false,
      isFitToHeight: false,
      hasScrollingPdfZoomBeenCalculated: false,
      pageCount: 1,
      currentPageNumber: 1,
      pagesInViewport: [],
      isZoomablePdfLoaded: false,
      isPublishButtonEnabled: true,
      publishHasBeenTriggered: false,
      historicalVersionTimestamp: null,
      inProgressPublishJobsForFile: [],

      // This is used to conditionally update the draw area when a new subscription event comes in,
      // but only in certain cases. Otherwise it would be far too wasteful/slow/problematic.
      // It's also useful to prevent changes sent by one user coming back to them and updating their draw area for no reason.
      reviewRandomNumbers: [],

      // drawing area
      initialDrawState: undefined,
      currentDrawState: undefined,
      drawAreaKey: uniqid(),

      highlightedAnnotationId: null,
    };

    this.fetchAsyncJobsInterval = null;

    this.debouncedComputeZoom = _.debounce(this.computeZoom, 100);

    this.throttledOnDrawingChange = _.debounce(this.onDrawingChange, 1000);
    this.throttledSaveDrawing = _.throttle(this.saveDrawing, 2000);
    this.drawingContainerRef = React.createRef();
    this.zoomableContentRef = React.createRef();
    this.debouncedSetScale = null;
    this.debouncedSetPositionX = null;
    this.debouncedSetPositionY = null;

    this.computeZoomInterval = null;
    this.scrollingPdfOnScrollCallbackRef = React.createRef(() => {});

    WheelReact.config({
      left: () => {},

      right: () => {},
      up: () => {
        this.scrollByPage("up");
      },
      down: () => {
        this.scrollByPage("down");
      },
    });
  }

  async componentDidMount() {
    const { file, apiUser } = this.props;

    callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: this.props.task.id,
          projectId: this.props.task.projectId,
          fileId: file.id,
          content: `${FILE_TYPES_READABLE[file.type]} file, sheet ${this.props.sheet.name}`,
          clientId: this.props.task.clientId,
          page: "REVIEW",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });

    this.setState({
      reviewRandomNumbers: [...this.state.reviewRandomNumbers, this.props.taskRevision.review.randomNumber],
    });

    this.fetchPdf();

    if (HAS_SHEETS[this.props.file.type]) {
      window[`reviewSheet-${this.props.sheet.id}-state`] = this.state;
    }
    this.computeZoomInterval = setInterval(this.computeZoom, 100);

    window.addEventListener("resize", this.debouncedComputeZoom);

    const projectFolder = await encodeKey({
      type: KEY_TYPES.PROJECT_FOLDER,
      data: {
        organisation: this.props.task.organisation,
        projectId: this.props.task.project.id,
      },
    });
    this.setState({ projectFolder, numberForRendererRefCheck: Math.random() });

    this.setInitialDrawState();
    if (!this.props.isExternalReview) {
      this.fetchAsyncJobsInterval = setInterval(
        this.fetchAsyncJobs,
        apiUser.isHidden && !window.location.href.includes("localhost") ? 2000 : 10000
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFileListOpen !== this.props.isFileListOpen || this.props.match.url !== prevProps.match.url) {
      this.debouncedComputeZoom();
    }

    this.checkIfAnnotationsNeedRefresh();

    const prevFileVersion = prevProps.file?.versions.items.slice(-1)[0];
    const fileVersion = this.props.file?.versions.items.slice(-1)[0];
    if (prevFileVersion.publishEndAt !== fileVersion.publishEndAt) {
      this.fetchPdf({ force: true });
    }
    // else {
    //   this.fetchPdf();
    // }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedComputeZoom);
    if (this.computeZoomInterval) {
      clearInterval(this.computeZoomInterval);
    }
    WheelReact.clearTimeout();
    if (this.fetchAsyncJobsInterval) {
      clearInterval(this.fetchAsyncJobsInterval);
    }
  }

  fetchAsyncJobs = async () => {
    const { organisationDetails, file } = this.props;
    const { inProgressPublishJobsForFile } = this.state;
    const asyncJobs = (
      await callGraphQLSimple({
        displayError: false,
        queryCustom: "listPublishJobsByFile",
        variables: {
          organisation: organisationDetails.id,
          limit: 1000,
          sortDirection: "DESC",
          fileVersionId: file.versions.items.slice(-1)[0].id,
        },
      })
    ).data.listPublishJobsByFile.items;

    let newInProgressPublishJobsForFile = asyncJobs?.filter((asyncJob) => asyncJob.status === "IN_PROGRESS");

    if (JSON.stringify(newInProgressPublishJobsForFile) !== JSON.stringify(inProgressPublishJobsForFile)) {
      this.setState({
        inProgressPublishJobsForFile: newInProgressPublishJobsForFile,
      });
    }
  };

  getFileKey = () => {
    return getLatestFileVersion(this.props.file).key.replace("public/", "");
  };

  setInitialDrawState = () => {
    const { taskRevision, externalReview, sheet } = this.props;
    const { review } = taskRevision;

    const activityForSheet = review.reviewThread.filter((x) => x.sheetId === this.props.sheet.id);

    let externalReviewActivityForSheet = (externalReview?.reviewThread || []).filter((reviewActivityItem) => {
      return reviewActivityItem.sheetId === sheet.id || reviewActivityItem.sheetId === sheet.constantId;
    });

    let compiledActivityForSheet = [...activityForSheet, ...externalReviewActivityForSheet].sort((a, b) =>
      a.createdAt < b.createdAt ? -1 : 1
    );

    const annotationsForSheet = compiledActivityForSheet
      .filter((x) => x.type.includes("ANNOTATION"))
      .map((activityItem) => JSON.parse(activityItem.content));

    let lines = [];
    let arrows = [];
    let drawings = [];
    let textBoxes = [];
    let leaderLines = [];
    let rectangles = [];
    let ellipses = [];

    annotationsForSheet.forEach((annotation) => {
      switch (annotation.type) {
        case "DRAWING":
          drawings.push(annotation);
          break;
        case "ARROW":
          arrows.push(annotation);
          break;
        case "LINE":
          lines.push(annotation);
          break;
        case "TEXT":
          textBoxes.push({
            ...annotation,
            fontSize:
              !annotation.fontSize || annotation.fontSize < REVIEW_MIN_COMMENT_FONT_SIZE
                ? REVIEW_DEFAULT_COMMENT_FONT_SIZE
                : annotation.fontSize,
          });
          break;
        case "LEADER_LINE":
          leaderLines.push({
            ...annotation,
            fontSize:
              !annotation.fontSize || annotation.fontSize < REVIEW_MIN_COMMENT_FONT_SIZE
                ? REVIEW_DEFAULT_COMMENT_FONT_SIZE
                : annotation.fontSize,
          });
          break;
        case "RECTANGLE":
          rectangles.push(annotation);
          break;
        case "ELLIPSE":
          ellipses.push(annotation);
          break;
        default:
          break;
      }
    });

    let newInitialDrawState = {
      ...this.state.initialDrawState,
      lines,
      arrows,
      drawings,
      textBoxes,
      leaderLines,
      rectangles,
      ellipses,
    };
    this.setState({
      needsRefreshForAnnotations: false,
      initialDrawState: newInitialDrawState,
      currentDrawState: newInitialDrawState,
      drawAreaKey: uniqid(),
    });
  };

  onDrawingChange = (newDrawState) => {
    this.setState(
      {
        currentDrawState: newDrawState,
      },
      this.throttledSaveDrawing
    );
  };

  checkIfAnnotationsNeedRefresh = () => {
    const { reviewRandomNumbers } = this.state;
    const { taskRevision } = this.props;
    const { review } = taskRevision;

    if (!reviewRandomNumbers.includes(review.randomNumber)) {
      this.setState(
        {
          reviewRandomNumbers: [...reviewRandomNumbers, review.randomNumber],
        },
        this.setInitialDrawState
      );
    }
  };

  onHighlightAnnotation = (id) => {
    this.setState({ highlightedAnnotationId: id });
  };

  computeZoom = () => {
    const { taskRevision, sheet } = this.props;

    const file = taskRevision.files.items.find((x) => !x.isArchived && x.id === sheet.fileId);

    if (HAS_SHEETS[this.props.file.type]) {
      const zoomableContent = document.querySelector(".react-pdf__Document");
      let boundsZoomableContent;
      if (zoomableContent) {
        boundsZoomableContent = zoomableContent.getBoundingClientRect();
      }
      if (
        this.drawingContainerRef.current &&
        boundsZoomableContent &&
        boundsZoomableContent.width > 0 &&
        boundsZoomableContent.height > 0
      ) {
        const boundsDrawingContainer = this.drawingContainerRef.current.getBoundingClientRect();
        let initialWidth = 510;
        let initialHeight = 360;
        if (file.templateId === "a3") {
          initialWidth = 255;
          initialHeight = 180;
        }

        if (this.computeZoomInterval) {
          clearInterval(this.computeZoomInterval);
          this.computeZoomInterval = null;
        }

        const scaleX = boundsDrawingContainer.width / initialWidth;
        const scaleY = boundsDrawingContainer.height / initialHeight;
        const minZoom = Math.min(scaleX, scaleY);

        if (this.debouncedSetScale) {
          this.setState({ minZoom });
          this.debouncedSetScale(0, 0, minZoom);
        }
      }
    } else {
      if (this.drawingContainerRef.current) {
        const boundsDrawingContainer = this.drawingContainerRef.current.getBoundingClientRect();
        const initialWidth = 596;

        if (this.computeZoomInterval) {
          clearInterval(this.computeZoomInterval);
          this.computeZoomInterval = null;
        }

        const SCALE_ADDED_COEFFICIENT = 0; // this is a magic number we use to override a scaling deficit that the transformer seems to add
        const scaleX = (boundsDrawingContainer.width + SCALE_ADDED_COEFFICIENT) / initialWidth;

        const minZoom = Math.min(scaleX, 1.5);
        this.setState({ scrollingPdfZoom: minZoom }, () => {
          this.setState({ hasScrollingPdfZoomBeenCalculated: true });
        });
      }
    }
  };

  fetchPdf = async (params) => {
    const { pdfDataByFileId, sheet, file, isLoadingReports } = this.props;
    const force = params?.force;
    if (force && this.state.historicalVersionTimestamp) {
      return;
    }

    if (file.type === "REPORT") {
      if (isLoadingReports) {
        setTimeout(() => {
          this.fetchPdf(params);
        }, 200);
        return;
      }

      if (pdfDataByFileId[file.id]) {
        let pdfData = pdfDataByFileId[file.id];

        this.setState({
          pdfData,
          isLoading: false,
          numberForRendererRefCheck: Math.random(),
        });
      }
      return;
    }

    const sheetRevision = getLatestRevision(sheet);

    const fileVersion = file.versions.items.find((x) => x.id === sheetRevision.fileVersionId);

    if (!force && fileVersion.updatedAt === this.state.lastFileVersionUpdateTime) {
      return;
    }

    let exportedFileKey = params?.key || sheetRevision.exports[0].key;
    if (!params?.key && !HAS_SHEETS[file.type]) {
      // if the file has no sheets, then we load the main annotated PDF
      exportedFileKey = fileVersion.exports[0].key;
    }

    this.setState({
      numberForRendererRefCheck: Math.random(),
      lastFileVersionUpdateTime: fileVersion.updatedAt,
    });

    let shouldLoadPdf = true;

    if (!force && this.state.pdfData && !this.state.pdfData.byteLength) {
      return;
    }

    if (file.type !== "REPORT" && !shouldLoadPdf && !fileVersion.publishEndAt) {
      return;
    }
    this.setState({ isZoomablePdfLoaded: false });
    const publicPdfUrl = await getS3File(exportedFileKey.replace("public/", ""), params?.versionId);
    await this.downloadPDF(publicPdfUrl, 0);
  };

  downloadPDF = async (publicPdfUrl, retryCount) => {
    const { inProgressPublishJobsForFile } = this.state;
    const { file } = this.props;

    let pdfDataBlob;

    let MAX_RETRY_COUNT = window.Cypress ? 1000 : 10;
    if (file.type === "REPORT") {
      MAX_RETRY_COUNT = 1;
    }
    const messageKey = "failed-to-load-sheet-pdf-message";

    if (retryCount < MAX_RETRY_COUNT) {
      try {
        pdfDataBlob = (await axios.get(publicPdfUrl, { responseType: "blob" })).data;
        message.destroy(messageKey);
      } catch (e) {
        if (inProgressPublishJobsForFile?.length > 0) {
          retryCount = 0;
          message.destroy(messageKey);
        } else if (retryCount > 1) {
          if (file.type !== "REPORT") {
            message.loading({
              content: `Failed to load the sheet PDF, retrying: ${retryCount}/${MAX_RETRY_COUNT}...`,
              duration: 0,
              key: messageKey,
            });
          }
        }
        setTimeout(async () => {
          await this.downloadPDF(publicPdfUrl, retryCount + 1);
        }, 2000);
      }
    } else {
      message.destroy(messageKey);
      if (file.type !== "REPORT") {
        Modal.warning({
          title: "The file needs to be published",
          content: "The sheet PDF couldn't be loaded. The file needs to be published.",
        });
      }
    }

    const pdfData = await new Response(pdfDataBlob).arrayBuffer();
    let pdfScale;
    try {
      const pdfWidthHeight = await this.getWidthHeightOfFirstPage({ pdfData });
      if (pdfWidthHeight) {
        const { width, height } = pdfWidthHeight;
        let longestSide = Math.max(width, height);

        if (HAS_SHEETS[file.type]) {
          pdfScale = Math.max(0.5, longestSide / 3000);
        } else {
          pdfScale = Math.max(1, longestSide / 3000);
        }
      }
    } catch (e) {
      console.error("Error getting width and height of first page", e);
    }

    this.setState({
      pdfData,
      isLoading: false,
      numberForRendererRefCheck: Math.random(),
      pdfScale: pdfScale || this.state.pdfScale,
    });
  };

  getWidthHeightOfFirstPage = ({ pdfData }) => {
    return new Promise((resolve, reject) => {
      const loadingTask = window.pdfjs.getDocument({ data: pdfData });

      loadingTask.promise
        .then(function (pdf) {
          // Get the first page
          pdf.getPage(1).then(function (page) {
            const viewport = page.getViewport({ scale: 1 });
            const width = viewport.width;
            const height = viewport.height;

            resolve({ width, height });
          });
        })
        .catch(function (error) {
          console.error("Error loading PDF:", error);
          reject(error);
        });
    });
  };

  viewDocumentForComment = async ({ createdAt }) => {
    const { file } = this.props;

    this.setState({ historicalVersionTimestamp: createdAt });
    let versions = [];

    if (HAS_SHEETS[file.type]) {
      const { sheet } = this.props;
      const sheetRevision = getLatestRevision(sheet);
      let sheetPdfKey = sheetRevision.exports[0].key;
      const versionsResponse = await callRest({
        method: "GET",
        route: `/s3-list-versions?prefix=${btoa(sheetPdfKey)}`,
        includeCredentials: false,
      });
      versions.push(...versionsResponse.Versions);
    } else {
      for (let i = 0; i < file.versions.items.length; i++) {
        const fileVersion = file.versions.items[i];
        const key = fileVersion.exports[0].key;
        const versionsResponse = await callRest({
          method: "GET",
          route: `/s3-list-versions?prefix=${btoa(key)}`,
          includeCredentials: false,
        });
        versions.push(...versionsResponse.Versions);
      }
    }

    versions = versions.sort((a, b) => (a.LastModified < b.LastModified ? 1 : -1));
    let targetVersion = versions.find((x) => x.LastModified < createdAt);
    let key = targetVersion?.Key;
    let versionId = targetVersion?.VersionId;
    this.fetchPdf({ force: true, key, versionId });
  };

  getSheetLabel({ file, sheet }) {
    let result = `${FILE_TYPES_READABLE[file.type]}`;

    if (HAS_SHEETS[file.type]) {
      result += ` (${file.name} - ${sheet.name})`;
    } else {
      result += ` (${file.name})`;
    }

    return result;
  }

  getUpToDateReview = async () => {
    const upToDateReview = (
      await callGraphQLSimple({
        message: "Failed to retrieve review details",
        queryName: "getReview",
        variables: {
          id: this.props.taskRevision.reviewId,
        },
      })
    ).data.getReview;

    return upToDateReview;
  };

  onSubmitComment = async (commentBody) => {
    const { taskRevision, file, apiUser, sheet, task } = this.props;

    const upToDateReview = await this.getUpToDateReview();

    await callGraphQLSimple({
      message: "Failed to submit comment",
      queryName: "updateReview",
      variables: {
        input: {
          id: upToDateReview.id,
          reviewThread: [
            ...upToDateReview.reviewThread,
            {
              id: uniqid(),
              type: "COMMENT",
              createdAt: new Date().toISOString(),
              sheetId: sheet.id,
              sheetLabel: this.getSheetLabel({ file, sheet }),
              content: commentBody,
              author: apiUser.id,
            },
          ],
        },
      },
    });

    if (!this.props.isExternalReview) {
      await callGraphQLSimple({
        message: "Failed to submit comment",
        queryName: "updateTaskRevision",
        variables: {
          input: {
            id: taskRevision.id,
            randomNumber: Math.floor(Math.random() * 100000),
          },
        },
      });
    } else {
      await callGraphQLSimple({
        message: "Failed to refresh review",
        mutation: "updateRequest",
        variables: {
          input: {
            id: task.id,
            itemSubscription: Math.floor(Math.random() * 100000),
          },
        },
      });
    }

    message.success(<Typography.Text>Your comment has been added</Typography.Text>);

    setTimeout(() => {
      let reviewThreadItemElements = document.querySelectorAll(".review-thread-item");

      if (!reviewThreadItemElements?.length) {
        return;
      }

      let lastReviewThreadItemElement = reviewThreadItemElements[reviewThreadItemElements.length - 1];

      lastReviewThreadItemElement.scrollIntoView({ behavior: "smooth" });
    }, 300);
  };

  onItemHoverStart = (id) => {
    this.setState({ highlightedAnnotationId: id });
  };

  onItemHoverEnd = () => {
    this.setState({ highlightedAnnotationId: null });
  };

  onChangeActiveTool = (activeTool) => {
    this.setState({ activeTool });
  };

  onToolClick = (toolName) => {
    if (toolName === TOOLS.save) {
      this.saveDrawing();
    }
    if (toolName === TOOLS.zoomPlus) {
      this.onPlusClick();
    }
    if (toolName === TOOLS.zoomMinus) {
      this.onMinusClick();
    }
    if (toolName === TOOLS.refresh) {
      this.fetchPdf({ force: true });
      this.setState({ pdfData: null, isLoading: true });
    }
    if (toolName === TOOLS.fitToWidth) {
      this.fitToWidth();
    }
    if (toolName === TOOLS.fitToHeight) {
      this.fitToHeight();
    }
  };

  fitToHeight = () => {
    const firstPageElement = document.querySelector(".react-pdf__Page");
    if (!firstPageElement) {
      setTimeout(this.fitToWidth, 200);
    }
    const pageDimensions = firstPageElement.getBoundingClientRect();
    const viewportDimensions = this.drawingContainerRef.current.getBoundingClientRect();
    const verticalScaleHeight = viewportDimensions.height / pageDimensions.height;
    const verticalScaleWidth = viewportDimensions.width / pageDimensions.width;
    const newScale = Math.min(verticalScaleHeight, verticalScaleWidth);
    this.setState({
      scrollingPdfZoom: this.state.scrollingPdfZoom * newScale,
      isFitToWidth: false,
      isFitToHeight: true,
    });
  };

  fitToWidth = () => {
    const firstPageElement = document.querySelector(".react-pdf__Page");
    if (!firstPageElement) {
      setTimeout(this.fitToWidth, 200);
    }
    const pageDimensions = firstPageElement.getBoundingClientRect();

    // this is because if the page is scaled, the width calculation needs to take that into account
    pageDimensions.width *= REVIEW_PAGE_SCALE;
    pageDimensions.height *= REVIEW_PAGE_SCALE;

    const viewportDimensions = this.drawingContainerRef.current.getBoundingClientRect();
    const verticalScaleHeight = viewportDimensions.height / pageDimensions.height;
    const verticalScaleWidth = viewportDimensions.width / pageDimensions.width;
    const newScale = Math.max(verticalScaleHeight, verticalScaleWidth);
    this.setState({
      scrollingPdfZoom: this.state.scrollingPdfZoom * newScale,
      isFitToWidth: true,
      isFitToHeight: false,
    });
  };

  scrollByPage(direction) {
    if (this.state.activeTool !== TOOLS.pageScroll) {
      return;
    }
    const currentScrollTop = this.drawingContainerRef.current.scrollTop;
    let newScrollTop = currentScrollTop;
    let newPageIndex = this.state.scrollingContainerCurrentPageIndex;
    const pageHeight = document.querySelector(".react-pdf__Page").getBoundingClientRect().height;

    newPageIndex += direction === "down" ? -1 : 1;

    newScrollTop = newPageIndex * pageHeight;
    const { pageCount } = this.state;
    if (newPageIndex >= pageCount - 2) {
      newPageIndex = pageCount - 2;
    }
    if (newPageIndex <= 0) {
      newPageIndex = 0;
    }

    this.setState({
      scrollingContainerScrollTop: newScrollTop,
      scrollingContainerCurrentPageIndex: newPageIndex,
    });

    setTimeout(() => {
      this.drawingContainerRef.current.scrollTop = newScrollTop;
    }, 200);
  }

  addAnnotation = (reviewThread, item, type) => {
    const { apiUser, sheet, file } = this.props;

    const existingMatchInReviewIndex = reviewThread.findIndex((activityItem) =>
      this.activityItemMatches(activityItem, item)
    );

    if (existingMatchInReviewIndex === -1) {
      reviewThread.push({
        id: String(Date.now()) + String(Math.floor(Math.random() * 1000)),
        type: type,
        createdAt: new Date().toISOString(),
        sheetId: sheet.id,
        sheetLabel: this.getSheetLabel({ file, sheet }),
        content: JSON.stringify(item),
        author: apiUser.id,
      });
    } else {
      reviewThread[existingMatchInReviewIndex].content = JSON.stringify(item);
    }
  };

  saveDrawing = async (tryCount) => {
    const { task, taskRevision, apiUser, file, sheet } = this.props;

    const { currentDrawState, reviewRandomNumbers } = this.state;

    const upToDateReview = await this.getUpToDateReview();

    let reviewThread = JSON.parse(JSON.stringify(upToDateReview.reviewThread || []));

    currentDrawState.lines?.forEach((item) => this.addAnnotation(reviewThread, item, "ANNOTATION_LINE"));
    currentDrawState.arrows?.forEach((item) => this.addAnnotation(reviewThread, item, "ANNOTATION_ARROW"));
    currentDrawState.drawings?.forEach((item) => this.addAnnotation(reviewThread, item, "ANNOTATION_DRAWING"));
    currentDrawState.rectangles?.forEach((item) => this.addAnnotation(reviewThread, item, "ANNOTATION_RECTANGLE"));
    currentDrawState.ellipses?.forEach((item) => this.addAnnotation(reviewThread, item, "ANNOTATION_ELLIPSE"));

    currentDrawState.textBoxes?.forEach((item) => {
      const existingMatchInReviewIndex = upToDateReview.reviewThread.findIndex((activityItem) =>
        this.activityItemMatches(activityItem, item)
      );
      if (existingMatchInReviewIndex === -1) {
        reviewThread.push({
          id: String(Date.now()) + String(Math.floor(Math.random() * 1000)),
          type: "ANNOTATION_TEXT",
          createdAt: new Date().toISOString(),
          sheetId: this.props.sheet.id,
          content: JSON.stringify(item),
          sheetLabel: this.getSheetLabel({ file, sheet }),
          author: apiUser.id,
        });
      } else {
        reviewThread[existingMatchInReviewIndex].content = JSON.stringify(item);
      }
    });

    currentDrawState.leaderLines?.forEach((item) => {
      const existingMatchInReviewIndex = upToDateReview.reviewThread.findIndex((activityItem) =>
        this.activityItemMatches(activityItem, item)
      );
      if (existingMatchInReviewIndex === -1) {
        reviewThread.push({
          id: String(Date.now()) + String(Math.floor(Math.random() * 1000)),
          type: "ANNOTATION_LEADER_LINE",
          createdAt: new Date().toISOString(),
          sheetId: this.props.sheet.id,
          content: JSON.stringify(item),
          sheetLabel: this.getSheetLabel({ file, sheet }),
          author: apiUser.id,
        });
      } else {
        reviewThread[existingMatchInReviewIndex].content = JSON.stringify(item);
      }
    });

    reviewThread = reviewThread.filter((item) => {
      try {
        const parsedContent = JSON.parse(item.content);
        return !parsedContent.deleted;
      } catch (e) {
        // nothing to do, it just means no content or the content is not a JSON
        return true;
      }
    });

    const reviewRandomNumber = Math.floor(Math.random() * 100000);

    this.setState({
      reviewRandomNumbers: [...reviewRandomNumbers, reviewRandomNumber],
    });

    try {
      await callGraphQLSimple({
        message: "Failed to update review",
        queryName: "updateReview",
        variables: {
          input: {
            id: upToDateReview.id,
            randomNumber: reviewRandomNumber,
            reviewThread,
          },
        },
      });

      if (!this.props.isExternalReview) {
        await callGraphQLSimple({
          message: `Failed to update ${getSimpleLabel("task revision")}`,
          queryName: "updateTaskRevision",
          variables: {
            input: {
              id: taskRevision.id,
              randomNumber: Math.floor(Math.random() * 100000),
            },
          },
        });
      } else {
        await callGraphQLSimple({
          message: "Failed to refresh review",
          mutation: "updateRequest",
          variables: {
            input: {
              id: task.id,
              itemSubscription: Math.floor(Math.random() * 100000),
            },
          },
        });
      }
    } catch (e) {
      // if this fails, we should try again
      if (tryCount < 5) {
        setTimeout(() => {
          this.saveDrawing(tryCount + 1);
        }, 1000);
      } else {
        throw e;
      }
    }
  };

  activityItemMatches = (activityItem, item) => {
    if (!activityItem.type.includes("ANNOTATION")) {
      return false;
    }

    const parsedContent = JSON.parse(activityItem.content);
    if (parsedContent.id === item.id) {
      return true;
    }
    return false;
  };

  onPlusClick = () => {
    this.setState({
      scrollingPdfZoom: this.state.scrollingPdfZoom + 0.25,
      isFitToHeight: false,
      isFitToWidth: false,
    });
  };

  onMinusClick = () => {
    this.setState({
      scrollingPdfZoom: this.state.scrollingPdfZoom - 0.25,
      isFitToHeight: false,
      isFitToWidth: false,
    });
  };

  onZoomablePdfLoad = () => {
    setTimeout(() => {
      this.setState({ isZoomablePdfLoaded: true });
    }, 2000);
  };

  onScrollingPdfLoad = ({ onScroll, totalHeight }) => {
    if (onScroll) {
      this.scrollingPdfOnScrollCallbackRef.current = onScroll;
      this.scrollingPdfOnScrollCallbackRef.current();
    }
    if (totalHeight !== undefined) {
      this.setState({ scrollingPdfHeight: totalHeight });
    }
  };

  triggerPublish = async ({ file, fileVersion }) => {
    const { task, taskRevision } = this.props;
    this.setState({ isPublishButtonEnabled: false }, () => {
      setTimeout(() => {
        this.setState({ isPublishButtonEnabled: true });
      }, 10000);
    });
    await publish({
      file,
      fileVersionId: fileVersion.id,
      taskRevisionId: taskRevision.id,
      task,
    });
  };

  displayZoomablePdf = ({ activityForSheet, reviewIsActive }) => {
    const { pdfData, activeTool, isZoomablePdfLoaded, numberForRendererRefCheck } = this.state;
    const { isActivityOpen } = this.props;
    const { apiUser, users, windowWidth, windowHeight, isFileListOpen } = this.props;

    const annotationsForSheet = activityForSheet
      .filter((x) => x.type.includes("ANNOTATION"))
      .map((activityItem) => JSON.parse(activityItem.content));

    return (
      <>
        <div className="message-container">
          {this.displayHistoricPdfBanner()}
          {this.displayPublishMessage()}
        </div>
        <div
          className={cx("drawing-container", {
            "is-file-list-open": isFileListOpen,
            "is-activity-open": isActivityOpen,
          })}
          ref={this.drawingContainerRef}
        >
          <Zoomable
            key={pdfData}
            className="drawing-wrapper"
            isLoaded={isZoomablePdfLoaded && pdfData}
            refreshOnChange={[
              windowWidth,
              windowHeight,
              isZoomablePdfLoaded,
              isFileListOpen,
              isActivityOpen,
              numberForRendererRefCheck,
            ]}
            active={activeTool === "HIDE_ANNOTATIONS" || !activeTool}
            containerRef={this.drawingContainerRef}
            content={(scale) => {
              return (
                <div className="zoomable-content" ref={this.zoomableContentRef}>
                  {!pdfData ? (
                    <>
                      <div className="sheet-image-placeholder" />
                      <div className="no-image">
                        <Empty description="No image yet" />
                      </div>
                    </>
                  ) : (
                    <>
                      <PdfRenderer
                        refToCheck={numberForRendererRefCheck}
                        fileData={pdfData}
                        includePagination={false}
                        renderMode="canvas"
                        onLoad={this.onZoomablePdfLoad}
                      />

                      <DrawArea
                        key={this.state.drawAreaKey}
                        zoom={4.68}
                        scale={4.68}
                        activeTool={activeTool}
                        annotations={annotationsForSheet}
                        highlightedAnnotationId={this.state.highlightedAnnotationId}
                        initialDrawState={this.state.initialDrawState}
                        onChange={this.throttledOnDrawingChange}
                        setActiveTool={(activeTool) => this.setState({ activeTool })}
                        documentSize={this.state.pdfScale}
                        defaultFontSize={this.getDefaultCommentFontSize()}
                        reviewIsActive={reviewIsActive}
                        apiUser={apiUser}
                        users={users}
                        onHighlightAnnotation={this.onHighlightAnnotation}
                      />
                    </>
                  )}
                </div>
              );
            }}
          />
        </div>
      </>
    );
  };

  displayHistoricPdfBanner = () => {
    const { historicalVersionTimestamp } = this.state;

    if (!historicalVersionTimestamp) {
      return null;
    }

    return (
      <div className="message-item">
        <Typography.Text>
          Displaying PDF from {moment(historicalVersionTimestamp).format("DD-MM-YYYY [at] HH:mm").toLowerCase()}
          <Button
            type="secondary"
            onClick={() => {
              this.setState({ historicalVersionTimestamp: null });
              this.fetchPdf({ force: true });
            }}
          >
            Show current version
          </Button>
        </Typography.Text>
      </div>
    );
  };

  displayPublishMessage = () => {
    const { inProgressPublishJobsForFile, publishHasBeenTriggered } = this.state;
    const { file, sheet } = this.props;
    const sheetRevision = getLatestRevision(sheet);
    const fileVersion = file.versions.items.find((x) => x.id === sheetRevision.fileVersionId);
    let isOutOfDate = fileVersion.publishEndAt < fileVersion.savedAt;

    let isPublishing =
      inProgressPublishJobsForFile?.length > 0 ||
      (fileVersion.publishStartAt > fileVersion.publishEndAt && !fileVersion.publishError);

    if (isPublishing) {
      return (
        <div className="message-item generating-new-pdf">
          <LoadingOutlined />
          <Typography.Text> We're generating a new PDF...</Typography.Text>
        </div>
      );
    }

    if (isOutOfDate && !publishHasBeenTriggered) {
      return (
        <div className="message-item">
          <Typography.Text>PDF is out of date</Typography.Text>
          <Button
            type="secondary"
            onClick={async () => {
              message.success({
                content: "A new publish has been triggered",
              });
              await this.triggerPublish({ file, fileVersion });
              this.setState({
                publishHasBeenTriggered: true,
              });
            }}
          >
            Publish again
          </Button>
        </div>
      );
    }

    return null;
  };

  displayScrollingPdf = ({ activityForSheet, reviewIsActive }) => {
    const { activeTool, numberForRendererRefCheck, pdfData, currentPageNumber } = this.state;
    const { file, task, users, apiUser, isFileListOpen, isActivityOpen } = this.props;

    const annotationsForSheet = activityForSheet
      .filter((x) => x.type.includes("ANNOTATION"))
      .map((activityItem) => JSON.parse(activityItem.content));

    return (
      <>
        <div className="message-container">
          {this.displayHistoricPdfBanner()}
          {this.displayPublishMessage()}
        </div>
        <div
          className={cx("drawing-container", "scrollable", file.type.toLowerCase(), {
            "is-file-list-open": isFileListOpen,
            "is-activity-open": isActivityOpen,
          })}
          data-file-type={file.type}
          ref={this.drawingContainerRef}
          onScroll={this.scrollingPdfOnScrollCallbackRef.current}
          {...WheelReact.events}
        >
          <div className="inner-drawing-container">
            {pdfData && pdfData.byteLength > 0 ? (
              <PdfRenderer
                refToCheck={numberForRendererRefCheck}
                fileData={pdfData}
                layout="scrolling"
                renderMode="canvas"
                onLoad={this.onScrollingPdfLoad}
                zoom={this.state.scrollingPdfZoom}
                currentPageNumber={currentPageNumber}
                includePagination={false}
              />
            ) : (
              file.type === "REPORT" && (
                <Link to={`/tasks/${task.id}/REPORT/${file.id}`}>
                  <Typography.Text className="no-report-message" data-cy="no-report-message">
                    <Typography.Text className="main-message">Report not published yet.</Typography.Text> <br />
                    If you want it to display in the review, <br />
                    click here to go to the report page, fix any issues, then come back.
                  </Typography.Text>
                </Link>
              )
            )}

            <DrawArea
              key={this.state.drawAreaKey}
              activeTool={activeTool}
              annotations={annotationsForSheet}
              scale={1}
              currentPageNumber={currentPageNumber}
              height={this.state.scrollingPdfHeight}
              zoom={this.state.scrollingPdfZoom}
              highlightedAnnotationId={this.state.highlightedAnnotationId}
              initialDrawState={this.state.initialDrawState}
              onChange={this.throttledOnDrawingChange}
              setActiveTool={(activeTool) => this.setState({ activeTool })}
              defaultFontSize={this.getDefaultCommentFontSize()}
              reviewIsActive={reviewIsActive}
              documentSize={this.state.pdfScale}
              apiUser={apiUser}
              users={users}
              onHighlightAnnotation={this.onHighlightAnnotation}
              // viewport={{ x: 0, y: currentPageNumber * currentPage }}
            />
          </div>
        </div>
      </>
    );
  };

  displayContents = ({ activityForSheet, reviewIsActive }) => {
    const { file } = this.props;

    if (HAS_SHEETS[file.type]) {
      return this.displayZoomablePdf({ activityForSheet, reviewIsActive });
    } else {
      return this.displayScrollingPdf({ activityForSheet, reviewIsActive });
    }
  };

  getDefaultCommentFontSize = () => {
    const { organisationDetails, file } = this.props;
    const templateDetails = organisationDetails.templates.items.find((x) => x.id === file.templateId);
    return (
      templateDetails?.reviewCommentFontSize ||
      organisationDetails.settings?.review?.defaultCommentFontSize ||
      REVIEW_DEFAULT_COMMENT_FONT_SIZE
    );
  };

  render() {
    const { taskRevision, apiUser, file, reviewIsActive, task, sheet, externalReview, isLoadingReports } = this.props;
    const { review } = taskRevision;
    const { pdfData } = this.state;

    const activityForSheet = review.reviewThread.filter(
      (x) => x.sheetId === sheet.id || ["START", "STATUS_CHANGE", "REVIEWER_CHANGE", "REVIEWER_SET"].includes(x.type)
    );

    let externalReviewActivityForSheet = (externalReview?.reviewThread || []).filter((reviewActivityItem) => {
      return reviewActivityItem.sheetId === sheet.id || reviewActivityItem.sheetId === sheet.constantId;
    });

    let compiledActivityForSheet = [...activityForSheet, ...externalReviewActivityForSheet].sort((a, b) =>
      a.createdAt < b.createdAt ? -1 : 1
    );

    let enabledTools = [];

    return (
      <LoadingWrapper
        isLoading={(isLoadingReports && file.type === "REPORT") || this.state.isLoading}
        content={() => (
          <div
            className="review-sheet"
            data-sheet-id={this.props.sheet.id}
            data-sheet-name={this.props.sheet.name}
            data-file-type={this.props.file.type}
          >
            <div className="inner-review-sheet-container">
              <ReviewSheetToolbar
                enabled={reviewIsActive && pdfData}
                onChange={this.onChangeActiveTool}
                onToolClick={this.onToolClick}
                activeTool={this.state.activeTool}
                contentType={HAS_SHEETS[file.type] ? "ZOOMABLE" : "SCROLLABLE"}
                enabledTools={enabledTools}
              />
              {this.displayContents({ activityForSheet: compiledActivityForSheet, reviewIsActive })}
            </div>
            <ReviewSheetActivity
              sheet={this.props.sheet}
              task={task}
              review={review}
              externalReview={this.props.externalReview}
              items={compiledActivityForSheet}
              taskRevision={taskRevision}
              file={file}
              apiUser={apiUser}
              zoom={this.state.scrollingPdfZoom || this.state.minZoom}
              onSubmitComment={this.onSubmitComment}
              onItemHoverStart={this.onItemHoverStart}
              onItemHoverEnd={this.onItemHoverEnd}
              isOpen={this.props.isActivityOpen}
              onHide={this.props.onHideActivity}
              onShow={this.props.onShowActivity}
              refreshDrawing={this.setInitialDrawState}
              viewDocumentForComment={this.viewDocumentForComment}
              historicalVersionTimestamp={this.state.historicalVersionTimestamp}
              reviewIsActive={reviewIsActive}
              highlightedAnnotationId={this.state.highlightedAnnotationId}
              request={this.props.request}
            />
          </div>
        )}
      />
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: ReviewSheet,
    subscriptions: ["organisationDetails"],
  })
);
