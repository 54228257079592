import React from "react";
import _ from "lodash";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import Textarea from "Form/Textarea/Textarea";

export default class TaskDescription extends React.Component {
  constructor(props) {
    super(props);

    this.debouncedSendToApi = _.debounce((newDescription) => this.sendToApi(newDescription), 1500);
  }

  sendToApi = (newDescription) => {
    const { task } = this.props;

    if (task.subtitle === newDescription) {
      return;
    }

    callGraphQLSimple({
      message: "Failed to update description",
      queryCustom: "updateTask",
      variables: {
        input: {
          id: task.id,
          subtitle: newDescription,
        },
      },
    });

    window.callGraphQLSimple({
      mutation: "createTaskActivityItem",
      message: `Failed to record ${getSimpleLabel("task")} activity item`,
      variables: {
        input: {
          taskId: task.id,
          author: window.apiUser.id,
          organisation: task.organisation,
          type: "DESCRIPTION_EDITED",
          content: JSON.stringify({
            newDescription: newDescription,
          }),
        },
      },
    });
  };

  render() {
    const { defaultValue } = this.props;
    return <Textarea defaultValue={defaultValue} onChange={this.debouncedSendToApi} />;
  }
}
