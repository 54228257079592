import React from "react";
import { InputNumber } from "antd";

import FormInputLabel from "Form/FormInputLabel/FormInputLabel";

export function FormNumericalInput({ fieldData, fieldName, nestedFieldParams, readOnly, updateFieldValue }) {
  return (
    <div className="input-group" key={fieldName}>
      <FormInputLabel fieldData={fieldData} fieldName={fieldName} />
      <InputNumber
        placeholder={fieldData.placeholder}
        disabled={readOnly}
        onChange={(value) => {
          updateFieldValue({
            fieldName,
            value,
            nestedFieldParams,
          });
        }}
        defaultValue={fieldData.value}
      />
    </div>
  );
}

export default React.memo(FormNumericalInput, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["readOnly", "refreshKey", "validationErrors", "hiddenFormFieldKeys"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps.topLevelParams[propName] !== nextProps.topLevelParams[propName]) {
      return false;
    }
  }

  return true;
});
