import { useState } from "react";
import { Button, Typography, Modal, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";

import EditFileStatusModal from "Modals/EditFileStatusModal/EditFileStatusModal";

import "./FileStatusList.scss";

function FileStatusList(props) {
  const { organisationDetails, setProps, context } = props;
  const [selectedStatus, setSelectedStatus] = useState(); // eslint-disable-line
  const [selectedIndex, setSelectedIndex] = useState(); // eslint-disable-line
  const [isEditFileStatusModalVisible, setIsEditFileStatusModalVisible] = useState(false);

  const reorder = (startIndex, endIndex) => {
    const result = Array.from(organisationDetails.fileStatuses);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  async function onDeleteClick(fileStatus) {
    Modal.confirm({
      title: `Are you sure you want to delete the file status "${fileStatus.name}"?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        await deleteFileStatus(fileStatus);
      },
    });
  }

  async function deleteFileStatus(fileStatus) {
    const newFileStatuses = organisationDetails.fileStatuses.filter((x) => x.name !== fileStatus.name);
    setProps({
      context: {
        ...context,
        organisation: {
          ...organisationDetails,
          fileStatuses: newFileStatuses,
        },
        organisationDetails: {
          ...organisationDetails,
          fileStatuses: newFileStatuses,
        },
      },
    });
    await callGraphQLSimple({
      message: "Could not delete file status",
      queryName: "updateOrganisation",
      variables: {
        input: {
          id: organisationDetails.id,
          fileStatuses: newFileStatuses,
        },
      },
    });
  }

  async function onDragEnd(result) {
    // item dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedFileStatuses = reorder(result.source.index, result.destination.index);
    setProps({
      context: {
        ...context,
        organisation: {
          ...organisationDetails,
          fileStatuses: reorderedFileStatuses,
        },
        organisationDetails: {
          ...organisationDetails,
          fileStatuses: reorderedFileStatuses,
        },
      },
    });
    await callGraphQLSimple({
      message: "Could not reorder file statuses",
      queryName: "updateOrganisation",
      variables: {
        input: {
          id: organisationDetails.id,
          fileStatuses: reorderedFileStatuses,
        },
      },
    });
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div className="file-status-list" {...provided.droppableProps} ref={provided.innerRef}>
              {organisationDetails.fileStatuses.map((fileStatus, index) => {
                return (
                  <Draggable
                    key={fileStatus.name}
                    draggableId={fileStatus.name}
                    index={index}
                    className={`draggable-file-status ${fileStatus.name.split(" ").join("")}`}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="file-status-item"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Typography.Text className="name" data-cy="file-status-name">
                          {fileStatus.name}
                        </Typography.Text>
                        {fileStatus.canSkipReview && (
                          <Tag color="blue" style={{ marginLeft: "0.5rem" }}>
                            Can skip review
                          </Tag>
                        )}
                        <div className="actions">
                          <Button
                            key={`file-status-edit-${fileStatus.name}`}
                            icon={<EditOutlined />}
                            className="edit-file-status"
                            onClick={() => {
                              setSelectedIndex(index);
                              setSelectedStatus(fileStatus);
                              setIsEditFileStatusModalVisible(true);
                            }}
                          />
                          <Button
                            key={`file-status-delete-${fileStatus.name}`}
                            icon={<DeleteOutlined />}
                            className="delete-file-status"
                            onClick={() => onDeleteClick(fileStatus)}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isEditFileStatusModalVisible && (
        <EditFileStatusModal
          visible={true}
          statusDetails={selectedStatus}
          index={selectedIndex}
          onClose={() => setIsEditFileStatusModalVisible(false)}
        />
      )}
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: FileStatusList,
    subscriptions: ["organisationDetails"],
  })
);
