import moment from "moment";
import { processIdForDisplay } from "common/helpers";
import { getSimpleLabel } from "common/labels";

export function getTableColumns() {
  return [
    { title: `${getSimpleLabel("Request")} ID`, fieldFunction: (request) => processIdForDisplay(request.id) },
    { title: `${getSimpleLabel("Client")} name`, align: "left", fieldFunction: (request) => request.client?.name },
    {
      title: `${getSimpleLabel("Project")} title`,
      fieldFunction: (request) => request.project?.title,
      onFilter: (value, record) => record.name.includes(value),
    },
    { title: "Latest form name", fieldFunction: (request) => request.latestFormName },
    {
      title: `${getSimpleLabel("Request")} status`,
      fieldFunction: (request) => request.status?.split("_")?.join(" "),
      width: 170,
    },
    !window.organisationDetails?.settings?.request?.hideAssignedTo && {
      title: "Assigned to",
      fieldFunction: (request) => request.assignedTo || "",
    },
    {
      title: "Last updated at",
      fieldFunction: (item) => moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
      width: 200,
    },
    {
      title: "Requested at",
      fieldFunction: (item) => moment(item.requestedAt).format("DD-MM-YYYY HH:mm:ss"),
      width: 200,
    },
    {
      title: "Requested for date",
      fieldFunction: (item) => moment(item.requestedForDate).format("DD-MM-YYYY"),
      width: 200,
    },
  ].filter((x) => x);
}
