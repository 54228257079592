import { useState } from "react";
import { Form, Modal, Button, Input, Space, Checkbox } from "antd";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";

import "./CreateFileStatusModal.scss";

export function CreateFileStatusModal({ onClose, visible, apiUser, organisationDetails }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ name, canSkipReview }) {
    setIsLoading(true);

    await callGraphQLSimple({
      message: "Could not create file status",
      queryName: "updateOrganisation",
      variables: {
        input: {
          id: apiUser.organisation,
          fileStatuses: [
            ...(organisationDetails.fileStatuses || []),
            {
              name,
              canSkipReview,
            },
          ],
        },
      },
    });

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  return (
    <Modal
      maskClosable={false}
      title="Create file status"
      open={visible}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-file-status-modal"
    >
      <Form form={form} initialValues={{}} onFinish={onSubmit}>
        <Space direction="vertical" className="spread">
          <Form.Item
            label="Name"
            name="name"
            className="file-status-name"
            rules={[
              {
                required: true,
                message: "You must choose a name for the file status",
              },
            ]}
          >
            <Input data-cy="file-status-name-input" />
          </Form.Item>

          <Form.Item label="Skip internal review" name="canSkipReview" valuePropName="checked">
            <Checkbox />
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading} data-cy="create-file-status-button">
              Submit
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}

export default withSubscriptions({
  Component: CreateFileStatusModal,
  subscriptions: ["organisationDetails"],
});
