import { useState } from "react";
import moment from "moment";
import { Table, Button, message, Modal } from "antd";
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { getLabel } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { isAuthorised } from "common/permissions";

import Textarea from "Form/Textarea/Textarea";
import Card from "Card/Card";
import Switch from "Switch/Switch";
import RichTextDisplay from "RichTextDisplay/RichTextDisplay";
import ActivityItemPDFButton from "ActivityItemPDFButton/ActivityItemPDFButton";

import "./QuoteActivity.scss";

export default function QuoteActivity({ organisationDetails, quote, users, apiUser }) {
  const [includeTotalChanges, setIncludeTotalChanges] = useState(false);
  const [includeReviewActivity, setIncludeReviewActivity] = useState(false);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [commentAreaKey, setCommentAreaKey] = useState(0);
  const [selectedItemIdForEditing, setSelectedItemIdForEditing] = useState();
  const [initialCommentValue, setInitialCommentValue] = useState();

  let columns = [
    {
      title: "Title",
      dataIndex: "content",
      key: "content",
      width: 350,
      align: "left",
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
      width: 170,
    },
    organisationDetails.settings?.general?.hideFinancials && !isAuthorised(["QUOTES.WRITE_AMOUNT"])
      ? undefined
      : {
          title: `${getLabel({ id: "Quote", defaultValue: "Quote" })} total`,
          dataIndex: "totalFormatted",
          key: "total",
          width: 100,
        },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 170,
    },
    {
      title: "Download PDF",
      dataIndex: "download",
      key: "download",
      width: 100,
      render: (_, record) => {
        return <ActivityItemPDFButton parent={quote} activityItem={record} />;
      },
    },
  ].filter((column) => column);

  async function updateComment(activityItem) {
    await callGraphQLSimple({
      message: "Failed to update comment",
      mutation: "updateQuoteActivityItem",
      variables: {
        input: {
          id: activityItem.id,
          content: commentValue,
        },
      },
    });
    setSelectedItemIdForEditing(undefined);

    await callGraphQLSimple({
      displayError: false,
      queryCustom: "updateQuote",
      variables: {
        input: {
          id: quote.id,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      },
    });
  }

  function confirmDeleteComment(activityItem) {
    Modal.confirm({
      title: "Are you sure you want to delete this comment?",
      content: "This action cannot be undone.",
      onOk: async () => {
        await callGraphQLSimple({
          message: "Failed to delete comment",
          mutation: "deleteQuoteActivityItem",
          variables: {
            input: {
              id: activityItem.id,
            },
          },
        });

        await callGraphQLSimple({
          displayError: false,
          queryCustom: "updateQuote",
          variables: {
            input: {
              id: quote.id,
              itemSubscription: Math.floor(Math.random() * 100000),
            },
          },
        });
      },
    });
  }
  return (
    <Card
      title={`${getLabel({ id: "Quote", defaultValue: "Quote" })} activity`}
      className="quote-activity-card"
      flexTitle
      wrapHeader
      actions={
        <div className="activity-filters">
          <div className="quote-filter-item">
            <Switch
              data-cy="switch-display-review-activity"
              checked={includeReviewActivity}
              onChange={(checked) => {
                setIncludeReviewActivity(checked);
              }}
              label="Display review activity"
            />
          </div>
          {(!organisationDetails.settings?.general?.hideFinancials || isAuthorised(["QUOTES.WRITE_AMOUNT"])) && (
            <div className="quote-filter-item">
              <Switch
                data-cy="switch-display-changes-to-total"
                checked={includeTotalChanges}
                onChange={(checked) => {
                  setIncludeTotalChanges(checked);
                }}
                label="Display changes to the total"
              />
            </div>
          )}

          <Button
            type="primary"
            className="add-comment-button"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setIsAddingComment(true);
            }}
          >
            Add comment
          </Button>

          {isAddingComment && (
            <Modal
              open={true}
              onOk={async () => {
                await callGraphQLSimple({
                  message: "Failed to add comment",
                  mutation: "createQuoteActivityItem",
                  variables: {
                    input: {
                      type: "COMMENT",
                      quoteId: quote.id,
                      total: quote.total,
                      organisation: quote.organisation,
                      content: commentValue,
                      author: apiUser.id,
                    },
                  },
                });
                message.success("Comment added");
                setIsAddingComment(false);
                setCommentAreaKey((commentAreaKey) => commentAreaKey + 1);

                await callGraphQLSimple({
                  displayError: false,
                  queryCustom: "updateQuote",
                  variables: {
                    input: {
                      id: quote.id,
                      itemSubscription: Math.floor(Math.random() * 100000),
                    },
                  },
                });
              }}
              title="Add comment"
              onCancel={() => setIsAddingComment(false)}
            >
              <div key={commentAreaKey}>
                <Textarea
                  onChange={(value) => {
                    setCommentValue(value);
                  }}
                  basicFormattingOnly
                />
              </div>
            </Modal>
          )}
        </div>
      }
    >
      <Table
        pagination={{ hideOnSinglePage: true, pageSize: 500 }}
        tableLayout="fixed"
        bordered={true}
        scroll={{ x: 800 }}
        columns={columns}
        dataSource={[...quote.activity.items]
          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          .filter((activityItem) => {
            if (activityItem.type === "TOTAL_CHANGED") {
              return includeTotalChanges;
            } else if (activityItem.type.startsWith("REVIEW")) {
              return includeReviewActivity;
            }
            return true;
          })
          .map((activityItem) => {
            let content = activityItem.content;

            let parsedContent;
            try {
              parsedContent = JSON.parse(content);
            } catch (e) {
              // nothing;
            }

            switch (activityItem.type) {
              case "QUOTE_LINE_ITEM_ACCEPTED":
                if (parsedContent) {
                  content = `Line item accepted: ${parsedContent.quoteLineItemTitle}`;
                }
                break;
              case "QUOTE_LINE_ITEM_REJECTED":
                if (parsedContent) {
                  content = `Line item rejected: ${parsedContent.quoteLineItemTitle}`;
                }
                break;

              case "TOTAL_CHANGED":
                content = "Total changed";
                break;
              case "REVISION_CREATED":
                content = `Revision added: ${content}`;
                break;
              case "IN_PROGRESS":
                content = `${getLabel({
                  id: "Quote",
                  defaultValue: "Quote",
                })} is back in progress`;
                break;
              case "DRAFT":
                content = `${getLabel({
                  id: "Quote",
                  defaultValue: "Quote",
                })} is now a draft`;
                break;
              case "COMMENT":
                content = (
                  <div className="comment-content">
                    {activityItem.author === apiUser.id && (
                      <div className="comment-actions">
                        <>
                          <Button
                            icon={<EditOutlined />}
                            onClick={() => {
                              setSelectedItemIdForEditing(activityItem.id);

                              try {
                                let parsedContent = JSON.parse(activityItem.content);
                                setInitialCommentValue(parsedContent);
                              } catch (error) {
                                setInitialCommentValue(undefined);
                              }
                            }}
                            type="link"
                            className="edit-comment-button"
                          >
                            Edit
                          </Button>
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              confirmDeleteComment(activityItem);
                            }}
                            type="link"
                          >
                            Delete
                          </Button>
                        </>
                      </div>
                    )}
                    {selectedItemIdForEditing === activityItem.id ? (
                      <Modal
                        open={true}
                        onOk={() => updateComment(activityItem)}
                        title="Edit comment"
                        onCancel={() => setSelectedItemIdForEditing(undefined)}
                      >
                        <div className="comment-editor">
                          <Textarea
                            onChange={(value) => {
                              setCommentValue(value);
                            }}
                            defaultValue={initialCommentValue}
                            basicFormattingOnly
                          />
                        </div>
                      </Modal>
                    ) : (
                      <>
                        <div>Comment: </div>
                        <RichTextDisplay content={activityItem.content} />
                      </>
                    )}
                  </div>
                );
              default:
                if (!content) {
                  content = `${getLabel({
                    id: "Quote",
                    defaultValue: "Quote",
                  })} ${activityItem.type.toLowerCase()}`;
                }
                break;
            }

            let author;
            if (activityItem.author) {
              let authorDetails = users?.find((user) => user.id === activityItem.author);
              if (!authorDetails) {
                author = activityItem.author;
              } else {
                author = `${authorDetails.firstName} ${authorDetails.lastName}`;
              }
            }
            return {
              ...activityItem,
              content,
              author,
              dateAgo: `${moment(activityItem.createdAt).fromNow()}`,
              date: moment(activityItem.createdAt).format("DD MMM YYYY - HH:mm:ss"),
              totalFormatted: activityItem.total === null ? null : window.formatCurrency("GBP", activityItem.total),
            };
          })}
      />
    </Card>
  );
}
