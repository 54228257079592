import { Space, Form, Modal, Button, Select, Input, Checkbox, InputNumber } from "antd";

import { useForceUpdate } from "common/helpers";
import AttachmentPickerForNestedFieldList from "Form/AttachmentPickerForNestedFieldList/AttachmentPickerForNestedFieldList";

import "./ReportUserListModal.scss";

export default function ReportUserListModal({ onClose, field, onSubmit, initialValues, ...props }) {
  const [form] = Form.useForm();
  const forceUpdate = useForceUpdate();

  async function onFormSubmit(item) {
    onSubmit(item);
    form.resetFields();
    onClose();
  }

  function displayFormItem(key) {
    const formItemData = field.fields[key];
    let style = {};
    if (formItemData.hasOwnProperty("width")) {
      style.width = formItemData.width;
    }
    const commonProps = {
      style,
      placeholder: formItemData.placeholder,
    };

    switch (formItemData.type) {
      case "number":
        return <InputNumber {...commonProps} />;
      case "textfield":
        return <Input {...commonProps} />;
      case "textarea":
        return <Input.TextArea {...commonProps} autoSize={{ minRows: 3 }} />;
      case "checkbox-list":
        return <Checkbox.Group {...commonProps} options={formItemData.options} />;
      case "dropdown":
        return (
          <Select {...commonProps}>
            {formItemData.options.map((option, i) => {
              return (
                <Select.Option value={option.value} key={option.value}>
                  {option.label}
                </Select.Option>
              );
            })}
          </Select>
        );
      case "attachmentPicker":
        return (
          <AttachmentPickerForNestedFieldList
            {...props}
            {...commonProps}
            formItemName={key}
            formItemData={formItemData}
            onChange={(attachments, dates, sizes) => {
              form.setFieldsValue({
                [key]: attachments,
              });
              forceUpdate();
            }}
          />
        );

      default:
        return null;
    }
  }

  let sortedFieldKeys = Object.keys(field.fields).sort(
    (a, b) => field.fields[a].adminOrder - field.fields[b].adminOrder
  );

  let modalTitle = null;
  if (!initialValues) {
    modalTitle = `Add ${field.label} item`;
  } else {
    modalTitle = `Edit ${field.label} item`;
  }

  return (
    <Modal
      maskClosable={false}
      title={modalTitle}
      open={true}
      onOk={onFormSubmit}
      onCancel={onClose}
      footer={null}
      className="report-user-list-modal full-screen-on-mobile"
    >
      <Form layout="vertical" form={form} initialValues={initialValues} onFinish={onFormSubmit}>
        <Space direction="vertical" style={{ width: "100%" }}>
          {sortedFieldKeys.map((key, i) => {
            return (
              <Form.Item
                key={i}
                label={field.fields[key].shortLabel || field.fields[key].label}
                name={key}
                className={`report-user-list-${key}`}
                rules={[
                  {
                    required: field.fields[key].required === false ? false : true,
                    message: `${field.fields[key].shortLabel || field.fields[key].label} is required`,
                  },
                ]}
              >
                {displayFormItem(key)}
              </Form.Item>
            );
          })}

          <div className="submit-container">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}
