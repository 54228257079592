import React from "react";
import { Modal, Typography, Checkbox, Button, Input as AntInput } from "antd";
import { CheckCircleOutlined, EditOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

import FormInputLabel from "Form/FormInputLabel/FormInputLabel";

function FormCheckboxList({
  fieldData,
  fieldName,
  nestedFieldParams,
  readOnly,
  updateFieldValue,
  refreshKey,
  topLevelParams,
}) {
  const { fieldUnderEditName, fieldUnderEditValue, isFormEditor, setFormState, form, updateFormContent } =
    topLevelParams;

  function removeCheckboxOption({ optionIndex }) {
    const newFields = { ...form.fields };
    newFields[fieldUnderEditName].options.splice(optionIndex, 1);

    updateFormContent({
      ...form,
      fields: newFields,
    });
  }

  function openCheckboxOptionAddModal() {
    const modal = Modal.info({
      title: "Option value:",
      icon: null,
      className: "add-report-field-option-modal",
      maskClosable: true,
      content: (
        <AntInput
          onChange={(e) => {
            setFormState({ fieldUnderEditValue: e.target.value });
          }}
        />
      ),
      onOk: () => {
        addCheckboxOption({
          option: {
            label: fieldUnderEditValue,
            value: fieldUnderEditValue,
          },
        });
        modal.destroy();
      },
    });
  }

  function addCheckboxOption({ option }) {
    const newFields = { ...form.fields };
    newFields[fieldUnderEditName].options.push(option);

    updateFormContent({
      ...form,
      fields: newFields,
    });
  }

  let style = {
    width: "100%",
  };
  if (fieldData.hasOwnProperty("width")) {
    style.width = fieldData.width;
  }

  const fieldIsUnderEdit = fieldUnderEditName === fieldName;

  let editOptionsButton = null;
  let fieldContent = null;

  if (fieldData.useDynamicOptions && isFormEditor) {
    fieldContent = (
      <Typography.Text style={{ opacity: 0.7, fontStyle: "italic" }}>
        This checkbox list uses dynamic options. The options will only appear when you create a document from this
        template
      </Typography.Text>
    );
  } else {
    if (!fieldData.useDynamicOptions && !readOnly && fieldData.editable !== false) {
      editOptionsButton = (
        <Button
          icon={fieldIsUnderEdit ? <CheckCircleOutlined /> : <EditOutlined />}
          type="clear"
          onClick={() => {
            setFormState({
              fieldUnderEditName: fieldIsUnderEdit ? null : fieldName,
            });
          }}
        >
          {fieldIsUnderEdit ? "Done" : "Edit options"}
        </Button>
      );
    }

    if (fieldIsUnderEdit) {
      fieldContent = (
        <>
          {Array.isArray(fieldData.options) &&
            fieldData.options.map((option, i) => (
              <div className="editable-option-item" key={i}>
                <Button
                  icon={<DeleteOutlined />}
                  disabled={readOnly}
                  onClick={() => {
                    removeCheckboxOption({ optionIndex: i });
                  }}
                />
                <Typography.Text>{option.label}</Typography.Text>
              </div>
            ))}

          <Button
            style={{ display: "inline-block", marginTop: 10 }}
            icon={<PlusCircleOutlined />}
            disabled={readOnly}
            type="primary"
            onClick={openCheckboxOptionAddModal()}
          >
            Add option
          </Button>
        </>
      );
    } else {
      if (fieldData.options && fieldData.options.length > 0) {
        let options = fieldData.options.filter(
          (option) => Object.keys(option).length !== 0 && option.label && option.value
        );
        fieldContent = (
          <Checkbox.Group
            disabled={readOnly}
            onChange={(value) => {
              updateFieldValue({
                fieldName,
                value,
                nestedFieldParams,
              });
            }}
            options={options}
            defaultValue={fieldData.value}
          />
        );
      } else {
        fieldContent = (
          <Typography.Text style={{ opacity: 0.7, fontStyle: "italic" }}>No options available</Typography.Text>
        );
      }
    }
  }

  return (
    <div className="input-group" key={`${fieldName}-checkbox-list-${refreshKey}`}>
      <FormInputLabel fieldData={fieldData} fieldName={fieldName} extraContent={editOptionsButton} />

      {fieldContent}
    </div>
  );
}

export default React.memo(FormCheckboxList, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["readOnly", "refreshKey", "validationErrors", "hiddenFormFieldKeys"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps.topLevelParams[propName] !== nextProps.topLevelParams[propName]) {
      return false;
    }
  }

  return true;
});
