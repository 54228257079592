import cx from "classnames";

import { getFieldsToDisplay, getSortedAndNotHiddenFieldNames } from "./formHelpers";

import FormFieldEditorOverlay from "Form/FormFieldEditorOverlay/FormFieldEditorOverlay";
import FormTextField from "Form/FormTextField/FormTextField";
import FormDropdown from "Form/FormDropdown/FormDropdown";
import FormTextarea from "Form/FormTextarea/FormTextarea";
import FormSeparator from "Form/FormSeparator/FormSeparator";
import FormSection from "Form/FormSection/FormSection";
import FormRadioButtonList from "Form/FormRadioButtonList/FormRadioButtonList";
import FormNumericalInput from "Form/FormNumericalInput/FormNumericalInput";
import FormCheckboxList from "Form/FormCheckboxList/FormCheckboxList";
import FormButton from "Form/FormButton/FormButton";
import FormSectionHeading from "Form/FormSectionHeading/FormSectionHeading";
import FormCheckbox from "Form/FormCheckbox/FormCheckbox";
import FormDatePicker from "Form/FormDatePicker/FormDatePicker";
import FormDateRangePicker from "Form/FormDateRangePicker/FormDateRangePicker";
import FormAttachmentPicker from "Form/FormAttachmentPicker/FormAttachmentPicker";
import FormAttachmentUploader from "Form/FormAttachmentUploader/FormAttachmentUploader";
import FormSpreadsheet from "Form/FormSpreadsheet/FormSpreadsheet";
import FormProjectPicker from "Form/FormProjectPicker/FormProjectPicker";

export default function displayFields(params) {
  const {
    topLevelParams,
    showHiddenByModal,
    showNestedFieldsInModal,
    sectionNameToDisplayFieldsFor,
    onFieldEdit,
    onFieldDelete,
    changeFieldOrder,
    rootField,
    rootFieldName,
    childField,
    childIndex,
  } = params || {};

  const {
    selectedNestedContainerField,
    subFieldUnderEditIndex,
    modalWithFieldsName,
    hiddenFormFields,
    form,
    isFormEditor,
    readOnly,
    updateFieldValue,
    contextForComputations,
    refreshKey,
    setFormState,
  } = topLevelParams;

  if (!form) {
    return null;
  }

  const fieldsToDisplay = getFieldsToDisplay({
    form,
    selectedNestedContainerField,
    showNestedFieldsInModal,
    subFieldUnderEditIndex,
    rootField,
    childField,
  });

  const { sortedFieldNames, sortedNotHiddenFieldNames } = getSortedAndNotHiddenFieldNames({
    fieldsToDisplay,
    sectionNameToDisplayFieldsFor,
    showHiddenByModal,
    modalWithFieldsName,
    hiddenFormFields,
    onFieldEdit,
    isFormEditor,
  });

  let fieldElements = sortedNotHiddenFieldNames.map((fieldName, i) => {
    const fieldData = fieldsToDisplay[fieldName];

    let fieldParams = {
      fieldData,
      fieldName,
      isFormEditor,
      topLevelParams,
      readOnly,
      updateFieldValue,
      contextForComputations,
      refreshKey,
      editorParams: {
        onFieldEdit,
        onFieldDelete,
        changeFieldOrder,
      },
      nestedFieldParams: {
        rootField,
        rootFieldName,
        childField,
        childIndex,
      },
      showNestedFieldsInModal,
      sortedFieldNames,
      sectionNameToDisplayFieldsFor,
    };

    let field = null;

    switch (fieldData.type) {
      case "nestedFieldListWithModal":
        // field = displayNestedFieldListWithModal.call(this, fieldParams);
        break;
      case "nestedFieldListNoModal":
        // field = displayNestedFieldListNoModal.call(this, fieldParams);
        break;
      case "dynamicSectionList":
        // field = displayDynamicSectionList.call(this, fieldParams);
        break;

      case "section-heading":
        field = <FormSectionHeading {...fieldParams} />;
        break;
      case "section":
        field = <FormSection {...fieldParams} />;
        break;
      case "separator":
        field = <FormSeparator {...fieldParams} />;
        break;
      case "textarea":
        field = <FormTextarea {...fieldParams} />;
        break;
      case "textfield":
        field = <FormTextField {...fieldParams} />;
        break;
      case "number":
        field = <FormNumericalInput {...fieldParams} />;
        break;
      case "date-picker":
        field = <FormDatePicker {...fieldParams} />;
        break;
      case "date-range-picker":
        field = <FormDateRangePicker {...fieldParams} />;
        break;
      case "dropdown":
        field = <FormDropdown {...fieldParams} />;
        break;
      case "checkbox-list":
        field = <FormCheckboxList {...fieldParams} />;
        break;
      case "checkbox":
        field = <FormCheckbox {...fieldParams} />;
        break;
      case "radio-list":
        field = <FormRadioButtonList {...fieldParams} />;
        break;
      case "attachmentPicker":
        field = <FormAttachmentPicker {...fieldParams} />;
        break;
      case "attachmentUploader":
        field = <FormAttachmentUploader {...fieldParams} />;
        break;
      case "taskFilePicker":
        // field = displayTaskFilePicker.call(this, fieldParams);
        break;
      case "modalWithFields":
        // field = displayModalWithFieldsInput.call(this, fieldParams);
        break;
      case "spreadsheet":
        field = <FormSpreadsheet {...fieldParams} />;
        break;
      case "button":
        field = <FormButton {...fieldParams} />;
        break;
      case "projectPicker":
        field = <FormProjectPicker {...fieldParams} />;
        break;
      default:
        console.error("unknown field type:", fieldData.type, fieldParams);
        field = null;
        break;
    }

    return (
      <div className={cx("report-field-container", `field-type-${fieldData.type}`)} key={fieldName}>
        {isFormEditor && (
          <FormFieldEditorOverlay
            {...fieldParams}
            setFormState={setFormState}
            fieldsToDisplay={fieldsToDisplay}
            i={i}
          />
        )}
        {field}
      </div>
    );
  });

  return <div className="form">{fieldElements}</div>;
}
