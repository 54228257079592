// old version of the component, before the refactoring of the form was done

import { useGetSetState } from "react-use";
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { getSimpleLabel } from "common/labels";

import ProjectPicker from "ProjectPicker/ProjectPicker";
import CreateProjectModal from "CreateProjectModal/CreateProjectModal";

import "./ProjectPickerForm.scss";

// old version of the component, before the refactoring of the form was done

export default function ProjectPickerForm({
  apiUser,
  fieldName,
  fieldData,
  disabled,
  nestedFieldParams,
  updateFieldValue,
  windowWidth,
}) {
  const [getState, setState] = useGetSetState({
    isCreateProjectModalOpen: false,
  });

  const { isCreateProjectModalOpen } = getState();

  return (
    <div className="project-picker-form">
      <ProjectPicker
        value={fieldData.value}
        disabled={disabled}
        onChange={(value) => {
          updateFieldValue.call(this, {
            fieldName,
            value,
            nestedFieldParams,
          });
        }}
      />
      <Button
        type="primary"
        className="create-project"
        onClick={() => setState({ isCreateProjectModalOpen: true })}
        disabled={disabled}
      >
        <PlusCircleOutlined /> Create {windowWidth > 600 && getSimpleLabel("project")}
      </Button>
      {isCreateProjectModalOpen && (
        <CreateProjectModal
          apiUser={apiUser}
          organisation={apiUser.organisation}
          onClose={() => setState({ isCreateProjectModalOpen: false })}
          predefinedFields={{
            clientId: fieldData.predefinedClientId,
          }}
          onSave={(project) => {
            updateFieldValue.call(this, {
              fieldName,
              value: project.id,
              nestedFieldParams,
            });
          }}
        />
      )}
    </div>
  );
}
