import { Button } from "antd";
import { CopyOutlined, DeleteOutlined, DownOutlined, EditOutlined, UpOutlined } from "@ant-design/icons";

export default function FormFieldEditorOverlay(props) {
  const {
    form,
    onFieldEdit,
    onFieldDelete,
    changeFieldOrder,
    fieldData,
    fieldName,
    fieldsToDisplay,
    setFormState,
    selectedNestedContainerField,
    showNestedFieldsInModal,
    modalWithFieldsName,
    sortedFieldNames,
    sectionNameToDisplayFieldsFor,
    i,
  } = props;

  function onEditFieldsClick() {
    let newState = {
      isModalWithFieldsVisible: true,
    };

    switch (fieldData.type) {
      case "modalWithFields":
        newState = {
          ...newState,
          modalWithFieldsName: fieldName,
          selectedModalContainerField: fieldData,
        };
        break;
      case "nestedFieldListWithModal":
      case "nestedFieldListNoModal":
        newState = {
          ...newState,
          isNestedFieldModalOpen: true,
          selectedNestedContainerField: fieldData,
        };
        break;
      case "section":
        newState = {
          ...newState,
          modalWithSectionFieldsName: fieldName,
          selectedSectionContainerField: fieldData,
        };
        break;
      case "dynamicSectionList":
        let placeholderSection;
        for (let crtFieldName in fieldsToDisplay) {
          let crtFieldDetails = fieldsToDisplay[crtFieldName];
          if (crtFieldDetails.isPlaceholderForDynamicSectionList === fieldName) {
            placeholderSection = crtFieldDetails;
            break;
          }
        }
        newState = {
          ...newState,
          modalWithSectionFieldsName: placeholderSection.id,
          selectedSectionContainerField: placeholderSection,
        };
        break;
      default:
        break;
    }

    setFormState(newState);
  }

  function displayEditFieldsButton() {
    if (
      ![
        "nestedFieldListWithModal",
        "nestedFieldListNoModal",
        "modalWithFields",
        "section",
        "dynamicSectionList",
      ].includes(fieldData.type)
    ) {
      return null;
    }

    return (
      <Button type="dark" icon={<EditOutlined />} onClick={onEditFieldsClick}>
        Edit fields
      </Button>
    );
  }

  return (
    <div className="form-field-edit-overlay">
      <div className="overlay-buttons-container">
        {displayEditFieldsButton()}

        {!fieldData.isPlaceholderForDynamicSectionList && (
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              onFieldEdit({ fieldData });
            }}
          />
        )}
        {!fieldData.isPlaceholderForDynamicSectionList && (
          <Button
            icon={<CopyOutlined />}
            onClick={() => {
              onFieldEdit({ fieldData, duplicate: true });
            }}
          />
        )}

        {!(selectedNestedContainerField && showNestedFieldsInModal) && (
          <>
            <Button
              disabled={i === 0}
              icon={<UpOutlined />}
              onClick={() => {
                let delta = -1;
                let skipFieldsWithParent = true;
                if (modalWithFieldsName || sectionNameToDisplayFieldsFor) {
                  skipFieldsWithParent = false;
                  const previousFieldName = sortedFieldNames[i + delta];
                  if (previousFieldName) {
                    const previousField = form.fields[previousFieldName];
                    if (previousField) {
                      delta = previousField.adminOrder - fieldData.adminOrder;
                    }
                  }
                }

                changeFieldOrder({
                  fieldData,
                  delta,
                  skipFieldsWithParent,
                });
              }}
            >
              1
            </Button>
            <Button
              disabled={i === 0}
              icon={<UpOutlined />}
              onClick={() => {
                let delta = -5;
                let skipFieldsWithParent = true;
                if (modalWithFieldsName || sectionNameToDisplayFieldsFor) {
                  skipFieldsWithParent = false;
                  const previousFieldName = sortedFieldNames[i + delta];
                  if (previousFieldName) {
                    const previousField = form.fields[previousFieldName];
                    if (previousField) {
                      delta = previousField.adminOrder - fieldData.adminOrder;
                    }
                  }
                }

                changeFieldOrder({
                  fieldData,
                  delta,
                  skipFieldsWithParent,
                });
              }}
            >
              5
            </Button>

            <Button
              disabled={i >= sortedFieldNames.length - 1}
              icon={<DownOutlined />}
              onClick={() => {
                let delta = 1;
                let skipFieldsWithParent = true;
                if (modalWithFieldsName || sectionNameToDisplayFieldsFor) {
                  skipFieldsWithParent = false;
                  const nextFieldName = sortedFieldNames[i + delta];
                  if (nextFieldName) {
                    const nextField = form.fields[nextFieldName];
                    if (nextField) {
                      delta = nextField.adminOrder - fieldData.adminOrder;
                    }
                  }
                }

                changeFieldOrder({
                  fieldData,
                  delta,
                  skipFieldsWithParent,
                });
              }}
            />
          </>
        )}

        {!fieldData.isPlaceholderForDynamicSectionList && (
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              onFieldDelete({ fieldData });
            }}
          />
        )}
      </div>
    </div>
  );
}
