import React from "react";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { Text, View, Image, Document } from "@react-pdf/renderer";

import { callGraphQLSimple } from "common/apiHelpers";
import getS3File from "common/getS3File";
import { getLatestFileVersion } from "common/shared";
import { initialiseLambdaPdfInserts } from "common/documentRenderHelpers";

import Page from "ReportPage/Report/ReportPdfPage";
import ReportPreview from "ReportPage/ReportPreview";
import OrganisationLogo from "./awd-logo.png";
import { PageFooter, colors, styles } from "./AWD_CommonReportComponents";
import { Table } from "ReportPage/PdfTable";

import "./AWD_Report.scss";

export class ReportDocumentRegister extends React.Component {
  state = {
    isLoading: true,
    designersRiskAssessmentImageUrl: null,
    attachmentImages: null,
    defaultRiskAssessmentReference: null,
  };

  async componentDidMount() {
    let newState = {};
    const reportFile = this.props.file;
    // if we don't receive the preview data, we load it ourselves
    // this is useful on pages that don't already have that data to pass it down to us
    if (!this.props.previewData) {
      newState.reportJsonData = await this.loadForm({
        file: reportFile,
      });
    } else {
      newState.reportJsonData = this.props.previewData;
    }

    const { taskRevision } = this.props;
    let filePromises = taskRevision.files.items
      .filter((file) => file.type !== "REPORT")
      .map((file) =>
        callGraphQLSimple({
          message: "Failed to update job title",
          queryName: "getFile",
          variables: {
            id: file.id,
          },
        })
      );

    newState.files = (await Promise.all(filePromises)).map((getFileResponse) => getFileResponse.data.getFile);

    try {
      this.setState({
        ...newState,
        isLoading: false,
      });
    } catch (e) {
      // nothing, we already show an error message in the function which actually failed
    }
  }

  async componentDidUpdate(prevProps) {
    // if we don't have this block of code, the preview won't update when the user changes something
    // only used on the report page
    if (this.props.previewData !== prevProps.previewData) {
      try {
        this.setState({
          reportJsonData: this.props.previewData,
          isLoading: false,
        });
      } catch (e) {
        // nothing, we already show an error message in the function which actually failed
      }
    }
  }

  loadForm = async ({ file }) => {
    const fileKey = getLatestFileVersion(file).key.replace("public/", "");
    const filePublicURL = await getS3File(fileKey);
    const form = (await axios.get(filePublicURL)).data;
    return form;
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.isStatic && nextState.isLoading === this.state.isLoading && this.props.zoom === nextProps.zoom) {
      return false;
    }
    return true;
  };

  displayDocument = () => {
    const { task, users, taskRevision } = this.props;
    const { isLoading, files } = this.state;

    window.pdfPageNumbers = {};
    window.pdfPageNumbersToDownload = {};

    window.lambdaPdfAssets = [];

    if (!task || !users || !taskRevision || isLoading) {
      return (
        <div className="report-preloader">
          <LoadingOutlined />
        </div>
      );
    }

    const REGISTER_COLUMNS = [
      {
        title: "",
        dataIndex: "index",
        key: "index",
        width: "33",
      },
      {
        title: "",
        dataIndex: "label",
        key: "label",
        width: "150",
      },
    ];
    const DOCUMENT_TRANSMITTAL_COLUMNS = [
      {
        title: "",
        dataIndex: "label",
        key: "label",
        width: "183",
      },
    ];

    const DISTRIBUTION_COLUMNS = [
      {
        title: "",
        dataIndex: "label",
        key: "label",
        width: "183",
      },
    ];

    let sheets = [];
    files.forEach((file) => {
      if (file.type === "EXCEL") {
        sheets.push({ ...file.sheets.items[0], name: "Design Calculations" });
      } else {
        sheets.push(...file.sheets.items);
      }
    });

    sheets = sheets.filter((sheet) => !sheet.excludeFromRegister);

    let dates = [];

    sheets.forEach((sheet) => {
      sheet.revisions.items.forEach((revision) => {
        let formattedDate = moment(revision.reviewAcceptDate || revision.createdAt).format("DD-MM-YYYY");
        if (!dates.includes(formattedDate)) {
          dates.push(formattedDate);
        }
      });
    });

    dates = [...dates].sort((a, b) => (moment(a, "DD-MM-YYYY") < moment(b, "DD-MM-YYYY") ? -1 : 1));

    dates.forEach((date) => {
      REGISTER_COLUMNS.push({
        title: "",
        dataIndex: date,
        key: date,
        width: 25,
      });

      DOCUMENT_TRANSMITTAL_COLUMNS.push({
        title: "",
        dataIndex: date,
        key: date,
        width: 25,
      });
      DISTRIBUTION_COLUMNS.push({
        title: "",
        dataIndex: date,
        key: date,
        width: 25,
      });
    });

    let registerRows = [...sheets]
      .sort((a, b) => (a.description < b.description ? -1 : 1))
      .map((sheet, index) => {
        const rowData = {
          index: sheet.description,
          label: sheet.name,
        };
        dates.forEach((date) => {
          let targetRevisionName = "-";

          let targetRevision = sheet.revisions.items.find(
            (revision) => moment(revision.reviewAcceptDate || revision.createdAt).format("DD-MM-YYYY") === date
          );

          if (targetRevision) {
            targetRevisionName = targetRevision.name;
          }
          rowData[date] = targetRevisionName;
        });
        return rowData;
      });

    let transmittalRows = [
      {
        label: "Day",
      },
      {
        label: "Month",
      },
      {
        label: "Year",
      },
    ];
    let distributionRows = [
      {
        label: task.client.name,
      },
    ];
    dates.forEach((date, index) => {
      let dateParts = date.split("-");
      transmittalRows[0][date] = dateParts[0];
      transmittalRows[1][date] = dateParts[1];
      transmittalRows[2][date] = dateParts[2].substring(2);
      distributionRows[0][date] = index + 1;
    });

    const getCellStyleDocumentRegister = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 1 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: REGISTER_COLUMNS[col].width,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: row === 0 ? colors.gray : colors.gray,
        padding: col === 1 ? "1pt 5pt" : "1pt",
        fontSize: 10,
        textAlign: col === 1 ? "left" : "center",
      };

      let specificStyle = {
        backgroundColor: row === 0 ? colors.accentColorDark : "#fff",
        color: row === 0 ? "#fff" : "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };
    const getCellStyleDocumentTransmittal = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 1 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: DOCUMENT_TRANSMITTAL_COLUMNS[col].width,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: row === 0 ? colors.gray : colors.gray,
        padding: col === 0 ? "1pt 5pt" : "1pt",
        fontSize: 10,
        textAlign: col === 0 ? "right" : "center",
      };

      let specificStyle = {
        backgroundColor: row === 0 ? colors.accentColorDark : "#fff",
        color: row === 0 ? "#fff" : "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    return (
      <Document>
        <Page size="A4" style={styles.page} wrap>
          <Text
            render={() => {
              initialiseLambdaPdfInserts();
              return null;
            }}
            fixed
          />
          <View>
            <Image src={OrganisationLogo} style={styles.organisationLogo} />

            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Document Register & Transmittal Record</Text>
            </Text>
            <Text style={{ ...styles.pageSubtitle, fontFamily: "Brandon" }}>
              Project No.: <Text style={styles.bold}>{task.projectId} </Text>
            </Text>
            <Text style={{ ...styles.pageSubtitle, fontFamily: "Brandon" }}>
              Task No.: <Text style={styles.bold}>{task.id}</Text>
            </Text>
            <Text style={{ ...styles.pageSubtitle, fontFamily: "Brandon" }}>
              Client name: <Text style={styles.bold}>{task.client.name} </Text>
            </Text>
            <Text style={{ ...styles.pageSubtitle, fontFamily: "Brandon" }}>
              Project title: <Text style={styles.bold}>{task.project.title} </Text>
            </Text>
            <Text style={{ ...styles.pageSubtitle, fontFamily: "Brandon" }}>
              Task title: <Text style={styles.bold}>{task.title}</Text>
            </Text>

            <Text style={{ ...styles.pageSubtitle, fontFamily: "Brandon" }}>
              Date: <Text style={styles.bold}>{moment().format("DD-MMMM-YYYY").split("-").join(" ")}</Text>
            </Text>
          </View>
          <View>
            <Text
              style={{
                ...styles.bold,
                marginTop: 20,
                marginBottom: 2,
              }}
            >
              Document Register
            </Text>
            <Table
              columns={REGISTER_COLUMNS}
              data={registerRows}
              includeHeader={false}
              style_function={getCellStyleDocumentRegister}
            />
          </View>
          <Text style={{ ...styles.bold, marginBottom: 2, marginTop: 7 }}>Document Transmittal</Text>
          <View>
            <Table
              columns={DOCUMENT_TRANSMITTAL_COLUMNS}
              data={transmittalRows}
              includeHeader={false}
              style_function={getCellStyleDocumentTransmittal}
            />
          </View>
          {/* <Text style={{ ...styles.bold, marginBottom: 2, marginTop: 7 }}>Distribution</Text>
          <View>
            <Table
              columns={DISTRIBUTION_COLUMNS}
              data={distributionRows}
              includeHeader={false}
              style_function={getCellStyleDocumentDistribution}
            />
          </View> */}
          <PageFooter pageColor="white" includeTemplateDetails colors={colors} styles={styles} />
        </Page>
      </Document>
    );
  };

  render() {
    const { task, users, taskRevision, layout = "default", renderMode } = this.props;
    const { isLoading } = this.state;

    window.pdfPageNumbers = {};
    window.pdfPageNumbersToDownload = {};

    if (!task || !users || !taskRevision || isLoading) {
      return (
        <div className="report-preloader">
          <LoadingOutlined />
        </div>
      );
    }

    return (
      <div className="report report-AEC">
        <ReportPreview
          document={this.displayDocument()}
          layout={layout}
          renderMode={renderMode}
          renderKey={JSON.stringify(this.state.reportJsonData)}
          onDataUri={this.props.onDataUri}
        />
      </div>
    );
  }
}

export default React.memo(ReportDocumentRegister);
