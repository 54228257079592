import React from "react";
import cx from "classnames";
import { Typography } from "antd";

import Textarea from "Form/Textarea/Textarea";
import FieldLabel from "Form/FieldLabel/FieldLabel";
import Explanation from "Explanation/Explanation";

function FormInputLabel({
  fieldData,
  fieldName,
  extraContent = null,
  onlyDisplayExplanation = false,
  documentPropsForTextarea,
  validationErrors,
  isFormEditor,
}) {
  let inlineDescriptionElement = null;
  let tooltipDescriptionElement = null;

  if (fieldData.hasInlineDescription && fieldData.inlineDescription) {
    let parsedInlineDescription;
    try {
      parsedInlineDescription = JSON.parse(fieldData.inlineDescription);
    } catch (e) {
      // nothing;
    }
    if (parsedInlineDescription) {
      inlineDescriptionElement = (
        <div className="field-inline-description">
          <Textarea
            memoize
            defaultValue={parsedInlineDescription}
            disabled
            documentProps={documentPropsForTextarea}
            extraToolbarButtons={null}
            basicFormattingOnly
          />
        </div>
      );
    }
  }

  if (fieldData.hasTooltipDescription && fieldData.tooltipDescription) {
    let parsedTooltipDescription;
    try {
      parsedTooltipDescription = JSON.parse(fieldData.tooltipDescription);
    } catch (e) {
      // nothing;
    }
    if (parsedTooltipDescription) {
      tooltipDescriptionElement = (
        <span className="field-tooltip-description">
          <Explanation
            overlayClassName="field-tooltip-description-overlay"
            title={
              <Textarea
                memoize
                defaultValue={parsedTooltipDescription}
                disabled
                documentProps={documentPropsForTextarea}
                extraToolbarButtons={null}
                basicFormattingOnly
              />
            }
          />
        </span>
      );
    }
  }

  return (
    <Typography.Paragraph
      className={cx("field-label", {
        "only-display-explanation": onlyDisplayExplanation,
      })}
    >
      {onlyDisplayExplanation ? (
        inlineDescriptionElement
      ) : (
        <>
          <Typography.Text className="field-name">
            <FieldLabel
              fieldData={fieldData}
              fieldName={fieldName}
              isFormEditor={isFormEditor}
              validationErrors={validationErrors}
            />
            {/* {displayLabel.call(this, { fieldData, fieldName })} */}
            {tooltipDescriptionElement ? <> {tooltipDescriptionElement}</> : null}
            {extraContent ? extraContent : null}
          </Typography.Text>
          {inlineDescriptionElement}
        </>
      )}
    </Typography.Paragraph>
  );
}

export default React.memo(FormInputLabel, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["extraContent"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps[propName] !== nextProps[propName]) {
      return false;
    }
  }

  return true;
});
