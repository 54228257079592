import { useState, useEffect } from "react";
import { Button, Modal, Input, message as antMessage } from "antd";
import moment from "moment";
import { EditOutlined, SendOutlined } from "@ant-design/icons";

import { getDetailsForFormAndTaskRevision } from "common/sharedRequestHelpers";
import { trimStringToLength } from "common/shared";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import { processIdForDisplay } from "common/helpers";
import { sendTaskFilesSentIssuedNotification } from "common/notificationHelpers";
import { calculateReadableSize } from "common/helpers";
import { extractEmailsFromSlateValue, recordContacts, fetchSeparateContacts } from "common/emailHelpers";

import InfoItem from "InfoItem/InfoItem";
import Attachments from "Attachments/Attachments";
import Textarea from "Form/Textarea/Textarea";

import "./SendTaskFilesModal.scss";

export default function SendTaskFilesModal({
  apiUser,
  task,
  project,
  client,
  onClose,
  taskRevisionId,
  activityItemsByRequest,
  publicUrlsForSending,
  sheetIdsForSending,
  organisationDetails,
  users,
}) {
  const [separateContacts, setSeparateContacts] = useState([]);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [isLoadingContacts, setIsLoadingContacts] = useState(true);

  useEffect(() => {
    fetchAndSetSeparateContacts();
  }, []);

  async function fetchAndSetSeparateContacts() {
    const separateContacts = await fetchSeparateContacts({ parentId: apiUser.organisation });
    setSeparateContacts(separateContacts);
    setIsLoadingContacts(false);

    let suggestionsFromSeparateContacts = separateContacts
      .filter((contact) => contact.email)
      .map((contact) => {
        let labelTopRow =
          contact.firstName || contact.lastName ? (
            <>
              <b>{`${contact.firstName} ${contact.lastName}`.trim()}</b>
              <br />
            </>
          ) : null;
        let labelBottomRow = contact.email;
        return {
          label: (
            <>
              {labelTopRow}
              {labelBottomRow}
            </>
          ),

          matchText: `${contact.firstName || ""} ${contact.lastName || ""} ${contact.email}`.trim().toLowerCase(),
          value: contact.email,
        };
      });
    let suggestionsFromClientContacts = client.contacts
      ?.filter((contact) => contact.email)
      .map((contact) => {
        let labelTopRow =
          contact.firstName || contact.lastName ? (
            <>
              <b>{`${contact.firstName} ${contact.lastName}`.trim()}</b>
              <br />
            </>
          ) : null;
        let labelBottomRow = contact.email;
        return {
          label: (
            <>
              {labelTopRow}
              {labelBottomRow}
            </>
          ),
          matchText: `${contact.firstName || ""} ${contact.lastName || ""} ${contact.email}`.trim().toLowerCase(),
          value: contact.email,
        };
      });
    let suggestionsFromUsers = users?.map((user) => {
      let labelTopRow =
        user.firstName || user.lastName ? (
          <>
            <b>{`${user.firstName} ${user.lastName}`.trim()}</b>
            <br />
          </>
        ) : null;
      let labelBottomRow = user.id;
      return {
        label: (
          <>
            {labelTopRow}
            {labelBottomRow}
          </>
        ),
        matchText: `${user.firstName || ""} ${user.lastName || ""} ${user.id}`.trim().toLowerCase(),
        value: user.id,
      };
    });
    let allSuggestions = [...(suggestionsFromClientContacts || [])];
    if (suggestionsFromSeparateContacts) {
      for (let separateContactSuggestion of suggestionsFromSeparateContacts) {
        let isDuplicate = false;
        for (let suggestion of allSuggestions) {
          if (suggestion.value === separateContactSuggestion.value) {
            isDuplicate = true;
            break;
          }
        }
        if (!isDuplicate) {
          allSuggestions.push(separateContactSuggestion);
        }
      }
    }

    if (suggestionsFromUsers) {
      for (let userSuggestion of suggestionsFromUsers) {
        let isDuplicate = false;
        for (let suggestion of allSuggestions) {
          if (suggestion.value === userSuggestion.value) {
            isDuplicate = true;
            break;
          }
        }
        if (!isDuplicate) {
          allSuggestions.push(userSuggestion);
        }
      }
    }

    setEmailSuggestions(
      allSuggestions
        .filter((suggestion) => suggestion.value)
        .sort((a, b) => (a.value.toLowerCase() < b.value.toLowerCase() ? -1 : 1))
    );
  }

  const taskRevisionDetails = task?.revisions?.items.find((revision) => revision.id === taskRevisionId);
  const [request, setRequest] = useState();

  let clientContactDetails =
    task?.clientContact && client.contacts?.find((contact) => contact.id === task.clientContact);

  let clientContactEmailAddress = clientContactDetails?.email;

  let defaultTo = clientContactEmailAddress
    ? JSON.stringify([
        {
          type: "paragraph",
          children: [{ text: clientContactEmailAddress }],
        },
      ])
    : undefined;

  const [keyForToInput, setKeyForToInput] = useState(0);
  const [to, setTo] = useState(defaultTo);
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [extraMessage, setExtraMessage] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [attachmentDates, setAttachmentDates] = useState();
  const [attachmentSizes, setAttachmentSizes] = useState();
  const [isAttachmentModalVisible, setIsAttachmentModalVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const requestFormActivityItem = getDetailsForFormAndTaskRevision({
    activityItemsByRequest: activityItemsByRequest,
    taskRevisionId,
  });

  let requestId = requestFormActivityItem?.parentId;

  if (!requestId && task.requestIds && task.requestIds.length > 0) {
    requestId = task.requestIds.slice(-1)[0];
  }

  useEffect(() => {
    async function fetchAndSetRequest() {
      let request = (
        await callGraphQLSimple({
          query: "getRequest",
          variables: { id: requestId },
          message: `Failed to fetch ${getSimpleLabel("request")} details`,
        })
      ).data.getRequest;
      setRequest(request);
      let defaultTo = request?.requestedBy
        ? JSON.stringify([
            {
              type: "paragraph", // Set this to any block type you need
              children: [
                { text: request.requestedBy }, // The string as a text node within the block
              ],
            },
          ])
        : undefined;
      if (request?.requestedBy) {
        setTo(defaultTo);
        setKeyForToInput((prev) => prev + 1);
      }
      setIsLoading(false);
    }
    if (requestId) {
      fetchAndSetRequest();
    } else {
      setIsLoading(false);
    }
  }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return null;
  }

  if (organisationDetails.settings?.task?.needsRequestForTaskRevisionReview && !requestId) {
    antMessage.error(
      `This ${getSimpleLabel("task revision")} requires a ${getSimpleLabel("request")} form before it can be sent.`
    );
    return null;
  }

  async function onSubmit() {
    const messageKey = "send-task-files";
    try {
      let toEmails = [];
      let ccEmails = [];
      let bccEmails = [];

      try {
        const parsedTo = JSON.parse(to);
        toEmails = extractEmailsFromSlateValue(parsedTo);
      } catch (e) {
        toEmails = to.split(" ");
      }

      try {
        const parsedCC = JSON.parse(cc);
        ccEmails = extractEmailsFromSlateValue(parsedCC);
      } catch (e) {
        ccEmails = cc.split(" ");
      }

      try {
        const parsedBCC = JSON.parse(bcc);
        bccEmails = extractEmailsFromSlateValue(parsedBCC);
      } catch (e) {
        bccEmails = bcc.split(" ");
      }

      antMessage.loading({ content: `Sending email...`, key: messageKey, duration: 0 });

      await recordContacts({
        contacts: [...toEmails, ...ccEmails, ...bccEmails],
        parentId: apiUser.organisation,
      });

      let newRequestActivityItem;
      if (requestId) {
        const review = (
          await callGraphQLSimple({
            message: "Failed to create review",
            mutation: "createReview",
            variables: {
              input: {
                organisation: apiUser.organisation,
                reviewThread: [],
                approvedItems: [],
              },
            },
          })
        ).data.createReview;
        newRequestActivityItem = (
          await callGraphQLSimple({
            mutation: "createActivityItem",
            message: "Failed to record activity item",
            variables: {
              input: {
                parentId: requestId,
                author: apiUser.id,
                content: JSON.stringify({
                  type: "TASK_FILES_SENT",
                  parentType: "REQUEST",
                  taskRevisionId,
                  to: toEmails.join(" "),
                  cc: ccEmails.join(" "),
                  bcc: bccEmails.join(" "),
                  attachments,
                  taskId: task.id,
                  taskTitle: task.title,
                  projectTitle: task.project.title,
                  taskRevisionName: taskRevisionDetails.name,
                  formName: requestFormActivityItem?.content?.formName,
                  formFileId: requestFormActivityItem?.content?.formFileId,
                  sheetIds: sheetIdsForSending,
                  reviewId: review.id,
                  publicUrls: publicUrlsForSending.map(({ url, file, fileName, fileKey, sheetConstantId }) => {
                    return {
                      url,
                      draughtHubFileName: file.name,
                      actualFileName: fileName,
                      fileKey,
                      sheetConstantId,
                      fileType: file.type,
                    };
                  }),
                  sharing: {
                    type: "PUBLIC",
                    expiresAt: moment()
                      .add(60 * 60 * 24 * 7, "seconds") // 7 days
                      .toISOString(),
                  },
                }),
                organisation: apiUser.organisation,
              },
            },
          })
        ).data.createActivityItem;
      }

      let contentForTaskActivityItem = {
        type: "TASK_FILES_SENT",
        taskRevisionId,
        to: toEmails.join(" "),
        cc: ccEmails.join(" "),
        bcc: bccEmails.join(" "),
        taskId: task.id,
        taskTitle: task.title,
        projectTitle: task.project.title,
        taskRevisionName: taskRevisionDetails.name,
        formName: requestFormActivityItem?.content?.formName,
        formFileId: requestFormActivityItem?.content?.formFileId,
        sheetIds: sheetIdsForSending,
        extraMessage,
        attachments,
        publicUrls: publicUrlsForSending.map(({ url, file, fileName, fileKey, sheetConstantId }) => {
          return {
            url,
            draughtHubFileName: file.name,
            actualFileName: fileName,
            fileKey,
            sheetConstantId,
            fileType: file.type,
          };
        }),
        sharing: {
          type: "PUBLIC",
          expiresAt: moment()
            .add(60 * 60 * 24 * 7, "seconds") // 7 days
            .toISOString(),
        },
      };

      const newTaskActivityItem = (
        await callGraphQLSimple({
          mutation: "createTaskActivityItem",
          message: "Failed to record activity item",
          variables: {
            input: {
              taskId: task.id,
              author: apiUser.id,
              type: "LIFECYCLE_EVENT",
              organisation: apiUser.organisation,
              content: JSON.stringify(contentForTaskActivityItem),
            },
          },
        })
      ).data.createTaskActivityItem;

      let emailSubject;
      let emailMessage;
      let emailLinkAddress;

      if (requestId) {
        emailSubject = `Design package issued: ${processIdForDisplay(request?.id)} (${trimStringToLength(
          request?.title,
          80
        )}) - ${requestFormActivityItem?.content?.formName}`;
        emailMessage = `${apiUser.firstName} ${apiUser.lastName} has issued a design package for ${processIdForDisplay(
          request?.id
        )} (${request?.title}) - ${requestFormActivityItem?.content?.formName}.`;
        emailLinkAddress = `${window.location.origin}/url/${newRequestActivityItem.id}`;
      } else {
        let taskAndProjectTitles = `${task?.project?.title} - ${task?.title}`;
        emailSubject = `Design package issued: ${trimStringToLength(taskAndProjectTitles, 80)} (${task?.id})`;
        emailMessage = `${apiUser.firstName} ${apiUser.lastName} has issued a design package for ${task?.project?.title} - ${task?.title} (${task?.id}).`;
        emailLinkAddress = `${window.location.origin}/url/${newTaskActivityItem.id}`;
      }

      if (extraMessage && extraMessage.trim() !== "") {
        let extraMessageAsHTML = extraMessage.replace(/\n/g, "<br/>");
        emailMessage += `<br/><br/>Here is a message from ${apiUser.firstName} ${apiUser.lastName}: <br/>${extraMessageAsHTML}`;
      }

      try {
        await sendTaskFilesSentIssuedNotification({
          apiUser,
          to: toEmails,
          cc: ccEmails,
          bcc: bccEmails,
          subject: emailSubject,
          messageContent: emailMessage,
          link: emailLinkAddress,
        });
        antMessage.success({ content: `Email sent`, key: messageKey, duration: 5 });
        await callGraphQLSimple({
          mutation: "updateTaskActivityItem",
          message: "Failed to update activity item",
          variables: {
            input: {
              id: newTaskActivityItem.id,
              content: JSON.stringify({
                ...contentForTaskActivityItem,
                emailSentSuccessfully: true,
              }),
            },
          },
        });
      } catch (e) {
        console.error("error sending email = ", e);
        antMessage.error({ content: `Failed to send email`, key: messageKey, duration: 5 });
        await callGraphQLSimple({
          mutation: "updateTaskActivityItem",
          message: "Failed to update activity item",
          variables: {
            input: {
              id: newTaskActivityItem.id,
              content: JSON.stringify({
                ...contentForTaskActivityItem,
                emailSentSuccessfully: false,
              }),
            },
          },
        });
      }
      onClose();
    } catch (e) {
      antMessage.error({ content: `Failed to send email`, key: messageKey, duration: 5 });
      console.error("error sending email = ", e);
    }
  }

  return (
    <>
      <Modal
        open={true}
        title="Email details"
        onCancel={onClose}
        onOk={onSubmit}
        okText="Send email"
        okButtonProps={{ icon: <SendOutlined /> }}
        className="send-task-files-modal"
      >
        <InfoItem label="From" value={apiUser.id} />
        <InfoItem
          label="To (space-separated email addresses)"
          value={
            <Textarea
              hideToolbar
              minHeight={32}
              defaultValue={to ? JSON.parse(to) : undefined}
              debouncedOnChange={(newValue) => setTo(newValue)}
              suggestions={emailSuggestions}
              key={keyForToInput}
            />
          }
        />
        <InfoItem
          label="CC (space-separated email addresses)"
          value={
            <Textarea
              hideToolbar
              minHeight={32}
              debouncedOnChange={(newValue) => setCc(newValue)}
              suggestions={emailSuggestions}
            />
          }
        />
        <InfoItem
          label="BCC (space-separated email addresses)"
          value={
            <Textarea
              hideToolbar
              minHeight={32}
              debouncedOnChange={(newValue) => setBcc(newValue)}
              suggestions={emailSuggestions}
            />
          }
        />

        <InfoItem
          label="Message (in addition to the standard message)"
          value={
            <Input.TextArea
              autoSize={{ minRows: 2 }}
              value={extraMessage}
              onChange={(e) => setExtraMessage(e.target.value)}
            />
          }
        />

        <InfoItem
          label="Extra attachments"
          inline
          value={
            <Button type="link" onClick={(e) => setIsAttachmentModalVisible(true)}>
              <EditOutlined /> Choose attachments
            </Button>
          }
        />
        {attachments.length > 0 && (
          <div className="selected-attachments-container">
            {attachments.map((attachment) => {
              let attachmentMetadata = [];
              let attachmentSize;
              let attachmentDate;

              if (attachmentSizes) {
                attachmentSize = attachmentSizes[attachment];
              }

              if (attachmentDates) {
                attachmentDate = attachmentDates[attachment];
              }

              if (attachmentSize) {
                attachmentMetadata.push(calculateReadableSize({ size: attachmentSize }));
              }
              if (attachmentDate) {
                attachmentMetadata.push(`updated on ${moment(attachmentDate).format("DD/MM/YYYY")}`);
              }

              return (
                <div key={attachment} className="selected-attachment">
                  {attachment.split("/").pop()} ({attachmentMetadata.join(", ")})
                </div>
              );
            })}
          </div>
        )}
      </Modal>
      {isAttachmentModalVisible && (
        <Modal
          className="send-task-files-attachments-modal"
          open={true}
          title="Attachments"
          onCancel={() => {
            setIsAttachmentModalVisible(false);
          }}
          footer={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button type="primary" onClick={() => setIsAttachmentModalVisible(false)}>
                Done
              </Button>
            </div>
          }
        >
          <Attachments
            task={task}
            project={project}
            apiUser={apiUser}
            selectedItems={attachments}
            onChange={(attachments, dates, sizes) => {
              setAttachments(attachments);
              setAttachmentDates(dates);
              setAttachmentSizes(sizes);
            }}
            isInModal={true}
          />
        </Modal>
      )}
    </>
  );
}
