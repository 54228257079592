// new version of the component, after the refactoring of the form was done

import { useGetSetState } from "react-use";
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { getSimpleLabel } from "common/labels";

import FormInputLabel from "Form/FormInputLabel/FormInputLabel";
import ProjectPicker from "ProjectPicker/ProjectPicker";
import CreateProjectModal from "CreateProjectModal/CreateProjectModal";

import "./FormProjectPicker.scss";

// new version of the component, after the refactoring of the form was done

export default function ProjectPickerForm({
  fieldName,
  fieldData,
  readOnly,
  nestedFieldParams,
  updateFieldValue,
  refreshKey,
  topLevelParams,
}) {
  const {
    contextForComputations: { apiUser },
  } = topLevelParams;
  const [getState, setState] = useGetSetState({
    isCreateProjectModalOpen: false,
  });

  const { isCreateProjectModalOpen } = getState();

  return (
    <div className="input-group" key={`${fieldName}-project-picker-${refreshKey}`}>
      <FormInputLabel fieldData={fieldData} fieldName={fieldName} />
      <div className="form-project-picker">
        <ProjectPicker
          value={fieldData.value}
          disabled={readOnly}
          onChange={(value) => {
            updateFieldValue({
              fieldName,
              value,
              nestedFieldParams,
            });
          }}
        />
        <Button
          type="primary"
          className="create-project"
          onClick={() => setState({ isCreateProjectModalOpen: true })}
          disabled={readOnly}
        >
          <PlusCircleOutlined /> Create {window.innerWidth > 600 && getSimpleLabel("project")}
        </Button>
        {isCreateProjectModalOpen && (
          <CreateProjectModal
            apiUser={apiUser}
            organisation={apiUser.organisation}
            onClose={() => setState({ isCreateProjectModalOpen: false })}
            predefinedFields={{
              clientId: fieldData.predefinedClientId,
            }}
            onSave={(project) => {
              updateFieldValue({
                fieldName,
                value: project.id,
                nestedFieldParams,
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
