import { useEffect, useState, useRef, useCallback } from "react";
import moment from "moment";
import { Storage } from "aws-amplify";
import axios from "axios";
import { Typography, message, Modal, notification } from "antd";
import { useGetSetState } from "react-use";
import { withRouter, useLocation } from "react-router-dom";
import withSubscriptions from "common/withSubscriptions";
import Toolbar, { TOOLS } from "./Toolbar/Toolbar";
import cx from "classnames";
import { debounce } from "lodash";

import { FILE_TYPES_DETAILS, getTemplateFromOrganisation } from "common/shared";
import getS3File from "common/getS3File";
import { callRest } from "common/apiHelpers";
import { isAuthorised } from "common/permissions";
import { initialiseFormulaBasedFields, buildHierarchyKey } from "./renderHelpers";
import { useForceUpdate } from "common/helpers";
import { replaceImageUrlsWithFreshImages } from "./browserOnlyRenderHelpers";
import { regenerateIdsForObjectAndChildren } from "common/sharedTemplateRenderHelpers";
import updateObjectRaw from "./updateObject";
import changeObjectOrderRaw from "./changeObjectOrder";
import addGroupField from "./addGroupField";
import removeDimensionsForSections from "./removeDimensionsForSections";
import removeGroupField from "./removeGroupField";
import moveChildToNewParentRaw from "./moveChildToNewParent";
import createNewObject from "./createNewObject";
import addObjectToParent from "./addObjectToParent";
import duplicateObjectRaw from "./duplicateObject";
import deleteObjectRaw from "./deleteObject";
import findChildAndParent from "./findChildAndParent";
import findAllMatchingObjects from "./findAllMatchingObjects";
import calculateSizes from "./calculateSizes";
import replaceLiveCopyObjects from "./replaceLiveCopyObjects";
import getSizeForAllPages from "./getSizeForAllPages";
import { getSimpleLabel } from "common/labels";

import TemplateEditorCanvas from "./TemplateEditorCanvas/TemplateEditorCanvas";
import ObjectPanel from "./ObjectPanel/ObjectPanel";
import CanvasPanel from "./CanvasPanel/CanvasPanel";
import HierarchyPanel from "./HierarchyPanel/HierarchyPanel";
import FormEditor from "./FormEditor/FormEditor";
import OverallSpinner from "OverallSpinner/OverallSpinner";

import "./TemplateEditorPage.scss";

export function TemplateEditorPage({
  setBoxedLayout,
  setBackground,
  windowWidth,
  windowHeight,
  match,
  history,
  organisationDetails,
  isPreloaderVisible,
  showPreloader,
  hidePreloader,
  setNoScroll,
  apiUser,
}) {
  const forceUpdate = useForceUpdate();
  const location = useLocation();

  const [thereAreUnsavedChanges, setThereAreUnsavedChanges] = useState(false);
  const [activeTool, setActiveTool] = useState();
  const [templateTask, setTemplateTask] = useState();
  const [pdfPreviewData, setPdfPreviewData] = useState();
  const [form, setForm] = useState();
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [s3Versions, setS3Versions] = useState();
  const [s3FormVersions, setS3FormVersions] = useState();
  const [hierarchyDefaultExpandedKeys, setHierarchyDefaultExpandedKeys] = useState([]);
  const [keyForHierarchyRefresh, setKeyForHierarchyRefresh] = useState("0");
  const [targetS3VersionId, setTargetS3VersionId] = useState();

  const [getState, setState] = useGetSetState({
    isFirstInitialisation: true,
    outputTemplate: undefined,
    canvasRefreshKey: 0,
    selectedObjects: [],
    selectedObjectForEditingChildren: undefined,
    templatePdf: undefined,
    templatePdfKey: undefined,
    isLoading: true,

    defaultScale: undefined,
    defaultPosition: undefined,
  });

  const state = getState();

  const isMounted = useRef(false);

  const fileType = match.params.fileType;
  const fileTypeDetails = FILE_TYPES_DETAILS[fileType];

  const [isFormEditorVisible, setIsFormEditorVisible] = useState(fileTypeDetails.isFormOnly);
  const templateId = match.params.templateId;
  const template = getTemplateFromOrganisation({
    organisationDetails,
    templateId,
    fileType,
    includeOldStyleTemplates: false,
  });
  const projectId = `${organisationDetails.id}-TEMPLATES`;
  const taskId = `${projectId}-${template?.id}`;

  const debouncedUpdateObject = useCallback(debounce(updateObject, 100), []);

  useEffect(() => {
    isMounted.current = true;
    setNoScroll(true);

    return () => {
      isMounted.current = false;
      setNoScroll(false);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    loadS3VersionHistory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!template) {
      notification.error({
        message: `Template not found in this organisation: ${organisationDetails.id}`,
        duration: 0,
      });
      return;
    }
  }, [template]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let keys = [];
    if (state.selectedObjectForEditingChildren) {
      keys = [buildHierarchyKey(state.selectedObjectForEditingChildren)];
    }
    setHierarchyDefaultExpandedKeys(keys);
  }, [state.selectedObjectForEditingChildren]);

  // every time the output template changes, we need to update the selected objects to point to the latest version of the output template
  useEffect(() => {
    const { outputTemplate, selectedObjects, selectedObjectForEditingChildren } = getState();

    if (!outputTemplate) {
      return;
    }

    let forceUpdateIsNeeded = false;
    if (selectedObjects && selectedObjects.length > 0) {
      let newSelectedObjects = [];
      selectedObjects.forEach((selectedObject) => {
        let { child } = findChildAndParent(outputTemplate, selectedObject.custom_id);

        if (child) {
          newSelectedObjects.push(child);
        }
      });
      setState({ selectedObjects: newSelectedObjects });
      forceUpdateIsNeeded = true;
    }

    if (selectedObjectForEditingChildren) {
      let { child } = findChildAndParent(outputTemplate, selectedObjectForEditingChildren.custom_id);
      if (child) {
        setState({ selectedObjectForEditingChildren: child });
        forceUpdateIsNeeded = true;
      }
    }

    if (forceUpdateIsNeeded) {
      setTimeout(forceUpdate, 500);
    }
  }, [getState().outputTemplate]);

  useEffect(() => {
    onQueryStringChange();
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (targetS3VersionId === undefined) {
      return targetS3VersionId;
    }
    setBoxedLayout(false);
    setBackground(false);
    initialise();

    window.addEventListener("keyup", onKeyUp);
    window.addEventListener("keydown", onKeyDown);

    return () => {
      setBoxedLayout(true);
      setBackground(true);
      window.removeEventListener("keyup", onKeyUp);
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [targetS3VersionId]); // eslint-disable-line react-hooks/exhaustive-deps

  function refreshHierarchy() {
    setKeyForHierarchyRefresh(`${Date.now()}_${Math.random()}`);
  }

  function setOutputTemplate(newOutputTemplate) {
    let outputTemplateAfterProcessing = addGroupField({ ...newOutputTemplate });
    if (!fileTypeDetails.isDocumentTemplate) {
      outputTemplateAfterProcessing = calculateSizes({ ...outputTemplateAfterProcessing });
    }
    setState({ outputTemplate: outputTemplateAfterProcessing });
  }

  async function loadFonts() {
    let fontLoadPromises = [];
    for (let i = 0; i < organisationDetails.variables.items.length; i++) {
      let variable = organisationDetails.variables.items[i];
      if (variable.type !== "FONT") {
        continue;
      }

      let publicFontFileUrl = await Storage.get(variable.value, {
        download: false,
        cacheControl: "no-cache",
      });

      const font = new FontFace(variable.name, `url(${publicFontFileUrl})`);
      fontLoadPromises.push(font.load());
    }

    const loadedFonts = await Promise.all(fontLoadPromises);
    loadedFonts.forEach((font) => {
      document.fonts.add(font);
    });
  }

  function onQueryStringChange() {
    setS3Versions((s3Versions) => {
      if (s3Versions === undefined) {
        setTimeout(onQueryStringChange, 100);
        return s3Versions;
      }
      const urlParams = new URLSearchParams(window.location.search);
      let versionId = urlParams.get("versionId");
      if (!versionId) {
        versionId = s3Versions[0].VersionId;
      }
      if (!s3Versions.find((x) => x.VersionId === versionId)) {
        notification.error({
          message: (
            <Typography.Text>
              The version you are trying to load is not available. Selected version ID: {versionId}
            </Typography.Text>
          ),
          duration: 0,
        });
        return;
      }
      setTargetS3VersionId(versionId);
      return s3Versions;
    });
  }

  async function initialise() {
    const { isFirstInitialisation } = getState();
    if (isFirstInitialisation) {
      showPreloader();
    }
    let promises = [];

    promises.push(loadFonts());

    if (fileTypeDetails.isDocumentTemplate) {
      promises.push(downloadForm());
    }

    if (!templateTask) {
      promises.push(fetchTemplateTask());
    }

    promises.push(loadOutputTemplate({ isFirstInitialisation }));

    await Promise.all(promises);

    hidePreloader();

    setState({ isFirstInitialisation: false });
  }

  async function loadS3VersionHistory() {
    if (!template || !template.key) {
      return;
    }
    let fileKey = `public/${template.key.split(".")[0]}_annotation.json`;
    const [templateVersionsResponse, formVersionsResponse] = await Promise.all([
      callRest({
        method: "GET",
        route: `/s3-list-versions?prefix=${btoa(fileKey)}`,
        includeCredentials: false,
      }),
      callRest({
        method: "GET",
        route: `/s3-list-versions?prefix=${btoa(`public/${template.key}`)}`,
        includeCredentials: false,
      }),
    ]);

    setS3Versions(
      templateVersionsResponse.Versions.map((version, index) => {
        return {
          ...version,
          index: templateVersionsResponse.Versions.length - index,
        };
      })
    );
    setS3FormVersions(formVersionsResponse.Versions);
    return templateVersionsResponse.Versions;
  }

  async function onKeyDown(e) {
    if (e.repeat) {
      return;
    }

    if (e.key === "c") {
      await handleKeyC(e);
    } else if (e.key === "v") {
      await handleKeyV(e);
    } else if (e.key === "s") {
      await handleKeyS(e);
    } else if (e.key === "p") {
      // await handleKeyP(e);
    } else if (e.key === "d") {
      await handleKeyD(e);
    }
  }

  async function handleKeyC(e) {
    const { selectedObjects } = getState();
    let targetTagName = e.target.tagName;

    if (["INPUT", "TEXTAREA"].includes(targetTagName)) {
      return;
    }
    let userIsCopying = (window.isMac && e.metaKey) || (!window.isMac && e.ctrlKey);
    if (!userIsCopying) {
      return;
    }

    if (!selectedObjects || selectedObjects.length === 0) {
      message.error({
        content: "Nothing to copy",
      });
    } else {
      const firstSelectedObjectWithoutGroupField = removeGroupField({ ...selectedObjects[0] });

      let serialisedSelection = JSON.stringify(firstSelectedObjectWithoutGroupField, null, 2);
      await navigator.clipboard.writeText(serialisedSelection);
      message.success({
        content: "Selection copied to clipboard",
      });
    }
  }

  async function handleKeyV(e) {
    const { selectedObjects, outputTemplate } = getState();
    let targetTagName = e.target.tagName;

    if (["INPUT", "TEXTAREA"].includes(targetTagName)) {
      return;
    }
    let userIsPasting = (window.isMac && e.metaKey) || (!window.isMac && e.ctrlKey);
    if (!userIsPasting) {
      return;
    }
    let parent = outputTemplate;
    if (selectedObjects && selectedObjects.length > 0) {
      parent = selectedObjects[0];

      if (!["section", "page", "chapter"].includes(parent.custom_type)) {
        message.error("You can only paste directly onto the canvas or into a section or page");
        return;
      }
    }

    if (!parent) {
      message.error("No object selected to paste into");
      return;
    }

    let valueToInsert = await navigator.clipboard.readText();

    let parsedValueToInsert;
    try {
      parsedValueToInsert = JSON.parse(valueToInsert);
    } catch (e) {
      message.error("Clipboard does not contain valid content for pasting");
      return;
    }

    let valueToInsertContainsSections = false;
    let valueToInsertContainsPages = false;

    if (Array.isArray(parsedValueToInsert)) {
      for (let i = 0; i < parsedValueToInsert.length; i++) {
        let object = parsedValueToInsert[i];
        if (object.custom_type === "section") {
          valueToInsertContainsSections = true;
        } else if (object.custom_type === "page") {
          valueToInsertContainsPages = true;
        }
      }
    } else {
      if (parsedValueToInsert.custom_type === "section") {
        valueToInsertContainsSections = true;
      } else if (parsedValueToInsert.custom_type === "page") {
        valueToInsertContainsPages = true;
      }
    }

    if (valueToInsertContainsSections) {
      if (!parent.custom_type && fileTypeDetails.isDocumentTemplate) {
        message.error("You cannot paste a section directly inside the canvas. It needs to be part of a page");
        return;
      }
    } else if (valueToInsertContainsPages) {
      if (parent.custom_type !== "chapter" && parent.custom_type) {
        message.error("You can only paste pages directly inside the document or inside a chapter");
        return;
      }
    }

    if (Array.isArray(parsedValueToInsert)) {
      for (let i = 0; i < parsedValueToInsert.length; i++) {
        regenerateIdsForObjectAndChildren(parsedValueToInsert[i]);
      }
    } else {
      regenerateIdsForObjectAndChildren(parsedValueToInsert);
    }

    const updatedOutputTemplate = addObjectToParent({
      currentObject: { ...outputTemplate },
      parentId: parent.custom_id,
      child: parsedValueToInsert,
    });

    setOutputTemplate(updatedOutputTemplate);
  }

  async function handleKeyS(e) {
    let userIsSaving = (window.isMac && e.metaKey) || (!window.isMac && e.ctrlKey);
    if (!userIsSaving) {
      return;
    }
    e.preventDefault();

    await save();
  }

  // async function handleKeyP(e) {
  //   let activeSelection = canvas.getActiveObject();

  //   // user wants to print the active selection to the console
  //   if ((window.isMac && e.metaKey) || (!window.isMac && e.ctrlKey)) {
  //     let targetToPrint;
  //     if (activeSelection) {
  //       targetToPrint = canvas.getActiveObjects()[0];
  //       console.log(
  //         JSON.stringify(
  //           {
  //             custom_name: targetToPrint.custom_name,
  //             type: targetToPrint.type,
  //             width: targetToPrint.width,
  //             height: targetToPrint.height,
  //             top: targetToPrint.top,
  //             left: targetToPrint.left,
  //           },
  //           null,
  //           2
  //         )
  //       );
  //     } else {
  //       targetToPrint = canvas.toJSON(getCustomPropertyNamesForExport(canvas));
  //       console.log(JSON.stringify(targetToPrint, null, 2));
  //     }
  //     e.preventDefault();
  //   }
  // }

  async function handleKeyD(e) {
    const { selectedObjects } = getState();

    let targetTagName = e.target.tagName;

    if (["INPUT", "TEXTAREA"].includes(targetTagName)) {
      return;
    }
    e.preventDefault();
    let userIsHoldingDownCmdCtrl = (window.isMac && e.metaKey) || (!window.isMac && e.ctrlKey);
    if (!userIsHoldingDownCmdCtrl) {
      return;
    }

    if (!selectedObjects || selectedObjects.length === 0) {
      message.error("Nothing to duplicate");
      return;
    }

    duplicateObject({ object: selectedObjects[0], isLiveCopy: e.shiftKey });
  }

  function onKeyUp(e) {
    const { selectedObjects } = getState();

    let multiplier = 1;
    if (e.shiftKey === true) {
      multiplier = 5;
    }
    if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
      return;
    }
    if (e.key === "Backspace" || e.key === "Delete") {
      if (selectedObjects && selectedObjects.length > 0) {
        deleteObject({ object: selectedObjects[0] });
      }
    } else if (e.key === "ArrowUp") {
      moveObjects({ axis: "top", amount: -1 * multiplier });
    } else if (e.key === "ArrowDown") {
      moveObjects({ axis: "top", amount: 1 * multiplier });
    } else if (e.key === "ArrowLeft") {
      moveObjects({ axis: "left", amount: -1 * multiplier });
    } else if (e.key === "ArrowRight") {
      moveObjects({ axis: "left", amount: 1 * multiplier });
    }
  }

  function moveObjects({ axis, amount }) {
    const { selectedObjects } = getState();
    if (!selectedObjects || selectedObjects.length === 0) {
      return;
    }

    updateObject({
      objectIds: selectedObjects[0].custom_id,
      fields: {
        [axis]: selectedObjects[0][axis] + amount,
      },
    });
  }

  async function fetchTemplateTask() {
    const newTemplateTask = (
      await window.callGraphQLSimple({
        message: `Failed to fetch template ${getSimpleLabel("task")}`,
        queryCustom: "getTaskWithFiles",
        variables: {
          id: taskId,
        },
      })
    ).data.getTask;
    setTemplateTask(newTemplateTask);

    if (!fileTypeDetails.isDocumentTemplate) {
      let pdfTemplateBlob;

      try {
        let pdfTemplateKey;
        try {
          pdfTemplateKey = `${organisationDetails.id}/templates/${fileType}/${template.id}.pdf`;
          pdfTemplateBlob = (
            await Storage.get(pdfTemplateKey, {
              download: true,
              cacheControl: "no-cache",
            })
          ).Body;
        } catch (e) {
          pdfTemplateKey = `${organisationDetails.id}/templates/${fileType}/${template.id}_0.pdf`;
          pdfTemplateBlob = (
            await Storage.get(pdfTemplateKey, {
              download: true,
              cacheControl: "no-cache",
            })
          ).Body;
        }

        if (pdfTemplateBlob) {
          const pdfTemplate = await new Response(pdfTemplateBlob).arrayBuffer();
          setState({
            templatePdf: pdfTemplate,
            templatePdfKey: pdfTemplateKey,
          });
        }
      } catch (e) {
        message.error({
          content: "Failed to download template PDF",
        });
      }
    }
  }

  async function loadOutputTemplate({ isFirstInitialisation }) {
    let outputTemplate;

    try {
      const outputTemplateFilePublicUrl = await getS3File(
        `public/${template.key.split(".")[0]}_annotation.json`,
        targetS3VersionId
      );
      const outputTemplateFileBlob = (
        await axios({
          url: outputTemplateFilePublicUrl,
          method: "GET",
          responseType: "blob",
        })
      ).data;

      outputTemplate = JSON.parse(await outputTemplateFileBlob.text());
      await replaceImageUrlsWithFreshImages(outputTemplate);
      await initialiseFormulaBasedFields(outputTemplate);

      try {
        if (outputTemplate.objects) {
          outputTemplate.objects.forEach((object) => {
            if (object.custom_type === "page") {
              object.objects = object.objects.filter((x) => x.custom_type !== "page_background");
            }
          });
        }
      } catch (e) {
        console.error("Failed to remove page backgrounds: ", e);
        throw e;
      }
    } catch (e) {
      message.error("Failed to download and initialise template");
      console.error("Failed to download annotation template: ", e);
    }
    if (outputTemplate) {
      let outputTemplateAfterProcessing = addGroupField({ ...outputTemplate });
      outputTemplateAfterProcessing = removeDimensionsForSections({ ...outputTemplateAfterProcessing });
      outputTemplateAfterProcessing.custom_id = "root";

      setOutputTemplate(outputTemplateAfterProcessing);

      if (isFirstInitialisation) {
        calculateDefaultScaleAndPosition(outputTemplateAfterProcessing);
      }
    }
  }

  async function calculateDefaultScaleAndPosition(outputTemplate, tryCount) {
    setTimeout(async () => {
      let defaultScale = 1;
      let defaultPosition = { x: 0, y: 0 };

      const canvasContainer = document.querySelector(".canvas-zoomable-container");
      let pageCanvasElement;

      if (!fileTypeDetails.isDocumentTemplate) {
        pageCanvasElement =
          document.querySelector(".react-pdf__Page__svg") || document.querySelector(".react-pdf__Page__canvas");
      }

      let allDependenciesArePresent;
      if (fileTypeDetails.isDocumentTemplate) {
        allDependenciesArePresent = !!canvasContainer;
      } else {
        allDependenciesArePresent = !!canvasContainer && !!pageCanvasElement;
      }

      if (!allDependenciesArePresent) {
        if (tryCount > 10) {
          message.error("Failed to load template");
          return;
        } else {
          setTimeout(() => {
            calculateDefaultScaleAndPosition(outputTemplate, tryCount + 1);
          }, 100);
          return;
        }
      }

      const canvasBounds = canvasContainer.getBoundingClientRect();

      let margin = 0; // Margin in pixels

      if (fileTypeDetails.isDocumentTemplate) {
        margin = 30; // Margin in pixels
        const { width, height } = getSizeForAllPages(outputTemplate.objects);

        // Adjust the canvas bounds to account for the margin
        const adjustedCanvasWidth = canvasBounds.width - 2 * margin;
        const adjustedCanvasHeight = canvasBounds.height - 2 * margin;

        // Calculate scale based on both width and height with margin
        const scaleX = adjustedCanvasWidth / width;
        const scaleY = adjustedCanvasHeight / height;
        defaultScale = Math.min(scaleX, scaleY); // Choose the smaller scale to fit the content within the canvas with margin

        // Calculate default position with margin

        defaultPosition.x =
          width * defaultScale < adjustedCanvasWidth
            ? (adjustedCanvasWidth - width * defaultScale) / 2 + margin
            : margin;
        defaultPosition.y =
          height * defaultScale < adjustedCanvasHeight
            ? (adjustedCanvasHeight - height * defaultScale) / 2 + margin
            : margin;
      } else {
        margin = 5;
        const adjustedCanvasWidth = canvasBounds.width - 2 * margin;
        const adjustedCanvasHeight = canvasBounds.height - 2 * margin;

        const pageCanvasBounds = pageCanvasElement.getBoundingClientRect();
        const scaleX = adjustedCanvasWidth / pageCanvasBounds.width;
        const scaleY = adjustedCanvasHeight / pageCanvasBounds.height;
        defaultScale = Math.min(scaleX, scaleY); // Choose the smaller scale to fit the content within the canvas with margin

        // Calculate default position with margin
        defaultPosition.x =
          pageCanvasBounds.width * defaultScale < adjustedCanvasWidth
            ? (adjustedCanvasWidth - pageCanvasBounds.width * defaultScale) / 2 + margin
            : margin;
        defaultPosition.y =
          pageCanvasBounds.height * defaultScale < adjustedCanvasHeight
            ? (adjustedCanvasHeight - pageCanvasBounds.height * defaultScale) / 2 + margin
            : margin;
      }

      setState({
        defaultScale,
        defaultPosition,
      });

      setTimeout(() => {
        setState({ isLoading: false });
      }, 100);
    }, 100);
  }

  async function onToolClick(toolName) {
    switch (toolName) {
      case TOOLS.UPLOAD:
        await save();
        break;
      default:
        const { outputTemplate } = getState();
        if (selectedObjects && selectedObjects.length > 1) {
          message.error("Please select only one object before attempting to add a new object");
          return;
        }
        let parent = outputTemplate;
        if (selectedObjects && selectedObjects.length > 0) {
          parent = selectedObjects[0];
        }
        const newObject = createNewObject({
          toolName,
          fileTypeDetails,
          parent,
        });
        if (newObject) {
          const updatedOutputTemplate = addObjectToParent({
            currentObject: { ...outputTemplate },
            parentId: parent.custom_id,
            child: newObject,
          });
          setOutputTemplate(updatedOutputTemplate);
        }
        break;
    }
  }

  async function save(params) {
    const { isRestore } = params || {};
    if (!isRestore && isReadOnly()) {
      message.error("Cannot save while in read-only mode");
      return;
    }

    let messageKey = "save-message";
    if (!isRestore) {
      message.loading({
        content: "Saving template...",
        key: messageKey,
        duration: 0,
      });
    } else {
      message.loading({
        content: "Restoring template version...",
        key: messageKey,
        duration: 0,
      });
    }

    let templateHasChangedSinceOpening = false;

    if (!isRestore) {
      const allS3VersionsInTheCloud = await loadS3VersionHistory();
      const latestS3VersionInTheCloud = allS3VersionsInTheCloud[0];
      if (latestS3VersionInTheCloud.VersionId !== targetS3VersionId) {
        templateHasChangedSinceOpening = true;
      }

      if (templateHasChangedSinceOpening) {
        try {
          await new Promise((resolve) => setTimeout(resolve, 300));
          message.destroy();
          await new Promise((resolve, reject) => {
            Modal.confirm({
              title: "Template has changed elsewhere",
              content: (
                <>
                  There is a newer version of this template available.
                  <br />
                  Would you like your changes to override the newer version?
                </>
              ),
              okText: "Yes, override",
              cancelText: "No, cancel",
              onOk: async () => {
                resolve();
              },
              onCancel: () => {
                reject();
              },
            });
          });
          message.loading({
            content: "Saving template...",
            key: messageKey,
            duration: 0,
          });
        } catch (e) {
          // user has declined to save
          return;
        }
      }
    }

    const { outputTemplate } = getState();

    const outputTemplateForExport = removeGroupField({ ...outputTemplate });

    const exportedData = JSON.stringify(outputTemplateForExport, null, 2);

    try {
      await Storage.put(`${template.key.split(".")[0]}_annotation.json`, exportedData);
      if (!isRestore) {
        message.success({ content: "Saved", key: messageKey, duration: 2 });
      } else {
        message.success({ content: "Template version restored", key: messageKey, duration: 2 });
      }
      setThereAreUnsavedChanges(false);
      const newVersions = await loadS3VersionHistory();
      let newPath = `${window.location.pathname}?versionId=${newVersions[0].VersionId}`;
      history.push(newPath);
    } catch (e) {
      if (!isRestore) {
        message.error({
          content: "Failed to save template",
          key: messageKey,
          duration: 10,
        });
      } else {
        message.error({
          content: "Failed to restore template version",
          key: messageKey,
          duration: 10,
        });
      }
    }
  }

  async function downloadForm() {
    const templateS3Version = s3Versions.find((x) => x.VersionId === targetS3VersionId);
    let formTemplateVersion = s3FormVersions.find((x) => x.LastModified < templateS3Version.LastModified);
    const s3FormVersionId = formTemplateVersion?.id || s3FormVersions[0].id;
    const formPublicUrl = await getS3File(`public/${template.key}`, s3FormVersionId);
    const formBlob = (
      await axios({
        url: formPublicUrl,
        method: "GET",
        responseType: "blob", // Important
      })
    ).data;

    const form = JSON.parse(await formBlob.text());
    setForm(form);
  }

  async function restoreTemplateVersion(versionDetails) {
    Modal.confirm({
      title: "Confirm restore",
      content: (
        <>
          Are you sure you want to restore version <b>{versionDetails.index}</b> from{" "}
          <b>{moment(versionDetails.LastModified).format("DD/MM/YYYY HH:mm:ss")}</b>? <br />
          This will not override existing versions, but will instead copy the desired version on top of everything else.
        </>
      ),
      onOk: async () => {
        save({ isRestore: true });
      },
    });
  }

  function recordChange() {
    setThereAreUnsavedChanges(true);
  }

  function isReadOnly() {
    if (!template) {
      return false;
    }
    if (
      (!apiUser.isHidden && !isAuthorised(["EDIT_LIVE_TEMPLATES"]) && template?.isLive) ||
      !isViewingLatestVersion() ||
      isPreviewVisible
    ) {
      return true;
    }

    return false;
  }

  function isViewingLatestVersion() {
    if (!targetS3VersionId || !s3Versions) {
      return false;
    }
    return targetS3VersionId === s3Versions[0].VersionId;
  }

  function getDisabledTools() {
    let disabledTools = [];

    if (template.outputType !== "APP_PAGE") {
      disabledTools.push(TOOLS.COMPONENT);
    }
    if (
      !fileTypeDetails.isPartOfATask ||
      (fileType === "REPORT" && (template.outputType !== "PDF" || template.parentType !== "TASK"))
    ) {
      disabledTools.push(TOOLS.QR_CODE);
    }
    if (!fileTypeDetails.isDocumentTemplate) {
      disabledTools.push(TOOLS.PAGE, TOOLS.SIGNATURE, TOOLS.DYNAMIC_FILE, TOOLS.CHAPTER);
    }
    if (isReadOnly()) {
      disabledTools.push(TOOLS.UPLOAD);
    }
    return disabledTools;
  }

  function displayObjectPanel() {
    const { templatePdfKey, outputTemplate } = getState();
    if (!selectedObjects || selectedObjects.length === 0) {
      return (
        <CanvasPanel
          template={template}
          templateTask={templateTask}
          organisationDetails={organisationDetails}
          form={form}
          s3Versions={s3Versions}
          targetS3VersionId={targetS3VersionId}
          fileType={fileType}
          windowHeight={windowHeight}
          onToolClick={onToolClick}
          onPdfPreviewLoaded={(pdfData) => {
            setPdfPreviewData(pdfData);
          }}
          pdfPreviewData={pdfPreviewData}
          setIsPreviewVisible={setIsPreviewVisible}
          isPreviewVisible={isPreviewVisible}
          fileTypeDetails={fileTypeDetails}
          showFormEditor={() => setIsFormEditorVisible(true)}
          hideFormEditor={() => setIsFormEditorVisible(false)}
          recordChange={recordChange}
          history={history}
          restoreTemplateVersion={restoreTemplateVersion}
          templatePdfKey={templatePdfKey}
          isFormEditorVisible={isFormEditorVisible}
          outputTemplate={outputTemplate}
          setOutputTemplate={setOutputTemplate}
          forceUpdate={forceUpdate}
          updateObject={updateObject}
        />
      );
    }

    return (
      <ObjectPanel
        key={`${selectedObjects.map((x) => x.custom_id).join("_")}`}
        organisationDetails={organisationDetails}
        template={template}
        outputTemplate={outputTemplate}
        selectedObjects={selectedObjects}
        fileTypeDetails={fileTypeDetails}
        form={form}
        recordChange={recordChange}
        isReadOnly={isReadOnly()}
        updateObject={debouncedUpdateObject}
        changeObjectOrder={changeObjectOrder}
        setSelectedObjects={(objects) => {
          setState({ selectedObjects: objects });
        }}
        duplicateObject={duplicateObject}
        deleteObject={deleteObject}
      />
    );
  }

  function duplicateObject({ object, isLiveCopy }) {
    const { updatedOutputTemplate, newObject } = duplicateObjectRaw({
      object,
      outputTemplate: getState().outputTemplate,
      isLiveCopy,
    });
    const newObjectsInUpdatedOutputTemplate = findAllMatchingObjects(
      updatedOutputTemplate,
      (object) => object.custom_id === newObject.custom_id
    );
    setOutputTemplate(updatedOutputTemplate);
    setTimeout(() => {
      if (newObjectsInUpdatedOutputTemplate) {
        setState({
          selectedObjects: newObjectsInUpdatedOutputTemplate,
          selectedObjectForEditingChildren: newObjectsInUpdatedOutputTemplate[0],
        });
      }
    }, 300);
  }

  function deleteObject({ object }) {
    const { outputTemplate } = getState();
    const updatedOutputTemplate = deleteObjectRaw({
      objectId: object.custom_id,
      outputTemplate,
    });
    setOutputTemplate(updatedOutputTemplate);
  }

  function updateObject(params) {
    const { outputTemplate } = getState();
    // debugger;
    const updatedOutputTemplate = updateObjectRaw({
      ...params,
      outputTemplate,
    });
    // let { child } = findChildAndParent(updatedOutputTemplate, params.objectIds ? params.objectIds[0] : params.objectId);

    setOutputTemplate(updatedOutputTemplate);
  }

  function changeObjectOrder(params) {
    const { outputTemplate } = getState();
    const updatedOutputTemplate = changeObjectOrderRaw({
      ...params,
      outputTemplate,
    });
    setOutputTemplate(updatedOutputTemplate);
  }

  function moveChildToNewParent(params) {
    const { outputTemplate } = getState();
    const updatedOutputTemplate = moveChildToNewParentRaw({
      ...params,
      outputTemplate,
    });
    setOutputTemplate(updatedOutputTemplate);
  }

  if (!s3Versions || !targetS3VersionId) {
    return <OverallSpinner />;
  }

  const {
    canvasRefreshKey,
    selectedObjects,
    selectedObjectForEditingChildren,
    outputTemplate,
    templatePdf,
    defaultScale,
    defaultPosition,
    isLoading,
  } = getState();

  let outputTemplateWithLiveCopy = outputTemplate;
  if (outputTemplateWithLiveCopy) {
    replaceLiveCopyObjects(outputTemplateWithLiveCopy, outputTemplate);
  }

  let targetS3VersionDetails = s3Versions.find((x) => x.VersionId === targetS3VersionId);

  return (
    <div
      className={cx("template-editor-page", {
        "spreadsheet-output": template.outputType?.includes("SPREADSHEET"),
        "form-only": fileTypeDetails.isFormOnly,
        "form-editor-open": isFormEditorVisible,
        invisible: isLoading,
      })}
    >
      {fileTypeDetails.isDocumentTemplate && form && (
        <FormEditor
          apiUser={apiUser}
          visible={isFormEditorVisible}
          form={form}
          template={template}
          templateTask={templateTask}
          organisationDetails={organisationDetails}
          fileType={fileType}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
          isReadOnly={isReadOnly()}
          fileTypeDetails={fileTypeDetails}
          onFormChange={(newForm) => {
            setForm(newForm);
          }}
          onClose={() => {
            setIsFormEditorVisible(false);
            downloadForm();
          }}
        />
      )}

      <div className={cx("output-editor")}>
        <Toolbar
          activeTool={activeTool}
          setActiveTool={setActiveTool}
          onClick={onToolClick}
          isPreviewVisible={isPreviewVisible}
          disabledTools={getDisabledTools()}
          thereAreUnsavedChanges={thereAreUnsavedChanges}
        />

        <HierarchyPanel
          key={keyForHierarchyRefresh}
          fileTypeDetails={fileTypeDetails}
          isPreviewVisible={isPreviewVisible}
          defaultExpandedKeys={hierarchyDefaultExpandedKeys}
          outputTemplate={outputTemplate}
          selectedObjects={selectedObjects}
          setSelectedObjects={(objects) => {
            setState({ selectedObjects: objects });
          }}
          setSelectedObjectForEditingChildren={(object) => {
            setState({ selectedObjectForEditingChildren: object });
          }}
          updateObject={debouncedUpdateObject}
          changeObjectOrder={changeObjectOrder}
          moveChildToNewParent={moveChildToNewParent}
        />

        {outputTemplate && (
          <TemplateEditorCanvas
            visible={fileType !== "REPORT" || !template.outputType?.includes("SPREADSHEET")}
            fileTypeDetails={fileTypeDetails}
            fileType={fileType}
            outputTemplate={outputTemplate}
            zoomableRefreshKey={canvasRefreshKey}
            selectedObjects={selectedObjects}
            setSelectedObjects={(objects) => {
              setState({ selectedObjects: objects });
            }}
            selectedObjectForEditingChildren={selectedObjectForEditingChildren}
            setSelectedObjectForEditingChildren={(object) => {
              setState({ selectedObjectForEditingChildren: object });
              setTimeout(() => {
                refreshHierarchy();
              }, 100);
            }}
            templatePdf={templatePdf}
            pdfPreviewData={pdfPreviewData}
            isViewingLatestVersion={isViewingLatestVersion()}
            isPreviewVisible={isPreviewVisible}
            template={template}
            targetS3VersionDetails={targetS3VersionDetails}
            s3Versions={s3Versions}
            apiUser={apiUser}
            updateObject={updateObject}
            defaultScale={defaultScale}
            defaultPosition={defaultPosition}
            organisationDetails={organisationDetails}
          />
        )}

        {displayObjectPanel()}
      </div>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TemplateEditorPage,
    subscriptions: ["organisationDetails"],
  })
);
