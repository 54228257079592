import React from "react";
import { Typography, Tag } from "antd";

function FieldLabel({ fieldData, fieldName, isFormEditor, validationErrors }) {
  let validationErrorsForField = (validationErrors || []).filter(
    (validationError) => validationError.fieldKey === fieldName
  );

  let label = fieldData.label ? fieldData.label : fieldName;

  if (isFormEditor && fieldData.isForLineItems) {
    label = (
      <>
        <Tag color="blue">Line items field</Tag>
        {label}
      </>
    );
  }

  let validationErrorsElements = null;
  if (validationErrorsForField && validationErrorsForField.length > 0) {
    validationErrorsElements = (
      <ul className="validation-errors-for-field">
        {validationErrorsForField.map((validationError) => {
          return (
            <li>
              <Typography.Text type="danger">{validationError.errorMessage}</Typography.Text>
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <Typography.Text className="field-label-inner" data-field-name={fieldName}>
      {validationErrorsElements}
      {fieldData.isMandatory ? <Typography.Text className="mandatory-marker">*</Typography.Text> : null}
      {label}
    </Typography.Text>
  );
}

export default React.memo(FieldLabel, () => {
  return true;
});
