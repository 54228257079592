import React from "react";
import { Checkbox } from "antd";

import FormInputLabel from "Form/FormInputLabel/FormInputLabel";

function FormCheckbox({ fieldData, fieldName, nestedFieldParams, readOnly, updateFieldValue, refreshKey }) {
  return (
    <div className="input-group inline-label" key={`${fieldName}-checkbox-${refreshKey}`}>
      <FormInputLabel fieldData={fieldData} fieldName={fieldName} />

      <Checkbox
        disabled={readOnly}
        onChange={(e) => {
          updateFieldValue({
            fieldName,
            value: e.target.checked,
            nestedFieldParams,
          });
        }}
        defaultChecked={fieldData.value}
      />
    </div>
  );
}

export default React.memo(FormCheckbox, (prevProps, nextProps) => {
  const PROPS_TO_MONITOR = ["readOnly", "refreshKey", "validationErrors", "hiddenFormFieldKeys"];
  for (let propName of PROPS_TO_MONITOR) {
    if (prevProps.topLevelParams[propName] !== nextProps.topLevelParams[propName]) {
      return false;
    }
  }

  return true;
});
